import { Box, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const SemiAutoRateUpdateData = ({themeMode, LineLink}) => {

  const flooz         = "../../images/flooz.png"
  const om            = "../../images/om.png"
  const momo          = "../../images/momo.png"



  const [SemiOmChargeStop, setSemiOmChargeStop] = useState(false)
  const [NT_ChargeSemiOm, setNT_ChargeSemiOm] = useState([])

  const [SemiMomoChargeStop, setSemiMomoChargeStop] = useState(false)
  const [NT_ChargeSemiMomo, setNT_ChargeSemiMomo] = useState([])

  const [SemiFloozChargeStop, setSemiFloozChargeStop] = useState(false)
  const [NT_ChargeSemiFlooz, setNT_ChargeSemiFlooz] = useState([])

  const [SemiOmModifCharge, setSemiOmModifCharge] = useState(false)
  const [SemiMomoModifCharge, setSemiMomoModifCharge] = useState(false)
  const [SemiFloozModifCharge, setSemiFloozModifCharge] = useState(false)

  const [ModifSemi, setModifSemi] = useState(false)
  const [ModifSemiOm, setModifSemiOm] = useState(false)
  const [ModifSemiMomo, setModifSemiMomo] = useState(false)
  const [ModifSemiFlooz, setModifSemiFlooz] = useState(false)

  const [SemiOmHandle, setSemiOmHandle] = useState()
  const [SemiMomoHandle, setSemiMomoHandle] = useState()
  const [SemiFloozHandle, setSemiFloozHandle] = useState()

  function customChargeSemiOmHandler(){ setSemiOmModifCharge(!SemiOmModifCharge) }
  function customChargeSemiMomoHandler(){ setSemiMomoModifCharge(!SemiMomoModifCharge) }
  function customChargeSemiFloozHandler(){ setSemiFloozModifCharge(!SemiFloozModifCharge) }

  const semiOmHandleChange = ({target: {value}}) => {
    setSemiOmHandle(value)
  }

  const semiMomoHandleChange = ({target: {value}}) => {
    setSemiMomoHandle(value)
  }

  const semiFloozHandleChange = ({target: {value}}) => {
    setSemiFloozHandle(value)
  }
  
  const validateSemiOmHandler = () => {

      setModifSemiOm(false)

      if(SemiOmHandle !== ""){

          try {
  
              axios.post('/api/update-semiom', 
                  {
                      ID: 1,
                      semiom: Number(SemiOmHandle),
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/tarification");
                          } else {
                          window.location.replace("http://localhost:3000/tarification");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateSemiMomoHandler = () => {

      setModifSemiMomo(false)

      if(SemiMomoHandle !== ""){

          try {
  
              axios.post('/api/update-semimomo', 
                  {
                      ID: 1,
                      semimomo: Number(SemiMomoHandle),
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/tarification");
                          } else {
                          window.location.replace("http://localhost:3000/tarification");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

 
  const validateSemiFloozHandler = () => {

      setModifSemiFlooz(false)

      if(SemiFloozHandle !== ""){

          try {
  
              axios.post('/api/update-semiflooz', 
                  {
                      ID: 1,
                      semiflooz: Number(SemiFloozHandle),
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/tarification");
                          } else {
                          window.location.replace("http://localhost:3000/tarification");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SemiOmChargeStop){

              try {
                  const res = await axios.get('/api/semiom')

                  if(res.data[0].ID){
                      setNT_ChargeSemiOm(res.data[0].semiom)
                      setSemiOmChargeStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SemiOmChargeStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SemiMomoChargeStop){

              try {
                  const res = await axios.get('/api/semimomo')

                  if(res.data[0].ID){
                      setNT_ChargeSemiMomo(res.data[0].semimomo)
                      setSemiMomoChargeStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SemiMomoChargeStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SemiFloozChargeStop){

              try {
                  const res = await axios.get('/api/semiflooz')

                  if(res.data[0].ID){
                      setNT_ChargeSemiFlooz(res.data[0].semiflooz)
                      setSemiFloozChargeStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SemiFloozChargeStop])




  return (
    <div className={`${themeMode === "true" ? "row mt-3 final-seesion-t" : "row mt-3 final-seesion"}`}>
            <div className='center'>
                <div><span className={`${themeMode === "true" ? "color-or" : ""}`}>Données variantes de l'<strong>option semi-auto</strong></span></div>
                <hr className={`${themeMode === "true" ? "color-or" : ""}`}/>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${om}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Semi auto (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                        {SemiOmModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateSemiOmHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                SemiOmModifCharge ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="semiom" label="X%" name="semiom" autoFocus onChange={semiOmHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customChargeSemiOmHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ChargeSemiOm}</span>
                                </div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Semi auto (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                        {SemiMomoModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateSemiMomoHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                SemiMomoModifCharge ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="semimomo" label="X%" name="semimomo" autoFocus onChange={semiMomoHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customChargeSemiMomoHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ChargeSemiMomo}</span>
                                </div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Semi auto (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                        {SemiFloozModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateSemiFloozHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                SemiFloozModifCharge ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="semiflooz" label="X%" name="semiflooz" autoFocus onChange={semiFloozHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customChargeSemiFloozHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ChargeSemiFlooz}</span>
                                </div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
  )
}

export default SemiAutoRateUpdateData