import React from 'react'
import { Link } from 'react-router-dom'
import ChatCustomer from '../components/ChatCustomer'
import BottomNavBar from '../components/BottomNavBar'

export default function OurCondition(props) {

  var _ProWallet = localStorage.getItem('_ProWallet')

  const LineLink = true

  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
  }
  

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="main-session">
        <div className="buy-item-section-details justify-content-center">
          <div className="d-flex justify-content-center">
            <h1 className="detail-title">Condition Générale</h1>
          </div>
          <div className="d-flex justify-content-center">
            <button className="buy-button-head" style={{borderRadius:'50px'}}>
              <Link
                to="/#oparation=buy"
                style={{
                  color: `${'#0e2a47'}`,
                  textTransform: `${'uppercase'}`,
                }}
              >
                Je veux acheter des crypto-actifs
              </Link>
            </button>
            <button className="sell-button-head" style={{borderRadius:'50px'}}>
              <Link
                to="/#oparation=sel"
                style={{
                  color: `${'#0e2a47'}`,
                  textTransform: `${'uppercase'}`,
                }}
              >
                Je veux vendre mes crypto-actifs
              </Link>
            </button>
          </div>
        </div>
        {/* <div className="alert-head-button-session">
          <div className="alert-head-button">
            Possibilité d'effectuer du transfert d'argent !
          </div>
          <button disabled={true} className="transaction-button-head blink">
            TRANSFERT D'ARGENT
          </button>
        </div> */}
      </div>
      <section>
        <div className="container">
          <div className="w3-container">
            <div className="justify-content-center">
              <div className="up-elment">
                <h1 className="middle-big-text">Nos condition générales</h1>
                <p className="small-text"></p>
              </div>
              <div className="details-section">
                <div className="waring-text">
                  <span>
                    <strong>Conditions générales d'utilisation</strong>
                    <p className="tab1">Mise en vigueur le 03/03/2022</p>
                    <p>
                      Les présentes conditions générales d'utilisation (dites «{' '}
                      <strong>CGU</strong> ») ont pour objet l'encadrement
                      juridique des modalités de mise à disposition du site et
                      des services par NKAB EXCHANGE et de définir les
                      conditions d’accès et d’utilisation des services par « l'
                      <strong>Utilisateur</strong> ».
                    </p>
                    <br />
                    <p>
                      Toute inscription ou utilisation du site implique
                      l'acceptation sans aucune réserve ni restriction des
                      présentes CGU par l’utilisateur. Lors de l'inscription sur
                      le site via le Formulaire d’inscription, chaque
                      utilisateur accepte expressément les présentes CGU en
                      cochant la case précédant le texte suivant : « Je
                      reconnais avoir lu et compris les CGU et je les accepte ».
                    </p>
                    <p>
                      En cas de non-acceptation des CGU stipulées dans le
                      présent contrat, l'Utilisateur se doit de renoncer à
                      l'accès des services proposés par le site.
                    </p>
                    <p>
                      NKAB EXCHANGE se réserve le droit de modifier
                      unilatéralement et à tout moment le contenu des présentes
                      CGU.
                    </p>
                    <br />
                    <p>
                      <strong>Article 1 : Les mentions légales</strong>
                    </p>
                    <p>
                      L'édition du site NKAB EXCHANGE est assurée par la Société
                      SARL NK GROUP TECHNOLOGY.
                    </p>
                    <br />
                    <p>
                      <strong>Article 2 : Accès au site</strong>
                    </p>
                    <p>
                      Le site NKAB EXCHANGE permet à l'Utilisateur un accès
                      gratuit aux services qui sont l'Achat, la Vente et
                      l'Echange de Cryto-actifs.
                    </p>
                    <p>
                      Il est accessible gratuitement en tout lieu à tout
                      Utilisateur ayant un accès à Internet.
                    </p>
                    <p>
                      L’Utilisateur membre (inscrit) s’engage à fournir des
                      informations sincères et exactes concernant sa civilité et
                      ses coordonnées, notamment son adresse email.
                    </p>
                    <p>
                      Pour accéder aux services, l’Utilisateur doit ensuite
                      s'identifier à l'aide de son identifiant et de son mot de
                      passe qui lui seront communiqués après son inscription.
                    </p>
                    <p>
                      Tout Utilisateur membre pourra également solliciter sa
                      désinscription en se rendant à la page dédiée sur son
                      espace personnel. Celle-ci sera effective dans un délai
                      raisonnable.
                    </p>
                    <p>
                      Tout événement dû à un cas de force majeure ayant pour
                      conséquence un dysfonctionnement du site ou serveur et
                      sous réserve de toute interruption ou modification en cas
                      de maintenance, n'engage pas la responsabilité de NKAB
                      EXCHANGE. Dans ces cas, l’Utilisateur accepte ainsi ne pas
                      tenir rigueur à l’éditeur de toute interruption ou
                      suspension de service, même sans préavis.
                    </p>
                    <p>
                      L'Utilisateur a la possibilité de contacter le site par
                      messagerie électronique.
                    </p>
                    <br />
                    <p>
                      <strong>Article 3 : Collecte des données</strong>
                    </p>
                    <p>
                      Le site est exempté de déclaration à la Commission
                      Nationale Informatique dans la mesure où il ne collecte
                      aucune donnée concernant les Utilisateurs.
                    </p>
                    <br />
                    <p>
                      <strong>Article 4 : Propriété intellectuelle</strong>
                    </p>
                    <p>
                      Les marques, logos, signes ainsi que tous les contenus du
                      site (textes, images, son…) font l'objet d'une protection
                      par le Code de la propriété intellectuelle et plus
                      particulièrement par le droit d'auteur.
                    </p>
                    <p>
                      L'Utilisateur doit solliciter l'autorisation préalable du
                      site pour toute reproduction, publication, copie des
                      différents contenus. Il s'engage à une utilisation des
                      contenus du site dans un cadre strictement privé, toute
                      utilisation à des fins commerciales et publicitaires est
                      strictement interdite.
                    </p>
                    <p>
                      Toute représentation totale ou partielle de cette
                      plateforme par quelque procédé que ce soit, sans
                      l’autorisation expresse de l’exploitant de celle-ci
                      constituerait une contrefaçon sanctionnée et suivants du
                      Code de la propriété intellectuelle.
                    </p>
                    <p>
                      Il est rappelé que l’Utilisateur qui reproduit, copie ou
                      publie le contenu protégé doit citer l’auteur et sa
                      source.
                    </p>
                    <br />
                    <p>
                      <strong>Article 5 : Responsabilité</strong>
                    </p>
                    <p>
                      Les sources des informations diffusées sur le site NKAB
                      EXCHANGE sont réputées fiables mais le site ne garantit
                      pas qu’il soit exempt de défauts, d’erreurs ou
                      d’omissions.
                    </p>
                    <p>
                      Les informations communiquées sont présentées à titre
                      indicatif et général sans valeur contractuelle. Malgré des
                      mises à jour régulières, le site NKAB EXCHANGE ne peut
                      être tenu responsable de la modification des dispositions
                      administratives et juridiques survenant après la
                      publication. De même, le site ne peut être tenue
                      responsable de l’utilisation et de l’interprétation de
                      l’information contenue dans ce site.
                    </p>
                    <p>
                      L'Utilisateur s'assure de garder son mot de passe secret.
                      Toute divulgation du mot de passe, quelle que soit sa
                      forme, est interdite. Il assume les risques liés à
                      l'utilisation de son identifiant et mot de passe. Le site
                      décline toute responsabilité.
                    </p>
                    <p>
                      Le site NKAB EXCHANGE ne peut être tenu pour responsable
                      d’éventuels virus qui pourraient infecter l’ordinateur ou
                      tout matériel informatique de l’Internaute, suite à une
                      utilisation, à l’accès, ou au téléchargement provenant de
                      ce site.
                    </p>
                    <p>
                      La responsabilité du site ne peut être engagée en cas de
                      force majeure ou du fait imprévisible et insurmontable
                      d'un tiers.
                    </p>
                    <br />
                    <p>
                      <strong>Article 6 : Liens hypertextes</strong>
                    </p>
                    <p>
                      Des liens hypertextes peuvent être présents sur le site.
                      L’Utilisateur est informé qu’en cliquant sur ces liens, il
                      sortira du site NKAB EXCHANGE. Ce dernier n’a pas de
                      contrôle sur les pages web sur lesquelles aboutissent ces
                      liens et ne saurait, en aucun cas, être responsable de
                      leur contenu.
                    </p>
                    <br />
                    <p>
                      <strong>Article 7 : Cookies</strong>
                    </p>
                    <p>
                      L’Utilisateur est informé que lors de ses visites sur le
                      site, un cookie peut s’installer automatiquement sur son
                      logiciel de navigation.
                    </p>
                    <p>
                      Les cookies sont de petits fichiers stockés temporairement
                      sur le disque dur de l’ordinateur de l’Utilisateur par
                      votre navigateur et qui sont nécessaires à l’utilisation
                      du site NKAB EXCHANGE. Les cookies ne contiennent pas
                      d’information personnelle et ne peuvent pas être utilisés
                      pour identifier quelqu’un. Un cookie contient un
                      identifiant unique, généré aléatoirement et donc anonyme.
                      Certains cookies expirent à la fin de la visite de
                      l’Utilisateur, d’autres restent.
                    </p>
                    <p>
                      L’information contenue dans les cookies est utilisée pour
                      améliorer le site NKAB EXCHANGE.
                    </p>
                    <br />
                    <p>
                      <strong>Article 8 : Publication par l’Utilisateur</strong>
                    </p>
                    <p>
                      Le site permet aux membres de publier les contenus tels
                      que les Témoignages.
                    </p>
                    <p>
                      Dans ses publications, le membre s’engage à respecter les
                      règles de la Netiquette (règles de bonne conduite de
                      l’internet) et les règles de droit en vigueur.
                    </p>
                    <p>
                      Le site peut exercer une modération sur les publications
                      et se réserve le droit de refuser leur mise en ligne, sans
                      avoir à s’en justifier auprès du membre.
                    </p>
                    <p>
                      Le membre reste titulaire de l’intégralité de ses droits
                      de propriété intellectuelle. Mais en publiant une
                      publication sur le site, il cède à la société éditrice le
                      droit non exclusif et gratuit de représenter, reproduire,
                      adapter, modifier, diffuser et distribuer sa publication,
                      directement ou par un tiers autorisé, dans le monde
                      entier, sur tout support (numérique ou physique), pour la
                      durée de la propriété intellectuelle. Le Membre cède
                      notamment le droit d'utiliser sa publication sur internet
                      et sur les réseaux de téléphonie mobile.
                    </p>
                    <p>
                      La société éditrice s'engage à faire figurer le nom du
                      membre à proximité de chaque utilisation de sa
                      publication.
                    </p>
                    <p>
                      Tout contenu mis en ligne par l'Utilisateur est de sa
                      seule responsabilité. L'Utilisateur s'engage à ne pas
                      mettre en ligne de contenus pouvant porter atteinte aux
                      intérêts de tierces personnes. Tout recours en justice
                      engagé par un tiers lésé contre le site sera pris en
                      charge par l'Utilisateur.
                    </p>
                    <p>
                      Le contenu de l'Utilisateur peut être à tout moment et
                      pour n'importe quelle raison supprimé ou modifié par le
                      site, sans préavis.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 9 : Droit applicable et juridiction compétente
                      </strong>
                    </p>
                    <p>
                      La législation s'applique au présent contrat. En cas
                      d'absence de résolution amiable d'un litige né entre les
                      parties, les tribunaux seront seuls compétents pour en
                      connaître.
                    </p>
                    <p>
                      Pour toute question relative à l’application des présentes
                      CGU, vous pouvez joindre l’éditeur.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ChatCustomer />
      <BottomNavBar />

    </div>
  )
}
