import React from 'react'
import './Banner.css'
import { Button } from '@mui/material'

const Banner = () => {

  const LineLink = true
  // const LineLink = false


  return (
    <div className='container mt-5 mb-5'>
        <img className='nkab-exchange-banner' src="../../images/nkab-bannier.jpg" alt="bannière nkab exchange" />
    </div>
  )
}

export default Banner