import React from 'react'
import './notification.css'

export const showErrMessage = (message) => {
    return <div className='errMessage'>
        {message}
    </div>
}

export const showSuccessMessage = (message) => {
    return <div className='successMessage'>
        {message}
    </div>
}
