import React, { useEffect } from 'react'
export default function NextVerificationScreen5(props) {

  var   _ProWallet = localStorage.getItem('_ProWallet')
  const Logged     = localStorage.getItem('userStatus')

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }


  useEffect(()=>{
    if(Logged === 'Logged'){
      window.location.replace("https://nkab-exchange.net/");
    }
  },[Logged])

  function lastsubmitHandler(){
    window.location.replace("https://nkab-exchange.net/profile");
  }

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="color-w signin-session">
        <form className="form form-register">
          <div className="verif-session2">
            <img
              className="register-logo"
              src="../../images/logo.png"
              alt="Logo NKAB Exchange"
            />
          </div>
          <div className="register-form-session">
            <div className="shareImgContainer">
              <img className="shareImg" src='../../images/Merci-Bleu.png' alt="" />
            </div>
                <h5 className='text-center' style={{color:'#0a146e'}}><strong>Félicitation ! Vous venez de terminer toutes les étapes. Veuillez cliquer sur le bouton <strong className='color-or'>" TERMINER "</strong> pour finir l'étape de la vérification. </strong></h5><br/>
            <div>
              <label />
              <span type="submit" className="primary-span" onClick={lastsubmitHandler} >
                Terminer
              </span>
              <br/>
              <br/>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
