import { faArrowAltCircleLeft, faExchangeAlt, faForward, faHome, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import BottomNavBar from '../components/BottomNavBar'
import { useHistory } from 'react-router-dom'

export default function Demo(props) {

    const history  = useHistory()

    var _ProWallet = localStorage.getItem('_ProWallet')

    const [YLink1, setYLink1] = useState('https://www.youtube.com/watch?v=Q89EGPrlldg')
    const [YLink2, setYLink2] = useState('https://www.youtube.com/watch?v=jFFm00zzrgg')
    const [YLink3, setYLink3] = useState('https://www.youtube.com/watch?v=vEEo3GWwvmg')
    const [YLink4, setYLink4] = useState('https://www.youtube.com/watch?v=f9gIl3IFm4A')
    const [YLink5, setYLink5] = useState('https://www.youtube.com/watch?v=EJ_e2uLVdp0')
    const [YLink6, setYLink6] = useState('https://www.youtube.com/watch?v=UjiJzczTbJE')
    const [YLink7, setYLink7] = useState('https://www.youtube.com/watch?v=lq0b2CDagms')
    const [YLink8, setYLink8] = useState('https://www.youtube.com/watch?v=Fqq2TBcjSy4')
    const [YLink9, setYLink9] = useState('https://www.youtube.com/watch?v=zFcWjYYc9cs&t=16s')
    const [YUnicLink, setYUnicLink] = useState('https://www.youtube.com/watch?v=Q89EGPrlldg')

    const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)

    const LineLink = true
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])



    if (_ProWallet === 'true') {
        props.history.push('/nkab-pro')
    }


    function LinkChecked1() {
        setYLink1("https://www.youtube.com/watch?v=Q89EGPrlldg")
        setYUnicLink(YLink1)
    }
    function LinkChecked2() {
        setYLink2("https://www.youtube.com/watch?v=jFFm00zzrgg")
        setYUnicLink(YLink2)
    }
    function LinkChecked3() {
        setYLink3("https://www.youtube.com/watch?v=vEEo3GWwvmg")
        setYUnicLink(YLink3)
    }
    function LinkChecked4() {
        setYLink4("https://www.youtube.com/watch?v=EJ_e2uLVdp0")
        setYUnicLink(YLink4)
    }
    function LinkChecked5() {
        setYLink5("https://www.youtube.com/watch?v=UjiJzczTbJE")
        setYUnicLink(YLink5)
    }
    function LinkChecked6() {
        setYLink6("https://www.youtube.com/watch?v=f9gIl3IFm4A")
        setYUnicLink(YLink6)
    }
    function LinkChecked7() {
        setYLink7("https://www.youtube.com/watch?v=lq0b2CDagms")
        setYUnicLink(YLink7)
    }
    function LinkChecked8() {
        setYLink8("https://www.youtube.com/watch?v=Fqq2TBcjSy4")
        setYUnicLink(YLink8)
    }
    function LinkChecked9() {
        setYLink9("https://www.youtube.com/watch?v=zFcWjYYc9cs&t=16s")
        setYUnicLink(YLink9)
    }

    useEffect(() => {
        setYUnicLink(YUnicLink)
    }, [YUnicLink])
    
    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  
    const transActivated         = localStorage.getItem('transActivated')
    
    const logoutAction = () => {
  
      localStorage.clear();
  
      if(_ProWallet === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", true)
        localStorage.setItem("userStatus", "Logout")

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
      }
      if(_ProWallet === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", false)
        localStorage.setItem("userStatus", "Logout")
      }
      if(transActivated === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("transActivated", true)
      }
      if(transActivated === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("transActivated", false)
      }
      localStorage.setItem("isRActive",false)

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }; 

    const handleLogIn = () => {

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
    }

    // const videos = [
    //     {
    //         LinkID : 1,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     },
    //     {
    //         LinkID : 2,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     },
    //     {
    //         LinkID : 3,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     },
    //     {
    //         LinkID : 4,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     },
    //     {
    //         LinkID : 5,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     },
    //     {
    //         LinkID : 6,
    //         Link : "https://www.youtube.com/watch?v=EJ_e2uLVdp0",
    //     }
    // ]

    return (
        <div>
            <div className='container'>
                <div className="py-5 text-center">
                    <div className="d-flex justify-content-between">
                        <div style={{ marginBottom: `${'10px'}` }}>
                        <span
                            className='goback'
                            onClick={() => history.goBack()}
                            style={{ color: '#0a146e', cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {'Retour'}
                        </span>
                        </div>
                        <div style={{ marginBottom: `${'10px'}` }}>
                        <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
                            <span
                            type="submit"
                            className='goback'
                            style={{ color: '#0a146e', cursor: 'pointer' }}
                            >
                            <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
                            </span>
                        </a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='embed-responsive' style={{ borderRadius: '10px' }}>
                                <ReactPlayer url={`${YUnicLink}`} controls />
                            </div>

                            <div className='p-5'>
                                <p>
                                    <strong style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}} >De courtes vidéos afin de vous guider comment utiliser notre plateforme NKAB.</strong><br />
                                    <strong>Lien de notre chaîne : <a href='https://www.youtube.com/channel/UC1_cnhTQE1FertIaBk7yPIQ'>https://www.youtube.com/channel/UC1_cnhTQE1FertIaBk7yPIQ</a></strong>
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='demo-section'>
                                <div className='pointer nkab-demo-hover' onClick={LinkChecked1} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink1}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT RECHARGER SON COMPTE NKAB PRO ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked2} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink2}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT ACHETER SUR NKAB PRO ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked3} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink3}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT TRANSFERER DE L'ARGENT A UN NUMERO VIA SON COMPTE NKAB PRO ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked4} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink4}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT ACHETER VOS CRYPTOMONNAIES VIA WAVE DEPUIS LE SENEGAL, LE BURKINA ET LE MALI ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked5} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink5}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT ACHETER SEMI AUTOMATIQUEMENT VOS CRYPTOMONNAIES SUR NKAB EXCHANGE ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked6} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink6}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT VENDRE DU BITCOIN, LITECOIN, DOGECOIN ET AVOIR DU FCFA ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked7} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink7}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT ACHETER AUTOMATIQUEMENT VOS CRYPTOMONNAIES AVEC WAVE DEPUIS LA COTE D'IVOIRE UNIQUEMENT ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked8} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink8}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT ACHETER AUTOMATIQUEMENT VOS CRYPTOMONNAIES DEPUIS LA CI, MALI, TOGO, BURKINA, SENEGAL ?</p>
                                    </div>
                                </div>
                                <div className='p-3 pointer nkab-demo-hover' onClick={LinkChecked9} style={{ borderRadius: '10px' }}>
                                    <div className="embed-responsive">
                                        <ReactPlayer url={`${YLink9}`} />
                                    </div>
                                    <div className='p-2'>
                                        <p className='bolder'>COMMENT RETIRER EN FCFA (VENDRE) SES USDT,BNB,TRON,PM... CHEZ NKAB EXCHANGE AUTOMATIQUEMENT ?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BottomNavBar />
        </div>
    )
}

