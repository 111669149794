import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './ProfileScreen.css'
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import BottomNavBar from '../components/BottomNavBar'


export default function ProfileScreen(props) {

  var   _ProWallet                   = localStorage.getItem('_ProWallet')
  const userID                       = localStorage.getItem('userID')
  const Logged                       = localStorage.getItem('userStatus')

  const [GetUser,                      setGetUser             ] = useState([])
  const [User,                         setUser                ] = useState([])
  const [UserStopReq,                  setUserStopReq         ] = useState(false)

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])


  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }
  },[Logged, props.history])



  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        if(!UserStopReq){
          const { data } = await axios.get(
            '/api/v1/get-users',
          )
          if(data.length > 0){
            setGetUser(data)
            setUserStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchDada12()

  },[UserStopReq])

  useEffect(()=>{

    if(GetUser.length > 0 && userID.length > 0){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      if(userGetted.length > 0){
        console.log(User)
        setUser(userGetted[0])
      }
    }

  },[GetUser, userID, User])


  return (
      <div className="color-w container">
        <div className='mt-4'>
          <div className='mb-2'><h1>Mon profil</h1></div>
          <div className='nkab-profil-menus'>
            <span className='nkab-profil-menu-item'>Biographie</span>
            <span className='nkab-profil-menu-item'>***?</span>
            <span className='nkab-profil-menu-item'>***?</span>
            <span className='nkab-profil-menu-item'>***?</span>
          </div>
        </div>

        <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <Card variant="outlined" style={{color: `${themeMode === "true" ? "#bae7ff" : ""}`, backgroundColor:`${themeMode === "true" ? "#0E233A" : ""}`}}>
                <CardContent>
                  <div className='nkab-profil-content-item mb-4'>
                    <Typography variant="h5" component="div"> Informations personelles </Typography>
                    <Typography sx={{ mb: 1.5, color: `${themeMode === "true" ? "#bae7ff" : ""}` }} color="text.secondary"> Personnalisez la façon dont les informations de votre profil apparaîtront sur cette plateforme. </Typography>
                  </div>
                  <div className='row nkab-profil-content-item'>
                    <div className='col-md-3'><img src="../../images/nkab-avatar.png" alt="nkab avatar" width="100" height="95" /></div>
                    <div className='col-md-9'>
                      <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }}}
                        noValidate
                        autoComplete="off"
                      >
                        {/* <div>
                          <TextField label="Nom" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.name} variant="filled" size="small" disabled />
                          <TextField label="Prénoms" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.lastName} variant="filled" size="small" disabled/>
                        </div>
                        <div>
                          <TextField label="E-Mail" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.email} variant="filled" size="small" disabled />
                          <TextField label="Pays" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.countryName} variant="filled" size="small" disabled />
                        </div>
                        <div>
                          <TextField label="Adresse" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={`${User.city},${User.countryname}`} variant="filled" size="small" disabled />
                          <TextField label="Téléphone" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.telNumber} variant="filled" size="small" disabled/>
                        </div>
                        <div>
                          <TextField label="Lieu de naissance" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.borningDate} variant="filled" size="small" disabled />
                          <TextField label="Lieu de résidence" id="filled-size-small" sx={{ color: `${themeMode === "true" ? "#bae7ff" : ""}` }} defaultValue={User.city} variant="filled" size="small" disabled/>
                        </div> */}
                      </Box>
                    </div>
                  </div>
                </CardContent>
                <CardActions sx={{float:"right"}}>
                  <Button variant="outlined" size="small"> Annuler </Button>
                  {/* <Button variant="contained" size="small"> { !NkabSpinner?"Enregistrer":<div className="nkab-spinner"></div> } </Button> */}
                  {/* <Button variant="contained" size="small" onClick={handleSubmit}> { !NkabSpinner?"Enregistrer":<div className="nkab-spinner"></div> } </Button> */}
                </CardActions>
              </Card>
            </div>
            <div className='col-md-3'></div>
          </div>
      <BottomNavBar />
    </div>
  )
}
