import React, { useEffect, useRef, useState } from 'react'
import './explorCrypto.css'
import coinGecko from '../../api/coinGecko'

export default function ExplorCrypto() {

  const [coins2, setCoins2] = useState([])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
          'bitcoin,binancecoin,binance-usd,tron,litecoin,dogecoin,shiba-inu,matic-network,chainlink,polkadot,cardano,solana,the-open-network',
        },
      })
      if(response.data.length > 0){
        setCoins2(response.data)
      }
    }
    fetchDada2()
  },[])

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )

  function useInterval(callback, delay) {
      const savedCallback = useRef()

      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }

  var [themeMode, setThemeMode] = useState()

  let ThemeMode = localStorage.getItem("ThemeMode")

  useEffect(()=>{
    
    if(secondsRemaining1 > 0){
      setThemeMode(ThemeMode)
    }

  },[ThemeMode, secondsRemaining1])

  const [ExplorBtn1, setExplorBtn1] = useState("nkab-explor-btn2")
  const [ExplorBtn2, setExplorBtn2] = useState("nkab-explor-btn")
  const [ExplorBtn3, setExplorBtn3] = useState("nkab-explor-btn")

  const [ExplorBtnt1, setExplorBtnt1] = useState("")
  const [ExplorBtnt2, setExplorBtnt2] = useState("")
  const [ExplorBtnt3, setExplorBtnt3] = useState("")

  const [Explor, setExplor] = useState("explor1")

  function explorBtn1Handler(){
    setExplor("explor1")

    setExplorBtn3("nkab-explor-btn")
    setExplorBtn2("nkab-explor-btn")
    setExplorBtn1("nkab-explor-btn2")

    setExplorBtnt1("nkab-explor-btnt")
    setExplorBtnt2("")
    setExplorBtnt3("")
  }

  function explorBtn2Handler(){
    setExplor("explor2")

    setExplorBtn3("nkab-explor-btn")
    setExplorBtn2("nkab-explor-btn2")
    setExplorBtn1("nkab-explor-btn")

    setExplorBtnt1("")
    setExplorBtnt2("nkab-explor-btnt")
    setExplorBtnt3("")
  }

  function explorBtn3Handler(){
    setExplor("explor3")

    setExplorBtn3("nkab-explor-btn2")
    setExplorBtn2("nkab-explor-btn")
    setExplorBtn1("nkab-explor-btn")

    setExplorBtnt1("")
    setExplorBtnt2("")
    setExplorBtnt3("nkab-explor-btnt")
  }

  return (
    <div className={`${themeMode === "true" ? "change-container change-container-t" : "change-container"}`}>
      <div className='d-flex justify-content-center pt-4 pb-4'>
        <div className={`${themeMode === "true" && Explor === "explor1" ? ExplorBtnt1:ExplorBtn1} bolder pointer`} onClick={explorBtn1Handler}>Echangeables </div>
        <div className={`${themeMode === "true" && Explor === "explor2" ? ExplorBtnt2:ExplorBtn2} bolder pointer`} onClick={explorBtn2Handler}>Top gagnants</div>
        <div className={`${themeMode === "true" && Explor === "explor1" ? ExplorBtnt3:ExplorBtn3} bolder pointer`} onClick={explorBtn3Handler}>Ristques</div>
      </div>
      <div className="change-items animate__animated animate__backInRight">
        {
          Explor === "explor2" ?<>
        {
          coins2.map((coin) => <>
          {
            coin.price_change_percentage_24h.toFixed(2) >= 4 ?
            <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
              <div className="change-card-content">
                <div className="change-image-container">
                  <img className="change-image" src={coin.image} alt={`nkab ${coin.id}`} />
                </div>
                <div className="change-titles">
                  <div className={`${themeMode === "true" ? "change-name change-name-theme nkab-explor-main-title" : "change-name nkab-explor-main-title"}`}>{coin.name}</div>
                    <div className='nkab-explor-price'> ${coin.current_price} </div>
                    <div> <span className='text-success nkab-explor-purcent-text' > {coin.price_change_percentage_24h.toFixed(2)}% </span> </div>
                </div>
              </div>
            </div>:null
          }
          </>)
        }
        </>:Explor === "explor1" ?<>
        {
          coins2.map((coin) => <>
          {
            coin.price_change_percentage_24h.toFixed(2) < 4 ?
            <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
              <div className="change-card-content">
                <div className="change-image-container">
                  <img className="change-image" src={coin.image} alt={`nkab ${coin.id}`} />
                </div>
                <div className="change-titles">
                  <div className={`${themeMode === "true" ? "change-name change-name-theme nkab-explor-main-title" : "change-name nkab-explor-main-title"}`}>{coin.name}</div>
                    <div className='nkab-explor-price'> ${coin.current_price} </div>
                    <div>
                      <span className={ coin.price_change_percentage_24h < 0 ? 'text-danger nkab-explor-purcent-text' : 'text-success nkab-explor-purcent-text'} >
                        {coin.price_change_percentage_24h.toFixed(2)}%
                      </span>
                    </div>
                </div>
              </div>
            </div>:null
          }
          </>)
        }</>:Explor === "explor3" ?<>
        {
          coins2.map((coin) => <>
          {
            coin.price_change_percentage_24h.toFixed(2) < 0 ?
            <div className={`${themeMode === "true" ? "change-card change-card-theme" : "change-card"}`}>
              <div className="change-card-content">
                <div className="change-image-container">
                  <img className="change-image" src={coin.image} alt={`nkab ${coin.id}`} />
                </div>
                <div className="change-titles">
                  <div className={`${themeMode === "true" ? "change-name change-name-theme nkab-explor-main-title" : "change-name nkab-explor-main-title"}`}>{coin.name}</div>
                    <div className='nkab-explor-price'> ${coin.current_price} </div>
                    <div>
                      <span className='text-danger nkab-explor-purcent-text' >
                        {coin.price_change_percentage_24h.toFixed(2)}%
                      </span>
                    </div>
                </div>
              </div>
            </div>:null
          }
          </>)
        }</>:null
        }
      </div>
    </div>
  )
}
