import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DomLoading from '../components/loadings/DomLoading/DomLoading'
import { Button, Grid } from '@mui/material'

export default function OperationProConfirmation(props) {
  

  const  Logged                                            = localStorage.getItem('userStatus')
  const  role                                              = localStorage.getItem('role')
  const { id }                                             = useParams()

  const [Operation,              setOperation         ] = useState([])
  const [AllOp,                  setAllOp             ] = useState([])
  const [loading,                setLoading           ] = useState(false)
  const [WitnessesStopReq,       setWitnessesStopReq  ] = useState(false)
  const [AllOpStopReq,           setAllOpStopReq      ] = useState(false)
  const [LoadingCancel,          setLoadingCancel     ] = useState(false)
  const [MyId,           setMyId           ] = useState([])

  const LineLink = true
  // const LineLink = false

  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }

    if (Number(role) !== 1) {
      props.history.push('/')
    }
  },[Logged, props.history, role])



  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!WitnessesStopReq){

          const { data } = await axios.get('/api/v1/get-p-recharge')

          if(data && id){
            const dataGetted = data.filter((obj) => {
              return obj.ID === Number(id)
            })
  
            setOperation(dataGetted[0])
            setMyId(AllOp.ID)

          if(data.length > 0){
            setWitnessesStopReq(true)
          }
        }
      }} catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [id, Operation, WitnessesStopReq])

  const _myID = Number(MyId)



  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!AllOpStopReq){

          const { data } = await axios.get('/api/v1/get-p-recharge')

          if(data && Operation){
            const dataGetted = data.filter((obj) => {
              return obj.operationUId === Operation.operationUId
            })
  
            setAllOp(dataGetted[0])
          if(data.length > 0){
            setAllOpStopReq(true)
          }
        }
      }} catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [id, Operation, AllOpStopReq])


  const successbyadminhandler = async () => {
    setLoading(true)
    const validation = 1
    if(validation && Number(role) === 1){
      axios.post(
          `/api/v1/p-recharge/validation`,
          {
            ID: Number(id),
            status: "successful"
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {

              axios.post(
                `/api/update/all-p-op`,
                {
                  ID: _myID,
                  status: "successful"
                },
              ).then((response) => {
                if (response.data.msg === 'success'){
                  if(LineLink){
                    window.location.replace("https://nkab-exchange.net/operation-pro");
                  } else {
                     window.location.replace("http://localhost:3000/operation-pro");
                  }
                }
              })

              if(LineLink){
                window.location.replace("https://nkab-exchange.net/operation-pro");
              } else {
                  window.location.replace("http://localhost:3000/operation-pro");
              }

            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }


  const canselbyadminhandler = async () => {

    setLoadingCancel(true)
    const validation = 1
    if(validation && Number(role) === 1){
      axios.post(
          `/api/v1/p-recharge/validation`,
          {
            ID: Number(id),
            status: "failed"
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {

              axios.post(
                `/api/update/all-p-op`,
                {
                  ID: _myID,
                  status: "failed"
                },
              ).then((response) => {
                if (response.data.msg === 'success'){
                  if(LineLink){
                    window.location.replace("https://nkab-exchange.net/operation-pro");
                  } else {
                     window.location.replace("http://localhost:3000/operation-pro");
                  }
                }
              })
              
              if(LineLink){
                window.location.replace("https://nkab-exchange.net/operation-pro");
              } else {
                 window.location.replace("http://localhost:3000/operation-pro");
              }
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  const loadMsg = "Patienter un moment..."

  return (
    <div className="container">
      <div className="py-5 text-center">
        <h2>NKAB CONFIRMATION DE {Operation.options}</h2>
          <div className='withdrav-content'>
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">
                  {
                    Operation.operatorAction === 'sel' ? 'Vente de ' : 
                    Operation.operatorAction === 'buy' ? 'Achat de ' : null
                  }
                  <span className='danger bolder uppercase'>{Operation.fullName}</span> effectue une opération de <strong>{Operation.options}</strong> par <strong>{Operation.banqueName}</strong>
                </h5>
                <h5 className='success bolder'> Montant du recharge : {Operation.rbserved} XOF</h5>
              </div>
              <div className="card-footer text-muted">
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(0,10)}{' '}à{' '}
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(11,19)}
              </div>
            </div>
          </div> 
        <div className="withdrav-content">
          <h4 className="text-center mt-3 mb-3">
            <span className="text-primary">
              {Operation.rbserved} XOF
            </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Type d'opération</h6> </div>
              <span className="text-muted"> {Operation.banqueImage} </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Montant (XOF)</h6> </div>
              <span className="text-muted">{Operation.rbserved} XOF</span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Montant (US)</h6> </div>
              <span className="text-muted"> {Operation.dollar} $ </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span><strong>Total (XOF)</strong></span>
              <span className="text-muted"><strong>{Operation.rbserved} XOF</strong></span>
            </li>
          </ul>

          <Grid container>
              <Grid item xs sx={{ mr: 1}}>
                {
                  LoadingCancel === false ? 
                  <Button type="submit" onClick={canselbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} >Annuler </Button>:
                  LoadingCancel === true ?
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} ><div className="nkab-spinner "></div> </Button>:null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loading === false ? 
                  <Button type="submit" onClick={successbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Confirmer </Button> :
                  loading === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
            </Grid>
        </div>
      </div>
    </div>
  )
}
