import React, { useEffect, useRef, useState } from 'react'
import './SingInTest.css'

const SingInTest = () => {

    const STATUS = { STARTED: 'Traitement en cours...', }
    
      const INITIAL_COUNT1 = 300
    
      const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
      const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
      var [themeMode, setThemeMode] = useState()
      
      let ThemeMode = localStorage.getItem("ThemeMode")
    
      useEffect(()=>{
        setStatus1(STATUS.STARTED)
      },[STATUS.STARTED])
  
    useInterval(
      () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
      },
  
      status1 === STATUS.STARTED ? 1000 : null,
      // passing null stops the interval
  )
  
  function useInterval(callback, delay) {
      const savedCallback = useRef()
  
      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }
  
  useEffect(()=>{
    
    if(secondsRemaining1 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining1])

  return (
    <div className='st-body' style={{background:`${themeMode === "true" ? "#0E233A" : ""}`}}>
        <div className='st-main' style={{background:`${themeMode === "true" ? "linear-gradient(to bottom, #0E233A, #bae7ff)" : ""}`}}>
            <input type="checkbox" id="st-chk" aria-hidden="true" />

            <div className='st-signup'>
                <form>
                    <label style={{color:`${themeMode === "true" ? "" : "#0E233A"}`}} className='st-label' htmlFor="st-chk" aria-hidden="true">S'inscription</label>
                    <input className='st-input' type="text" name="firstname" placeholder='Nom' required />
                    <input className='st-input' type="text" name="lastname" placeholder='Prénoms' required />
                    <input className='st-input' type="email" name="email" placeholder='Adresse Email' required />
                    <div className='st-country'>
                        <span><img className='images-drapeau ml-2 mr-2 pointer' src="../../images/shiba.png" alt="" /></span>
                        <span><img className='images-drapeau ml-2 mr-2 pointer' src="../../images/shiba.png" alt="" /></span>
                        <span><img className='images-drapeau ml-2 mr-2 pointer' src="../../images/shiba.png" alt="" /></span>
                        <span><img className='images-drapeau ml-2 mr-2 pointer' src="../../images/shiba.png" alt="" /></span>
                    </div>
                    <input className='st-input' type="phone" name="phone" placeholder='Téléphone' required />
                    <input className='st-input' type="password" name="pswd" placeholder='Mot de passe' required />
                    <input className='st-input' type="password" name="confpswd" placeholder='Fonfirmer le mot de passe' required />
                    <button className='st-button' style={{background:`${themeMode === "true" ? "#0E233A" : ""}`}}>Valider</button>
                </form>
            </div>

            <div className='st-login'>
                <form>
                    <label style={{color:`${themeMode === "true" ? "#0E233A" : ""}`}} className='st-label' htmlFor="st-chk" aria-hidden="true">Se connecter</label>
                    <input className='st-input' type="email" name="email" placeholder='Email' required />
                    <input className='st-input' type="password" name="pswd" placeholder='Password' required />
                    <button className='st-button'>Valider</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default SingInTest