import { faArrowAltCircleLeft, faBackward, faForward, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import { useRef } from 'react'
import BottomNavBar from '../components/BottomNavBar'

export default function Withdrawal(props){

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

  const [copyText,                      setCopyText                     ] = useState(false)
  const [orderConfirmationData,         setOrderConfirmationData        ] = useState([])
  const [BonuspaymentData,              setBonuspaymentData             ] = useState([])
  const [Parrained,                     setParrained                    ] = useState()
  const [CurrencyGet,                   setCurrencyGet                  ] = useState(0)
  const [stopper,                       setStopper                      ] = useState(false)
  const [SponsorCurrencyTotalBonus,     setSponsorCurrencyTotalBonus    ] = useState(0)
  const [SponsorBonuspaymentTotalBonus, setSponsorBonuspaymentTotalBonus] = useState(0)
  const [total,                         setTotal                        ] = useState(0)
  const [last_cfa_char_nbr,             setLast_cfa_char_nbr            ] = useState(0)
  const [finalPrice,                    setFinalPrice                   ] = useState()
  const [Caracters,                     setCaracters                    ] = useState(0)
  const [_phone,                        set_Phone                       ] = useState()
  const [loading,                       setLoading                      ] = useState(false)
  const [montant,                       setMontant                      ] = useState()
  const [numero,                        setNumero                       ] = useState()
  const [isCopied,                      setIsCopied                     ] = useState(false)
  const [ConfirmationStopReq,           setConfirmationStopReq          ] = useState(false)
  const [demand,                        setDemand                       ] = useState(false)
  const [GetUser,                       setGetUser                      ] = useState([])
  const [Getharvest,                    setGetharvest                   ] = useState([])
  const [User,                          setUser                         ] = useState([])
  const [UserStopReq,                   setUserStopReq                  ] = useState(false)
  const [harvestStopReq,                setharvestStopReq               ] = useState(false)
  const [BonuspaymentStopReq,           setBonuspaymentStopReq          ] = useState(false)

  const LineLink = true

  var    _ProWallet        = localStorage.getItem('_ProWallet')
  const  userID            = localStorage.getItem('userID')
  const  SenderCountry     = localStorage.getItem('SenderCountry')

  useEffect(()=>{

    if (_ProWallet === 'true') {
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      }else{
        window.location.replace("http://localhost:3000/nkab-pro");
      }
    }

  },[_ProWallet, LineLink])

  

  const Logged = localStorage.getItem('userStatus')

  useEffect(()=>{
    if(Logged === 'Logout'){
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      }else{
        window.location.replace("http://localhost:3000/login");
      }
    }
  },[Logged, LineLink])

  const [isMenuVisible, setMenuVisibility] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;
  
      setMenuVisibility(isVisible);
      setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos]);


  useEffect(()=>{

    const fetchDada1 = async () => {
      try {
        if(!UserStopReq){
          const { data } = await axios.get(
            '/api/v1/get-users',
          )
          setGetUser(data)
          if(data.length > 0){
            setUserStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchDada1()

    const fetchDada2 = async () => {
      try {
        if(!harvestStopReq){
          const { data } = await axios.get(
            '/api/v1/get-harvest',
          )
          setGetharvest(data)
          if(data.length > 0){
            setharvestStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchDada2()

  },[UserStopReq, harvestStopReq])

  useEffect(()=>{

    if(GetUser && userID){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      setUser(userGetted[0])
    }

  },[GetUser, userID, User])


  const history = useHistory()

  useEffect(()=>{
    if(montant){
      setTotal(Number(montant - (montant * 0.02)))
      var cfa_nbr = total
      if(cfa_nbr){
        const cfa_nbr_to_text = cfa_nbr.toString()
        const cfa_nbr_last_char = cfa_nbr_to_text.slice(-1)
        setLast_cfa_char_nbr(Number(cfa_nbr_last_char))
      }
    }
  },[montant,total])

  useEffect(() => {
    if (last_cfa_char_nbr === 0) {
      const FinalCount = total
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 1) {
      const FinalCount = total - 1
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 2) {
      const FinalCount = total - 2
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 3) {
      const FinalCount = total - 3
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 4) {
      const FinalCount = total - 4
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 5) {
      const FinalCount = total + 0
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 6) {
      const FinalCount = total - 1
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 7) {
      const FinalCount = total - 2 
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 8) {
      const FinalCount = total - 3
      setFinalPrice(FinalCount)
    }

    if (last_cfa_char_nbr === 9) {
      const FinalCount = total - 4
      setFinalPrice(FinalCount)
    }
    
  }, [
    last_cfa_char_nbr,
    total,
    finalPrice
  ])
    
  useEffect(() => {
    const fetchOrderConfirmationData = async () => {
      try {
        if(!ConfirmationStopReq){
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          setOrderConfirmationData(data)
          if(data.length > 0){
            setConfirmationStopReq(true)
          }
        }
      } catch (error) {}

      try {
        if(!BonuspaymentStopReq){
          const { data } = await axios.get(
            '/api/v1/get-bonuspayment',
          )
          setBonuspaymentData(data)
          if(data.length > 0){
            setBonuspaymentStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchOrderConfirmationData()
  }, [ConfirmationStopReq, BonuspaymentStopReq])

  var [i, setI] = useState(0)
  var [i2, setI2] = useState(0)
  
  useEffect(() => {
    
    if(GetUser && userID && stopper === false){

      const sponsor = GetUser.filter(obj => {
        return obj.sponsorshipRef === userID;
      });

      setCurrencyGet(1000.00)
      setParrained(sponsor.length)

      if(Parrained > 0 && orderConfirmationData && sponsor[i2]){

        const operationData = orderConfirmationData.filter(obj => {
          return obj.SenderId === sponsor[i2].ID;
        });

        const allOperationDataLength = operationData.length

        if(allOperationDataLength > 0){
          
          if(i < allOperationDataLength){

            if(operationData[i] && orderConfirmationData){

              var opId = operationData[i].OrderId
  
              if(opId){

                if(Getharvest.length > 0){

                  const harvest = Getharvest.filter(obj => {
                    return obj._ccb_order_id === opId;
                  });

                  if(harvest.length > 0){
                    i++
                    setI(i++)
                  } else {
                    const confirmationData = orderConfirmationData.filter(obj => {
                      return obj.OrderId === opId;
                    });
      
                    if(confirmationData.length === 0){
                      i++
                      setI(i++)
                    } else if(confirmationData.length > 0){
      
                      const OpFiltered = confirmationData.filter(obj => {
                        return obj.isAdminCompleted === 'validé';
                      });

                      var OpF = OpFiltered[0]

                      if(OpF){
    
                        const price = ((OpF.SenderChange * 0.4)/100)
        
                        if(OpFiltered.length > 0){
        
                          const currencyName = OpF.serviceName
                          const currencyPrice = price
                          const _user_id = userID
                          const _ccb_order_id = OpF.OrderId
      
                          axios.post(
                            `/api/v1/create-harvest`,
                            {
                              _user_id,
                              _ccb_order_id,
                              currencyPrice,
                              currencyName,
                              createdAt: new Date(),
                              updatedAt: new Date(),
                            },
                          )
                          .then(
                            (response) => {
                              if(response){
                                if(response.data.msg === "success"){
                                  i++
                                  setI(i++)
                                }
                              }
                            }
                          ) 
                          
                        }
                      }
                    }
                  }

                } else if(Getharvest.length === 0){

                    const confirmationData = orderConfirmationData.filter(obj => {
                      return obj.OrderId === opId;
                    });
      
                    if(confirmationData.length === 0){
                      i++
                      setI(i++)
                    } else if(confirmationData.length > 0){
      
                      const OpFiltered = confirmationData.filter(obj => {
                        return obj.isAdminCompleted === 'validé';
                      });
    
                      const price = ((OpFiltered[0].SenderChange * 0.4)/100)
      
                      if(OpFiltered.length > 0){
      
                        const currencyName = OpFiltered[0].serviceName
                        const currencyPrice = price
                        const _user_id = userID
                        const _ccb_order_id = OpFiltered[0].OrderId
    
                        axios.post(
                          `/api/v1/create-harvest`,
                          {
                            _user_id,
                            _ccb_order_id,
                            currencyPrice,
                            currencyName,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                          },
                        )
                        .then(
                          (response) => {
                            if(response){
                              if(response.data.msg === "success"){
                                i++
                                setI(i++)
                              }
                            }
                          },
                          (error) => {},
                        ) 
                        
                      }
                    }
                }
              }
            }
          }else if(i > allOperationDataLength){
            setI(0)
            setStopper(true)
          }else if(i === allOperationDataLength){
            i2++
            setI2(i2++)
            setStopper(true)
          }
          
        }
      }
    }
    

  },[GetUser, userID, Parrained, orderConfirmationData, stopper, i, i2, Getharvest])
    
  useEffect(()=>{

    if(Getharvest && BonuspaymentData && userID){

      if(Getharvest.length > 0){
        
        const GetharvestFiltered = Getharvest.filter(obj => {
          return obj._user_id === userID;
        });
        
        const Bonuspayment = BonuspaymentData.filter(obj => {
          return obj._user_id === userID &&  obj.isAdminCompleted === 1;
        });

        const GetharvestTotal = (GetharvestFiltered.reduce((total, currentItem) =>  total = total + currentItem.currencyPrice , 0 ));
        const BonuspaymentTotal = (Bonuspayment.reduce((total, currentItem) =>  total = total + currentItem.finalprice , 0 ));

        setSponsorCurrencyTotalBonus(Number((GetharvestTotal * 1).toFixed(0)))
        setSponsorBonuspaymentTotalBonus(Number((BonuspaymentTotal * 1).toFixed(0)))
      }
    }

  },[Getharvest, userID, SponsorCurrencyTotalBonus, SponsorBonuspaymentTotalBonus, BonuspaymentData])
    
  

  const totalBonus = SponsorCurrencyTotalBonus
  const totalBonusLessing = SponsorBonuspaymentTotalBonus
  const totalParrainedBonus = (Parrained * 100)
  const totalCount = (CurrencyGet + totalBonus + totalParrainedBonus)
  const PermissionPrice = 9999
  const FinalTotal = (totalCount - totalBonusLessing)


  useEffect(()=>{
    if(userID && numero){
      const CaractersCounter = (numero).length
      setCaracters(CaractersCounter)

      if(Caracters === 8 || Caracters === 10){
        set_Phone(numero)
      }

      if(Caracters > 10){
        set_Phone(numero.slice(4,20))
      }
    }

  },[userID, Caracters, numero, _phone])

  const sendaftersuccessbyadminhandler = async () => {
    setLoading(true)

    if(finalPrice && userID){
      
      try {
        
        const _user_id = userID
        const _status = "Demande de retrait"
        const finalprice = finalPrice
        const phone = _phone
        const email = User.email
        axios
          .post(
            `/api/v1/create-bonuspayment`,
            {
              _user_id,
              _status,
              finalprice,
              SenderPhone : phone,
              SenderEmail : email,
              SenderCountry,
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {
              if (response.data.msg === "success") {
                setNumero('')
                setMontant('')
                axios.post(
                    '/api/v1/notification/create-notif',
                    {
                      notifierId : _user_id,
                      notifyImageLink : "../images/cahs2.png",
                      notifyServicesId : "no id",
                      notifyOperatorAction : "Retrait",
                      notifySenderName : 'Commission',
                      notifySenderPhone : phone,
                      notifySenderEmail : email,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                    },
                  ).then((res)=>{
                      if(res){
                        alert("Votre demande de retrait a été envoyé avec succès !")
                        setDemand(true)
                      }
                  })
              }
            },
            (error) => {},
          )
      } catch (error) {}
    }
  }

  const loadMsg = "Veuillez patienter un moment..."
  
  useEffect(()=>{
    if(userID){
      setCopyText(`https://nkab-exchange.net/singup-sp/${userID}`)
    }
  },[userID])


  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }
    
  return (
    <div>
      <div className='container'>
        <div className={`${themeMode === "true" ? "text-center wallet-body wallet-body-t mt-3 pb-3" : "text-center wallet-body mt-3 pb-3"}`}>
          <div className='row pt-4 pb-4'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className='pointer' onClick={() => history.goBack()} style={{ color: '#0a146e', backgroundColor:'#dbdbdba1', borderRadius:'50px', padding:'3px', fontSize:"13px" }} >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Retour
                </span>
              </div>
              <div>
                <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
                  <span type="submit" className='goback' style={{ color: '#0a146e', backgroundColor:'#dbdbdba1', cursor: 'pointer' }} >
                    <FontAwesomeIcon icon={faForward} /> {'Accueil services'}
                  </span>
                </a>
              </div>
            </div>

          </div>
          <div className='row justify-content-around'>
            <div className='row pt-3 col-md-6' style={{backgroundColor:'#948d8c3f', borderRadius:'10px'}}>
              <FontAwesomeIcon icon={faWallet} style={{ fontSize: 40, color: `${'#6b413b'}` }}/>
              <h4 style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>NKAB E-WALLET</h4>
              <div className='withdrav-content'>
                <div className='nkab-affiliation'>
                  <div className='uppercase color-or'><strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Bonus affiliation chez Nkab Exchange</strong></div><br/>
                  <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> <strong className='color-or'>+1000 fcfa</strong> Bonus inscription bienvenue</span><br/>
                  <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> <strong className='color-or'>+100 fcfa</strong> bonus de parrainage pour chaque personne parrainé</span><br/>
                  <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> <strong className='color-or'>+0,5%</strong> commission sur toutes les transactions des personnes parrainées</span><br/><br/>
                </div>
              </div>
              <div className='withdrav-content'>
                <div className='nkab-affiliation'>
                  <div className='uppercase'><span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Mon lien de parrainage :</span></div>
                  <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText} </span>
                  <button className='button-isCopied p-2' onClick={handleCopyClick}>
                    <span>{isCopied ? 'Adresse copiée!' : 'Copier'}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="withdrav-content col-md-6 pb-3 pt-3">
              <div className="d-flex justify-content-around align-items-center wallet-status">
                <span className="PriceFontSize color-b">Globale : {totalCount} XOF</span>
                <span className="danger PriceFontSize">Retirée : {((totalBonusLessing))} XOF</span>
                <span className="PriceFontSize color-gr">Restante : {FinalTotal} XOF</span>
              </div>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0 color-r">Vous avez parrainé</h6>
                  </div>
                  <span className="text-muted"><strong>{Parrained}</strong> personne(s)</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Gain par parrainage</h6>
                    {/* <small className="text-muted">Instantané</small> */}
                  </div>
                  <span className="text-muted">{totalParrainedBonus} XOF</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-sm">
                  <div>
                    <h6 className="my-0">Bonus d'inscription</h6>
                    {/* <small className="text-muted">Instantané</small> */}
                  </div>
                  <span className="text-muted">{CurrencyGet} XOF</span>
                </li>
                <li className="list-group-item d-flex justify-content-between bg-light">
                  <div className="text-success">
                    <h6 className="my-0"><strong>Gain courant </strong></h6>
                    {/* <small>Total</small> */}
                  </div>
                  <strong><span className="text-success">{totalBonus} XOF</span></strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <strong><span>Gain total (XOF)</span></strong>
                  <strong>{FinalTotal} XOF</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between color-b uppercase bolder">
                  <strong><span>Balance totale (XOF)</span></strong>
                  <strong>{FinalTotal} XOF</strong>
                </li>
              </ul>

              <div className='d-flex justify-content-between'>
                <input style={{width:'49%'}} type="text" id="numero" value={numero} name="numero" placeholder="Numéro à créditer" onChange={(e)=> setNumero(e.target.value)} />
                <input style={{width:'49%'}} type="text" id="montant" value={montant} name="montant" placeholder="Montant à retirer" onChange={(e)=>setMontant(e.target.value)} />
              </div>

              <div className='p-2'>
                <span className='danger'>
                  Montant minimum de retrait : <strong>10.000 XOF</strong> 
                </span>
              </div>

              <div className='p-2 success'>
                <strong>MONTANT A RECEVOIR : <strong>{finalPrice} XOF</strong></strong> 
              </div>
              
              {
                !FinalTotal?null:
                FinalTotal > PermissionPrice ?
                <div className='p-2'>
                  <span className='blink danger bolder'>
                    Vous recevrez votre gain dans un délai de 24H après avoir lancé la demande de retrait.
                  </span>
                </div>:null
              }

              <div className='row'>
                <div className="card p-2 col-md-6">
                  <div className="input-group">
                      <button className="w-100 btn btn-danger btn-lg wallet_button" type="submit" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faBackward} /> 
                          {' Retour'}
                      </button>
                  </div>
                </div>

                <div className="card p-2 col-md-6">
                  <div className="input-group">
                    {
                      !FinalTotal?null:FinalTotal < PermissionPrice ?
                      <button className="w-100 btn btn-secondary btn-lg wallet_button" type="submit" disabled>Retrait non autorisé</button>:
                      FinalTotal > PermissionPrice ?
                      <>
                      {
                        !montant?
                          <button className="w-100 btn btn-secondary btn-lg wallet_button" type="submit" disabled>Retrait autorisé</button>:
                        montant > PermissionPrice ?
                          <>
                          {
                            loading === false && demand === false ? 
                            <span className="w-100 btn btn-primary btn-lg wallet_button" onClick={sendaftersuccessbyadminhandler} >Retrait autorisé</span> :
                            loading === true && demand === false ? 
                            <button className="w-100 btn btn-primary btn-lg wallet_button" type="submit" >
                              <LoadingBox loadMsg={loadMsg} />
                            </button>:
                            loading === true && demand === true ? 
                            <button className="w-100 btn btn-success btn-lg wallet_button" type="submit" disabled >
                              Demande envoyée !
                            </button>: null
                          }
                          </>:<button className="w-100 btn btn-secondary btn-lg wallet_button" type="submit" disabled>Retrait autorisé</button>
                      }
                      </>:
                      <button className="w-100 btn btn-secondary btn-lg wallet_button" type="submit" disabled>Retrait non autorisé</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <BottomNavBar />
    </div>
  )
}

