import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faHome, faSignInAlt, faSignOutAlt, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

export default function ViewMore(props) {

  var   _ProWallet                                           = localStorage.getItem('_ProWallet')
  const Logged                                               = localStorage.getItem('userStatus')


  const { id }                                               = useParams()
  const history                                              = useHistory()
  const [editUser,                      setEditUser        ] = useState([])
  const [SponsorLeaders,                setSponsorLeaders  ] = useState()
  const [Parrained,                     setParrained       ] = useState()
  const [AllParrained,                  setAllParrained    ] = useState()
  const [SeeList,                       setSeeList         ] = useState(false)
  const [SeeImage,                      setSeeImage        ] = useState(false)
  const [NkabSearchResult,              setNkabSearchResult] =  useState()
  const [NkabSearch,                    setNkabSearch      ] =  useState('')
  const [SponsorInfo,                   setSponsorInfo     ] = useState({ ID:[] })
  const [Multiple,                      setMultiple        ] = useState(false)
  const [Permission,                    setPermission      ] = useState(false)
  const [GetUser,                       setGetUser         ] = useState([])
  const [UserStopReq,                   setUserStopReq     ] = useState(false)
  var   [I,                             setI               ] = useState(0)

  const LineLink = true

  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }
}


  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }

  },[Logged, props.history, LineLink])



  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        if(!UserStopReq){
          const { data } = await axios.get(
            '/api/v1/get-users',
          )
          setGetUser(data)
          if(data.length > 0){
            setUserStopReq(true)
          }
        }
      } catch (error) {}
    }
    fetchDada12()

  },[UserStopReq])


  useEffect(() => {
    if (GetUser.length > 0) {

      GetUser.forEach((user) => {
        if (user.ID === Number(id)) {
          setEditUser(user)
        }
      })
    }
  }, [id, GetUser])

  useEffect(()=>{
    
    if(GetUser && editUser.ID){

      const sponsorLeader = GetUser.filter(obj => {
        return obj.sponsorshipRef === id;
      });

      if(sponsorLeader){
        setSponsorLeaders(sponsorLeader[0])
      }

      setParrained(sponsorLeader.length)
      setAllParrained(sponsorLeader)
    }

  },[GetUser, editUser, id, SponsorLeaders])

  function seeList(){
    setSeeList(true)
    setSeeImage(false)
  }
  
  function seeImage(){
    setSeeImage(true)
    setSeeList(false)
  }

  const handleSearchChange = (e) => {
    const {value} = e.target
    if(AllParrained.length > 0 && value){
      const SearchUser = AllParrained.filter((obj)=>{
        return obj.name.includes(value)
      })

      setNkabSearchResult(SearchUser)
    }
  }

  const handleSearchChange2 = (e) => {
    const {value} = e.target
    if(AllParrained.length > 0 && value){
      const SearchUser = AllParrained.filter((obj)=>{
        return obj.email.includes(value)
      })

      setNkabSearchResult(SearchUser)
    }

  }

  const handleSearchChange3 = (e) => {
    const {value} = e.target
    if(AllParrained.length > 0 && value){
      const SearchUser = AllParrained.filter((obj)=>{
        return obj.telNumber.includes(value)
      })

      setNkabSearchResult(SearchUser)
    }

  }

  useEffect(()=>{
    if(NkabSearchResult){
      setNkabSearch(NkabSearchResult)
    }
  },[NkabSearchResult, NkabSearch])


  const handleChange = ({ target: { value ,checked } }) => {

    var { ID } = SponsorInfo

    if (checked) {
      setSponsorInfo({ID: [...ID, value]})
    } else {
      setSponsorInfo({
        ID: ID.filter((e) => e !== value),
        response: ID.filter((e) => e !== value),
      })
    }
  }

  useEffect(()=>{ 
    if(SponsorInfo){
      setSponsorInfo(SponsorInfo)
    }
  },[SponsorInfo])


  useEffect(()=>{
    if(SponsorInfo.ID.length === 1 || SponsorInfo.ID.length > 1){
      setMultiple(true)
    }
    if(SponsorInfo.ID.length === 0){
      setMultiple(false)
    }
  },[SponsorInfo])

  const multipleHandler = () => {
    if(Multiple){
      setPermission(true)
    }

  }

  useEffect(()=>{
    if(Permission){
      if(SponsorInfo.ID.length > I){
        axios.post(`/api/v1/delete-users`,
          {
            ID: SponsorInfo.ID[I],
          },
        ).then((response)=>{
          if(response.data.msg === "success"){
            I++
            setI(I++)
          }
        })
      } else if(SponsorInfo.ID.length === I){
        setPermission(false)
      }
    }
  },[Permission, SponsorInfo, I])


  const notifHandler = async (id) =>{

    const SearchUser = GetUser.filter((obj)=>{
      return obj.ID === id
    })

    if(SearchUser){
      if(SearchUser[0]){
        if(SearchUser[0].role === 1){
          alert(`Vous ne pouvez pas supprimer l'administrateur ${id}!`)
        } else {
          alert(`Veuillez confirmer la suppression de l'utilisateur ${id}!`)
          axios.post(`/api/v1/delete-users`,
            {
              ID: id,
            },
          ).then((response)=>{
            if(response.data.msg === "success"){
              setUserStopReq(false)
              setUserStopReq(false)
            }
          })
        }
      }
    }
  }
    
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }


  return (
    <div className="color-w container" style={{backgroundColor:"#90d9ff"}}>
      <div className="about-session">
        <div className="container emp-profile">
          <div className="row">
            <div className='col-md-2'>
              <span onClick={() => history.goBack()} className='pointer bg-danger p-1' >
                <FontAwesomeIcon icon={faBackward} /> {'[Retour]'}
              </span>
            </div>
            <div className='col-md-6 color-b'>
              <span className='bolder color-or' style={{backgroundColor:'#9596a1', borderRadius:'3px', padding:'3px', fontSize:'15px'}}><strong style={{color:'#fff'}}> {Parrained}</strong></span>
              <span> Utilisateurs parrainé(s) par <strong>{editUser.lastName} {editUser.name}</strong></span>
            </div>
            <div className='col-md-2'>
              {
                !AllParrained ? null : AllParrained.length === 0 ? 
                <span className="no-see-more-list pointer color-white mt-2 mb-2"> Voir la liste </span>: 
                <span className="see-more-list pointer color-white mt-2 mb-2" onClick={seeList}> Voir la liste </span>
              }
            </div>
            <div className='col-md-2'>
              {
                editUser.NextImage === "no image" ? 
                <span className="no-see-more-list pointer color-white mt-2 mb-2" style={{borderRight:'5px solid color-b'}}> Son dossier </span> :
                <span className="see-more-list pointer color-white mt-2 mb-2" onClick={seeImage} style={{borderRight:'5px solid color-b'}}> Son dossier </span>
              }
            </div>
          </div>
          {
            SeeList ?
            <>
            {
              !AllParrained ? null : AllParrained.length === 0 ? null:
              <>
              <br/><br/>
              <div className='row'>
                <div className="col-md-4">
                  <div className="input-group">
                    <div className="form-outline">
                      <input 
                        type="search"
                        name='search'
                        className="form-control" 
                        placeholder='Recherche par nom...' 
                        onChange={handleSearchChange} 
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <div className="form-outline">
                      <input 
                        type="search"
                        name='search2'
                        className="form-control" 
                        placeholder='Recherche par email...' 
                        onChange={handleSearchChange2} 
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <div className="form-outline">
                      <input 
                        type="search"
                        name='search3'
                        className="form-control" 
                        placeholder='Recherche par téléphone...' 
                        onChange={handleSearchChange3} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              </>
            }
            <br/>
            <br/>
            <div className="row">
              <div style={{ overflowX: 'auto' }} className="table-section">
                <table className="customers">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Prénoms</th>
                      <th>Email</th>
                      <th>C.P</th>
                      <th>Téléphone</th>
                      <th>{Multiple ? <span className='nkab-delete-multiple' onClick={multipleHandler}>Delete {SponsorInfo.ID.length}</span>:"Cocher"}</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    NkabSearch.length === 0 ? AllParrained.map((userSearch) => (
                      <tr key={userSearch.ID} style={{backgroundColor:"#010814"}}>
                        <td>{userSearch.name}</td>
                        <td>{userSearch.lastName}</td>
                        <td>{userSearch.email}</td>
                        <td>({userSearch.country})</td>
                        <td>{userSearch.telNumber}</td>
                        <td className='nkab-notif-trash'>                  
                          <input
                            type="checkbox"
                            name="email"
                            value={`${userSearch.ID}`}
                            onChange={handleChange}
                          />
                        </td>
                        <td className='nkab-notif-trash'>
                          <FontAwesomeIcon icon={faTrashAlt} onClick={() => notifHandler(userSearch.ID)}/>
                        </td>
                      </tr>
                    )) :
                    !NkabSearch ? null : NkabSearch.length > 0 ? NkabSearch.map((userSearch) => (
                      <tr key={userSearch.ID} style={{backgroundColor:"#010814"}}>
                        <td>{userSearch.name}</td>
                        <td>{userSearch.lastName}</td>
                        <td>{userSearch.email}</td>
                        <td>({userSearch.country})</td>
                        <td>{userSearch.telNumber}</td>
                        <td className='nkab-notif-trash'>              
                          <input
                            type="checkbox"
                            name="email"
                            value={`${userSearch.ID}`}
                            onChange={handleChange}
                          />
                        </td>
                        <td className='nkab-notif-trash'>
                          <FontAwesomeIcon icon={faTrashAlt} onClick={() => notifHandler(userSearch.ID)}/>
                        </td>
                      </tr>
                    )) :null
                    }
                  </tbody>
                </table>
              </div>
            </div>
            </>:null
          }
          {
            SeeImage ?
            <>
            <br/>
            <br/>
            <div className="row">
              <div className='col-md-4'>
                <span>
                  <img src={editUser.NextImage} width="325" height="300" alt="" />
                </span>
              </div>
              <div className='col-md-4'>
                <span>
                  <img src={editUser.NextImage2} width="325" height="300" alt="" />
                </span>
              </div>
              <div className='col-md-4'>
                <span>
                  <img src={editUser.NextImage3} width="325" height="300" alt="" />
                </span>
              </div>
            </div>
            </>:null
          }
        </div>
      </div>

      <div className='nkp_diplay_container'>
          <div className="nkp_bottom_navbar">
              <div className='nkab_ul'>
                  <div className="nkab_li">
                      <a href="/">
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px", color: "#c7b301"}} icon={faHome} /></span>
                          <span className='nkp_bottom_navbar_title'>Home</span>
                      </a>
                  </div>
                  <div className="nkab_li">
                      <a href="/sell-or-buy-crypto">
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={faSync} /></span> 
                          <span className='nkp_bottom_navbar_title'>Exchange</span>
                      </a>
                  </div>
                  <div className="nkab_li">
                      <a href="#logout" onClick={handleLogout}>
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={Logged === 'Logged'?faSignOutAlt:faSignInAlt} /></span>
                          <span className='nkp_bottom_navbar_title'>{Logged === 'Logged'?"Logout":"login"}</span>
                      </a>
                  </div>
              </div>
          </div>
      </div>

    </div>
  )
}
