import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import axios from 'axios'
import ChatCustomer from '../components/ChatCustomer'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BottomNavBar from '../components/BottomNavBar'

export default function FinalTetherPaymentScreen(props) {

  const STATUS = {
    STARTED: 'Traitement en cours...',
  }
  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }

  const Logged                      = localStorage.getItem('userStatus')
  const operatorListName            = localStorage.getItem('operatorListName')
  const operatorAction              = localStorage.getItem('operatorAction')
  const operatorUserInfoName        = localStorage.getItem('operatorUserInfoName')
  const operatorUserInfoLastName    = localStorage.getItem('operatorUserInfoLastName')
  const operatorUserInfoPhone       = localStorage.getItem('operatorUserInfoPhone')
  const serviceSymbol               = localStorage.getItem('serviceSymbol')
  const serviceName                 = localStorage.getItem('serviceName')
  const converted                   = Number(localStorage.getItem('converted'))
  const dollar                      = Number(localStorage.getItem('dollar'))
  const rate                        = Number(localStorage.getItem('rate'))
  const finalPrice                  = localStorage.getItem('finalPrice')
  const tetherNet                   = localStorage.getItem('tetherNet')
  const userID                      = localStorage.getItem('userID') 
  // const lat                      = localStorage.getItem('lat')
  // const long                     = localStorage.getItem('long')


  const [displayConfirme,   setDisplayConfirme ] = useState(false)
  const [adress,            setAdress          ] = useState('')
  const [isCopied,          setIsCopied        ] = useState(false);
  const [isCopied2,         setIsCopied2       ] = useState(false);
  const [isCopied3,         setIsCopied3       ] = useState(false);
  const [isCopied4,         setIsCopied4       ] = useState(false);
  const [isCopied5,         setIsCopied5       ] = useState(false);
  const [isCopied6,         setIsCopied6       ] = useState(false);
  const [isCopied7,         setIsCopied7       ] = useState(false);

  const [copyText,          setCopyText        ] = useState(false);
  const [copyText2,         setCopyText2       ] = useState(false);
  const [copyText3,         setCopyText3       ] = useState(false);
  const [copyText4,         setCopyText4       ] = useState(false);
  const [copyText5,         setCopyText5       ] = useState(false);
  const [copyText6,         setCopyText6       ] = useState(false);
  const [copyText7,         setCopyText7       ] = useState(false);
  const [GetUser,           setGetUser         ] = useState([])
  const [User,              setUser            ] = useState([])

  const {id}                                    = useParams()
  const INITIAL_COUNT                           = 10
  const INITIAL_COUNT2 = 300
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
  const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
  const [status, setStatus]                     = useState(STATUS.STOPPED)
  const [status2,           setStatus2          ] = useState(STATUS2.STOPPED2)

  const [statusCount, setStatusCount]           = useState()

  const secondsToDisplay                        = secondsRemaining % 60
  const minutesRemaining                        = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay                        = minutesRemaining % 60
  const hoursToDisplay                          = (minutesRemaining - minutesToDisplay) / 60
  const adrTotal                                = adress.length
  const limitAdrCount                           = 8

  const adr_Coin                    = adress 

  //////////////////////////////

  // const BEP20_adress   = "0x1c73c34053d353958ba15e1537aea017a7cebb7b"
  // const BEP20_adress   = "0xE4a274a69cEe355AC25D9C56FA361f9c94A9FAEc"
  //  const BEP20_adress  = "0x56870c0606b92741394ef120dea0b2d5225a9f87"
  const BEP20_adress      = "0xe3aa65c5e116559a3016f7028f48b60c7a9107bc"
    // const BEP20_adress        = "0x4328f663044b71c3313d459b11cc60f65480a24e"
  // const TRC20_adress   = "TVPXcaQ1zd1mHzd9ZdPzBVydVfkte7qV9G"
  //  const TRC20_adress  = "TBqRP4C6Vz76Ly6au29V4aXjgDvcYke9EZ"
  const TRC20_adress      = "TT6h8QDASpG29jLcffD35A5yygE76z4t3y"
  // const TRC20_adress      = "TAvWQPz8G7uYp2AqpqZ8aj2xjo5ZDw6uxb"
  // const TRC20_adress      = "TJh4e8rQusgKsEfTgacxEipMwUcXpdi3jw"
  const PAYEER_adress     = "P1055108245"
  const PM_adress         = "U16598512"
  // const BTC_adress        = "32kspRbBPJo16ZWpVRyZwrEvsfNE6W2t4Y"
  const BTC_adress        = "1HMvHDJDDqTa6FYHcQAZypvLgECVjZEvnM"
  // const BTC_adress        = "1JEYGKiDTzfzEBK9vSYjJpwSxCxZnNNYzy"
  // const LTC_adress        = "LgdHThBx7U3JJHjb8VdAgxwMgd327aieGu"
  const LTC_adress        = "LM1uJGPLMniZhUXiuPbaH7xzPEkYMcGLcu"
  const DOGE_adress       = "D75eK533GFgxpbYQp7aMxpZcX7GRB1sW8d"

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }
  },[Logged])



  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])

  useEffect(()=>{

    if(GetUser && userID){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      setUser(userGetted[0])
    }

  },[GetUser, userID, User])



  function alerter() {
    props.history.push(`/orderhistory/`)
  }


  useEffect(() => {
    setStatusCount(twoDigits(secondsRemaining))
  }, [secondsRemaining, statusCount])

  useEffect(() => {
    setStatus(STATUS.STARTED)
  }, [STATUS.STARTED])
  
  useEffect(()=>{
    setStatus2(STATUS2.STARTED2)
  },[STATUS2.STARTED2])

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },

    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )
  
  useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },
  
    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
  )

  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining2 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining2])

  useEffect(() => {
    if (
      Number(twoDigits(minutesRemaining)) === 0 &&
      Number(twoDigits(secondsToDisplay)) === 0
    ) {
      setDisplayConfirme(true)
    }
  }, [minutesRemaining, secondsToDisplay])





  useEffect(()=>{
    if(TRC20_adress){
      setCopyText(TRC20_adress)
    }
    if(BEP20_adress){
      setCopyText2(BEP20_adress)
    }
    if(PAYEER_adress){
      setCopyText3(PAYEER_adress)
    }
    if(PM_adress){
      setCopyText4(PM_adress)
    }
    if(BTC_adress){
      setCopyText5(BTC_adress)
    }
    if(LTC_adress){
      setCopyText6(LTC_adress)
    }
    if(DOGE_adress){
      setCopyText7(DOGE_adress)
    }
  },[TRC20_adress ,BEP20_adress, PAYEER_adress, PM_adress, BTC_adress, LTC_adress, DOGE_adress])



  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick2 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText2)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied2(true);
        setTimeout(() => {
          setIsCopied2(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick3 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText3)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied3(true);
        setTimeout(() => {
          setIsCopied3(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick4 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText4)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied4(true);
        setTimeout(() => {
          setIsCopied4(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick5 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText5)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied5(true);
        setTimeout(() => {
          setIsCopied5(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick6 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText6)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied6(true);
        setTimeout(() => {
          setIsCopied6(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // onClick handler function for the copy button
  const handleCopyClick7 = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText7)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied7(true);
        setTimeout(() => {
          setIsCopied7(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const payByBscHandler = () => {
    const OrderId = id
    const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
    const SenderPhone = operatorUserInfoPhone
    const SenderChange = finalPrice
    const PaymentMethod = operatorListName
    const SenderId = User.ID
    const SenderCountry = User.countryName

      if (
        SenderName &&
        SenderPhone &&
        SenderChange &&
        PaymentMethod &&
        SenderId &&
        OrderId &&
        operatorAction &&
        SenderCountry &&
        serviceName &&
        serviceSymbol &&
        converted &&
        dollar && 
        rate &&
        adr_Coin
      ) {
        axios
          .post(
            '/api/v1/operation/create',
            {
              SenderId,
              SenderCountry,
              SenderName,
              SenderPhone,
              SenderChange : Number(SenderChange),
              PaymentMethod,
              OrderId,
              operatorAction,
              SenderEmail: User.email,
              serviceName,
              serviceSymbol,
              tetherNet,
              memo:"no memo",
              converted,
              dollar,
              rate,
              adr_Coin,
              pmode: "coins",
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {
              if(response){
                function alertHandler() {
                  confirmAlert({
                    title: `Validé avec succès !`,
                    message: ` Nous vous remercions d'avoir validé votre envoi et vous invitons à suivre le 
                    statut du traitement dans votre historique !`,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => alerter(),
                      },
                    ],
                  })
                }
                alertHandler()
  
                localStorage.removeItem('servicesId')
                localStorage.removeItem('serviceName')
                localStorage.removeItem('serviceSymbol')
                localStorage.removeItem('serviceImageLink')
                localStorage.removeItem('rate')
                localStorage.removeItem('adr_Coin')
                localStorage.removeItem('montant')
                localStorage.removeItem('converted')
                localStorage.removeItem('cfa')
                localStorage.removeItem('dollar')
                localStorage.removeItem('operatorListName')
                localStorage.removeItem('operatorAction')
                localStorage.removeItem('operatorUserInfoId')
                localStorage.removeItem('operatorUserInfoName')
                localStorage.removeItem('operatorUserInfoLastName')
                localStorage.removeItem('operatorUserInfoEmail')
                localStorage.removeItem('operatorUserInfoPhone')
                localStorage.removeItem('codePostalCountry')
                localStorage.removeItem('operatorUserInfoCountry')
                localStorage.removeItem('tetherNet')
                localStorage.removeItem('finalPrice')
                localStorage.removeItem('finalPrice3')
                localStorage.removeItem('lat')
                localStorage.removeItem('long')
                localStorage.removeItem('memo')
              }
            },
            (error) => {
              console.log(error)
            },
          )
      }
  }


  const loadingType = 'Décompte en cours...'
  const loadingAdres = "Contrôle d'adresse entrant en cours..."


  return (
    <div className="container pb-5">
      <div className='pb-5'>
        <div className="mt-5">
          <div className='pb-5'>
            {
              <div className='pb-5'>
                <div className={`${themeMode === "true" ? "final-seesion final-seesion-t pb-5" : "final-seesion pb-5"}`}>
                  <div className="order-left-side">
                    <div className="nkab-card nkab-card-body">
                      <h4 className="final-step-alert">N°: {id} </h4>
                      <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                          Priez suivre les instructions ci-dessous pour l'envoi des {operatorAction === 'sel' && (serviceSymbol !== 'BTC' || serviceSymbol !== 'LTC' || serviceSymbol !== 'DOGE') ? <strong> {' '} ${' '}{(converted * 1).toFixed(2)} </strong> : operatorAction === 'sel' && (serviceSymbol === 'BTC' || serviceSymbol === 'LTC' || serviceSymbol === 'DOGE') ? <strong> {' '} {(converted * 1).toFixed(5)} {serviceSymbol} </strong> : null}{' '}:
                      </div>
                      <span style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                        <strong>N°1 : </strong>{serviceSymbol !== 'PAYEER' && serviceSymbol !== 'PM' ? <>Scannez le{' '}
                        <strong>code QR</strong> ou copiez</>:<>Copiez</>} l'
                        <strong>adresse</strong> pour le paiement,
                      </span>
                      <br />
                      <span style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                        <strong>N°2 : </strong>Envoyez exactement le montant
                        {operatorAction === 'sel' && (serviceSymbol !== 'BTC' || serviceSymbol !== 'LTC' || serviceSymbol !== 'DOGE') ? 
                          <>
                            <strong> {' '}{(converted * 1).toFixed(2)} $ {serviceSymbol === 'PM' ? null : 'USDT'}  </strong>{' '}
                          </>
                         :operatorAction === 'sel' && (serviceSymbol === 'BTC' || serviceSymbol === 'LTC' || serviceSymbol === 'DOGE') ? 
                         <>
                           <strong> {' '}{(converted * 1).toFixed(5)} {serviceSymbol}  </strong>{' '}
                         </>
                        : null}{' '} dans un délai de <strong>5 à 10 minutes</strong>.
                      </span>
                      <br />
                      {operatorAction === 'sel' ? (
                      <div className="qr-code-div text-center">
                        {
                          serviceSymbol !== 'PAYEER' && serviceSymbol !== 'PM' && serviceSymbol !== 'BTC' && serviceSymbol === 'LTC' && serviceSymbol === 'DOGE' ?
                          <div className="qr-code-span">
                            <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Veuillez scanner le code QR ci-après afin de finaliser la transaction de{' '}
                              <strong className='success'> {(converted * 1).toFixed(2)} $ USDT </strong>{' '}
                            </div>
                            <br />
                            <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Vous pouvez utiliser une autre possibilité qui est de copier l'adresse ci-dessous afin de finaliser la transaction de{' '}
                              <strong className='success'>
                                {(converted * 1).toFixed(2)} $ USDT
                              </strong>{' '}
                            </div>
                          </div>:serviceSymbol !== 'PAYEER' && serviceSymbol !== 'PM' && serviceSymbol === 'BTC' && serviceSymbol === 'LTC' && serviceSymbol === 'DOGE' ?
                          <div className="qr-code-span">
                            <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Veuillez scanner le code QR ci-après afin de finaliser la transaction de{' '}
                              <strong className='success'> {(converted * 1).toFixed(5)} {serviceSymbol} </strong>{' '}
                            </div>
                            <br />
                            <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Vous pouvez utiliser une autre possibilité qui est de copier l'adresse ci-dessous afin de finaliser la transaction de{' '}
                              <strong className='success'> {(converted * 1).toFixed(5)} {serviceSymbol} </strong>{' '}
                            </div>
                          </div>:null
                        }
                        <div>
                          {
                          serviceSymbol === 'USDT' && tetherNet === 'bep20' ? <img src="../../images/qr_code_usdt_bep20.png" alt="qr code bep20 nkab exchange" /> : 
                            serviceSymbol === 'USDT' && tetherNet === 'trc20' ? <img src="../../images/qr_code_usdt_trc20.png" alt="qr code trc20 nkab exchange" />  : 
                            serviceSymbol === 'BTC' ? <img src="../../images/qr_code_btc.png" alt="qr code btc nkab exchange" />:
                            serviceSymbol === 'LTC' ? <img src="../../images/qr_code_ltc.png" alt="qr code ltc nkab exchange" />:
                            serviceSymbol === 'DOGE' ? <img src="../../images/qr_code_doge.png" alt="qr code doge nkab exchange" />: null}
                        </div>
                      </div>
                      ) : null}
                    </div>
                    <div className="nkab-card nkab-card-body">
                      {operatorAction === 'sel' ? (
                        <div>
                          {serviceSymbol === 'USDT' && tetherNet === 'trc20' ? (
                            <div>
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                Notre adresse de réception{' '} <strong>TRC20</strong> :{' '}
                              </span>
                              <div>
                                <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText} </span>
                                <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                                  <span>{isCopied ? 'Adresse copiée!' : 'Copier'}</span>
                                </button>
                              </div>
                              <br />
                            </div>
                          ) : serviceSymbol === 'USDT' && tetherNet === 'bep20' ? (
                            <div>
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                Notre adresse de réception{' '} <strong>BEP20</strong> :{' '}
                              </span>
                              <div>
                                <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText2} </span>
                                <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick2}>
                                  <span>{isCopied2 ? 'Adresse copiée!' : 'Copier'}</span>
                                </button>
                              </div>
                            </div>
                          ) : serviceSymbol === 'PAYEER' ? (
                          <div>
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Notre numéro de compte{' '}
                              <strong>{serviceSymbol}</strong> :
                            </span>
                            <div>
                              <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText3} </span>
                              <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick3}>
                                <span>{isCopied3 ? 'Adresse copiée!' : 'Copier'}</span>
                              </button>
                            </div>
                          </div>
                        ) : serviceSymbol === 'PM' ? (
                          <div>
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Notre numéro de compte{' '}
                              <strong>{serviceSymbol}</strong> :
                            </span>
                            <div>
                              <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText4} </span>
                              <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick4}>
                                <span>{isCopied4 ? 'Adresse copiée!' : 'Copier'}</span>
                              </button>
                            </div>
                          </div>
                        ) : serviceSymbol === 'BTC' ? (
                          <div>
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Notre adresse de réception{' '} <strong>BTC</strong> :{' '}
                            </span>
                            <div>
                              <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText5} </span>
                              <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick5}>
                                <span>{isCopied5 ? 'Adresse copiée!' : 'Copier'}</span>
                              </button>
                            </div>
                          </div>
                        ) : serviceSymbol === 'LTC' ? (
                          <div>
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Notre adresse de réception{' '} <strong>LTC</strong> :{' '}
                            </span>
                            <div>
                              <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText6} </span>
                              <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick6}>
                                <span>{isCopied5 ? 'Adresse copiée!' : 'Copier'}</span>
                              </button>
                            </div>
                          </div>
                        ) : serviceSymbol === 'DOGE' ? (
                          <div>
                            <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                              Notre adresse de réception{' '} <strong>DOGECOIN</strong> :{' '}
                            </span>
                            <div>
                              <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText7} </span>
                              <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick7}>
                                <span>{isCopied5 ? 'Adresse copiée!' : 'Copier'}</span>
                              </button>
                            </div>
                          </div>
                        ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="order-right-side">
                    <div className="nkab-card nkab-card-body">
                      <div className='row'>
                        <h5>
                          <div className='row text-center'>
                            {displayConfirme === false ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Bouton de validation dans...</span>
                            ) : displayConfirme === true ? (
                              <><br/>
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                                Renseignez votre adresse {serviceSymbol === 'USDT' && tetherNet === 'bep20'? "BEP20": serviceSymbol === 'USDT' && tetherNet === 'trc20'? "TRC20": serviceSymbol} après l'envoi puis validez !
                              </span>
                              <br/>
                              <input
                                className="wavePaymentNameInput mt-3"
                                type="text"
                                placeholder="Entrez votre adresse créditeur"
                                onChange={(e) =>
                                  setAdress(e.target.value)
                                }
                              /><br/><br/>
                              </>
                            ) : null}
                          </div>
                          {displayConfirme === false ? (
                            <div className='bolder' style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                              <span>
                                {Number(twoDigits(minutesRemaining))} {'mn'}{' '}
                              </span>
                              <span>{' : '}</span>
                              <span>
                                {Number(twoDigits(secondsToDisplay))} {'s'}{' '}
                              </span>
                            </div>
                          ) : null}
                        </h5>
                      </div>
                    </div>
                    {displayConfirme === false ? (
                      <LoadingBox loadingType={loadingType} />
                    ) : displayConfirme === true ? (
                      <div className="nkab-card nkab-card-body">
                        {
                          !adress && adrTotal < limitAdrCount ? 
                          <h2 className="confirmeDisplaing2" > Valider </h2>:
                          adress && adrTotal >= limitAdrCount ? 
                          <h2 className="confirmeDisplaing" onClick={payByBscHandler} > Valider </h2>:<LoadingBox loadingType={loadingAdres} />
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')
