import { Box, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const CurrencyRateUpdateData = ({themeMode, LineLink}) => {


  const tron        = "../images/tron.png"
  const btc         = "../images/btc.png"
  const ltc         = "../images/ltc.png"
  const usdt        = "../images/usdt.png"
  const doge        = "../images/doge.png"
  const busd        = "../images/busd.png"
  const shiba       = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum        = "../images/eth.png"
  const chainlink       = "../images/chainlink.png"
  const polkadot        = "../images/pokadot.png"
  const cardano         = "../images/cardano.png"
  const solana          = "../images/solana.png"
  const pm              = "../images/pm.png"
  const bnb             = "../images/bnb.png"
  const ton             = "../images/ton.png"


  const [BTCModifRate, setBTCModifRate] = useState(false)
  const [TRONModifRate, setTRONModifRate] = useState(false)
  const [LTCModifRate, setLTCModifRate] = useState(false)
  const [USDTModifRate, setUSDTModifRate] = useState(false)
  const [DOGEModifRate, setDOGEModifRate] = useState(false)
  const [BUSDModifRate, setBUSDModifRate] = useState(false)
  const [SHIBAModifRate, setSHIBAModifRate] = useState(false)
  const [MATICModifRate, setMATICModifRate] = useState(false)
  const [ETHModifRate, setETHModifRate] = useState(false)
  const [LINKModifRate, setLINKModifRate] = useState(false)
  const [DOTModifRate, setDOTModifRate] = useState(false)
  const [ADAModifRate, setADAModifRate] = useState(false)
  const [SOLModifRate, setSOLModifRate] = useState(false)
  const [PMModifRate, setPMModifRate] = useState(false)
  const [BNBModifRate, setBNBModifRate] = useState(false)
  const [TONModifRate, setTONModifRate] = useState(false)

  function customRateBTCHandler(){ setBTCModifRate(!BTCModifRate) }
  function customRateTRONHandler(){ setTRONModifRate(!TRONModifRate) }
  function customRateLTCHandler(){ setLTCModifRate(!LTCModifRate) }
  function customRateUSDTHandler(){ setUSDTModifRate(!USDTModifRate) }
  function customRateDOGEHandler(){ setDOGEModifRate(!DOGEModifRate) }
  function customRateBUSDHandler(){ setBUSDModifRate(!BUSDModifRate) }
  function customRateSHIBAHandler(){ setSHIBAModifRate(!SHIBAModifRate) }
  function customRateMATICHandler(){ setMATICModifRate(!MATICModifRate) }
  function customRateETHHandler(){ setETHModifRate(!ETHModifRate) }
  function customRateLINKHandler(){ setLINKModifRate(!LINKModifRate) }
  function customRateDOTHandler(){ setDOTModifRate(!DOTModifRate) }
  function customRateADAHandler(){ setADAModifRate(!ADAModifRate) }
  function customRateSOLHandler(){ setSOLModifRate(!SOLModifRate) }
  function customRatePMHandler(){ setPMModifRate(!PMModifRate) }
  function customRateBNBHandler(){ setBNBModifRate(!BNBModifRate) }
  function customRateTONHandler(){ setTONModifRate(!TONModifRate) }

  const [BTCModifRate2, setBTCModifRate2] = useState(false)
  const [TRONModifRate2, setTRONModifRate2] = useState(false)
  const [LTCModifRate2, setLTCModifRate2] = useState(false)
  const [USDTModifRate2, setUSDTModifRate2] = useState(false)
  const [DOGEModifRate2, setDOGEModifRate2] = useState(false)
  const [BUSDModifRate2, setBUSDModifRate2] = useState(false)
  const [SHIBAModifRate2, setSHIBAModifRate2] = useState(false)
  const [MATICModifRate2, setMATICModifRate2] = useState(false)
  const [ETHModifRate2, setETHModifRate2] = useState(false)
  const [LINKModifRate2, setLINKModifRate2] = useState(false)
  const [DOTModifRate2, setDOTModifRate2] = useState(false)
  const [ADAModifRate2, setADAModifRate2] = useState(false)
  const [SOLModifRate2, setSOLModifRate2] = useState(false)
  const [PMModifRate2, setPMModifRate2] = useState(false)
  const [BNBModifRate2, setBNBModifRate2] = useState(false)
  const [TONModifRate2, setTONModifRate2] = useState(false)

  function customRate2BTCHandler(){ setBTCModifRate2(!BTCModifRate2) }
  function customRate2TRONHandler(){ setTRONModifRate2(!TRONModifRate2) }
  function customRate2LTCHandler(){ setLTCModifRate2(!LTCModifRate2) }
  function customRate2USDTHandler(){ setUSDTModifRate2(!USDTModifRate2) }
  function customRate2DOGEHandler(){ setDOGEModifRate2(!DOGEModifRate2) }
  function customRate2BUSDHandler(){ setBUSDModifRate2(!BUSDModifRate2) }
  function customRate2SHIBAHandler(){ setSHIBAModifRate2(!SHIBAModifRate2) }
  function customRate2MATICHandler(){ setMATICModifRate2(!MATICModifRate2) }
  function customRate2ETHHandler(){ setETHModifRate2(!ETHModifRate2) }
  function customRate2LINKHandler(){ setLINKModifRate2(!LINKModifRate2) }
  function customRate2DOTHandler(){ setDOTModifRate2(!DOTModifRate2) }
  function customRate2ADAHandler(){ setADAModifRate2(!ADAModifRate2) }
  function customRate2SOLHandler(){ setSOLModifRate2(!SOLModifRate2) }
  function customRate2PMHandler(){ setPMModifRate2(!PMModifRate2) }
  function customRate2BNBHandler(){ setBNBModifRate2(!BNBModifRate2) }
  function customRate2TONHandler(){ setTONModifRate2(!TONModifRate2) }



  const [BNBRateStop, setBNBRateStop] = useState(false)
  const [NT_rateBNB, setNT_rateBNB] = useState([])

  const [BTCRateStop, setBTCRateStop] = useState(false)
  const [NT_rateBTC, setNT_rateBTC] = useState([])

  const [TRONRateStop, setTRONRateStop] = useState(false)
  const [NT_rateTRON, setNT_rateTRON] = useState([])

  const [USDTRateStop, setUSDTRateStop] = useState(false)
  const [NT_rateUSDT, setNT_rateUSDT] = useState([])

  const [LINKRateStop, setLINKRateStop] = useState(false)
  const [NT_rateLINK, setNT_rateLINK] = useState([])

  const [MATICRateStop, setMATICRateStop] = useState(false)
  const [NT_rateMATIC, setNT_rateMATIC] = useState([])

  const [DOTERateStop, setDOTERateStop] = useState(false)
  const [NT_rateDOTE, setNT_rateDOTE] = useState([])

  const [BUSDRateStop, setBUSDRateStop] = useState(false)
  const [NT_rateBUSD, setNT_rateBUSD] = useState([])

  const [PMRateStop, setPMRateStop] = useState(false)
  const [NT_ratePM, setNT_ratePM] = useState([])

  const [SOLRateStop, setSOLRateStop] = useState(false)
  const [NT_rateSOL, setNT_rateSOL] = useState([])

  const [ADARateStop, setADARateStop] = useState(false)
  const [NT_rateADA, setNT_rateADA] = useState([])

  const [ETHRateStop, setETHRateStop] = useState(false)
  const [NT_rateETH, setNT_rateETH] = useState([])

  const [SHIBARateStop, setSHIBARateStop] = useState(false)
  const [NT_rateSHIBA, setNT_rateSHIBA] = useState([])

  const [DOGERateStop, setDOGERateStop] = useState(false)
  const [NT_rateDOGE, setNT_rateDOGE] = useState([])

  const [LTCRateStop, setLTCRateStop] = useState(false)
  const [NT_rateLTC, setNT_rateLTC] = useState([])

  const [TONRateStop, setTONRateStop] = useState(false)
  const [NT_rateTON, setNT_rateTON] = useState([])



  const [BNBRate2Stop, setBNBRate2Stop] = useState(false)
  const [NT_rate2BNB, setNT_rate2BNB] = useState([])

  const [BTCRate2Stop, setBTCRate2Stop] = useState(false)
  const [NT_rate2BTC, setNT_rate2BTC] = useState([])

  const [TRONRate2Stop, setTRONRate2Stop] = useState(false)
  const [NT_rate2TRON, setNT_rate2TRON] = useState([])

  const [USDTRate2Stop, setUSDTRate2Stop] = useState(false)
  const [NT_rate2USDT, setNT_rate2USDT] = useState([])

  const [LINKRate2Stop, setLINKRate2Stop] = useState(false)
  const [NT_rate2LINK, setNT_rate2LINK] = useState([])

  const [MATICRate2Stop, setMATICRate2Stop] = useState(false)
  const [NT_rate2MATIC, setNT_rate2MATIC] = useState([])

  const [DOTERate2Stop, setDOTERate2Stop] = useState(false)
  const [NT_rate2DOTE, setNT_rate2DOTE] = useState([])

  const [BUSDRate2Stop, setBUSDRate2Stop] = useState(false)
  const [NT_rate2BUSD, setNT_rate2BUSD] = useState([])

  const [PMRate2Stop, setPMRate2Stop] = useState(false)
  const [NT_rate2PM, setNT_rate2PM] = useState([])

  const [SOLRate2Stop, setSOLRate2Stop] = useState(false)
  const [NT_rate2SOL, setNT_rate2SOL] = useState([])

  const [ADARate2Stop, setADARate2Stop] = useState(false)
  const [NT_rate2ADA, setNT_rate2ADA] = useState([])

  const [ETHRate2Stop, setETHRate2Stop] = useState(false)
  const [NT_rate2ETH, setNT_rate2ETH] = useState([])

  const [SHIBARate2Stop, setSHIBARate2Stop] = useState(false)
  const [NT_rate2SHIBA, setNT_rate2SHIBA] = useState([])

  const [DOGERate2Stop, setDOGERate2Stop] = useState(false)
  const [NT_rate2DOGE, setNT_rate2DOGE] = useState([])

  const [LTCRate2Stop, setLTCRate2Stop] = useState(false)
  const [NT_rate2LTC, setNT_rate2LTC] = useState([])

  const [TONRate2Stop, setTONRate2Stop] = useState(false)
  const [NT_rate2TON, setNT_rate2TON] = useState([])

  const [CurrencyHandle, setCurrencyHandle] = useState()

  const currencyHandleChange = ({target: {value}}) => {
    setCurrencyHandle(value)
  }

  const validateBTCRateHandler = () => {

      setBTCModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratebtc', 
                  {
                      ID: 1,
                      btc: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateBTCRate2Handler = () => {

      setBTCModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2btc', 
                  {
                      ID: 1,
                      btc: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateTRONHandler = () => {

      setTRONModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratetron', 
                  {
                      ID: 1,
                      tron: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }
  }
  const validateRate2TRONHandler = () => {

      setTRONModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2tron', 
                  {
                      ID: 1,
                      tron: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }
  }

  const validateRateLTCHandler = () => {

      setLTCModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateltc', 
                  {
                      ID: 1,
                      ltc: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }
  }
  const validateRate2LTCHandler = () => {

      setLTCModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2ltc', 
                  {
                      ID: 1,
                      ltc: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }
  }
  const validateRateUSDTHandler = () => {

      setUSDTModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateusdt', 
                  {
                      ID: 1,
                      usdt: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2USDTHandler = () => {

      setUSDTModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2usdt', 
                  {
                      ID: 1,
                      usdt: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateDOGEHandler = () => {

      setDOGEModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratedoge', 
                  {
                      ID: 1,
                      doge: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2DOGEHandler = () => {

      setDOGEModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2doge', 
                  {
                      ID: 1,
                      doge: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateBUSDHandler = () => {

      setBUSDModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratebusd', 
                  {
                      ID: 1,
                      busd: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2BUSDHandler = () => {

      setBUSDModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2busd', 
                  {
                      ID: 1,
                      busd: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateSHIBAHandler = () => {

      setSHIBAModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateshiba', 
                  {
                      ID: 1,
                      shiba: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2SHIBAHandler = () => {

      setSHIBAModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2shiba', 
                  {
                      ID: 1,
                      shiba: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateMATICHandler = () => {

      setMATICModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratematic', 
                  {
                      ID: 1,
                      matic: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2MATICHandler = () => {

      setMATICModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2matic', 
                  {
                      ID: 1,
                      matic: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateETHHandler = () => {

      setETHModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateeth', 
                  {
                      ID: 1,
                      eth: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2ETHHandler = () => {

      setETHModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2eth', 
                  {
                      ID: 1,
                      eth: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateLINKHandler = () => {

      setLINKModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratelink', 
                  {
                      ID: 1,
                      link: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2LINKHandler = () => {

      setLINKModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2link', 
                  {
                      ID: 1,
                      link: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateDOTHandler = () => {

      setDOTModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratedote', 
                  {
                      ID: 1,
                      dote: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2DOTHandler = () => {

      setDOTModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2dote', 
                  {
                      ID: 1,
                      dote: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateADAHandler = () => {

      setADAModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateada', 
                  {
                      ID: 1,
                      ada: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2ADAHandler = () => {

      setADAModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2ada', 
                  {
                      ID: 1,
                      ada: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateSOLHandler = () => {

      setSOLModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratesol', 
                  {
                      ID: 1,
                      sol: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2SOLHandler = () => {

      setSOLModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2sol', 
                  {
                      ID: 1,
                      sol: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRatePMHandler = () => {

      setPMModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratepm', 
                  {
                      ID: 1,
                      pm: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2PMHandler = () => {

      setPMModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2pm', 
                  {
                      ID: 1,
                      pm: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateBNBHandler = () => {

      setBNBModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-ratebnb', 
                  {
                      ID: 1,
                      bnb: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2BNBHandler = () => {

      setBNBModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2bnb', 
                  {
                      ID: 1,
                      bnb: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }

  const validateRateTONHandler = () => {

      setTONModifRate(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rateton', 
                  {
                      ID: 1,
                      ton: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }
  const validateRate2TONHandler = () => {

      setTONModifRate2(false)

      if(CurrencyHandle !== ""){

          try {
  
              axios.post('/api/update-nt-rate2ton', 
                  {
                      ID: 1,
                      ton: CurrencyHandle,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  ).then((res)=>{
                      if(res.data.msg === "success"){
  
                          if(LineLink){
                          window.location.replace("https://nkab-exchange.net/dashboard");
                          } else {
                          window.location.replace("http://localhost:3000/dashboard");
                          }
                      }
                  })
  
          } catch (err) {}
      }

  }


  //FETCH RATE DATA
    
    useEffect(()=>{

      const fetchData = async () => {

          if(!BTCRateStop){

              try {
                  const res = await axios.get('/api/nt-ratebtc')

                  if(res.data[0].ID){
                      setNT_rateBTC(res.data[0].btc)
                      setBTCRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BTCRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!BTCRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2btc')

                  if(res.data[0].ID){
                      setNT_rate2BTC(res.data[0].btc)
                      setBTCRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BTCRate2Stop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!TRONRateStop){

              try {
                  const res = await axios.get('/api/nt-ratetron')

                  if(res.data[0].ID){
                      setNT_rateTRON(res.data[0].tron)
                      setTRONRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TRONRateStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!TRONRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2tron')

                  if(res.data[0].ID){
                      setNT_rate2TRON(res.data[0].tron)
                      setTRONRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TRONRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LTCRateStop){

              try {
                  const res = await axios.get('/api/nt-rateltc')

                  if(res.data[0].ID){
                      setNT_rateLTC(res.data[0].ltc)
                      setLTCRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LTCRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!LTCRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2ltc')

                  if(res.data[0].ID){
                      setNT_rate2LTC(res.data[0].ltc)
                      setLTCRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LTCRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!USDTRateStop){

              try {
                  const res = await axios.get('/api/nt-rateusdt')

                  if(res.data[0].ID){
                      setNT_rateUSDT(res.data[0].usdt)
                      setUSDTRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[USDTRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!USDTRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2usdt')

                  if(res.data[0].ID){
                      setNT_rate2USDT(res.data[0].usdt)
                      setUSDTRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[USDTRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOGERateStop){

              try {
                  const res = await axios.get('/api/nt-ratedoge')

                  if(res.data[0].ID){
                      setNT_rateDOGE(res.data[0].doge)
                      setDOGERateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOGERateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOGERate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2doge')

                  if(res.data[0].ID){
                      setNT_rate2DOGE(res.data[0].doge)
                      setDOGERate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOGERate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BUSDRateStop){

              try {
                  const res = await axios.get('/api/nt-ratebusd')

                  if(res.data[0].ID){
                      setNT_rateBUSD(res.data[0].busd)
                      setBUSDRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BUSDRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!BUSDRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2busd')

                  if(res.data[0].ID){
                      setNT_rate2BUSD(res.data[0].busd)
                      setBUSDRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BUSDRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SHIBARateStop){

              try {
                  const res = await axios.get('/api/nt-rateshiba')

                  if(res.data[0].ID){
                      setNT_rateSHIBA(res.data[0].shiba)
                      setSHIBARateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SHIBARateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!SHIBARate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2shiba')

                  if(res.data[0].ID){
                      setNT_rate2SHIBA(res.data[0].shiba)
                      setSHIBARate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SHIBARate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!MATICRateStop){

              try {
                  const res = await axios.get('/api/nt-ratematic')

                  if(res.data[0].ID){
                      setNT_rateMATIC(res.data[0].matic)
                      setMATICRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[MATICRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!MATICRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2matic')

                  if(res.data[0].ID){
                      setNT_rate2MATIC(res.data[0].matic)
                      setMATICRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[MATICRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ETHRateStop){

              try {
                  const res = await axios.get('/api/nt-rateeth')

                  if(res.data[0].ID){
                      setNT_rateETH(res.data[0].eth)
                      setETHRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ETHRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!ETHRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2eth')

                  if(res.data[0].ID){
                      setNT_rate2ETH(res.data[0].eth)
                      setETHRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ETHRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LINKRateStop){

              try {
                  const res = await axios.get('/api/nt-ratelink')

                  if(res.data[0].ID){
                      setNT_rateLINK(res.data[0].link)
                      setLINKRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LINKRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!LINKRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2link')

                  if(res.data[0].ID){
                      setNT_rate2LINK(res.data[0].link)
                      setLINKRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LINKRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOTERateStop){

              try {
                  const res = await axios.get('/api/nt-ratedote')

                  if(res.data[0].ID){
                      setNT_rateDOTE(res.data[0].dote)
                      setDOTERateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOTERateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOTERate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2dote')

                  if(res.data[0].ID){
                      setNT_rate2DOTE(res.data[0].dote)
                      setDOTERate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOTERate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ADARateStop){

              try {
                  const res = await axios.get('/api/nt-rateada')

                  if(res.data[0].ID){
                      setNT_rateADA(res.data[0].ada)
                      setADARateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ADARateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!ADARate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2ada')

                  if(res.data[0].ID){
                      setNT_rate2ADA(res.data[0].ada)
                      setADARate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ADARate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SOLRateStop){

              try {
                  const res = await axios.get('/api/nt-ratesol')

                  if(res.data[0].ID){
                      setNT_rateSOL(res.data[0].sol)
                      setSOLRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SOLRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!SOLRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2sol')

                  if(res.data[0].ID){
                      setNT_rate2SOL(res.data[0].sol)
                      setSOLRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SOLRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!PMRateStop){

              try {
                  const res = await axios.get('/api/nt-ratepm')

                  if(res.data[0].ID){
                      setNT_ratePM(res.data[0].pm)
                      setPMRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[PMRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!PMRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2pm')

                  if(res.data[0].ID){
                      setNT_rate2PM(res.data[0].pm)
                      setPMRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[PMRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BNBRateStop){

              try {
                  const res = await axios.get('/api/nt-ratebnb')

                  if(res.data[0].ID){
                      setNT_rateBNB(res.data[0].bnb)
                      setBNBRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BNBRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!BNBRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2bnb')

                  if(res.data[0].ID){
                      setNT_rate2BNB(res.data[0].bnb)
                      setBNBRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BNBRate2Stop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!TONRateStop){

              try {
                  const res = await axios.get('/api/nt-rateton')

                  if(res.data[0].ID){
                      setNT_rateTON(res.data[0].ton)
                      setTONRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TONRateStop])
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!TONRate2Stop){

              try {
                  const res = await axios.get('/api/nt-rate2ton')

                  if(res.data[0].ID){
                      setNT_rate2TON(res.data[0].ton)
                      setTONRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TONRate2Stop])




  return (
    <div className={`${themeMode === "true" ? "row mt-3 final-seesion-t" : "row mt-3 final-seesion"}`}>
        <div className='center'>
            <div><span className={`${themeMode === "true" ? "color-or" : ""}`}>Données variantes des <strong>charges standardes</strong></span></div>
            <hr className={`${themeMode === "true" ? "color-or" : ""}`}/>
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Bitcoin</span>
                    {BTCModifRate ? <span className='stat-see-card-detail-achat' onClick={validateBTCRateHandler}>Valider</span> : null}
                    {BTCModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateBTCRate2Handler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BTCModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="btc" label="XXXXX BTC" name="btc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BTCModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateBTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateBTC} XOF</span>
                            </div>
                        } 
                        {
                            BTCModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="btc" label="XXXXX BTC" name="btc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BTCModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2BTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2BTC} XOF</span>
                            </div>
                        } 
                    </div>
                </div>
            </div>
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Tron</span>
                    {TRONModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateTRONHandler}>Valider</span> : null}
                    {TRONModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2TRONHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            TRONModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="tron" label="XXXXX TRON" name="tron" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            TRONModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateTRONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateTRON} XOF</span>
                            </div>
                        } 
                        {
                            TRONModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="tron" label="XXXXX TRON" name="tron" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            TRONModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2TRONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2TRON} XOF</span>
                            </div>
                        } 
                    </div>
                </div>
            </div>  
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Litecoin</span>
                    {LTCModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateLTCHandler}>Valider</span> : null}
                    {LTCModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2LTCHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            LTCModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ltc" label="XXXXX LTC" name="ltc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            LTCModifRate2 ? null:
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateLTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateLTC} XOF</span>
                            </div>
                        } 
                        {
                            LTCModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ltc" label="XXXXX LTC" name="ltc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            LTCModifRate ? null:
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2LTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2LTC} XOF</span>
                            </div>
                        } 
                    </div>
                </div>
            </div>  
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Tether</span>
                    {USDTModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateUSDTHandler}>Valider</span> : null}
                    {USDTModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2USDTHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            USDTModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            USDTModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateUSDTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateUSDT} XOF</span>
                            </div>
                        } 
                        {
                            USDTModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            USDTModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2USDTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2USDT} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Dogecoin</span>
                    {DOGEModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateDOGEHandler}>Valider</span> : null}
                    {DOGEModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2DOGEHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            DOGEModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="doge" label="XXXXX DOGE" name="doge" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            DOGEModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateDOGEHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateDOGE} XOF</span>
                            </div>
                        } 
                        {
                            DOGEModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="doge" label="XXXXX DOGE" name="doge" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            DOGEModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2DOGEHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2DOGE} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Binance usd</span>
                    {BUSDModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateBUSDHandler}>Valider</span> : null}
                    {BUSDModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2BUSDHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BUSDModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="busd" label="XXXXX BUSD" name="busd" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BUSDModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateBUSDHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateBUSD} XOF</span>
                            </div>
                        } 
                        {
                            BUSDModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="busd" label="XXXXX BUSD" name="busd" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BUSDModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2BUSDHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2BUSD} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Shiba</span>
                    {SHIBAModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateSHIBAHandler}>Valider</span> : null}
                    {SHIBAModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2SHIBAHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            SHIBAModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="shiba" label="XXXXX SHIBA" name="shiba" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            SHIBAModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateSHIBAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateSHIBA} XOF</span>
                            </div>
                        } 
                        {
                            SHIBAModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="shiba" label="XXXXX SHIBA" name="shiba" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            SHIBAModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2SHIBAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2SHIBA} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Polygon matic</span>
                    {MATICModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateMATICHandler}>Valider</span> : null}
                    {MATICModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2MATICHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            MATICModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="matic" label="XXXXX MATIC" name="matic" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            MATICModifRate2 ? null : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateMATICHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateMATIC} XOF</span>
                            </div>
                        } 
                        {
                            MATICModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="matic" label="XXXXX MATIC" name="matic" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            MATICModifRate ? null : 
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2MATICHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2MATIC} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Ethereum</span>
                    {ETHModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateETHHandler}>Valider</span> : null}
                    {ETHModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2ETHHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            ETHModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="eth" label="XXXXX ETH" name="eth" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            ETHModifRate2 ? null : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateETHHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateETH} XOF</span>
                            </div>
                        } 
                        {
                            ETHModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="eth" label="XXXXX ETH" name="eth" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            ETHModifRate ? null : 
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2ETHHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2ETH} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Chain link</span>
                    {LINKModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateLINKHandler}>Valider</span> : null}
                    {LINKModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2LINKHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            LINKModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="link" label="XXXXX LINK" name="link" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            LINKModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateLINKHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateLINK} XOF</span>
                            </div>
                        } 
                        {
                            LINKModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="link" label="XXXXX LINK" name="link" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            LINKModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2LINKHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2LINK} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Polkadot</span>
                    {DOTModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateDOTHandler}>Valider</span> : null}
                    {DOTModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2DOTHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            DOTModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="dot" label="XXXXX DOT" name="dot" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            DOTModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateDOTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateDOTE} XOF</span>
                            </div>
                        } 
                        {
                            DOTModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="dot" label="XXXXX DOT" name="dot" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            DOTModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2DOTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2DOTE} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Cardano</span>
                    {ADAModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateADAHandler}>Valider</span> : null}
                    {ADAModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2ADAHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'> 
                        {
                            ADAModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ada" label="XXXXX ADA" name="ada" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            ADAModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateADAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateADA} XOF</span>
                            </div>
                        } 
                        {
                            ADAModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ada" label="XXXXX ADA" name="ada" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            ADAModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2ADAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2ADA} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Solana</span>
                    {SOLModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateSOLHandler}>Valider</span> : null}
                    {SOLModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2SOLHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            SOLModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="sol" label="XXXXX SOL" name="sol" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            SOLModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateSOLHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateSOL} XOF</span>
                            </div>
                        } 
                        {
                            SOLModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="sol" label="XXXXX SOL" name="sol" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            SOLModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2SOLHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2SOL} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Perfect Money</span>
                    {PMModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRatePMHandler}>Valider</span> : null}
                    {PMModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2PMHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            PMModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="pm" label="XXXXX PM" name="pm" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            PMModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRatePMHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_ratePM} XOF</span>
                            </div>
                        } 
                        {
                            PMModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="pm" label="XXXXX PM" name="pm" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            PMModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2PMHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2PM} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Binance Smart Chain</span>
                    {BNBModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateBNBHandler}>Valider</span> : null}
                    {BNBModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2BNBHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BNBModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="bnb" label="XXXXX BNB" name="bnb" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BNBModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateBNBHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateBNB} XOF</span>
                            </div>
                        } 
                        {
                            BNBModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="bnb" label="XXXXX BNB" name="bnb" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            BNBModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2BNBHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2BNB} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ton}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Toncoin</span>
                    {TONModifRate ? <span className='stat-see-card-detail-achat' onClick={validateRateTONHandler}>Valider</span> : null}
                    {TONModifRate2 ? <span className='stat-see-card-detail-achat' onClick={validateRate2TONHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            TONModifRate ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ton" label="XXXXX TON" name="ton" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            TONModifRate2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customRateTONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rateTON} XOF</span>
                            </div>
                        } 
                        {
                            TONModifRate2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ton" label="XXXXX TON" name="ton" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            TONModifRate ? null :
                            <div>
                                <span className='stat-see-card-detail-vente' onClick={customRate2TONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>{NT_rate2TON} XOF</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default CurrencyRateUpdateData