import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import DomLoading from '../components/loadings/DomLoading/DomLoading'

export default function OperationWaveConfirmationScreen(props) {

  var   _ProWallet                                           = localStorage.getItem('_ProWallet')
  const Logged                                               = localStorage.getItem('userStatus')
  const role                                                 = localStorage.getItem('role')

  const   auth                                               = useSelector((state) => state.auth)
  const { user }                                             = auth
  const   params                                             = useParams()
  const   orderId                                            = params.id

  const [orderData,                setOrderData            ] = useState()
  const [montantTotal,             setMontantTotal         ] = useState()
  const [stopExchangeCount,        setStopExchangeCount    ] = useState(false)
  const [finalPrice,               setFinalPrice           ] = useState()
  const [editMoney,                setEditMoney            ] = useState(false)
  const [Montant,                  setMontant              ] = useState(0)
  const [last_cfa_char_nbr,        setLast_cfa_char_nbr    ] = useState()
  const [total,                    setTotal                ] = useState()
  const [isCopied,                 setIsCopied             ] = useState(false);
  const [copyText,                 setCopyText             ] = useState(false);
  const [loading,                  setLoading              ] = useState(false)
  const [WaveInfo,                 setWaveInfo             ] = useState()

  const LineLink = true


  const _adresse_a_copier   = `${!orderData?null:orderData.adr_Coin}`


  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/operation-pro");
    } else {
       window.location.replace("http://localhost:3000/operation-pro");
    }
  }

  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }

    if (Number(role) !== 1) {

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/");
      } else {
         window.location.replace("http://localhost:3000/");
      }
    }
  },[Logged, role])
  


  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/orders/${orderId}`,
        )
        setOrderData(data)
      } catch (error) {
        console.log(error)
      }

      try {
        const { data } = await axios.get(
          '/api/v1/waveconfirme/list',
        )
        
        if(data && orderData){
          const wavePay = data.filter((waveitem)=>{
            return waveitem.waveOrderId === orderData._id
          })
          const items = wavePay.slice(0, 1)
          setWaveInfo(items[0].waveSenderPhone)
          setMontantTotal(items[0].waveSenderMoney)
        }

      } catch (error) {
        console.log(error)
      }
    }
    fetchOrderData()
  }, [orderId, orderData])


  useEffect(()=>{
    if(Montant === 0){
      setTotal(Number(montantTotal))
      const cfa_nbr = total
      if(cfa_nbr){
        const cfa_nbr_to_text = cfa_nbr.toString()
        const cfa_nbr_last_char = cfa_nbr_to_text.slice(-1)
        setLast_cfa_char_nbr(Number(cfa_nbr_last_char))
      }
    }else if(Montant > 0){
      setTotal(Number(Montant))
      const cfa_nbr = total
      if(cfa_nbr){
        const cfa_nbr_to_text = cfa_nbr.toString()
        const cfa_nbr_last_char = cfa_nbr_to_text.slice(-1)
        setLast_cfa_char_nbr(Number(cfa_nbr_last_char))
      }
    }
  },[Montant,montantTotal,total])


  useEffect(() => {
    if (stopExchangeCount === false) {
      if (last_cfa_char_nbr === 0) {
        const FinalCount = total
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 5) {
        const FinalCount = total
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 1) {
        const FinalCount = total - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 2) {
        const FinalCount = total - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 3) {
        const FinalCount = total - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 4) {
        const FinalCount = total - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 5) {
        const FinalCount = total + 0
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 6) {
        const FinalCount = total - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 7) {
        const FinalCount = total - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 8) {
        const FinalCount = total - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_cfa_char_nbr === 9) {
        const FinalCount = total - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
    }
  }, [last_cfa_char_nbr, total, stopExchangeCount, finalPrice])


  useEffect(()=>{
    if(_adresse_a_copier){
      setCopyText(_adresse_a_copier)
    }
  },[_adresse_a_copier])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const sendaftersuccessbyadminhandler = async () => {
    setLoading(true)
    if(orderData){
      const ccb_user_id = orderData.adr_Coin
      const ccb_charge_id = "No charge"
      const ccb_code = "No code"
      const ccb_order_id = orderData._id
      const ccb_status_id = 'Quantité envoyée'
      const finalprice = finalPrice
      const order_name = orderData.serviceName
      const order_converted =  orderData.exchange[0].converted
      const order_dollar =  orderData.exchange[0].dollar
      const operator_name =  orderData.operatorListName 
      const order_country =  orderData.operatorUserInfoCountry
      axios
        .post(
          `/api/v1/orderconfirmed/create-orderconfirmed`,
          {
            ccb_user_id,
            ccb_charge_id,
            ccb_code,
            ccb_order_id,
            ccb_status_id,
            finalprice,
            order_name,
            order_converted,
            order_dollar,
            operator_name,
            order_country,
          },
        )
        .then(
          (response) => {
            if (response.data._id) {
              window.location.replace('https://nkab-exchange.net/operation')
              // props.history.push(`/operation`)
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }


  const BuyOrderConfirmHandler2 = () => {
    setLoading(true)
    if(orderData){
        const ccb_user_id = orderData.adr_Coin
        const ccb_charge_id = 'null'
        const ccb_code = 'null'
        const ccb_order_id = orderData._id
        const ccb_status_id = 'Achat refusé'
        const finalprice = finalPrice
        const order_converted = orderData.exchange[0].converted
        const order_dollar = orderData.exchange[0].dollar
        const order_name = orderData.serviceName
        const operator_name = orderData.operatorListName
        const order_country = orderData.operatorUserInfoCountry
        axios
          .post(
            `/api/v1/orderconfirmed/create-orderconfirmed`,
            {
              ccb_user_id,
              ccb_charge_id,
              ccb_code,
              ccb_order_id,
              ccb_status_id,
              finalprice,
              order_name,
              order_converted,
              order_dollar,
              operator_name,
              order_country,
            },
          )
          .then(
            (response) => {
                if (response.data._id) {
                  window.location.replace('https://nkab-exchange.net/operation')
                  // props.history.push(`/operation`)
                }
            },
            (error) => {
              console.log(error)
            },
          )
    }
  }


  function editedbyadminhandler(){
    setEditMoney(true)
  }

  const loadMsg = "Veuillez patienter un moment..."

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="py-5 text-center">
        <img
          className="d-block mx-auto mb-4"
          src={user.avatar}
          alt=""
          width="100"
          height="80"
        />
        <h2>NKAB CONFIRMATION DEMANDE</h2>
        { !orderData ? <DomLoading />:
          <div className='withdrav-content'>
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">
                  {orderData.operatorAction === 'sel' ? (
                    <>{'Vente de '}</>
                  ) :orderData.operatorAction === 'buy' ? (
                    <>{'Achat de '}</>
                  ) : <DomLoading />}
                  <span className='danger bolder uppercase'>{orderData.serviceName}</span> sous l'adresse :
                  <div>
                      <input className='refInput' type="text" value={copyText} readOnly disabled  style={{backgroundColor:'#fff'}}/>
                      <span className='button-isCopied p-2' onClick={handleCopyClick}>
                        <span>{isCopied ? 'Adresse copiée!' : 'Copier'}</span>
                      </span>
                    </div>
                </h5>
                <h5 className='success bolder'>
                  Quantité : {' '}
                  {!orderData ? <DomLoading /> : 
                    <>
                    {
                      orderData.serviceSymbol === "USDT" ? 
                      <>{(orderData.exchange[0].dollar * 1).toFixed(2)}$ {orderData.serviceSymbol}</>:
                      orderData.serviceSymbol === "PAYEER" ? 
                      <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                      orderData.serviceSymbol === "PM" ? 
                      <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                      orderData.serviceSymbol === "trx" ? 
                      <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                      <>{(orderData.exchange[0].converted * 1).toFixed(5)} {orderData.serviceSymbol}</>
                    }
                    </>
                  }
                </h5>
                <h5 className='danger'>Numéro wave expéditeur : {!WaveInfo?<DomLoading />:WaveInfo}</h5>
              </div>
              <div className="card-footer text-muted">
                {!orderData.updatedAt?<DomLoading />:orderData.updatedAt.slice(0,10)}{' '}à{' '}
                {!orderData.updatedAt?<DomLoading />:Number(orderData.updatedAt.slice(11,13))}
                {!orderData.updatedAt?<DomLoading />:orderData.updatedAt.slice(13,19)}
              </div>
            </div>
          </div>
        } 
        <br />
        <div className="withdrav-content">
          <h4 className="text-center mb-3">
            <span className="text-primary">
              {!orderData ? <DomLoading /> : finalPrice} XOF
            </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Produit</h6>
              </div>
              <span className="text-muted">
                {!orderData ? <DomLoading /> : orderData.serviceName}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Quantité</h6>
              </div>
              <span className="text-muted">
                {!orderData ? <DomLoading /> : 
                  <>
                  {
                    orderData.serviceSymbol === "USDT" ? 
                    <>{(orderData.exchange[0].dollar * 1).toFixed(2)}$ {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "PAYEER" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "PM" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "trx" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    <>{(orderData.exchange[0].converted * 1).toFixed(5)} {orderData.serviceSymbol}</>
                  }
                  </>
                }
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Taux</h6>
              </div>
              <span className="text-muted">
                {!orderData ? <DomLoading /> : orderData.rate} XOF
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between bg-light">
              <div className="text-success">
                <h6 className="my-0">Résultat</h6>
              </div>
              <span className="text-success">
                {!orderData ? <DomLoading /> : finalPrice} XOF
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (XOF)</span>
              <strong>
                { editMoney? <input type='number' placeholder='Entrez un nouveau montant' onChange={(e)=>setMontant(e.target.value)} />:<>{!orderData ? <DomLoading /> : finalPrice} XOF</> } 
              </strong>
            </li>
          </ul>

          <span className="card p-2" >
            {
              loading === false ? 
              <div className="input-group">
                <span className="w-100 btn btn-primary btn-lg" onClick={sendaftersuccessbyadminhandler}>
                  Confirmer la demande
                </span>
              </div> :
              loading === true ? 
              <div className="input-group">
              <span className="w-100 secondary-span2 btn-lg">
                  <LoadingBox loadMsg={loadMsg} />
                </span>
              </div> : <DomLoading />
            }
          </span>

            <span className="p-2 d-flex justify-content-between">
                {
                    loading === false ?
                    <div className="input-group">
                        <button className="w-100 btn btn-danger btn-lg" type="submit" onClick={BuyOrderConfirmHandler2}>
                            Réfuser la demande
                        </button>
                    </div>:
                loading === true ? 
                <div className="input-group">
                    <button className="w-100 btn btn-danger btn-lg" type="submit">
                  <LoadingBox loadMsg={loadMsg} />
                    </button>
                </div> : <DomLoading />
                }
                <div className="input-group">
                    <button className="w-100 btn bg-or btn-lg" type="submit" onClick={editedbyadminhandler}>
                        Editer
                    </button>
                </div>
            </span>
        </div>
      </div>
    </div>
  )
}
