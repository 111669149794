import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Modal from 'react-awesome-modal'
import ChatCustomer from '../components/ChatCustomer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward, faForward, faHome, faSignInAlt, faSignOutAlt, faSync, faWallet } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import './UserHistoryScreen.css'


export default function UserHistoryScreen(props) {

  var   _ProWallet      = localStorage.getItem('_ProWallet')
  const userID          = localStorage.getItem('userID')
  const userLastName    = localStorage.getItem('userLastName')
  const userName        = localStorage.getItem('userName')
  const Logged          = localStorage.getItem('userStatus')

  const history         = useHistory()
  const [Operation,                setOperation         ] = useState([])
  const [OperationDetail,          setOperationDetail   ] = useState([])
  const [stable,                   setStable            ] = useState(false)
  const [stableCoinbase,           setStableCoinbase    ] = useState(false)

  const LineLink = true



  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
  }


  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/nlogin");
      }
    }

  },[Logged, props.history, LineLink])

  const STATUS = {
    STARTED: 'Traitement en cours...',
  }

  const coinbaseCloseModal = () => {
    setStableCoinbase(false)
    props.history.push(`/orderhistory`)
  }

  const coinbaseOpenModal = () => {
    setStableCoinbase(true)
  }


  const COINBASE_STATUS = {
    STARTED: 'Traitement en cours...',
  }

  const COINBASE_INITIAL_COUNT = 1500

  const [COINBASE_secondsRemaining, setCOINBASE_SecondsRemaining] = useState(
    COINBASE_INITIAL_COUNT,
  )
  const [COINBASE_status, setCOINBASE_Status] = useState(
    COINBASE_STATUS.STOPPED,
  )
  const [COINBASE_statusCount, setCOINBASE_StatusCount] = useState()

  const COINBASE_secondsToDisplay = COINBASE_secondsRemaining % 60
  const COINBASE_minutesRemaining =
    (COINBASE_secondsRemaining - COINBASE_secondsToDisplay) / 60
  const COINBASE_minutesToDisplay = COINBASE_minutesRemaining % 60
  const COINBASE_hoursToDisplay =
    (COINBASE_minutesRemaining - COINBASE_minutesToDisplay) / 60

  useEffect(() => {
    setCOINBASE_StatusCount(twoDigits(COINBASE_secondsRemaining))
  }, [COINBASE_secondsRemaining, COINBASE_statusCount])

  useInterval(
    () => {
      if (COINBASE_secondsRemaining > 0) {
        setCOINBASE_SecondsRemaining(COINBASE_secondsRemaining - 1)
      } else {
        setCOINBASE_Status(COINBASE_STATUS.STOPPED)
      }
    },

    COINBASE_status === COINBASE_STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )

  useEffect(() => {
    setCOINBASE_Status(COINBASE_STATUS.STARTED)
  }, [])

  const counterStarted = props.location.hash.slice(1, 13)

  useEffect(() => {
    if (counterStarted === 'cb2%by-user') {
      coinbaseOpenModal()
    }
  }, [counterStarted])

  useEffect(() => {
    if (counterStarted === 'cb2%by-user') {
      if (
        Number(twoDigits(COINBASE_minutesRemaining)) === 0 &&
        Number(twoDigits(COINBASE_secondsToDisplay)) === 0
      ) {
        props.history.push(`/orderhistory`)
        coinbaseCloseModal()
      }
    }
  }, [
    counterStarted,
    COINBASE_minutesRemaining,
    COINBASE_secondsToDisplay,
    props.history,
    coinbaseCloseModal,
  ])

  const closeModal = () => {
    setStable(false)
    props.history.push(`/orderhistory`)
  }


  const popupstarted = props.location.pathname

  useEffect(()=>{
    if(popupstarted === "/orderhistory/"){
      setStableCoinbase(true)
    }

  },[popupstarted])

  const openModal = () => {
    setStable(true)
  }

  const INITIAL_COUNT = 1500

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
  const [status, setStatus] = useState(STATUS.STOPPED)
  const [statusCount, setStatusCount] = useState()

  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

  useEffect(() => {
    setStatusCount(twoDigits(secondsRemaining))
  }, [secondsRemaining, statusCount])

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },

    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )

  useEffect(() => {
    setStatus(STATUS.STARTED)
  }, [])

  const historyOrderRef = Number(props.location.hash.slice(1, 2))


  useEffect(() => {
    if (historyOrderRef === 1) {
      openModal()
    }
    if (historyOrderRef === 2) {
      if (
        Number(twoDigits(minutesRemaining)) === 0 &&
        Number(twoDigits(secondsToDisplay)) === 0
      ) {
        props.history.push(`/orderhistory`)
        closeModal()
      }
    }
  }, [
    historyOrderRef,
    minutesRemaining,
    secondsToDisplay,
    props.history,
    closeModal,
  ])

  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining])

  useEffect(() => {

    const fetchDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/operations',
        )

        if(data && userID){
          const dataGetted = data.filter((obj) => {
            return obj.SenderId === Number(userID)
          })

          if(dataGetted){
            const _dataGetted = dataGetted
            const items = _dataGetted.slice(0, 30)
            setOperation(items)
          }
        }

      } catch (error) {
        console.log(error)
      }
  
      localStorage.removeItem('servicesId')
      localStorage.removeItem('serviceName')
      localStorage.removeItem('serviceSymbol')
      localStorage.removeItem('serviceImageLink')
      localStorage.removeItem('rate')
      localStorage.removeItem('adr_Coin')
      localStorage.removeItem('montant')
      localStorage.removeItem('converted')
      localStorage.removeItem('cfa')
      localStorage.removeItem('dollar')
      localStorage.removeItem('operatorListName')
      localStorage.removeItem('operatorAction')
      localStorage.removeItem('operatorUserInfoId')
      localStorage.removeItem('operatorUserInfoName')
      localStorage.removeItem('operatorUserInfoLastName')
      localStorage.removeItem('operatorUserInfoEmail')
      localStorage.removeItem('operatorUserInfoPhone')
      localStorage.removeItem('codePostalCountry')
      localStorage.removeItem('operatorUserInfoCountry')
      localStorage.removeItem('tetherNet')
      localStorage.removeItem('finalPrice')
      localStorage.removeItem('finalPrice3')
    }

    fetchDada()
  }, [userID, Operation])
  
  
  function redirectToProfile() {
    props.history.push('/profile')
  }
  function redirectToServices() {
    props.history.push('/sell-or-buy-crypto')
  }

  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  const TRON    = "TRON"
  const BTC     = "Bitcoin"
  const LTC     = "Litecoin"
  const USDT   = "Tether(USDT)"
  const DOGE    = "Dogecoin"
  const BUSD    = "Binance USD"
  const SHIB    = "Shiba Inu"
  const MATIC    = "Polygon"
  const ETH     = "Ethereum"
  const LINK    = "Chainlink"
  const POLKADOT    = "Polkadot"
  const CARDANO    = "Cardano"
  const SOLANA    = "Solana"
  const PAYEER  = "Payeer"
  const PM      = "Perfect Money"
  const BNB     = "Binance Smart Chain"

  const tron      = "../images/tron.png"
  const btc    = "../images/btc.png"
  const ltc   = "../images/ltc.png"
  const usdt   = "../images/usdt.png"
  const doge    = "../images/doge.png"
  const busd    = "../images/busd.png"
  const shiba    = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum    = "../images/eth.png"
  const chainlink    = "../images/chainlink.png"
  const polkadot    = "../images/pokadot.png"
  const cardano    = "../images/cardano.png"
  const solana    = "../images/solana.png"
  const payeer      = "../images/payeer.png"
  const pm      = "../images/pm.png"
  const bnb      = "../images/bnb.png"

  const detailOption = (e) => {
    const __orderId = e.target.getAttribute('value')
    if (__orderId && Operation) {
      const dataGetted = Operation.filter((obj) => {
        return obj.ID === Number(__orderId)
      })

      setOperationDetail(dataGetted[0])
    }
  }

  const methodpayment = OperationDetail.PaymentMethod
  const op = OperationDetail.operatorAction
  const qt = (Number(OperationDetail.converted)*1).toFixed(5)
  const opname = OperationDetail.serviceSymbol
  const opaddress = OperationDetail.adr_Coin
  const opid = OperationDetail.OrderId
  const name = OperationDetail.SenderName
  const validated = OperationDetail.isAdminCompleted
  const phone = OperationDetail.SenderPhone
  const taux = OperationDetail.rate
    
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }


  return (
    <div className="container">
      <div className='mt-5'>
        <div className="px-4 py-5  text-center profile-section nkab-rounded">
          <h1 className="display-5 fw-bold color-grey"><img src="https://res.cloudinary.com/dlo6aumre/image/upload/v1660554752/avatar_j3kap7.png" className='nkab-avatar' width="30" height="30" alt="nkab avatar" /> {userLastName} {userName} </h1>
          <div className="col-lg-6 mx-auto">
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <button type="button" className="btn bg-or btn-lg px-4 gap-3" onClick={() => history.goBack()} >
                <FontAwesomeIcon icon={faBackward} style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}} /> {'Retour'}
              </button>
              <button type="button" className='btn bg-or btn-lg px-4 gap-3' onClick={redirectToServices} >
                <FontAwesomeIcon icon={faForward} style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}/> {'Accueil du service'}
              </button>
              <button type="button" className="btn btn-outline-secondary btn-lg px-4" onClick={redirectToProfile} style={{color:`${themeMode === "true" ? "" : "#c7b301"}`}}>
                Mon profil
              </button>
              <button type="button" className="btn btn-outline-secondary btn-lg px-4">
                <FontAwesomeIcon icon={faWallet} style={{ fontSize: 40, color: `${'#6b413b'}` }} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="__op-body-table">
        <table className='__op-responsive-table'>
          <thead style={{ fontSize: `${'11px'}` }}>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Contact</th>
              <th scope="col">Opération</th>
              <th scope="col">Crypto</th>
              <th scope="col">Taux</th>
              <th scope="col">Quantité</th>
              <th scope="col">Par</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
              <th scope="col">Statut</th>
              <th scope="col">Détails</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: `${'11px'}` }}>
            {!Operation ? null : (
              Operation.map((item) => (
                
                <tr key={item.ID}>
                    <th scope="row" style={{color:"#90d9ff"}}> {item.SenderEmail} </th>
                    <td data-title="Contact"> {item.SenderPhone} </td>
                    <td data-title="Opération"> {item.operatorAction === 'buy' ? 'Achat' : 'Vente'} </td>
                    <td data-title="Coin">
                      {
                        item.serviceName === TRON ? 
                        <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />:
                        item.serviceName === BTC ? 
                        <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />:
                        item.serviceName === LTC ? 
                        <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />:
                        item.serviceName === USDT ? 
                        <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />:
                        item.serviceName === DOGE ? 
                        <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />:
                        item.serviceName === BUSD ? 
                        <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />:
                        item.serviceName === SHIB ? 
                        <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />:
                        item.serviceName === MATIC ? 
                        <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />:
                        item.serviceName === POLKADOT ? 
                        <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />:
                        item.serviceName === ETH ? 
                        <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />:
                        item.serviceName === LINK ? 
                        <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />:
                        item.serviceName === CARDANO ? 
                        <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />:
                        item.serviceName === SOLANA ? 
                        <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />:
                        item.serviceName === PAYEER ? 
                        <img className='images-drapeau mr-2' src={`../${payeer}`} alt="" />:
                        item.serviceName === PM ? 
                        <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />:
                        item.serviceName === BNB ? 
                        <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />:null
                      } 
                    </td>
                    <td data-title="Taux"> {item.rate} XOF </td>
                    <td data-title="Quantité">
                    {item.operatorAction === 'buy' ? 
                      <span className='text-success'>
                        {
                          item.serviceSymbol === "USDT" ? 
                          <>{(item.dollar * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                          item.serviceSymbol === "trx" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          item.serviceSymbol === "PM" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          item.serviceSymbol === "PAYEER" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                        }</span> : 
                          item.operatorAction === 'sel' ? <span className='text-danger'>
                        {
                          item.serviceSymbol === "USDT" ? 
                          <>{(item.dollar * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                          item.serviceSymbol === "trx" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          item.serviceSymbol === "PM" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          item.serviceSymbol === "PAYEER" ? 
                          <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                          <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                        }</span> : 
                      null}</td>
                    <td data-title="Par">
                      {
                        item.PaymentMethod === OM ? 
                        <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                        item.PaymentMethod === MOMO ? 
                        <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                        item.PaymentMethod === MOOV ? 
                        <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                        item.PaymentMethod === WAVE ? 
                        <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                        item.PaymentMethod === TM ? 
                        <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                        item.PaymentMethod === FM ? 
                        <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                        item.PaymentMethod === EM ? 
                        <img className='images-drapeau mr-2' src={`../${em}`} alt="" />:null
                      }
                    </td>
                    <td data-title="Montant">
                      {`${Number(item.SenderChange)} XOF`}
                    </td>
                    <td data-title="Date">
                      {item.createdAt}
                    </td>
                    <td data-title="Statut" style={{ fontSize: `${'12px'}` }}>
                      {item.isAdminCompleted === 'non validé' ? <span className='text-warning'>non validé</span> : 
                      item.isAdminCompleted === 'validé' ? <span className='text-success'>validé</span> : 
                      item.isAdminCompleted === 'annulé' ? <span className='text-danger'>annulé</span> : null}
                    </td>
                    <td data-title="Détails">
                      <span className="details" ><a href="#nkab-popup" value={item.ID} onClick={detailOption}>Details</a></span>
                    </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <section>
        <Modal
          visible={stable}
          width="550"
          height="350"
          effect="fadeInDown"
          shouldCloseOnOverlayClick={false}
          // onClickAway={() => closeModal()}
        >
          <div className="alertModal">
            <div className="d-flex justify-content-between">
              <span>
                <h5>
                  <span className="minuteFont">
                    {Number(twoDigits(minutesRemaining))}
                    {'mn'}
                  </span>
                  <span>{' : '}</span>
                  <span className="minuteFont">
                    {Number(twoDigits(secondsToDisplay))}
                    {'s'}
                  </span>
                </h5>
              </span>
              <span className="closer" onClick={closeModal}>
                X
              </span>
            </div>
            <br />
            <div className="card text-center">
              <div className="card-header">
                <h3>Traitement d'opération</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  Nous vous remercions d'avoir finialiser votre opération. Le
                  traitement de celle-ci est en cours et sera validée après
                  toute vérification dans un délais de{' '}
                  <strong className="color-r">2 à 25 minutes</strong>.
                </h5>
              </div>
              <div className="card-footer text-muted">
                Merci de suivre le <strong>"statut"</strong> de votre historique !
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={stableCoinbase}
          width="550"
          height="350"
          effect="fadeInDown"
          shouldCloseOnOverlayClick={false}
          // onClickAway={() => closeModal()}
        >
          <div className="alertModal">
            <div className="d-flex justify-content-between">
              <span>
                {/* <h5>
                  <span className='minuteFont'>{Number(twoDigits(COINBASE_minutesRemaining))}{'mn'}</span>
                  <span>{' : '}</span>
                  <span className='minuteFont'>{Number(twoDigits(COINBASE_secondsToDisplay))}{'s'}</span>
                </h5> */}
              </span>
              <span className="closer" onClick={coinbaseCloseModal}>
                X
              </span>
            </div>
            <br />
            <div className="card text-center">
              <div className="card-header">
                <h3>Important</h3>
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  Nous vous remercions d'avoir finialiser votre opération. Le
                  traitement de celle-ci débutera et sera validé après toute
                  vérification et confirmation dans un délais de{' '}
                  <strong className="color-r">5 à 25 minutes</strong>.
                </h5>
              </div>
              <div className="card-footer text-muted">
                Merci de suivre le <strong>"statut"</strong> de votre historique !
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <ChatCustomer />

      <div>
        <div id="closed"></div>
        <div className="nkab-popup-wrapper" id="nkab-popup">
        <div className="nkab-popup-container">
        <center>
          <img className='nkab-up-img' src="https://tinypic.host/images/2023/02/16/pngtree-television-news-background-image_909008.jpg" width="400" alt=''/><br/><br/>
          <div>
            <div>ID : <strong className='text-warning'>{!opid?null:opid}</strong> </div>
            <div>{!op?null:op} de <strong className='text-success'>{!qt?null:qt} {!opname?null:opname}</strong> au taux de <strong>{!taux?null:taux} XOF</strong> par <strong>{!name?null:name}</strong> sous l'adresse : <strong className='text-info'>{!opaddress?null:opaddress}</strong> au numéro de téléphone : <strong>{!phone?null:phone}</strong>.</div>
            <div>Moyen de paiement : <strong className='text-info'>{!methodpayment?null:methodpayment}</strong></div>
            <div>
              <span>Statut : </span>
              {
                validated === "validé" ? <span className='text-success'>Validée avec succès !</span> : validated === "non validé"? <span className='text-danger'>Non traitée</span> : validated === "annulé"? <span className='text-danger'>échec de l'opération</span> :  null
              } 
            </div>
          </div>
        </center>
        <a className="nkab-popup-close" href="#closed">X</a>
        </div>
        </div>
      </div>

      <div className='nkp_diplay_container'>
          <div className="nkp_bottom_navbar">
              <div className='nkab_ul'>
                  <div className="nkab_li">
                      <a href="/">
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px", color: "#c7b301"}} icon={faHome} /></span>
                          <span className='nkp_bottom_navbar_title'>Home</span>
                      </a>
                  </div>
                  <div className="nkab_li">
                      <a href="/sell-or-buy-crypto">
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={faSync} /></span> 
                          <span className='nkp_bottom_navbar_title'>Exchange</span>
                      </a>
                  </div>
                  <div className="nkab_li">
                      <a href="#logout" onClick={handleLogout}>
                          <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={Logged === 'Logged'?faSignOutAlt:faSignInAlt} /></span>
                          <span className='nkp_bottom_navbar_title'>{Logged === 'Logged'?"Logout":"login"}</span>
                      </a>
                  </div>
              </div>
          </div>
      </div>

    </div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')