import React from 'react'

export default function ChatCustomer() {


  return (
    <section>
      <label className="chat-btn">
          {/* <a href="https://wa.me/message/WLPU34A56XCAF1"> <i className="fa fa-whatsapp fa-2x"></i> </a> */}
          <a href="https://t.me/+wTl8DCgNOwI5YWI0"> <i className="fa fa-telegram fa-2x"></i> </a>
        </label>
    </section>
  )
}

