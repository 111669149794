import React, { useEffect, useRef, useState } from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import './AdminStat.css'
import CheckIcon from '@mui/icons-material/Check';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from '@mui/material'

// Initialise pdfmake avec les polices de caractères
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AdminStatBuyMoment = (props) => {

  var _ProWallet = localStorage.getItem('_ProWallet')

   if (_ProWallet === 'true') {
     props.history.push('/nkab-pro')
   }


  const Logged = localStorage.getItem('userStatus')
  const role = localStorage.getItem('role')

   if (Logged === 'Logout') {
     props.history.push('/login')
   }

   if (Number(role) !== 1) {
     props.history.push('/')
   }


   const [LastData, setLastData] = useState([])
   
   const [counter, setCounter] = useState(50);

   const [UserStopReq, setUserStopReq] = useState(false)

   const LineLink = true
    
   const seeMoreItemsHandler= () =>{
    setStopOperation(false)
     setCounter(counter + 10);
   }

    const STATUS2 = {
      STARTED2: 'Traitement en cours...',
      }

    const INITIAL_COUNT2 = 300
    const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
    const [status2, setStatus2] = useState(STATUS2.STOPPED2)
  
    useEffect(()=>{
      setStatus2(STATUS2.STARTED2)
    },[STATUS2.STARTED2])
  
    useInterval(
      () => {
      if (secondsRemaining2 > 0) {
          setSecondsRemaining2(secondsRemaining2 - 1)
      } else {
          setStatus2(STATUS2.STOPPED2)
      }
      },
    
      status2 === STATUS2.STARTED2 ? 1000 : null,
      // passing null stops the interval
    )

    function useInterval(callback, delay) {
        const savedCallback = useRef()
    
        // Remember the latest callback.
        useEffect(() => {
        savedCallback.current = callback
        }, [callback])
    
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
    }

    const [GetUser, setGetUser] = useState([])

    const [Operation, setOperation] = useState([])

    const {id} = useParams()

    const TimeFilter = Number(id)
    const TimeStatus = id
  
  
    useEffect(()=>{
  
      const fetchDada12 = async () => {
        try {
          const { data } = await axios.get(
            '/api/v1/get-users',
          )
          setGetUser(data)
        } catch (error) {}
      }
      fetchDada12()
  
    },[GetUser])
  
    useEffect(() => {
  
      const fetchData = async () => {
        try {
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            setOperation(data)
          }
          
        } catch (error) {}
      }
  
      fetchData()
  
    }, [Operation])

    const OrderTo24Hours = (Operation) => {
      let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
      let dayOperation = Operation.filter((item) => (new Date(item.createdAt)).getTime() >= date);
      return dayOperation;
    }

    const _OrderNbr = OrderTo24Hours(Operation).length

      const [GlobalValidated, setGlobalValidated] = useState([])
      const [OnlyBuyFiltered, setOnlyBuyFiltered] = useState([])
      const [OnlyBuyFilteredData, setOnlyBuyFilteredData] = useState([])
      const [TotalBuyMoney, setTotalBuyMoney] = useState([])
      const [AllOp, setAllOp] = useState([])
      const [StopOperation, setStopOperation] = useState(false)

  useEffect(() => {

    ///////////////// Global Validated ///////////////////
    if(!StopOperation){
      if(Operation.length > 1 && TimeFilter){
        const GlobalValidatedFiltered = Operation.filter((obj) => {
          return obj.isAdminCompleted === 'validé'
        })

        if(secondsRemaining2 > 0){
          if(GlobalValidatedFiltered.length > 0){
    
            const _data = GlobalValidatedFiltered
            const items = _data.slice(0, _OrderNbr)

            setAllOp(items.length)
            
            if(items.length > 0){
              setGlobalValidated(items)
    
              if(GlobalValidated.length > 0){
    
                const GlobalOnlyBuyFiltered = GlobalValidated.filter((obj) => {
                  return obj.operatorAction === 'buy'
                })
      
                if(GlobalOnlyBuyFiltered.length > 0){
                
                    //Total Achat
                    const TotalMoneyOnlyBuy = (GlobalOnlyBuyFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyMoney(TotalMoneyOnlyBuy)
                    
                    if(GlobalOnlyBuyFiltered.length > 0 && counter === 0){
                    
                      const items = GlobalOnlyBuyFiltered.slice(0, 50)
                  
                      if(items.length > 0){
                        setOnlyBuyFilteredData(items)
                        setStopOperation(true)
                      }
                  
                    } else if(GlobalOnlyBuyFiltered.length > 0 && counter > 0){
                  
                      const items = GlobalOnlyBuyFiltered.slice(0, counter)
                  
                      if(items.length > 0){
                        setOnlyBuyFilteredData(items)
                        setStopOperation(true)
                      }
                    } 
                }
              }
    
            }
          }
        }
      }
    }

  }, [Operation, TimeFilter, TotalBuyMoney, counter])


  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  const TRON    = "TRON"
  const BTC     = "Bitcoin"
  const LTC     = "Litecoin"
  const USDT    = "Tether(USDT)"
  const DOGE    = "Dogecoin"
  const BUSD    = "Binance USD"
  const SHIB    = "Shiba Inu"
  const MATIC   = "Polygon"
  const ETH     = "Ethereum"
  const LINK    = "Chainlink"
  const POLKADOT    = "Polkadot"
  const CARDANO     = "Cardano"
  const SOLANA      = "Solana"
  const PAYEER      = "Payeer"
  const PM          = "Perfect Money"
  const BNB         = "Binance Smart Chain"
  
  const tron      = "../images/tron.png"
  const btc    = "../images/btc.png"
  const ltc   = "../images/ltc.png"
  const usdt   = "../images/usdt.png"
  const doge    = "../images/doge.png"
  const busd    = "../images/busd.png"
  const shiba    = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum    = "../images/eth.png"
  const chainlink    = "../images/chainlink.png"
  const polkadot    = "../images/pokadot.png"
  const cardano    = "../images/cardano.png"
  const solana    = "../images/solana.png"
  const payeer      = "../images/payeer.png"
  const pm      = "../images/pm.png"
  const bnb      = "../images/bnb.png"

useEffect(()=>{

  const fetchDada12 = async () => {
    try {
      if(!UserStopReq){
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        if(data.length > 0){
          setGetUser(data)
          setUserStopReq(true)
        }
      }
    } catch (error) {}
  }
  fetchDada12()

},[UserStopReq])

useEffect(()=>{
  if(OnlyBuyFilteredData.length > 0 && counter === 0){

    const items = OnlyBuyFilteredData.slice(0, 50)

    if(items > 0){
      setLastData(items)
    }

  } else if(OnlyBuyFilteredData.length > 0 && counter > 0){

    const items = OnlyBuyFilteredData.slice(0, counter)

    if(items > 0){
      setLastData(items)
    }
  } 

},[OnlyBuyFilteredData.length, OnlyBuyFilteredData, counter])

// Tableau pour stocker les lignes du tableau
const tableBody = [];

// Ajoute chaque information de la liste au tableau
LastData.forEach((info, index) => {
  tableBody.push([index+1, info.SenderEmail, info.SenderPhone, `${info.operatorAction === "buy"?"Achat":"Vente"}`, info.serviceName, `${info.rate} XOF`, (info.converted*1).toFixed(5), info.PaymentMethod, `${info.SenderChange} XOF`, `${info.updatedAt.slice(0, 10)} à ${Number(info.updatedAt.slice(11,13)) +2}${info.updatedAt.slice(13, 19)}`]);
});

// Définit la structure du document PDF
const documentDefinition = {
  content: [
    {
      text: `NKAB Exchange | Liste des opérations validées en ${TimeStatus}`,
      style: 'header',
    },
    {
      table: {
        headerRows: 1,
        // widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'], '*'],
        body: [
          ['N°', 'Email', 'Contact', 'Opération', 'Crypto', 'Taux', 'Quantité', 'MP', 'Montant', 'Date'],
          ...tableBody
        ]
      },
      fontSize: 8,
    }
  ],
  styles: {
    header: {
      fontSize: 14,
      bold: true,
      margin: [0, 0, 0, 10]
    },
  }
};

const PdfDucument = () => {
  // Génère le document PDF
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.open();
}

function returnHandler(){

  if(LineLink){
    window.location.replace(`https://nkab-exchange.net/stat`);
  } else {
     window.location.replace(`http://localhost:3000/stat`);
  }
  
}

  return (
    <div className='container pb-5' style={{backgroundColor:"#90d9ff"}}>
        <div className='pt-3'>
            <div className='stat-title p-3 mb-2 uppercase d-flex justify-content-between'>
                <span> ({AllOp}) Opérations </span>
                <div className='color-b bolder' style={{fontSize:'19px'}}>{TotalBuyMoney} XOF</div>
                <div><span className='pointer mr-2'><Button style={{backgroundColor:'red'}} onClick={returnHandler} variant="contained" size="small"> Retour</Button></span><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer (<strong>{OnlyBuyFilteredData.length}</strong>) </Button></span></div>
            </div>
            <div>
              <div className="__op-body-table">
                <table className='__op-responsive-table'>
                  <thead>
                    <tr>
                      <th scope="col">N°</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Opération</th>
                      <th scope="col">Crypto</th>
                      <th scope="col">Taux</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Réseau</th>
                      <th scope="col">Par</th>
                      <th scope="col">Montant</th>
                      <th scope="col">Date</th>
                      <th scope="col">Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OnlyBuyFilteredData.length === 0 ? <div className="d-flex justify-content-center p-3"><div className="nkab-spinner"></div></div> : OnlyBuyFilteredData.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <span className='color-white'>{index + 1}</span>
                          </th>
                          <td data-title="Email">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>{item.SenderEmail}</span> : null}
                          </td>
                          <td data-title="Contact">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>{item.SenderPhone}</span> : null}
                          </td>
                          <td data-title="Opération">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>Achat</span> : null}
                          </td>
                          <td data-title="Monnaie">
                            {
                              item.serviceName === TRON ? 
                              <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />:
                              item.serviceName === BTC ? 
                              <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />:
                              item.serviceName === LTC ? 
                              <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />:
                              item.serviceName === USDT ? 
                              <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />:
                              item.serviceName === DOGE ? 
                              <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />:
                              item.serviceName === BUSD ? 
                              <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />:
                              item.serviceName === SHIB ? 
                              <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />:
                              item.serviceName === MATIC ? 
                              <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />:
                              item.serviceName === POLKADOT ? 
                              <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />:
                              item.serviceName === ETH ? 
                              <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />:
                              item.serviceName === LINK ? 
                              <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />:
                              item.serviceName === CARDANO ? 
                              <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />:
                              item.serviceName === SOLANA ? 
                              <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />:
                              item.serviceName === PAYEER ? 
                              <img className='images-drapeau mr-2' src={`../${payeer}`} alt="" />:
                              item.serviceName === PM ? 
                              <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />:
                              item.serviceName === BNB ? 
                              <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />:null
                            } 
                          </td>
                          <td data-title="Taux">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>{item.rate} XOF</span> : null}
                          </td>
                          <td data-title="Quantité">
                            {item.operatorAction === 'buy' ? 
                              <span className='text-success'>
                              {
                                item.serviceSymbol === "USDT" ? 
                                <>{(item.converted * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                                item.serviceSymbol === "trx" ? 
                                <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                item.serviceSymbol === "PM" ? 
                                <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                item.serviceSymbol === "PAYEER" ? 
                                <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                              }</span> : null}
                          </td>
                          <td data-title="Réseau">
                            {item.tetherNet}
                          </td>
                          <td data-title="Par">
                            {
                              item.PaymentMethod === OM ? 
                              <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                              item.PaymentMethod === MOMO ? 
                              <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                              item.PaymentMethod === MOOV ? 
                              <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                              item.PaymentMethod === WAVE ? 
                              <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                              item.PaymentMethod === TM ? 
                              <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                              item.PaymentMethod === FM ? 
                              <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                              item.PaymentMethod === EM ? 
                              <img className='images-drapeau mr-2' src={`../${em}`} alt="" />:null
                            }
                            </td>
                          <td data-title="Montant">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>{item.SenderChange} XOF</span> :null}
                          </td>
                          <td data-title="Date">
                            {item.operatorAction === 'buy' ? 
                            <span className='text-success'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))+2}{item.updatedAt.slice(13, 19)}</span> : null}
                          </td>
                          <td data-title="Statut">
                            <CheckIcon sx={{color:"green"}} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div>
                  <div className='text-center p-2'><span className="stat-btn-see-more" onClick={seeMoreItemsHandler}>Voir plus <FontAwesomeIcon icon={faArrowRight} /></span></div>
                </div>
              </div>
            </div> 
        </div>
    </div>
  )
}

export default AdminStatBuyMoment