import React, { useEffect, useState } from 'react'
import './RInterfaceComponent.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import axios from 'axios'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { uid } from 'uid'

const BInterfaceComponent = ({Wallets, MyCodePin, themeMode}) => {

    const LineLink = true
    // const LineLink = false

    const [BtnOp, setBtnOp] = useState(false)
    const [CodePin, setCodePin] = useState()

    const handleChangeInput = (e) => {
      const { value } = e.target
      setCodePin(value)
    }

    function viewOpBtn(){
        setBtnOp(!BtnOp)
    }

    const [SeePrice, setSeePrice ] = useState(false)
  
    function seePriceHandler(){
        setSeePrice(true)
    }
    function unSeePriceHandler(){
        setSeePrice(false)
    }

    function RSection(){ 
          localStorage.setItem('RI', 'RInterface')
          localStorage.setItem('BI', '')
          localStorage.setItem('TI', '')
    }
    function TSection(){ 
          localStorage.setItem('RI', '')
          localStorage.setItem('BI', '')
          localStorage.setItem('TI', 'TInterface')
    }
    const [CurrencyStatus, setCurrencyStatus] = useState(false)
    const INIT2 =  { montant: 0 }
    const [Currency, setCurrency] = useState(INIT2)
    const moneyImage = localStorage.getItem("moneyImage")
    const moneyName = localStorage.getItem("moneyName")


    const [BNBProRateStop, setBNBProRateStop] = useState(false)
    const [NT_ProrateBNB, setNT_ProrateBNB] = useState([])

    const [BTCProRateStop, setBTCProRateStop] = useState(false)
    const [NT_ProrateBTC, setNT_ProrateBTC] = useState([])

    const [TRONProRateStop, setTRONProRateStop] = useState(false)
    const [NT_ProrateTRON, setNT_ProrateTRON] = useState([])

    const [USDTProRateStop, setUSDTProRateStop] = useState(false)
    const [NT_ProrateUSDT, setNT_ProrateUSDT] = useState([])

    const [LINKProRateStop, setLINKProRateStop] = useState(false)
    const [NT_ProrateLINK, setNT_ProrateLINK] = useState([])

    const [MATICProRateStop, setMATICProRateStop] = useState(false)
    const [NT_ProrateMATIC, setNT_ProrateMATIC] = useState([])

    const [DOTEProRateStop, setDOTEProRateStop] = useState(false)
    const [NT_ProrateDOTE, setNT_ProrateDOTE] = useState([])

    const [BUSDProRateStop, setBUSDProRateStop] = useState(false)
    const [NT_ProrateBUSD, setNT_ProrateBUSD] = useState([])

    const [PMProRateStop, setPMProRateStop] = useState(false)
    const [NT_ProratePM, setNT_ProratePM] = useState([])

    const [SOLProRateStop, setSOLProRateStop] = useState(false)
    const [NT_ProrateSOL, setNT_ProrateSOL] = useState([])

    const [ADAProRateStop, setADAProRateStop] = useState(false)
    const [NT_ProrateADA, setNT_ProrateADA] = useState([])

    // const [ETHProRateStop, setETHProRateStop] = useState(false)
    // const [NT_ProrateETH, setNT_ProrateETH] = useState([])

    const [SHIBAProRateStop, setSHIBAProRateStop] = useState(false)
    const [NT_ProrateSHIBA, setNT_ProrateSHIBA] = useState([])

    const [DOGEProRateStop, setDOGEProRateStop] = useState(false)
    const [NT_ProrateDOGE, setNT_ProrateDOGE] = useState([])

    const [LTCProRateStop, setLTCProRateStop] = useState(false)
    const [NT_ProrateLTC, setNT_ProrateLTC] = useState([])


    const [BNBStop, setBNBStop] = useState(false)
  const [BSCprice, setBSCprice] = useState([])

  const [BTCStop, setBTCStop] = useState(false)
  const [BTCprice, setBTCprice] = useState([])

  const [TRONStop, setTRONStop] = useState(false)
  const [TRONprice, setTRONprice] = useState([])

  const [USDTStop, setUSDTStop] = useState(false)
  const [USDTprice, setUSDTprice] = useState([])

  const [LINKStop, setLINKStop] = useState(false)
  const [CHAINLINKprice, setCHAINLINKprice] = useState([])

  const [MATICStop, setMATICStop] = useState(false)
  const [POLYGONMATICprice, setPOLYGONMATICprice] = useState([])

  const [DOTEStop, setDOTEStop] = useState(false)
  const [POKADOTprice, setPOKADOTprice] = useState([])

  const [BUSDStop, setBUSDStop] = useState(false)
  const [BUSDprice, setBUSDprice] = useState([])

  const [SOLStop, setSOLStop] = useState(false)
  const [SOLANAprice, setSOLANAprice] = useState([])

  const [ADAStop, setADAStop] = useState(false)
  const [CARDANOprice, setCARDANOprice] = useState([])

  const [SHIBAStop, setSHIBAStop] = useState(false)
  const [SHIBAprice, setSHIBAprice] = useState([])

  const [DOGEStop, setDOGEStop] = useState(false)
  const [DOGEprice, setDOGEprice] = useState([])

  const [LTCStop, setLTCStop] = useState(false)
  const [LTCprice, setLTCprice] = useState([])

  const [PMStop, setPMStop] = useState(false)
  const [PMprice, setPMprice] = useState([])

  const [ETHStop, setETHStop] = useState(false)
  const [ETHprice, setETHprice] = useState([])


  const btcPrice            = BTCprice
  const tronPrice           = TRONprice
  const ltcPrice            = LTCprice
  const dogePrice           = DOGEprice
  const bnbPrice            = BSCprice
  const usdtPrice           = USDTprice
  const busdPrice           = BUSDprice
  const maticPrice          = POLYGONMATICprice
  const shibPrice           = SHIBAprice
  const dotPrice            = POKADOTprice
  const linkPrice           = CHAINLINKprice
  const adaPrice            = CARDANOprice
  const solPrice            = SOLANAprice
  const ethPrice            = ETHprice
  const pmPrice             = PMprice
    
  useEffect(()=>{

      const fetchData = async () => {

          if(!BTCStop){

              try {
                  const res = await axios.get('/api/nt-btc')

                  if(res.data[0].ID){
                    setBTCprice(res.data[0].btc)
                    setBTCStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BTCStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!TRONStop){

              try {
                  const res = await axios.get('/api/nt-tron')

                  if(res.data[0].ID){
                    setTRONprice(res.data[0].tron)
                    setTRONStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TRONStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LTCStop){

              try {
                  const res = await axios.get('/api/nt-ltc')

                  if(res.data[0].ID){
                    setLTCprice(res.data[0].ltc)
                    setLTCStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LTCStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!USDTStop){

              try {
                  const res = await axios.get('/api/nt-usdt')

                  if(res.data[0].ID){
                    setUSDTprice(res.data[0].usdt)
                    setUSDTStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[USDTStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOGEStop){

              try {
                  const res = await axios.get('/api/nt-doge')

                  if(res.data[0].ID){
                    setDOGEprice(res.data[0].doge)
                    setDOGEStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOGEStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BUSDStop){

              try {
                  const res = await axios.get('/api/nt-busd')

                  if(res.data[0].ID){
                    setBUSDprice(res.data[0].busd)
                    setBUSDStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BUSDStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SHIBAStop){

              try {
                  const res = await axios.get('/api/nt-shiba')

                  if(res.data[0].ID){
                    setSHIBAprice(res.data[0].shiba)
                    setSHIBAStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SHIBAStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!MATICStop){

              try {
                  const res = await axios.get('/api/nt-matic')

                  if(res.data[0].ID){
                    setPOLYGONMATICprice(res.data[0].matic)
                    setMATICStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[MATICStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ETHStop){

              try {
                  const res = await axios.get('/api/nt-eth')

                  if(res.data[0].ID){
                    setETHprice(res.data[0].eth)
                    setETHStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ETHStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LINKStop){

              try {
                  const res = await axios.get('/api/nt-link')

                  if(res.data[0].ID){
                    setCHAINLINKprice(res.data[0].link)
                    setLINKStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LINKStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOTEStop){

              try {
                  const res = await axios.get('/api/nt-dote')

                  if(res.data[0].ID){
                    setPOKADOTprice(res.data[0].dote)
                    setDOTEStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOTEStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ADAStop){

              try {
                  const res = await axios.get('/api/nt-ada')

                  if(res.data[0].ID){
                    setCARDANOprice(res.data[0].ada)
                    setADAStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ADAStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SOLStop){

              try {
                  const res = await axios.get('/api/nt-sol')

                  if(res.data[0].ID){
                    setSOLANAprice(res.data[0].sol)
                    setSOLStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SOLStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!PMStop){

              try {
                  const res = await axios.get('/api/nt-pm')

                  if(res.data[0].ID){
                      setPMprice(res.data[0].pm)
                      setPMStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[PMStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BNBStop){

              try {
                  const res = await axios.get('/api/nt-bnb')

                  if(res.data[0].ID){
                    setBSCprice(res.data[0].bnb)
                    setBNBStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BNBStop])

    
    useEffect(()=>{

    const fetchData = async () => {

        if(!BTCProRateStop){

            try {
                const res = await axios.get('/api/nt-proratebtc')

                if(res.data[0].ID){
                    setNT_ProrateBTC(res.data[0].btc)
                    setBTCProRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

    },[BTCProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!TRONProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratetron')

                    if(res.data[0].ID){
                        setNT_ProrateTRON(res.data[0].tron)
                        setTRONProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TRONProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!LTCProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateltc')

                    if(res.data[0].ID){
                        setNT_ProrateLTC(res.data[0].ltc)
                        setLTCProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LTCProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!USDTProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateusdt')

                    if(res.data[0].ID){
                        setNT_ProrateUSDT(res.data[0].usdt)
                        setUSDTProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[USDTProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOGEProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratedoge')

                    if(res.data[0].ID){
                        setNT_ProrateDOGE(res.data[0].doge)
                        setDOGEProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOGEProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!BUSDProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratebusd')

                    if(res.data[0].ID){
                        setNT_ProrateBUSD(res.data[0].busd)
                        setBUSDProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BUSDProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!SHIBAProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateshiba')

                    if(res.data[0].ID){
                        setNT_ProrateSHIBA(res.data[0].shiba)
                        setSHIBAProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SHIBAProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!MATICProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratematic')

                    if(res.data[0].ID){
                        setNT_ProrateMATIC(res.data[0].matic)
                        setMATICProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[MATICProRateStop])
        
    // useEffect(()=>{

    //     const fetchData = async () => {

    //         if(!ETHProRateStop){

    //             try {
    //                 const res = await axios.get('/api/nt-prorateeth')

    //                 if(res.data[0].ID){
    //                     setNT_ProrateETH(res.data[0].eth)
    //                     setETHProRateStop(true)
    //                 }
    //             } catch (error) {
    //             }
    //         }
    //     }
    //     fetchData()

    // },[ETHProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!LINKProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratelink')

                    if(res.data[0].ID){
                        setNT_ProrateLINK(res.data[0].link)
                        setLINKProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LINKProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOTEProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratedote')

                    if(res.data[0].ID){
                        setNT_ProrateDOTE(res.data[0].dote)
                        setDOTEProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOTEProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!ADAProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateada')

                    if(res.data[0].ID){
                        setNT_ProrateADA(res.data[0].ada)
                        setADAProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ADAProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!SOLProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratesol')

                    if(res.data[0].ID){
                        setNT_ProrateSOL(res.data[0].sol)
                        setSOLProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SOLProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!PMProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratepm')

                    if(res.data[0].ID){
                        setNT_ProratePM(res.data[0].pm)
                        setPMProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[PMProRateStop])
        
    useEffect(()=>{

        const fetchData = async () => {

            if(!BNBProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratebnb')

                    if(res.data[0].ID){
                        setNT_ProrateBNB(res.data[0].bnb)
                        setBNBProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BNBProRateStop])



    const btcTaux      = NT_ProrateBTC
    const usdtTaux     = NT_ProrateUSDT
    const pmTaux       = NT_ProratePM
    const bnbTaux      = NT_ProrateBNB
    const adaTaux      = NT_ProrateADA
    const dogeTaux     = NT_ProrateDOGE
    const solTaux      = NT_ProrateSOL
    const tronTaux     = NT_ProrateTRON
    const ltcTaux      = NT_ProrateLTC
    const maticTaux    = NT_ProrateMATIC
    const dotTaux      = NT_ProrateDOTE
    const shibTaux     = NT_ProrateSHIBA
    const busdTaux     = NT_ProrateBUSD
    const linkTaux     = NT_ProrateLINK

    const INIT3 = {mt:0}
    const [Montant, setMontant] = useState(INIT3)

    
    const taux = moneyName === "Bitcoin"?btcTaux:moneyName === "Tether"?usdtTaux:moneyName === "Binance USD"?busdTaux:moneyName === "Perfect Money"?pmTaux:moneyName === "Litecoin"?ltcTaux:moneyName === "Binance"?bnbTaux:moneyName === "Cardano"?adaTaux:moneyName === "Polkadot"?dotTaux:moneyName === "Polygon"?maticTaux:moneyName === "Shiba"?shibTaux:moneyName === "Solana"?solTaux:moneyName === "Tron"?tronTaux:moneyName === "Chain link"?linkTaux:moneyName === "Dogecoin"?dogeTaux:600

    const buylimit = 400
    const buyMin = buylimit*taux

    // const charginglimit = 16
    // const chargingMin = charginglimit*taux

    const dollar = !Montant?0: Number(((Montant/taux)*1).toFixed(2))
    
    function ChoixCurrency1(){
        setCurrencyStatus(true)
    }
    function ChoixCurrency1b(){
        setCurrencyStatus(false)
    }

    //CURRENCY
    function ChoixCurrency2a(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/btc.png")
        localStorage.setItem("moneyName", "Bitcoin")
        setCurrency('')
    }
    function ChoixCurrency2b(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/usdt.png")
        localStorage.setItem("moneyName", "Tether")
        setCurrency('')
    }
    function ChoixCurrency2c(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/busd.png")
        localStorage.setItem("moneyName", "Binance USD")
        setCurrency('')
    }
    function ChoixCurrency2d(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/pm.png")
        localStorage.setItem("moneyName", "Perfect Money")
        setCurrency('')
    }
    function ChoixCurrency2e(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/ltc.png")
        localStorage.setItem("moneyName", "Litecoin")
        setCurrency('')
    }
    function ChoixCurrency2f(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/bnb.png")
        localStorage.setItem("moneyName", "Binance")
        setCurrency('')
    }
    function ChoixCurrency2g(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/cardano.png")
        localStorage.setItem("moneyName", "Cardano")
        setCurrency('')
    }
    function ChoixCurrency2h(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/pokadot.png")
        localStorage.setItem("moneyName", "Polkadot")
        setCurrency('')
    }
    function ChoixCurrency2i(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/polygonmatic.png")
        localStorage.setItem("moneyName", "Polygon")
        setCurrency('')
    }
    function ChoixCurrency2j(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/shiba.png")
        localStorage.setItem("moneyName", "Shiba")
        setCurrency('')
    }
    function ChoixCurrency2k(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/solana.png")
        localStorage.setItem("moneyName", "Solana")
        setCurrency('')
    }
    function ChoixCurrency2l(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/tron.png")
        localStorage.setItem("moneyName", "Tron")
        setCurrency('')
    }
    function ChoixCurrency2m(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/chainlink.png")
        localStorage.setItem("moneyName", "Chain link")
        setCurrency('')
    }
    function ChoixCurrency2n(){
        setCurrencyStatus(false)
        localStorage.setItem("moneyImage", "../../images/doge.png")
        localStorage.setItem("moneyName", "Dogecoin")
        setCurrency('')
    }

    const INIT = { montant: 0, converted: 0, cfa: 0, dollar: 0 }
    const [exchange, setExchange] = useState(INIT)

    const [NKABCFA, setNKABCFA ] = useState("")
    const [NKABCONVERTED, setNKABCONVERTED ] = useState("")


    useEffect(()=>{
        setNKABCONVERTED(NKABCONVERTED)
        setNKABCFA(NKABCFA)
    },[NKABCONVERTED, NKABCFA])

    const handleCurrency = (e) => {

        const { value, name } = e.target

        setCurrency(value)

        console.log(Currency)
        if(Currency && taux){
            if(moneyName === "Tether"){
                const val = Number(value.trim())
                const dollar = val * Number(usdtPrice)
                // const cfa = Number(((dollar * taux)).toFixed(2))
                // setNKABCFA((dollar * taux))
                const cfa = Number(((dollar * taux)+(2.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(2.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Binance USD"){
                const val = Number(value.trim())
                const dollar = val * Number(busdPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Bitcoin"){
                const val = Number(value.trim())
                const dollar = val * Number(btcPrice)
                const cfa = Number(((dollar * taux)+(5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Perfect Money"){
                const val = Number(value.trim())
                const dollar = val * Number(1)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Litecoin"){
                const val = Number(value.trim())
                const dollar = val * Number(ltcPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Binance"){
                const val = Number(value.trim())
                const dollar = val * Number(bnbPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Cardano"){
                const val = Number(value.trim())
                const dollar = val * Number(adaPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Polkadot"){
                const val = Number(value.trim())
                const dollar = val * Number(dotPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Polygon"){
                const val = Number(value.trim())
                const dollar = val * Number(maticPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Shiba"){
                const val = Number(value.trim())
                const dollar = val * Number(shibPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Solana"){
                const val = Number(value.trim())
                const dollar = val * Number(solPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Tron"){
                const val = Number(value.trim())
                const dollar = val * Number(tronPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Chain link"){
                const val = Number(value.trim())
                const dollar = val * Number(linkPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Dogecoin"){
                const val = Number(value.trim())
                const dollar = val * Number(dogePrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Perfect Money"){
                const val = Number(value.trim())
                const dollar = val * Number(pmPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
            if(moneyName === "Ethereum"){
                const val = Number(value.trim())
                const dollar = val * Number(ethPrice)
                const cfa = Number(((dollar * taux)+(1.5*taux)).toFixed(2))
                setNKABCFA((dollar * taux)+(1.5*taux))
                const converted = val
                setExchange({
                    [name]: val,
                    converted,
                    cfa,
                    dollar,
                })
            }
        }
    }

    const handleMontant = (e) => {

      const { value, name } = e.target

      setMontant(value)

      if(moneyName === "Tether"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(2.5)) / Number(usdtPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }

      if(moneyName === "Binance USD"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(busdPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }

      if(moneyName === "Bitcoin"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(5)) / Number(btcPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }

      if(moneyName === "Perfect Money"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / 1)
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }

      if(moneyName === "Litecoin"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(ltcPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Binance"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(bnbPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Cardano"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(adaPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Polkadot"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(dotPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Polygon"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(maticPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Shiba"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(shibPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Solana"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(solPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Tron"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(tronPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Chain link"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(linkPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Dogecoin"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(dogePrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Perfect Money"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(pmPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
      if(moneyName === "Ethereum"){
            const val = Number(value.trim())
            const dollar = val / taux
            const converted = ((dollar-(1.5)) / Number(ethPrice))
            setNKABCONVERTED(converted)
            const cfa = Number((val).toFixed(0))

            setExchange({
                [name]: val,
                converted,
                cfa,
                dollar,
            })
      }
    }

    const [NetAddress, setNetAddress] = useState("")
    const [AddressCkecked, setAddressCkecked] = useState(false)

    function trc20CheckHandler(){
        setNetAddress("TRC20")
        setAddressCkecked(true)
    }
    function polkadotCheckHandler(){
        setNetAddress("POLKADOT")
        setAddressCkecked(true)
    }
    function maticCheckHandler(){
        setNetAddress("MATIC")
        setAddressCkecked(true)
    }
    function cardanoCheckHandler(){
        setNetAddress("CARDANO")
        setAddressCkecked(true)
    }
    function solanaCheckHandler(){
        setNetAddress("SOLANA")
        setAddressCkecked(true)
    }
    function bep20CheckHandler(){
        setNetAddress("BEP20")
        setAddressCkecked(true)
    }

    const [Adress, setAdress] = useState([])

    const handleAdress = (e) => {
      const { value } = e.target
      setAdress(value)
    }

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const [passwordInput, setPasswordInput] = useState("")

    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }

    const userEmail     = localStorage.getItem('userEmail')
    const userID        = localStorage.getItem('userID')
    const userLastName  = localStorage.getItem("userLastName")
    const userName      = localStorage.getItem("userName")

    // const [Loader, setLoader] = useState(false)
    const [PassConfirmed, setPassConfirmed ] = useState("")

    const operatorUserInfoPhone  = localStorage.getItem('operatorUserInfoPhone')

    const BuyHandler = () => {
        
        if(Number(CodePin) === Number(MyCodePin)){

            // setLoader(true)
            handleClose();

            const OperationUId = uid(8)
            const OperationUId2 = uid(21)
        
            if(OperationUId && userID && moneyName && moneyImage && exchange.cfa && exchange.converted && exchange.dollar && Adress && userEmail && taux){
                axios.post('/api/v1/p-buy/create', {
                        operationUId : `ref_${OperationUId}`,
                        userId : userID,
                        tr_Id: `sop_${OperationUId2}`,
                        options : "ACHAT",
                        moneyName,
                        moneyImage,
                        montant : exchange.cfa,
                        converted : exchange.converted,
                        dollar: exchange.dollar,
                        taux,
                        netAddress : !NetAddress?moneyName:NetAddress,
                        adress : Adress,
                        email : userEmail.toLowerCase(),
                        status: "processing",
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                ).then((res)=>{ 
                    if(res){
                        axios.post('/api/v1/notification/create-notif', {
                                notifierId : `ref_${OperationUId}`,
                                notifyImageLink : moneyName,
                                notifyServicesId : `ref_${OperationUId}`,
                                notifyOperatorAction : "ACHAT",
                                notifySenderName : "premium",
                                notifySenderPhone : operatorUserInfoPhone,
                                notifySenderEmail : userEmail.toLowerCase(),
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                        ).then((res)=>{
                            if(res){
                                localStorage.setItem("buy_sop", `sop_${OperationUId2}`)
                                axios.post('/api/v1/all-p-op/create', {
                                    operationUId : `ref_${OperationUId}`,
                                    userId : userID,
                                    tr_Id: `sop_${OperationUId2}`,
                                    options : "ACHAT",
                                    moneyName,
                                    moneyImage,
                                    montant : exchange.cfa,
                                    converted : exchange.converted,
                                    dollar: exchange.dollar,
                                    fullName : `${userLastName} ${userName}`,
                                    phone : operatorUserInfoPhone,
                                    status: "no",
                                    taux,
                                    netAddress : !NetAddress?moneyName:NetAddress,
                                    adress : Adress,
                                    email : userEmail.toLowerCase(),
                                    numcompte : "no",
                                    rdv : "no",
                                    times : "no",
                                    createdAt: new Date(),
                                    updatedAt: new Date(),
                                },
                            ).then((res)=>{
                                if(res){
                                    setMontant(0)
                                    localStorage.removeItem("banqueImage")
                                    localStorage.removeItem("banqueName")
                                    localStorage.removeItem("changeImage")
                                    localStorage.removeItem("changeName")
                                    localStorage.removeItem("moneyImage")
                                    localStorage.removeItem("moneyName")
                                    localStorage.removeItem("banqueClickedName")
                                    localStorage.removeItem("nkab_rge_simple_tolk")
    
                                    if(LineLink){
                                      window.location.replace("https://nkab-exchange.net/nkab-pro");
                                    } else {
                                       window.location.replace("http://localhost:3000/nkab-pro");
                                    }
                                }
                            })
                            }
                        })
                    }
                    
                })
            }
        } else if(Number(CodePin) !== Number(MyCodePin)){
            setPassConfirmed("Ce n'est pas le bon code pin!")
        }

    }

    // const [ChoiceHover, setChoiceHover] = useState(true)
    // const [ChoiceHover2, setChoiceHover2] = useState(false)
    const [MontantCustom, setMontantCustom] = useState(false)
    const [CurrencyCustom, setCurrencyCustom] = useState(true)

    // function choiceHoverHandler(){
    //     setChoiceHover(!ChoiceHover)
    // }

    // function choiceHoverHandler2(){
    //     setChoiceHover2(!ChoiceHover2)
    // }

    function CurrencyModif(){
        setCurrencyCustom(!CurrencyCustom)
        setMontantCustom(false)
    }

    function MontantModif(){
        setMontantCustom(!MontantCustom)
        setCurrencyCustom(false)
    }

    function NewCodeHandler(){
        handleClose();
        localStorage.setItem('NewCode', 'ok')
    }

    function UpdateCodeHandler(){
        handleClose();
        localStorage.setItem('UpdateCode', 'ok')
    }


  return (
        <div className="nkab-charge-container-t mb-5">
            <div>
                <div className='d-flex justify-content-between p-4 nkab-r-text bolder'>
                    <div className="nkab-r-title-t">Achat</div>
                    <div className="nkab-dote-menu-t" onClick={viewOpBtn}>...</div>
                </div>
                <div className='nkp_status color-or bolder'>Solde disponible</div>
                {
                    !SeePrice ?
                    <div onClick={seePriceHandler} className='d-flex justify-content-center mt-4'>
                        <div className='uppercase pointer' style={{fontSize:"30px", color:"#c7b301"}}>********</div>
                        <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/visible--v1.png" alt="visible--v1"/></div>
                    </div>:
                    <div onClick={unSeePriceHandler} className='d-flex justify-content-center mt-4'>
                        <div className='uppercase bolder pointer' style={{fontSize:"25px", color:"#c7b301"}}>{(Wallets*1).toFixed(2)} XOF</div>
                        <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="27" height="27" src="https://img.icons8.com/ios/50/c7b301/closed-eye.png" alt="closed-eye"/></div>
                    </div>
                }
                {
                    BtnOp ? 
                    <div className='d-flex justify-content-center p-4 bolder'>
                        {/* <div className='nkab-r-btn ml-3 mr-3'>Recharger</div> */}
                        <div className='nkab-r-btn ml-3 mr-3' onClick={RSection}>Recharge</div>
                        <div className='nkab-r-btn ml-3 mr-3' onClick={TSection}>Transférer</div>
                    </div>:null
                }
            </div>

            { NKABCFA > 1000 && Wallets < NKABCFA ? <div className='text-center'><strong className='text-danger'>Solde insufisant!</strong></div>:null }
            
            <div className='center nkab-pro-field-color mt-3'>
                <div className='d-flex justify-content-center nkab-selector-widget mb-4'>
                    <div className="mb-1 nkp_items">
                        <div>
                            <span className={`d-flex align-items-center btn-square btn btn-light btn-lg uppercase nkp_items_square-t`} onClick={CurrencyStatus ? ChoixCurrency1b : ChoixCurrency1}>
                                <img src={!moneyImage?`${"../../images/question.png"}`:moneyImage} width="20" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-16" />
                                <span className="d-block text-left w-100 color-or bolder">{!moneyName?`${"Acheter"}`:moneyName}</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className="text-gray-500 flex-shrink-0">
                                    <path fillRule="evenodd" d="M2.707 4.293L8 9.585l5.293-5.292a1 1 0 111.414 1.414l-6 6a.996.996 0 01-.53.277l-.118.014h-.118a.997.997 0 01-.648-.29l-6-6a1 1 0 011.414-1.415z" clipRule="evenodd"></path>
                                </svg>
                            </span>
                            {
                                CurrencyStatus?
                                <div className="position-relative nkp_items_list">
                                    <div className={`ml-0 mt-2 w-100 text-left position-absolute nkp_position-absolute-t`}>
                                        <div className="_3rpmn">
                                            <div className="_65T61 css-2b097c-container">
                                                <div className="p4Aly mt-2 pb-2 css-0">
                                                    <div className={`px-2 _5HQUI css-0 select-scroll-container-t`}>
                                                        <div className="mt-1 css-0" id="react-select-2-option-0" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2a}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/btc.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Bitcoin</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">BTC</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-1" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2b}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/usdt.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Tether</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">USDT</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2c}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/busd.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Binance</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">BUSD</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2d}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/pm.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Perfect</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">PM</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2e}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/ltc.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Litecoin</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">LTC</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2f}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/bnb.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Binance</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">BNB</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2g}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/cardano.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Cardano</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">ADA</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2h}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/pokadot.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Polkadot</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">DOT</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2i}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/polygonmatic.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Polygon</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">MATIC</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2j}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/shiba.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Shiba</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">SHIB</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2k}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/solana.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Solana</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">SOL</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2l}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/tron.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title bolder'>Tron</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">TRON</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2m}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/chainlink.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Chain</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">LINK</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixCurrency2n}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/doge.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span className='nkp_buy_title'>Dogecoin</span>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 nkp_buy_title bolder">DOGE</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>:null
                            }
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='d-flex justify-content-center'>
                            {
                            moneyName === "Tether" ? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Trc20" id="trc20" className='pointer' onClick={trc20CheckHandler} />
                                <label htmlFor="trc20" className={`ml-2 pointer nkab-radio-label-t`} onClick={trc20CheckHandler}>TRC20</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateUSDT} XOF</span>
                            </div>:
                            moneyName === "Shiba" ? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Trc20" id="trc20" className='pointer' onClick={trc20CheckHandler} />
                                <label htmlFor="trc20" className={`ml-2 pointer nkab-radio-label-t`} onClick={trc20CheckHandler}>TRC20</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateSHIBA} XOF</span>
                            </div>:
                            moneyName === "Chain link"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Trc20" id="trc20" className='pointer' onClick={trc20CheckHandler} />
                                <label htmlFor="trc20" className={`ml-2 pointer nkab-radio-label-t`} onClick={trc20CheckHandler}>TRC20</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateLINK} XOF</span>
                            </div>:
                            moneyName === "Binance USD"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateBUSD} XOF</span>
                            </div>:
                            moneyName === "Polkadot"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Polkadot" id="polkadot" className='pointer' onClick={polkadotCheckHandler} />
                                <label htmlFor="polkadot" className={`ml-2 pointer nkab-radio-label-t`} onClick={polkadotCheckHandler}>POLKADOT</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateDOTE} XOF</span>
                            </div>:
                            moneyName === "Polygon"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Matic" id="matic" className='pointer' onClick={maticCheckHandler} />
                                <label htmlFor="matic" className={`ml-2 pointer nkab-radio-label-t`} onClick={maticCheckHandler}>MATIC</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateMATIC} XOF</span>
                            </div>:
                            moneyName === "Cardano"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Cardano" id="cardano" className='pointer' onClick={cardanoCheckHandler} />
                                <label htmlFor="cardano" className={`ml-2 pointer nkab-radio-label-t`} onClick={cardanoCheckHandler}>CARDANO</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateADA} XOF</span>
                            </div>:
                            moneyName === "Solana"? 
                            <div className='nkp_network_radio'>
                                <input type="radio" name="topping" value="Solana" id="solana" className='pointer' onClick={solanaCheckHandler} />
                                <label htmlFor="solana" className={`ml-2 pointer nkab-radio-label-t`} onClick={solanaCheckHandler}>SOLANA</label>

                                <input type="radio" name="topping" value="Bep20" id="bep20" className='ml-2 pointer' onClick={bep20CheckHandler}/>
                                <label htmlFor="bep20" className={`ml-2 pointer nkab-radio-label-t`} onClick={bep20CheckHandler}>BEP20</label>

                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateSOL} XOF</span>
                            </div>:
                            moneyName === "Bitcoin"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateBTC} XOF</span>
                            </div>:
                            moneyName === "Litecoin"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateLTC} XOF</span>
                            </div>:
                            moneyName === "Dogecoin"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateDOGE} XOF</span>
                            </div>:
                            moneyName === "Tron"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateTRON} XOF</span>
                            </div>:
                            moneyName === "Dogecoin"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateDOGE} XOF</span>
                            </div>:
                            moneyName === "Perfect Money"? 
                            <div className='nkp_network_radio'>
                                <span className={`ml-4 pointer nkab-radio-label-taux`}> Taux : {NT_ProrateDOGE} XOF</span>
                            </div>:null
                            }
                    </div>
                </div>
                {
                    CurrencyCustom ? 
                    <div className='mb-3'>
                        <div className='d-flex justify-content-around'>
                                <OutlinedInput className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCONVERTED > 0 ? (NKABCONVERTED*1).toFixed(5) : "Quantité"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleCurrency}/>
                            {/* {
                                ChoiceHover ?
                                <div className='pt-4'><span className='bolder pointer color-or nkab-choice-hover' onClick={choiceHoverHandler}>Saisir</span></div>:
                                <div className='pt-4'><span className='bolder pointer color-or nkab-choice-hover' onClick={choiceHoverHandler}>Saisir ici ?</span></div>
                            }
                            {
                                ChoiceHover ? 
                                <OutlinedInput style={{width:"60%"}} className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCONVERTED > 0 ? (NKABCONVERTED*1).toFixed(5) : "Quantité"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleCurrency}/>:
                                <OutlinedInput disabled style={{width:"60%"}} className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCONVERTED > 0 ? (NKABCONVERTED*1).toFixed(5) : "Quantité"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleCurrency}/>
                            } */}
                        </div>
                    </div>:
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center' style={{fontSize:"11px"}}>
                            <span className={`${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "text-danger" :     Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "text-success" : "nkp_price"}`}>
                            <strong>{`${NKABCONVERTED > 0 ? (NKABCONVERTED*1).toFixed(5) : "0"}`} XOF</strong></span>
                            <span className='nkp_price ml-3'><strong className="nkp_modif_price-t" onClick={CurrencyModif}>Modifier</strong></span>
                        </div>
                    </div>
                }
                {
                    MontantCustom ? 
                    <div className='mb-3'>
                        <div className='d-flex justify-content-around'>
                                <OutlinedInput className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCFA > 0 ? (NKABCFA*1).toFixed(2) : "Montant"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleMontant}/>
                            {/* {
                                ChoiceHover2 ?
                                <div className='pt-4'><span className='bolder pointer color-or nkab-choice-hover' onClick={choiceHoverHandler2}>Saisir</span></div>:
                                <div className='pt-4'><span className='bolder pointer color-or nkab-choice-hover' onClick={choiceHoverHandler2}>Saisir ici ?</span></div>
                            }
                            {
                                ChoiceHover2 ? 
                                <OutlinedInput style={{width:"60%"}} className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCFA > 0 ? (NKABCFA*1).toFixed(2) : "Montant"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleMontant}/>:
    
                                <OutlinedInput disabled style={{width:"60%"}} className={`nkab-r-input ${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "controler_danger" : Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "controler_success" : null}`} placeholder={`${NKABCFA > 0 ? (NKABCFA*1).toFixed(2) : "Montant"}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleMontant}/>
                            } */}
                        </div>
                    </div>:
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center' style={{fontSize:"11px"}}>
                            <span className={`${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "text-danger" :     Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "text-success" : "nkp_price"}`}>
                            <strong>{`${NKABCFA > 0 ? (NKABCFA*1).toFixed(2) : "0"}`} XOF</strong></span>
                            <span className='nkp_price ml-3'><strong className="nkp_modif_price-t" onClick={MontantModif}>Modifier</strong></span>
                        </div>
                    </div>
                }
                {
                    AddressCkecked ?
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input' placeholder={`Entrez votre adresse ${!NetAddress?"...":NetAddress}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAdress}/>
                        </div>
                    </div>:
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input' placeholder={`Entrez votre adresse ${moneyName === "Binance USD"?"BUSD":moneyName === "Bitcoin"?"BTC":moneyName === "Tether"?"USDT":moneyName === "Perfect Money"?"PM":moneyName === "Dogecoin"?"DOGE":moneyName === "Litecoin"?"LTC":moneyName === "Binance"?"BNB":moneyName === "Cardano"?"ADA":moneyName === "Polkadot"?"DOT":moneyName === "Polygon"?"MATIC":moneyName === "Shiba"?"SHIB":moneyName === "Solana"?"SOL":moneyName === "Tron"?"TRON":moneyName === "Chain link"?"LINK":""}`} type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAdress}/>
                        </div>
                    </div>
                }
                <div className='mb-3'>
                    <div className='d-flex justify-content-around' style={{fontSize:"11px"}}>
                        <span className={`${Number(exchange.cfa) > 1 && ((Number(exchange.cfa) < buyMin) || Wallets < 0) ? "text-danger" :     Number(exchange.cfa) > 50 && (Number(exchange.cfa) > buyMin) ? "text-success" : "nkp_price"}`}>
                        {
                        moneyName === "Binance USD" ? <strong>{`${((exchange.converted)*1).toFixed(5)} BUSD`}</strong> : 
                        moneyName === "Bitcoin" ? <strong>{`${((exchange.converted)*1).toFixed(5)} BTC`}</strong> : 
                        moneyName === "Tether" ? <strong>{`${((exchange.converted)*1).toFixed(2)} USDT`}</strong> : 
                        moneyName === "Perfect Money" ? <strong>{`${(exchange.converted).toFixed(2)} PM`}</strong> :
                        moneyName === "Dogecoin" ? <strong>{`${(exchange.converted).toFixed(2)} DOGE ≈ ${(dollar*1).toFixed(5)} $`}</strong> :
                        moneyName === "Litecoin" ? <strong>{`${((exchange.converted)*1).toFixed(5)} LTC`}</strong> : 
                        moneyName === "Binance" ? <strong>{`${((exchange.converted)*1).toFixed(5)} BNB`}</strong> :
                        moneyName === "Cardano" ? <strong>{`${((exchange.converted)*1).toFixed(2)} ADA`}</strong> :
                        moneyName === "Polkadot" ? <strong>{`${((exchange.converted)*1).toFixed(2)} DOT`}</strong> :
                        moneyName === "Polygon" ? <strong>{`${((exchange.converted)*1).toFixed(2)} MATIC`}</strong> :
                        moneyName === "Shiba" ? <strong>{`${((exchange.converted)*1).toFixed(2)} SHIB`}</strong> :
                        moneyName === "Solana" ? <strong>{`${((exchange.converted)*1).toFixed(2)} SOL`}</strong> :
                        moneyName === "Tron" ? <strong>{`${((exchange.converted)*1).toFixed(2)} TRON`}</strong> :
                        moneyName === "Chain link" ? <strong>{`${((exchange.converted)*1).toFixed(2)} LINK`}
                        </strong> :
                        "0 $" }</span>
                        <span className='nkp_price'><strong>min : $400</strong></span>
                    </div>
                </div>
                <div className='d-flex justify-content-center mb-5'>
                    {
                        Number(exchange.cfa) > buyMin && Adress.length > 5 && Wallets > NKABCFA? 
                        <span style={{textDecoration:"none"}} className='nkp_popup_link-1 text-center pointer' onClick={handleClickOpen}>Continuer</span>:
                        <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>
                    }
                </div>

                <Dialog
                    open={open}
                    PaperProps={{
                    component: 'form',
                    }}
                >
                    <DialogTitle><span style={{color:"#fff", fontSize:"21px"}}>Code Pin</span></DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    <div><span className='mb-2' style={{color:"#fff", fontSize:"16px"}}>Saisissez votre code pin à fin de finaliser votre <strong>achat</strong></span></div>
                    {
                        Number(CodePin) === Number(MyCodePin) ? null : <div className='text-center'><span className='mb-2 mt-2 text-danger'>{PassConfirmed}</span></div>
                    }
                    </DialogContentText>
                    <OutlinedInput
                        autoFocus
                        required
                        value={passwordInput}
                        onChange={handlePasswordChange}
                        margin="dense"
                        id="name"
                        name="cp"
                        label="Code pin"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff sx={{color:"#c7b301"}}/> : <Visibility sx={{color:"#c7b301"}} />}
                            </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        variant="standard"
                        placeholder="code pin"
                        sx={{color:"#fff", mb:"7px", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                    />
                    <OutlinedInput
                        required
                        value={CodePin}
                        onChange={handleChangeInput}
                        margin="dense"
                        id="name"
                        name="cp"
                        label="Code pin"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff sx={{color:"#c7b301"}} /> : <Visibility sx={{color:"#c7b301"}} />}
                            </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        variant="standard"
                        placeholder="confirmez le code pin"
                        sx={{color:"#fff", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                        className='nkab-popup-tex-color'
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} sx={{color:"#c7b301"}}>Revenir</Button>
                    <Button sx={{color:"#c7b301"}} onClick={BuyHandler}>Confirmer</Button>
                    </DialogActions>
                    <DialogActions>
                    <Button onClick={UpdateCodeHandler} sx={{color:"#c7b301"}}>Code oublié ?</Button>
                    {
                        MyCodePin.length > 3 ? null : 
                        <Button sx={{color:"#c7b301"}} onClick={NewCodeHandler}>Générer un code</Button>
                    }
                    </DialogActions>
                </Dialog>
            </div>
            <div></div>
        </div>
  )
}

export default BInterfaceComponent