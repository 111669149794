import React from 'react';

export default function LoadingBox({
    loadingType, 
    loadMsg, 
    loadServicesMsg, 
    loadOperationMsg, 
    loadingAdres, 
    loadChargeMsg, 
    uniqueLoading,
    loadingActived,
    activateLoading,
    validation
}) {
    return (
        <div className="loading">
            <i className="fa fa-spinner fa-spin"></i>
            {' '}
            {
                loadingType ? 
                loadingType : 
                loadMsg ? 
                loadMsg :
                loadingActived ? 
                loadingActived : 
                loadServicesMsg ? 
                loadServicesMsg : 
                loadOperationMsg ? 
                loadOperationMsg: 
                uniqueLoading ? 
                uniqueLoading: 
                loadChargeMsg ? 
                loadChargeMsg : 
                activateLoading ? 
                activateLoading :
                validation ? 
                validation :
                loadingAdres ? 
                loadingAdres : 
                "Lecture en cours..."
            }
            
        </div>
    );
};
