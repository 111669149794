import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  showErrMessage,
  showSuccessMessage,
} from '../components/utils/notification/Notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faKey } from '@fortawesome/free-solid-svg-icons'
import SupportCommunity from '../components/SupportCommunity'
import LoadingBox from '../components/LoadingBox'

function ActivationEmail(props) {

  const [msg,               setMsg     ] = useState('')
  const [err,               setErr     ] = useState()
  const [Loading,           setLoading ] = useState(true)

  const email = localStorage.getItem('email')

  useEffect(() => {
      const activationEmail = async () => {
        try {
          const res = await axios.post(
            '/api/activate', {email}
          )
          if(res.data.msg){
            setMsg(res.data.msg)
            localStorage.removeItem('email')
            setLoading(false)
          }
        } catch (err) {
          setErr(err)
        }
      }
      activationEmail()
  }, [email])

  const __color = "color-b"

  const activateLoading = "Veuillez patienter un moment pendant l'activation de votre compte..."

  return (
    <div className="container">
      <div style={{ padding: `${'10px'}` }}>
        {err && showErrMessage(err)}
        <div className='text-center p-3'>
          <h2>{Loading?<LoadingBox activateLoading={activateLoading} />:null}</h2>
        </div>
        {msg && showSuccessMessage(msg)}<div className="row">
          <div className="col-md-12">
              <div className="error-template">
                {
                  msg ?
                  <>
                  <h1>Félicitation !</h1>
                  <h2 className='success'>Votre compte est activé. Vous pouvez maintenant vous connecter!</h2>
                  <div className="error-details"> Marché d'échange de cryptomonnaie </div>
                  <SupportCommunity __color={__color} /> 
                  <div className="error-actions">
                    <a href="/login" className="btn btn-primary btn-lg" style={{backgroundColor: `${'#0a146e'}`}}>
                      <span className="glyphicon glyphicon-home"> <FontAwesomeIcon icon={faKey} /> </span> {' '} Se connecter 
                    </a>
                    <a href="/contact" className="btn btn-default btn-lg" style={{color: `${'#0a146e'}`}}>
                      <span className="glyphicon glyphicon-envelope"></span> 
                      Nous contacter
                    </a>
                  </div>
                  </>: 
                  err ?
                  <>
                  <h1>Hoops !</h1>
                  <h2 className='danger'>Quelque chose ne va pas.</h2>
                  <div className="error-details"> Marché d'échange de cryptomonnaie </div>
                  <SupportCommunity __color={__color} /> 
                  <div className="error-actions">
                    <a href="/" className="btn btn-primary btn-lg" style={{backgroundColor: `${'#0a146e'}`}}>
                      <span className="glyphicon glyphicon-home">
                        <FontAwesomeIcon
                          icon={faHome}
                        />
                      </span>
                        {' '} Accueil
                    </a>
                    <a href="/contact" className="btn btn-default btn-lg" style={{color: `${'#0a146e'}`}}>
                      <span className="glyphicon glyphicon-envelope"></span> 
                      Support
                    </a>
                  </div>
                  </>:null
                }
                  
              </div>
          </div>
      </div>
      </div>
    </div>
  )
}

export default ActivationEmail
