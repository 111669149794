import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function RecentSuccessOperation() {

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

  const [recentSuccess,          setRecentSuccess           ] = useState([])
  const [OperationStopReq,       setOperationStopReq        ] = useState(false)


  useEffect(() => {

    const fetchData = async () => {
      try {

        if(!OperationStopReq){
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            const _data = data
            const items = _data.slice(0, 20)
            
            if(items.length > 0){

              var RecentSuccess = items.filter(obj => {
                return obj.isAdminCompleted === "validé";
              });

              if(RecentSuccess.length > 0){

                setRecentSuccess(RecentSuccess)
                setOperationStopReq(true)
                
              }

            }
          }
        }
        
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()

  }, [OperationStopReq])

  // useEffect(() => {
  //   if(Operation.length > 0){
  //     if(Operation){
  //       var RecentSuccess = Operation.filter(obj => {
  //         return obj.isAdminCompleted === "validé";
  //       });
  //       if(RecentSuccess.length > 0){
  //         setRecentSuccess(RecentSuccess)
  //       }
  //     }
  //   }
  // }, [Operation])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const ci = "Côte d'Ivoire"
  const sen = "Sénégal"
  const burk = "Burkina Fasso"
  const benin = "Benin"
  const togo = "Togo"
  const mali = "Mali"



  return (
    <section className="temoigne-section-main animate__animated animate__backInLeft">

        <div className="_our-header">
          <h1 className="header__title" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}} >Transactions récentes</h1>
          <h2 className="header__subtitle" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Transactions les plus récentes effectuées par nos clients</h2>
        </div>

        
          {
            recentSuccess.length === 0 ? 
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>:
            <Carousel
              responsive={responsive}
              itemClass={`${themeMode === "true" ? "react-multi-carousel-item react-multi-carousel-item-t" : "react-multi-carousel-item"}`}
            >
              { recentSuccess.map((confirme)=>(
                  <div key={confirme.ID}>
                    <div className='lead-container'>
                      <div>
                        <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}><strong>Pays : </strong></span>
                        <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                          {
                            confirme.SenderCountry === ci ?
                            <img className="images-drapeau" src="../images/ci.png" alt="" style={{borderRadius:"50px"}}/>:
                            confirme.SenderCountry === sen ?
                            <img className="images-drapeau" src="../images/sen.png" alt="" style={{borderRadius:"50px"}}/>:
                            confirme.SenderCountry === burk ?
                            <img className="images-drapeau" src="../images/burk.png" alt="" style={{borderRadius:"50px"}}/>:
                            confirme.SenderCountry === benin ?
                            <img className="images-drapeau" src="../images/benin.png" alt="" style={{borderRadius:"50px"}}/>:
                            confirme.SenderCountry === togo ?
                            <img className="images-drapeau" src="../images/togo.png" alt="" style={{borderRadius:"50px"}}/>:
                            confirme.SenderCountry === mali ?
                            <img className="images-drapeau" src="../images/mali.png" alt="" style={{borderRadius:"50px"}}/>:null
                          }
                          {' '}
                          {confirme.SenderCountry}
                        </span>
                      </div>
                      <div>
                        <span>
                            {
                            confirme.operatorAction === "sel" ? <strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Vente</strong> : 
                            confirme.operatorAction === "buy" ? <strong style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Achat</strong> : null
                            } :{' '}
                            {
                              confirme.operatorAction === "sel" ? <strong className='danger'>
                              {confirme.serviceName === "Tether" || confirme.serviceName === "Tether(USDT)" ? <> {(confirme.converted* 1).toFixed(2)}{' $ USDT'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Binance Smart Chain" ? <> {(confirme.converted* 1).toFixed(5)}{' BNB'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Litecoin" ? <> {(confirme.converted* 1).toFixed(5)}{' LTC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Bitcoin" ? <> {(confirme.converted* 1).toFixed(5)}{' BTC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "TRON" ? <> {(confirme.converted* 1).toFixed(2)}{' TRX'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Dogecoin" ? <> {(confirme.converted* 1).toFixed(2)}{' DOGE'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Payeer" ? <> {(confirme.converted* 1).toFixed(2)}{' PAYEER'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Perfect Money" ? <> {(confirme.converted* 1).toFixed(2)}{' PM'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Binance USD" ? <> {(confirme.converted* 1).toFixed(2)}{' BUSD'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Shiba Inu" ? <> {(confirme.converted* 1).toFixed(2)}{' SHIB'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Polygon" ? <> {(confirme.converted* 1).toFixed(2)}{' MATIC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Ethereum" ? <> {(confirme.converted* 1).toFixed(2)}{' ETH'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Polkadot" ? <> {(confirme.converted* 1).toFixed(2)}{' DOT'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Chainlink" ? <> {(confirme.converted* 1).toFixed(2)}{' LINK'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Cardano" ? <> {(confirme.converted* 1).toFixed(2)}{' ADA'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Solana" ? <> {(confirme.converted* 1).toFixed(2)}{' SOL'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              null}
                              </strong>:
                              confirme.operatorAction === "buy" ? <strong className='success'>
                              {confirme.serviceName === "Tether" || confirme.serviceName === "Tether(USDT)" ? <> {(confirme.converted* 1).toFixed(2)}{' $ USDT'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Binance Smart Chain" ? <> {(confirme.converted* 1).toFixed(5)}{' BNB'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Litecoin" ? <> {(confirme.converted* 1).toFixed(5)}{' LTC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Bitcoin" ? <> {(confirme.converted* 1).toFixed(5)}{' BTC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "TRON" ? <> {(confirme.converted* 1).toFixed(2)}{' TRX'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Dogecoin" ? <> {(confirme.converted* 1).toFixed(2)}{' DOGE'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Payeer" ? <> {(confirme.converted* 1).toFixed(2)}{' PAYEER'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                              confirme.serviceName === "Perfect Money" ? <> {(confirme.converted* 1).toFixed(2)}{' PM'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Binance USD" ? <> {(confirme.converted* 1).toFixed(2)}{' BUSD'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Shiba Inu" ? <> {(confirme.converted* 1).toFixed(2)}{' SHIB'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Polygon" ? <> {(confirme.converted* 1).toFixed(2)}{' MATIC'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Ethereum" ? <> {(confirme.converted* 1).toFixed(2)}{' ETH'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Polkadot" ? <> {(confirme.converted* 1).toFixed(2)}{' DOT'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Chainlink" ? <> {(confirme.converted* 1).toFixed(2)}{' LINK'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Cardano" ? <> {(confirme.converted* 1).toFixed(2)}{' ADA'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              confirme.serviceName === "Solana" ? <> {(confirme.converted* 1).toFixed(2)}{' SOL'} <span style={{color:`${themeMode === "true" ? "#b1e4ff" : "#0a146e"}`}}> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                              null}
                              </strong>:null
                            }
                        </span>
                      </div>
                      <div style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                        <span>
                          <strong>Publié le : </strong>
                        </span>
                        <span>{' '}{confirme.updatedAt.slice(0,10)} à {Number(confirme.updatedAt.slice(11,13)) + 2}{confirme.updatedAt.slice(13,19)}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </Carousel>
          }

    </section>
  )
}
