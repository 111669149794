import { Box, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const CurrencyRateChargeProUpdateData = ({themeMode, LineLink}) => {


    const tron        = "../images/tron.png"
    const btc         = "../images/btc.png"
    const ltc         = "../images/ltc.png"
    const usdt        = "../images/usdt.png"
    const doge        = "../images/doge.png"
    const busd        = "../images/busd.png"
    const shiba       = "../images/shiba.png"
    const polygonmatic    = "../images/polygonmatic.png"
    const ethereum        = "../images/eth.png"
    const chainlink       = "../images/chainlink.png"
    const polkadot        = "../images/pokadot.png"
    const cardano         = "../images/cardano.png"
    const solana          = "../images/solana.png"
    const pm              = "../images/pm.png"
    const bnb             = "../images/bnb.png"
    const ton             = "../images/ton.png"


    const [BNBProRateStop, setBNBProRateStop] = useState(false)
    const [NT_ProrateBNB, setNT_ProrateBNB] = useState([])

    const [BTCProRateStop, setBTCProRateStop] = useState(false)
    const [NT_ProrateBTC, setNT_ProrateBTC] = useState([])

    const [TRONProRateStop, setTRONProRateStop] = useState(false)
    const [NT_ProrateTRON, setNT_ProrateTRON] = useState([])

    const [USDTProRateStop, setUSDTProRateStop] = useState(false)
    const [NT_ProrateUSDT, setNT_ProrateUSDT] = useState([])

    const [LINKProRateStop, setLINKProRateStop] = useState(false)
    const [NT_ProrateLINK, setNT_ProrateLINK] = useState([])

    const [MATICProRateStop, setMATICProRateStop] = useState(false)
    const [NT_ProrateMATIC, setNT_ProrateMATIC] = useState([])

    const [DOTEProRateStop, setDOTEProRateStop] = useState(false)
    const [NT_ProrateDOTE, setNT_ProrateDOTE] = useState([])

    const [BUSDProRateStop, setBUSDProRateStop] = useState(false)
    const [NT_ProrateBUSD, setNT_ProrateBUSD] = useState([])

    const [PMProRateStop, setPMProRateStop] = useState(false)
    const [NT_ProratePM, setNT_ProratePM] = useState([])

    const [SOLProRateStop, setSOLProRateStop] = useState(false)
    const [NT_ProrateSOL, setNT_ProrateSOL] = useState([])

    const [ADAProRateStop, setADAProRateStop] = useState(false)
    const [NT_ProrateADA, setNT_ProrateADA] = useState([])

    const [ETHProRateStop, setETHProRateStop] = useState(false)
    const [NT_ProrateETH, setNT_ProrateETH] = useState([])

    const [SHIBAProRateStop, setSHIBAProRateStop] = useState(false)
    const [NT_ProrateSHIBA, setNT_ProrateSHIBA] = useState([])

    const [DOGEProRateStop, setDOGEProRateStop] = useState(false)
    const [NT_ProrateDOGE, setNT_ProrateDOGE] = useState([])

    const [LTCProRateStop, setLTCProRateStop] = useState(false)
    const [NT_ProrateLTC, setNT_ProrateLTC] = useState([])

    const [TONProRateStop, setTONProRateStop] = useState(false)
    const [NT_ProrateTON, setNT_ProrateTON] = useState([])


    const [BTCModifProRate, setBTCModifProRate] = useState(false)
    const [TRONModifProRate, setTRONModifProRate] = useState(false)
    const [LTCModifProRate, setLTCModifProRate] = useState(false)
    const [USDTModifProRate, setUSDTModifProRate] = useState(false)
    const [DOGEModifProRate, setDOGEModifProRate] = useState(false)
    const [BUSDModifProRate, setBUSDModifProRate] = useState(false)
    const [SHIBAModifProRate, setSHIBAModifProRate] = useState(false)
    const [MATICModifProRate, setMATICModifProRate] = useState(false)
    const [ETHModifProRate, setETHModifProRate] = useState(false)
    const [LINKModifProRate, setLINKModifProRate] = useState(false)
    const [DOTModifProRate, setDOTModifProRate] = useState(false)
    const [ADAModifProRate, setADAModifProRate] = useState(false)
    const [SOLModifProRate, setSOLModifProRate] = useState(false)
    const [PMModifProRate, setPMModifProRate] = useState(false)
    const [BNBModifProRate, setBNBModifProRate] = useState(false)
    const [TONModifProRate, setTONModifProRate] = useState(false)


    function customProRateBTCHandler(){ setBTCModifProRate(!BTCModifProRate) }
    function customProRateTRONHandler(){ setTRONModifProRate(!TRONModifProRate) }
    function customProRateLTCHandler(){ setLTCModifProRate(!LTCModifProRate) }
    function customProRateUSDTHandler(){ setUSDTModifProRate(!USDTModifProRate) }
    function customProRateDOGEHandler(){ setDOGEModifProRate(!DOGEModifProRate) }
    function customProRateBUSDHandler(){ setBUSDModifProRate(!BUSDModifProRate) }
    function customProRateSHIBAHandler(){ setSHIBAModifProRate(!SHIBAModifProRate) }
    function customProRateMATICHandler(){ setMATICModifProRate(!MATICModifProRate) }
    function customProRateETHHandler(){ setETHModifProRate(!ETHModifProRate) }
    function customProRateLINKHandler(){ setLINKModifProRate(!LINKModifProRate) }
    function customProRateDOTHandler(){ setDOTModifProRate(!DOTModifProRate) }
    function customProRateADAHandler(){ setADAModifProRate(!ADAModifProRate) }
    function customProRateSOLHandler(){ setSOLModifProRate(!SOLModifProRate) }
    function customProRatePMHandler(){ setPMModifProRate(!PMModifProRate) }
    function customProRateBNBHandler(){ setBNBModifProRate(!BNBModifProRate) }
    function customProRateTONHandler(){ setTONModifProRate(!TONModifProRate) }

    const [CurrencyHandle, setCurrencyHandle] = useState()

    const currencyHandleChange = ({target: {value}}) => {
        setCurrencyHandle(value)
      }

    const validateBTCProRateHandler = () => {

        setBTCModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratebtc', 
                    {
                        ID: 1,
                        btc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateTRONHandler = () => {

        setTRONModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratetron', 
                    {
                        ID: 1,
                        tron: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateProRateLTCHandler = () => {

        setLTCModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateltc', 
                    {
                        ID: 1,
                        ltc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateProRateUSDTHandler = () => {

        setUSDTModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateusdt', 
                    {
                        ID: 1,
                        usdt: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateDOGEHandler = () => {

        setDOGEModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratedoge', 
                    {
                        ID: 1,
                        doge: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateBUSDHandler = () => {

        setBUSDModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratebusd', 
                    {
                        ID: 1,
                        busd: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateSHIBAHandler = () => {

        setSHIBAModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateshiba', 
                    {
                        ID: 1,
                        shiba: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateMATICHandler = () => {

        setMATICModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratematic', 
                    {
                        ID: 1,
                        matic: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateETHHandler = () => {

        setETHModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateeth', 
                    {
                        ID: 1,
                        eth: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateLINKHandler = () => {

        setLINKModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratelink', 
                    {
                        ID: 1,
                        link: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateDOTHandler = () => {

        setDOTModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratedote', 
                    {
                        ID: 1,
                        dote: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }
    const validateProRateADAHandler = () => {

        setADAModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateada', 
                    {
                        ID: 1,
                        ada: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }
    
    const validateProRateSOLHandler = () => {

        setSOLModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratesol', 
                    {
                        ID: 1,
                        sol: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }
    const validateProRatePMHandler = () => {

        setPMModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratepm', 
                    {
                        ID: 1,
                        pm: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateBNBHandler = () => {

        setBNBModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-proratebnb', 
                    {
                        ID: 1,
                        bnb: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateProRateTONHandler = () => {

        setTONModifProRate(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-prorateton', 
                    {
                        ID: 1,
                        ton: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }




    //FETCH RATE DATA
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TRONProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratetron')

                    if(res.data[0].ID){
                        setNT_ProrateTRON(res.data[0].tron)
                        setTRONProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TRONProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LTCProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateltc')

                    if(res.data[0].ID){
                        setNT_ProrateLTC(res.data[0].ltc)
                        setLTCProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LTCProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!USDTProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateusdt')

                    if(res.data[0].ID){
                        setNT_ProrateUSDT(res.data[0].usdt)
                        setUSDTProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[USDTProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOGEProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratedoge')

                    if(res.data[0].ID){
                        setNT_ProrateDOGE(res.data[0].doge)
                        setDOGEProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOGEProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BUSDProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratebusd')

                    if(res.data[0].ID){
                        setNT_ProrateBUSD(res.data[0].busd)
                        setBUSDProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BUSDProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SHIBAProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateshiba')

                    if(res.data[0].ID){
                        setNT_ProrateSHIBA(res.data[0].shiba)
                        setSHIBAProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SHIBAProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!MATICProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratematic')

                    if(res.data[0].ID){
                        setNT_ProrateMATIC(res.data[0].matic)
                        setMATICProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[MATICProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ETHProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateeth')

                    if(res.data[0].ID){
                        setNT_ProrateETH(res.data[0].eth)
                        setETHProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ETHProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LINKProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratelink')

                    if(res.data[0].ID){
                        setNT_ProrateLINK(res.data[0].link)
                        setLINKProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LINKProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOTEProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratedote')

                    if(res.data[0].ID){
                        setNT_ProrateDOTE(res.data[0].dote)
                        setDOTEProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOTEProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ADAProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateada')

                    if(res.data[0].ID){
                        setNT_ProrateADA(res.data[0].ada)
                        setADAProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ADAProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SOLProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratesol')

                    if(res.data[0].ID){
                        setNT_ProrateSOL(res.data[0].sol)
                        setSOLProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SOLProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!PMProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratepm')

                    if(res.data[0].ID){
                        setNT_ProratePM(res.data[0].pm)
                        setPMProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[PMProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BNBProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratebnb')

                    if(res.data[0].ID){
                        setNT_ProrateBNB(res.data[0].bnb)
                        setBNBProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BNBProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BTCProRateStop){

                try {
                    const res = await axios.get('/api/nt-proratebtc')

                    if(res.data[0].ID){
                        setNT_ProrateBTC(res.data[0].btc)
                        setBTCProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BTCProRateStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TONProRateStop){

                try {
                    const res = await axios.get('/api/nt-prorateton')

                    if(res.data[0].ID){
                        setNT_ProrateTON(res.data[0].ton)
                        setTONProRateStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TONProRateStop])



  return (
    <div className={`${themeMode === "true" ? "row mt-3 final-seesion-t" : "row mt-3 final-seesion"}`}>
            <div className='center'>
                <div><span className={`${themeMode === "true" ? "color-or" : ""}`}>Données variantes des <strong>charges professionnelles</strong></span></div>
                <hr className={`${themeMode === "true" ? "color-or" : ""}`}/>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Bitcoin</span>
                        {BTCModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateBTCProRateHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                BTCModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="btc" label="XXXXX BTC" name="btc" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateBTCHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateBTC} XOF</span>
                                </div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Tron</span>
                        {TRONModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateTRONHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                TRONModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="tron" label="XXXXX TRON" name="tron" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateTRONHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateTRON} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>  
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Litecoin</span>
                        {LTCModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateLTCHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                LTCModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="ltc" label="XXXXX LTC" name="ltc" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateLTCHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateLTC} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>  
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Tether</span>
                        {USDTModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateUSDTHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                USDTModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateUSDTHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateUSDT} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Dogecoin</span>
                        {DOGEModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateDOGEHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                DOGEModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="doge" label="XXXXX DOGE" name="doge" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateDOGEHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateDOGE} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Binance usd</span>
                        {BUSDModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateBUSDHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                BUSDModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="busd" label="XXXXX BUSD" name="busd" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateBUSDHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateBUSD} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Shiba</span>
                        {SHIBAModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateSHIBAHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                SHIBAModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="shiba" label="XXXXX SHIBA" name="shiba" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateSHIBAHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateSHIBA} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Polygon matic</span>
                        {MATICModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateMATICHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                MATICModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="matic" label="XXXXX MATIC" name="matic" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateMATICHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateMATIC} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Ethereum</span>
                        {ETHModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateETHHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                ETHModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="eth" label="XXXXX ETH" name="eth" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateETHHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateETH} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Chain link</span>
                        {LINKModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateLINKHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                LINKModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="link" label="XXXXX LINK" name="link" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateLINKHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateLINK} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Polkadot</span>
                        {DOTModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateDOTHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                DOTModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="dot" label="XXXXX DOT" name="dot" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateDOTHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateDOTE} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Cardano</span>
                        {ADAModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateADAHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'> 
                            {
                                ADAModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="ada" label="XXXXX ADA" name="ada" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateADAHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateADA} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Solana</span>
                        {SOLModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateSOLHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                SOLModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="sol" label="XXXXX SOL" name="sol" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateSOLHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateSOL} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Perfect Money</span>
                        {PMModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRatePMHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                PMModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="pm" label="XXXXX PM" name="pm" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRatePMHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProratePM} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Binance Smart Chain</span>
                        {BNBModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateBNBHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                BNBModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="bnb" label="XXXXX BNB" name="bnb" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateBNBHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateBNB} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
            <div className='col-md-3'>
                <div className='stat-card'>
                    <div className='stat-card-title'>
                        <img className='images-drapeau mr-2' src={`../${ton}`} alt="" />
                        <span className='ml-2 stat-see-card-detail-bnt'>Toncoin</span>
                        {TONModifProRate ? <span className='stat-see-card-detail-achat' onClick={validateProRateTONHandler}>Valider</span> : null}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <div className='stat-card-content'>
                            {
                                TONModifProRate ? 
                                <div>
                                    <Box component="form" noValidate sx={{ mt: 1 }}>
                                        <TextField fullWidth id="ton" label="XXXXX TON" name="ton" autoFocus onChange={currencyHandleChange}/>
                                    </Box>
                                </div> : 
                                <div>
                                    <span className='stat-see-card-detail-achat' onClick={customProRateTONHandler}>Modifier</span>
                                    <span className='ml-2' style={{fontSize:"16px"}}>{NT_ProrateTON} XOF</span>
                                </div>
                            }
                        </div>
                    </div>
                </div> 
            </div>
        </div>
  )
}

export default CurrencyRateChargeProUpdateData