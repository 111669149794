import React, { useState } from 'react'
import ChatCustomer from '../components/ChatCustomer'
import RecentSuccessOperation from '../components/RecentSuccessOperation'
import ServiceDisponible from '../components/ServiceDisponible'
import StatisticOfOperations from '../components/StatisticOfOperations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faForward } from '@fortawesome/free-solid-svg-icons'
import BottomNavBar from '../components/BottomNavBar'
import { useHistory } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

export default function ContactScreen(props) {

  const history  = useHistory()

  const LineLink = true
  // const LineLink = false

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  const Logged = localStorage.getItem('userStatus')

  function handleStartOperation(){

    if(LineLink){
    
      if(Logged === 'Logout'){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
        window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
      }
      
    } else {
    
      if(Logged === 'Logout'){
        window.location.replace("http://localhost:3000/login");
      } else {
        window.location.replace("http://localhost:3000/sell-or-buy-crypto");
      }
    }
  }

  const [open, setOpen] = useState(false);

  function handleClickOpen () {
    setOpen(true);
  };

  function handleClose () {
    setOpen(false);
  };

  function handleClose2 () {

    setOpen(false);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }

  };

  function handleClickOpenWhatsapp () {

    setOpen(false);
    window.location.replace("https://wa.me/message/6HY3WGBCUAH6G1");

  };

  function handleClickOpenTelegram () {

    setOpen(false);
    window.location.replace("https://t.me/+SV_lGX2Bl9cxNmFk");

  };


  return (
    <div>
    <section>
      <div className="contact-main_container">
        <div className="about-content">
          <h1><strong className='uppercase'>Où nous écrire ?</strong></h1>
              <h4 className="about-content">
                Contactez-nous via des moyens de communication plus rapide et plus aisés.
                <br />
                <br />
                NKAB EXCHANGE est une plateforme d'échange de crypto-actifs et de transfert d'argent national et international basée en Afrique de l'Ouest développée par NK Group Technology dont Elle est une filiale.
                <br />
                <br /> 
              </h4>
              <div className='mt-3'> 
                <img onClick={handleClickOpenWhatsapp} className='mr-2 pointer' src="../../images/whatsapp-icon.png" width="50" height="50" alt="" />
                <img onClick={handleClickOpenTelegram} className='mr-2 pointer' src="../../images/telegram-icon.png" width="50" height="50" alt="" />
              </div>
              <button onClick={Logged === 'Logout'?handleClickOpen:handleStartOperation} className="welcom-gradient-button uppercase">Commencer maintenant</button>
        </div>
      </div>
    </section>
      <div className="container">
        <div className="mt-5">
          <div className="d-flex justify-content-between">
            <div style={{ marginBottom: `${'10px'}` }}>
              <span
                className='goback'
                onClick={() => history.goBack()}
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {'Retour'}
              </span>
            </div>
            <div style={{ marginBottom: `${'10px'}` }}>
              <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
                <span
                  type="submit"
                  className='goback'
                  style={{ color: '#0a146e', cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
                </span>
              </a>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{color:"#c7b301", fontSize:"12px"}}>Vous souhaitez effectuer une opération?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{color:"#fff", fontSize:"16px"}}>
              Vous devez vous connecter pour commencer <EmojiEmotionsIcon style={{color:"#c7b301"}} /> !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{color:"#c7b301"}}> Fermer </Button>
            <Button onClick={handleClose2} sx={{ bgcolor: '#c7b301', color:"#0A1929", borderRadius:"20px"}}>Se connecter</Button>
          </DialogActions>
        </Dialog>

        <ServiceDisponible />
        <RecentSuccessOperation />
        <StatisticOfOperations />

        <ChatCustomer />

      </div>
      <BottomNavBar />
    </div>
  )
}
