import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';

export default function FilesScreen(props) {

  var   _ProWallet                        = localStorage.getItem('_ProWallet')
  const Logged                            = localStorage.getItem('userStatus')

  const params                            = useParams()
  const id                                = params.id
  const history                           = useHistory()
  const [nextWaveVerif, setNextWaveVerif] = useState([])

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  useEffect(()=>{
    if(Logged !== 'Logged'){
      props.history.push(`/login`)
    }
  },[Logged, props.history])


  
    useEffect(() => {
      const fetchNextWaveVerifDada = async () => {
          try {
            const { data } = await axios.get(
              `/api/api/v1/next/verification`,
            )
            setNextWaveVerif(data)
          } catch (error) {
            console.log(error)
          }
      }
      fetchNextWaveVerifDada()
    }, [id])

    const loadMsg =  "Chargement de documents en cours..."


  return (
    <div className='container' style={{backgroundColor:"#90d9ff"}}>
      <section className="py-5 text-center container">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">Vérification d'image</h1>
                <p className="lead text-muted">Ici vous pouvez mieux voir les images de plus près afin de vous assurer une meilleure visibilité.</p>
                <p>
                <span
                    onClick={() => history.goBack()}
                    style={{ color: '#0a146e', cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faBackward} /> {'[Retour]'}
                  </span>
                </p>
            </div>
          </div>
        </section>

        <section>
          <div className="py-2 d-flex justify-content-between">
            {!nextWaveVerif ? <LoadingBox loadMsg={loadMsg} /> :
              nextWaveVerif.map((w, index)=>(
                <div key={index} className="">
                  {
                    w.verifyId === id ?
                    <div className='card' style={{width:"25rem"}}>
                      <img src={w.filePath} className="card-img-top" alt="..."/>
                    </div>:null
                  }
                </div>
              ))
            }
          </div>
        </section>
    </div>
  )
}
