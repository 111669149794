import React, { useState } from 'react'
import ChatCustomer from '../components/ChatCustomer'
import RecentSuccessOperation from '../components/RecentSuccessOperation'
import StatisticOfOperations from '../components/StatisticOfOperations'
import ServiceDisponible from '../components/ServiceDisponible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faForward } from '@fortawesome/free-solid-svg-icons'
import BottomNavBar from '../components/BottomNavBar'
import { useHistory } from 'react-router-dom'
import './AboutScreen.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

export default function AboutScreen(props) {

  const history  = useHistory()

  const LineLink = true
  // const LineLink = false

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  const Logged = localStorage.getItem('userStatus')

  function handleStartOperation(){

    if(LineLink){
    
      if(Logged === 'Logout'){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
        window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
      }
      
    } else {
    
      if(Logged === 'Logout'){
        window.location.replace("http://localhost:3000/login");
      } else {
        window.location.replace("http://localhost:3000/sell-or-buy-crypto");
      }
    }
  }

  const [open, setOpen] = useState(false);

  function handleClickOpen () {
    setOpen(true);
  };

  function handleClose () {
    setOpen(false);
  };

  function handleClose2 () {

    setOpen(false);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }

  };
  
  return (
    <div>
      <section>
        <div className="about-main_container">
          <div className="about-content">
            <h1><strong>QUI NOUS SOMMES ?</strong></h1>
                <h4 className="about-content">
                  NKAB EXCHANGE est une plateforme d'échange de crypto-actifs et de transfert d'argent national et international basée en Afrique de l'Ouest développée par NK Group Technology dont Elle est une filiale.
                  <br />
                  <br />
                  Elle donne ainsi la possibilité à toute personne d'échanger de la cryptomonnaie contre du fiat et du fiat contre de la cryptomonnaie, d'effectuer leurs transactions de façon automatique et en toute sécurité.
                  <br />
                  <br />
                  Elle permet également de faire des transferts d'argent inter-opérateurs nationaux et internationaux sans se déplacer en respectant toutes les normes sécuritaires.
                  <br />
                  <br />
                  Nous avons choisi de faciliter la vie à nos utilisateurs tant
                  dans l'acquisition de monnaies crypto-actifs et dans les
                  transferts d'argent.
                  <br />
                  <br />
                  Nous mettons ainsi au devant une sécurité très optimale sécurisant tout cadre afin de garantir les transactions de nos différents utilisateurs.
                  <br />
                </h4>
                <button onClick={Logged === 'Logout'?handleClickOpen:handleStartOperation} className="welcom-gradient-button uppercase">Commencer maintenant</button>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="mt-4">
          <div className="d-flex justify-content-between">
            <div style={{ marginBottom: `${'10px'}` }}>
              <span
                className='goback'
                onClick={() => history.goBack()}
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {'Retour'}
              </span>
            </div>
            <div style={{ marginBottom: `${'10px'}` }}>
              <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
                <span
                  type="submit"
                  className='goback'
                  style={{ color: '#0a146e', cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
                </span>
              </a>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{color:"#c7b301", fontSize:"12px"}}>Vous souhaitez effectuer une opération?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{color:"#fff", fontSize:"16px"}}>
              Vous devez vous connecter pour commencer <EmojiEmotionsIcon style={{color:"#c7b301"}} /> !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{color:"#c7b301"}}> Fermer </Button>
            <Button onClick={handleClose2} sx={{ bgcolor: '#c7b301', color:"#0A1929", borderRadius:"20px"}}>Se connecter</Button>
          </DialogActions>
        </Dialog>

        <ServiceDisponible />
        <RecentSuccessOperation />
        <StatisticOfOperations />

        <ChatCustomer />

      </div>
      <BottomNavBar />
    </div>
  )
}
