import React, { useEffect, useRef, useState } from 'react'
import './Footer.css'

const Footer = () => {

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

  return (
    <div>
      <footer role="contentinfo">
        <div className="footer-middle" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <ul className="list-unstyled">
                    <li>
                      <a href="/"><span><img src="../../images/logo2.png" width="80" height="50" style={{borderRadius:"5px"}} alt="" /></span></a>
                    </li>
                    <li className='pt-2'><a href="#/" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>NKAB EXCHANGE est une plateforme d'échange de crypto-actifs et de transfert d'argent national et international basée en Afrique de l'Ouest développée par NK Group Technology dont Elle est une filiale. </a></li>
                    <li><a href="https://nkgroup-technology.com/" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}><span className='color-or'>Filiale à NK Group Technology</span></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 style={{fontSize:'15px'}}>Liens Rapides</h4>
                  <ul className="list-unstyled">
                    <li><a href="/home" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Accueil </a></li>
                    <li><a href="/about" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>A propos de nous </a></li>
                    <li><a href="/contact" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Notre support </a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className='pb-2' style={{fontSize:'15px'}}>Liens Utiles</h4>
                  <ul className="list-unstyled">
                    <li><a href="/our-condition" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Conditions Générales d'Utilisation </a></li>
                    <li><a href="/our-political" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Politique de Confidentialité </a></li>
                    <li><a href="/nkab-demo" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Vidéo demo </a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
              <div className="footer-pad">
                <h4 style={{fontSize:'15px'}}>Nous Ecrire</h4>
                <ul className="list-unstyled">
                  {/* <li>
                    <a href="https://chat.whatsapp.com/B9DsCr6DeaPLeN4CWbk0R1" className="icoFacebook" title="Whatsapp">
                      <img src="../../images/whatsapp-icon.png" alt="Whatsapp" width="25" height="25" />
                    </a>
                    Group Whatsapp
                  </li> */}
                  <li>
                    <a href="https://wa.me/message/WLPU34A56XCAF1" className="icoFacebook" title="Telegram">
                      <img src="../../images/telegram-icon.png" alt="Telegram" width="25" height="25" />
                    </a>
                    Group Telegram
                  </li>
                </ul>		
              </div>		
            </div>
            </div><br/>
            <div className='row text-center'>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.scdigital.nkabexchange&fbclid=IwAR2Jv1Yh" className="playstore nkab" title="Playstore">
                  <img src="../../images/playstore.png" alt="Playstore" width="155" height="50" />
                </a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 copy">
                <p className="text-center" style={{color:"#6f91a3"}}>NKAB Exchange &copy; { new Date().getFullYear()}</p>
              </div>
            </div>


          </div>
        </div>
      </footer>
    </div>

  )
}

export default Footer
