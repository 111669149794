import { faBackward, faHome, faSignInAlt, faSignOutAlt, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import './UserDetailsScreen.css'

const UserDetailsScreen = (props) => {
   
    var    _ProWallet                                 = localStorage.getItem('_ProWallet')
    const  Logged                                     = localStorage.getItem('userStatus')

    const params                                      = useParams()
    const history                                     = useHistory()
    const id                                          = params.id
    const [Users,                  setUsers         ] = useState([])
    const [FinalImages,            setFinalImages   ] = useState([])
    const [FiltedFounde,           setFiltedFounde  ] = useState()

    const LineLink = true


    if (_ProWallet === 'true') {

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
    }
  
    useEffect(()=>{
      if(Logged === 'Logout'){

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
      }

    },[Logged, props.history])


    useEffect(() => {
      const fetchAllUsers = async () => {
        const res = await axios.get( `/api/user/api/v1/all_infor`, )
        setUsers(res.data)
        if(Users && id){
            const usersFilted = Users.filter((item)=>{
                return item._id === id
            })
            if(usersFilted[0]){
                const email_founded = usersFilted[0].email
    
                const nv_res = await axios.get( `/api/v1/next/verification`, )
                if(nv_res.data){
                    const nv = nv_res.data
                    if(nv){
                        const nv_filted = nv.filter((nv_item)=>{
                            return nv_item.userId === email_founded
                        })
                        setFinalImages(nv_filted)
                        setFiltedFounde(nv_filted.length)
                    }
                }
            }
        }
      }
      fetchAllUsers()
    }, [Users, id])

    const loadMsg =  "Patientez un moment..."
    
    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}> 
     	<div className="row">
            {
                FiltedFounde === 0 || FiltedFounde > 0 ? null : 
                <div className='text-center p-5'>
                    <h1><LoadingBox loadMsg={loadMsg} /></h1>
                </div>
            }
         
            {
                FiltedFounde === 0 ?
                <div className='row'>
                    <div className="text-center danger">
                        <h1>Fichier image non trouvé !</h1>
                        <div className='p-3'>
                            <span
                                onClick={() => history.goBack()} 
                                className='goback btn btn-danger cursor-pointer'
                            >
                                <strong><FontAwesomeIcon icon={faBackward} /> {'Retour'}</strong>
                            </span>
                        </div>
                    </div>
                </div>:
                FiltedFounde > 0 ?
                <>
                {
                    !FinalImages?null:FinalImages.map((item, index)=>(
                        <div key={index} className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="box-part text-center">
                                <img src={item.filePath} alt="" />
                            </div>
                        </div>	
                    ))
                }
                </>:null
            }
		</div>
        {
            !FiltedFounde || FiltedFounde === 0 ?null : FiltedFounde > 0 ?
            <div className='row text-center pt-2 pb-4 verif-button-panel'>
                <div className='col-md-2 p-1'>
                    <span className='goback btn btn-danger cursor-pointer' onClick={() => history.goBack()} ><strong><FontAwesomeIcon icon={faBackward} /> {'Retour'}</strong></span>
                </div>
                <div className='col-md-2 p-1'>
                    <span className='btn btn-danger cursor-pointer'><strong>Refusé</strong></span>
                </div>
                <div className='col-md-2 p-1'>
                    <span className='btn btn-warning cursor-pointer'><strong>Démande de justification</strong></span>
                </div>
                <div className='col-md-2 p-1'>
                    <span className='btn btn-info cursor-pointer'><strong>Accordé avec réserve</strong></span>
                </div>
                <div className='col-md-2 p-1'>
                    <span className='btn btn-success cursor-pointer'><strong>Accordé</strong></span>
                </div>
            </div>:null
        }

        <div className='nkp_diplay_container'>
            <div className="nkp_bottom_navbar">
                <div className='nkab_ul'>
                    <div className="nkab_li">
                        <a href="/">
                            <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px", color: "#c7b301"}} icon={faHome} /></span>
                            <span className='nkp_bottom_navbar_title'>Home</span>
                        </a>
                    </div>
                    <div className="nkab_li">
                        <a href="/sell-or-buy-crypto">
                            <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={faSync} /></span> 
                            <span className='nkp_bottom_navbar_title'>Exchange</span>
                        </a>
                    </div>
                    <div className="nkab_li">
                        <a href="#logout" onClick={handleLogout}>
                            <span className="nkp_bottom_span"><FontAwesomeIcon style={{fontSize:"20px"}} icon={Logged === 'Logged'?faSignOutAlt:faSignInAlt} /></span>
                            <span className='nkp_bottom_navbar_title'>{Logged === 'Logged'?"Logout":"login"}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserDetailsScreen