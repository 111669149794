import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ExplorCrypto from './explorations/ExplorCrypto'

const NumbersStatus = ({themeMode}) => {

  const [GetUser, setGetUser] = useState([])
  const [UserFounded, setUserFounded] = useState(false)
  const [Operation, setOperation] = useState([])
  const [StopOperation, setStopOperation] = useState(false)
  const [ownWitness, setOwnWitness] = useState([])
  const [ownWitnessStopReq, setOwnWitnessStopReq] = useState(false)


  useEffect(() => {
    if(!UserFounded){
      const fetchDada12 = async () => {
        try {
          const { data } = await axios.get(
            '/api/v1/get-users'
          )
          if(data.length > 0){
            setGetUser(data)
            if(GetUser.length > 0){
              setUserFounded(true)
            }
          }
        } catch (error) { }
      }
      fetchDada12()
    }

  }, [GetUser, UserFounded])
  
  useEffect(() => {

    const fetchData = async () => {

      if(!StopOperation){
        try {
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            setOperation(data)
            setStopOperation(true)
          }
          
        } catch (error) {}
      }
    }

    fetchData()

  }, [Operation, StopOperation])
    
  useEffect(()=>{

    const fetchWitnessDada = async () => {
      if(!ownWitnessStopReq){
          try {
            const { data } = await axios.get(
              '/api/v1/get-witnesses',
            )
            setOwnWitness(data)
            setOwnWitnessStopReq(true)
          } catch (error) {}
      }
    }

    fetchWitnessDada()

  },[ownWitness, ownWitnessStopReq])

  return (
    <div>

      <div className='row mb-5 nkab-explor-section'>
        <div className='col-md-6'>
          <div>
            <p className='nkab-status-text1' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Explorez les crypto-monnaies comme Bitcoin, Tether, Dogecoin et Tron</p>
            <p className='nkab-status-text2' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Facile de savoir à quel moment et quand faire un choix avant de se lancer dans l'action en achatant ou vendant chez <span style={{color:"#c7b301"}}>NKAB Exchange</span>.</p>
          </div>
          <div className='d-flex justify-content-center'>
            <div></div>
            <div style={{marginRight:"7px", marginLeft:"7px"}}></div>
            <div></div>
          </div>
        <div className="nkab--catalog__container">
          <div className="nkab--catalog__copy">
                <p>
                  <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Membres actifs</span> {GetUser.length === 0 ? null : <strong style={{fontSize:"17px", color:"#c7b301"}}>{GetUser.length}+</strong>}
                  
                  {' '}<span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Opérations</span> {Operation.length === 0 ? null : <strong style={{fontSize:"17px", color:"#c7b301"}}>{Operation.length}+</strong>}
                  
                  {' '}<span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Témoignages</span> {ownWitness.length === 0 ? null : <strong style={{fontSize:"17px", color:"#c7b301"}}>{ownWitness.length}+</strong>} </p>
          </div>
        </div>
          <div>
            <button className="welcom-gradient-button">COMMENCEZ MAINTENANT</button>
          </div>
        </div>
        <div className='col-md-6'>
          <ExplorCrypto />
        </div>
      </div>

    </div>

  )
}

export default NumbersStatus