import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import axios from 'axios'
import ChatCustomer from '../components/ChatCustomer'
import BottomNavBar from '../components/BottomNavBar'

export default function WavePaymentScreen(props) {

  var     _ProWallet                                                = localStorage.getItem('_ProWallet')
  const   auth                                                      = useSelector((state) => state.auth)
  const { user}                                                     = auth

  const  params                                                     = useParams()
  const  orderId                                                    = params.id
  const [name,                          setName                   ] = useState('')
  const [phone,                         setPhone                  ] = useState('')
  const [waveMonay,                     setWaveMonay              ] = useState('')
  const [order,                         setOrder                  ] = useState([])
  const [orderExchange,                 setOrderExchange          ] = useState()
  const [sdkReady,                      setSdkReady               ] = useState(false)
  const [stopExchangeCount,             setStopExchangeCount      ] = useState(false)
  const [finalPrice,                    setFinalPrice             ] = useState()

  const [montantTotal, setMontantTotal] = useState()

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  

  const Logged = localStorage.getItem('userStatus')

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }

  },[Logged])
  


  function alerter() {
    props.history.push(`/final-transaction/loading/${orderId}`)
  }

  useEffect(() => {
    const fetchOrderDada = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/orders/${orderId}`,
        )
        setOrder(data)
        setOrderExchange(data.exchange[0].cfa)
        if (order) {
          setSdkReady(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchOrderDada()
  }, [orderId, order])

  const payByWaveHandler = () => {
    if (name && phone && waveMonay === `${finalPrice}`) {
      const wavePaymentName = name
      const wavePaymentPhone = phone
      const wavePaymentMoney = waveMonay
      const wavePaymentType = 'Transaction Wave'
      const wavePaymentSenderId = user._id
      const wavePaymentOrderId = order._id

      if (
        wavePaymentName &&
        wavePaymentPhone &&
        wavePaymentMoney &&
        wavePaymentSenderId &&
        wavePaymentOrderId
      ) {
        axios
          .post(
            '/api/v1/wavepayment/create-wavepayment',
            {
              wavePaymentName,
              wavePaymentPhone,
              wavePaymentMoney,
              wavePaymentType,
              wavePaymentSenderId,
              wavePaymentOrderId,
            },
          )
          .then(
            (response) => {
              if (response.data._id) {
                alerter()
              }
            },
            (error) => {
              console.log(error)
            },
          )
      }
    } else {
      alert('Veuillez correctement renseigner les champs !')
    }
  }
  
  
  useEffect(()=>{
    if(order._id){
      //BUY
      if(order.operatorAction === 'buy'){
        if(order.operatorUserInfoCountry === "Côte d'Ivoire"){
          if(order.operatorListName === 'Orange Money' || order.operatorListName === 'Mobile Money' || order.operatorListName === 'Moov Money'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.015)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(order.operatorUserInfoCountry === "Sénégal"){
          if(order.operatorListName === 'Orange Money' || order.operatorListName === 'Free Money' || order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
          if(order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(order.operatorUserInfoCountry === "Togo"){
          if(order.operatorListName === 'Moov Money'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.025)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(order.operatorListName === 'TMoney'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(order.operatorUserInfoCountry === "Burkina Fasso"){
          if(order.operatorListName === 'Orange Money'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.045)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(order.operatorListName === 'Moov Money'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.035)).toFixed(0), )
            setMontantTotal(montantTotal)
          }

          if(order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(order.operatorUserInfoCountry === "Mali"){
          if(order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.018)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }

        if(order.operatorUserInfoCountry === "Bénin"){
          if(order.operatorListName === 'Mobile Money'){
            const montantTotal = Number( (orderExchange + (orderExchange * 0.027)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
      }

      //SELL
      if(order.operatorAction === 'sel'){
        if(order.operatorUserInfoCountry === "Côte d'Ivoire"){
          if(order.operatorListName === 'Orange Money' || order.operatorListName === 'Mobile Money' || order.operatorListName === 'Moov Money' || order.operatorListName === 'Wave'){ const montantTotal = Number( (orderExchange - orderExchange * 0.02).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
        if(order.operatorUserInfoCountry === "Sénégal"){
          if(order.operatorListName === 'Orange Money' || order.operatorListName === 'Free Money' || order.operatorListName === 'Wave'){
            const montantTotal = Number( (orderExchange - (orderExchange * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
        if(order.operatorUserInfoCountry === "Togo"){
          if(order.operatorListName === 'Moov Money' || order.operatorListName === 'TMoney'){
            const montantTotal = Number( (orderExchange - (orderExchange * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
        if(order.operatorUserInfoCountry === "Burkina Fasso"){
          if(order.operatorListName === 'Moov Money' || order.operatorListName === 'TMoney'){
            const montantTotal = Number( (orderExchange - (orderExchange * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
        if(order.operatorUserInfoCountry === "Bénin"){
          if(order.operatorListName === 'Mobile Money'){
            const montantTotal = Number( (orderExchange - (orderExchange * 0.02)).toFixed(0), )
            setMontantTotal(montantTotal)
          }
        }
      }
    }
  },[order, orderExchange])



  useEffect(() => {
    if(montantTotal){

      const cfa_nbr = montantTotal
      const cfa_nbr_to_text = cfa_nbr.toString()
      const cfa_nbr_last_char = cfa_nbr_to_text.slice(-1)
      const last_cfa_char_nbr = Number(cfa_nbr_last_char)


    if (stopExchangeCount === false) {
    
      if (last_cfa_char_nbr === 0) {
        const FinalCount = montantTotal
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 5) {
        const FinalCount = montantTotal
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 1) {
        const FinalCount = montantTotal - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 2) {
        const FinalCount = montantTotal - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 3) {
        const FinalCount = montantTotal - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 4) {
        const FinalCount = montantTotal - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 5) {
        const FinalCount = montantTotal + 0
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 6) {
        const FinalCount = montantTotal - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 7) {
        const FinalCount = montantTotal - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 8) {
        const FinalCount = montantTotal - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
      if (last_cfa_char_nbr === 9) {
        const FinalCount = montantTotal - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
    }
    }
  }, [
    montantTotal,
    finalPrice,
    stopExchangeCount,
  ])

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div>
        <div className="main-session">
          <div>
            {
              <div className="">
                <h1 className="color-w buy-id">
                  {order.operatorAction === 'buy' ? (
                    <span>Identifiant de l'Achat : </span>
                  ) : order.operatorAction === 'sel' ? (
                    <span>Identifiant de la Vente : </span>
                  ) : null}
                  <span className="color-or">{!order._id ? null : order._id.slice(12, 23)}</span>
                </h1>
                <div className=" final-seesion">
                  <div className="order-left-side">
                    <div className="nkab-card nkab-card-body">
                      {order.operatorAction === 'buy' ? (
                        <h5>
                          Achat de{' '}
                          <span className="color-r">{order.serviceName}</span>{' '}
                          par{' '}
                          <span className="color-r">
                            {order.operatorListName}
                          </span>
                        </h5>
                      ) : order.operatorAction === 'sel' ? (
                        <h5>
                          Vente de{' '}
                          <span className="color-r">{order.serviceName}</span>
                        </h5>
                      ) : null}
                    </div>
                  </div>
                  <div className="order-right-side">
                    <div className="nkab-card nkab-card-body">
                      <div>
                        <h2>Resumé</h2>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Votre adresse wallet</span> : 
                          order.operatorAction === 'sel' ? <span>Vous nous envoyez</span>
                           : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? <span className="color-b">{order.adr_Coin}</span> : 
                          order.operatorAction === 'sel' ? (
                            <span className="cryptoMarker">
                                {' '}
                                {order.exchange[0].converted}{' '}
                                {order.serviceSymbol}
                              </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Montant en {order.serviceSymbol} </span> : 
                          order.operatorAction === 'sel' ? null : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? <span>
                            {(order.exchange[0].converted * 1).toFixed(5)}{' '}
                            {order.serviceSymbol}
                            </span> : order.operatorAction === 'sel' ? null : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Montant en dollar</span> : 
                          order.operatorAction === 'sel' ? <span>Montant en dollar</span> : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? <span> {' '} $ {(order.exchange[0].dollar * 1).toFixed(2)} </span> : 
                          order.operatorAction === 'sel' ? <span> {' '} $ {(order.exchange[0].dollar * 1).toFixed(2)} </span> : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Montant initial à transferer</span> : 
                          order.operatorAction === 'sel' ? <span>Vous recevez</span> : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? <span> {order.exchange[0].cfa} {' XOF'} </span> : 
                          order.operatorAction === 'sel' ? <span> {order.exchange[0].cfa} {' XOF'} </span> : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Taux d'achat</span> : 
                          order.operatorAction === 'sel' ? <span>Taux de vente</span> : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? <span> {order.rate} {' XOF'} </span> : 
                          order.operatorAction === 'sel' ? <span> {order.rate} {' XOF'} </span> : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? <span>Frais d'envois</span> : 
                          order.operatorAction === 'sel' ? null : null}
                        </div>
                        <div>
                          {order.operatorAction === 'buy' ? (
                            <>
                              {order.operatorListName === 'Wave' ? (
                                <span>
                                  {(order.exchange[0].cfa * 0.01).toFixed(0)}{' '}
                                  {' XOF'}
                                </span>
                              ) : (
                                <span>
                                  {(order.exchange[0].cfa * 0.025).toFixed(0)}{' '}
                                  {' XOF'}
                                </span>
                              )}
                            </>
                          ) : order.operatorAction === 'sel' ? null : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {order.operatorAction === 'buy' ? (
                            <>
                              {order.operatorListName === 'Wave' ? (
                                <strong>
                                  Montant total {order.operatorListName} à
                                  transferer{' '}
                                </strong>
                              ) : (
                                <strong>Montant total à débiter </strong>
                              )}
                            </>
                          ) : order.operatorAction === 'sel' ? <strong>Montant total à recevoir</strong> : null}
                        </div>
                        <div> <strong> {finalPrice} {' XOF'}{' '} </strong> </div>
                      </div>
                      <div>
                        {!sdkReady ? (
                          <LoadingBox />
                        ) : (
                          <>
                            {order.operatorAction === 'buy' ? (
                              <>
                                {order.operatorListName === 'Wave' ? (
                                  <div className="wavePaymentForm">
                                    <div className="wavePaymentWarningContent">
                                      <span className="wavePaymentWarning color-r"> Veuillez renseigner les champs ci-dessous * </span>
                                    </div>
                                    <div className="wavePaymentContent">
                                      <input className="wavePaymentNameInput" type="text" placeholder="Nom et Prénoms" onChange={(e) => setName(e.target.value) } />
                                      <input className="wavePaymentInputPhone" type="text" placeholder="Téléphone" onChange={(e) => setPhone(e.target.value) } />
                                      <input className="wavePaymentInputMoney" type="text" placeholder="Montant à débiter" onChange={(e) => setWaveMonay(e.target.value) } />
                                    </div>
                                    <span className="wavePaymentButton" onClick={payByWaveHandler} > Continuer </span>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}
