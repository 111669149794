import { faBackward, faBell, faForward, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import SampleLoading from '../components/loadings/SampleLoading/SampleLoading'

const CmssScreen = (props) => {
  
  var   _ProWallet                                          = localStorage.getItem('_ProWallet')
  const Logged                                              = localStorage.getItem('userStatus')
  const role                                                = localStorage.getItem('role')


  const [finalPrice3,            setFinalPrice3           ] = useState()
  const [LastMontantTotal3,      setLastMontantTotal3     ] = useState()
  const [operationAlertData,     setOperationAlertData    ] = useState([])
  const [Loading,                setLoading               ] = useState(false)
  const [Operation,              setOperation             ] = useState()
  const [OperationStopReq,       setOperationStopReq      ] = useState(false)
  const  history                                             = useHistory()

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  


  useEffect(()=>{
    if(Logged !== 'Logged'){
      props.history.push(`/login`)
    }

    if (Number(role) !== 1) {
      props.history.push('/')
    }
  },[Logged, props.history, role])


  useEffect(()=>{
    setLastMontantTotal3(LastMontantTotal3)
    setFinalPrice3(finalPrice3)
  },[LastMontantTotal3,finalPrice3])


  useEffect(() => {

    const fetchData = async () => {
      try {

        if(!OperationStopReq){
          const { data } = await axios.get(
            '/api/v1/get-bonuspayment',
          )
          if(data){
            const _data = data
            const items = _data.slice(0, 30)
            setOperation(items)
          }
          if(data.length > 0){
            setOperationStopReq(true)
          }
        }
        
      } catch (error) {}
    }

    fetchData()

  }, [Operation, OperationStopReq])


  useEffect(() => {
    const fetchOperationData = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-notifiers',
        )
        setOperationAlertData(data)
      } catch (error) {}
    }
    fetchOperationData()
  }, [])


  const notifHandler = async (id) =>{
    alert('Veuillez confirmer la suppression de notification!')
    if (id) {
      axios.post(`/api/v1/delete-notifiers`,
        {
          ID: id,
        },
      )
    }
  }


  const activationDeleteHandler = async (id) =>{
    if (id) {
      await axios.post(`/api/v1/delete-bonuspayment`,
        {
          ID: id,
        },
      ).then((res)=>{
        if(res){
          setOperationStopReq(false)
        }
      })
    }
  }

  const activationHandler = async (SenderEmail) =>{

    if(SenderEmail && !Loading){
      
      setLoading(true)

      try {
        
        const res = await axios.post(
          '/api/bonuspayment-validation', {SenderEmail}
        )
        if(res.data.msg){
          setLoading(false)
          setOperationStopReq(false)
        }
      } catch (err) {}
    }

  }

  const validation = "..."

  return (
    <div className="container">
      <div className="main-session">
        <div className="d-flex justify-content-center profile-section">
          <h1 style={{ color: 'white' }}>Liste des opérations effectuées</h1>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ marginBottom: `${'10px'}` }}>
            <span
              className='goback'
              onClick={() => history.goBack()}
              style={{ color: '#0a146e', cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faBackward} /> {'Retour'}
            </span>
          </div>
          <div style={{ marginBottom: `${'10px'}` }}>
            <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
              <span
                type="submit"
                className='goback'
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
              </span>
            </a>
          </div>
        </div>
      </div>

        <div className="__op-body-table">
          <table className='__op-responsive-table'>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Pays</th>
                <th scope="col">Option</th>
                <th scope="col">Client</th>
                <th scope="col">Beneficiaire</th>
                <th scope="col">Montant</th>
                <th scope="col">Date</th>
                <th scope="col">Statut</th>
              </tr>
            </thead>
            <tbody>
              {!Operation ? <SampleLoading /> : Operation.map((item) => (
                  <tr key={item.ID}>
                    <th scope="row">
                      {
                        operationAlertData.length === 0 ? null:
                        operationAlertData.length > 0 && operationAlertData.map((notif) => (
                          <span key={notif.ID}>
                            {
                              item.OrderId === notif.notifyServicesId ? 
                              <FontAwesomeIcon icon={faBell} style={{color:'#90d9ff'}} onClick={() => notifHandler(notif.ID)} />:null
                            }
                          </span>
                        ))
                      }
                      <span className='text-info'>{item._user_id}</span>
                    </th>
                    <td data-title="Pays"> <span className='text-info'>{item.SenderCountry}</span> </td>
                    <td data-title="Option"> <span className='text-info'>{item._status}</span> </td>
                    <td data-title="Client"> <span className='text-info'>{item.SenderEmail}</span> </td>
                    <td data-title="Beneficiaire"> <span className='text-info'>{item.SenderPhone}</span> </td>
                    <td data-title="Montant"> <span className='text-info'>{item.finalprice} XOF</span></td>
                    <td data-title="Date"> <span className='text-info'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13, 19)}</span> </td>
                    <td data-title="Statut">
                      {
                        item.isAdminCompleted === 0 ? 
                        <span className="creditation-btn" onClick={() => activationHandler(item.SenderEmail)} style={{fontSize:'11px'}}> Valider </span> :
                        Loading && item.isAdminCompleted === 0 ? <LoadingBox validation={validation} /> : 
                        item.isAdminCompleted === 1 ?
                        <span className="text-success" style={{fontSize:'11px'}}> Validée </span> : null
                        }
                        <span
                          style={{
                            cursor: 'pointer',
                            color: 'white',
                            fontWeight: '600',
                            backgroundColor: 'red',
                            padding: '2px',
                            borderRadius: '3px',
                          }}
                          className='ml-2'
                          onClick={() => activationDeleteHandler(item.ID)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default CmssScreen
