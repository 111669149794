import { faArrowAltCircleLeft, faArrowRight, faBell, faCircle, faForward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './OperationScreen.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Stack, TextField } from '@mui/material'
import ReactSwitch from 'react-switch'
import BottomNavBar from '../components/BottomNavBar'



const OperationScreen = (props) => {

  var   _ProWallet                                               = localStorage.getItem('_ProWallet')
  const Logged                                                   = localStorage.getItem('userStatus')
  const role                                                     = localStorage.getItem('role')
  const history                                                  = useHistory()

  const [finalPrice3,             setFinalPrice3               ] = useState()
  const [LastMontantTotal3,       setLastMontantTotal3         ] = useState()
  const [cPstatusData,            setCPstatusData              ] = useState()
  const [operationAlertData,      setOperationAlertData        ] = useState([])
  const [Operation,               setOperation                 ] = useState([])
  const [OperationDetail,         setOperationDetail           ] = useState([])
  
  const [counter, setCounter] = useState(30);
  const [AllOp, setAllOp] = useState([])
  const [StopOperation, setStopOperation] = useState(false)

  let isMarchand = localStorage.getItem("isMarchandActive")

  const [checked, setChecked] = useState(isMarchand)
    
  const seeMoreItemsHandler = () =>{
    setStopOperation(false)
    setCounter(counter + 10);
  }

  const LineLink = true
  // const LineLink = false


  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
  }


  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }

    if (Number(role) !== 1) {

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/");
      } else {
         window.location.replace("http://localhost:3000/");
      }
    }
  },[Logged, role])



  useEffect(()=>{
    setLastMontantTotal3(LastMontantTotal3)
    setFinalPrice3(finalPrice3)
  },[LastMontantTotal3,finalPrice3])


  useEffect(() => {

    const fetchData = async () => {
        if(!StopOperation){

          try {
  
              const { data } = await axios.get(
                '/api/v1/operations',
              )
              if(data.length > 0){
  
                setAllOp(data.length)
                      
                if(data.length > 0 && counter === 0){
                
                  const items = data.slice(0, 30)
              
                  if(items.length > 0){
                    setOperation(items)
                    setStopOperation(true)
                  }
              
                } else if(data.length > 0 && counter > 0){
              
                  const items = data.slice(0, counter)
              
                  if(items.length > 0){
                    setOperation(items)
                    setStopOperation(true)
                  }
                }
              }
            
          } catch (error) {
            console.log(error)
          }
        }
    }

    fetchData()

  }, [Operation, counter])

  useEffect(() => {
    const fetchData = async () => {

      try {
        const { data } = await axios.get(
          `/api/v1/get-cinetpay-status`,
        )
        setCPstatusData(data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()

  }, [cPstatusData])


  useEffect(() => {
    const fetchOperationData = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-notifiers',
        )
        setOperationAlertData(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOperationData()
  }, [])
  
  const detailOption = (id) => {

    if (id && Operation.length > 0) {

      const dataGetted = Operation.filter((obj) => {
        return obj.ID === Number(id)
      })

      setOperationDetail(dataGetted[0])
    }
  }

  const [copyText, setCopyText] = useState(false); 
  const [isCopied, setIsCopied] = useState(false);

  const _adresse_a_copier         = `${!OperationDetail?null:OperationDetail.adr_Coin}`


  useEffect(()=>{
    if(_adresse_a_copier){
      setCopyText(_adresse_a_copier)
    }
  },[_adresse_a_copier])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  function handleCopyClick () {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const methodpayment = OperationDetail.PaymentMethod
  const op = OperationDetail.operatorAction
  const qt = (Number(OperationDetail.converted)*1).toFixed(5)
  const opname = OperationDetail.serviceSymbol
  const opaddress = OperationDetail.adr_Coin
  const opid = OperationDetail.OrderId
  const name = OperationDetail.SenderName
  const validated = OperationDetail.isAdminCompleted
  const phone = OperationDetail.SenderPhone
  const taux = OperationDetail.rate

  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  const TRON    = "TRON"
  const BTC     = "Bitcoin"
  const LTC     = "Litecoin"
  const USDT    = "Tether(USDT)"
  const DOGE    = "Dogecoin"
  const BUSD    = "Binance USD"
  const SHIB    = "Shiba Inu"
  const MATIC   = "Polygon"
  const ETH     = "Ethereum"
  const LINK    = "Chainlink"
  const POLKADOT    = "Polkadot"
  const CARDANO     = "Cardano"
  const SOLANA      = "Solana"
  const PAYEER      = "Payeer"
  const PM          = "Perfect Money"
  const BNB         = "Binance Smart Chain"

  const tron        = "../images/tron.png"
  const btc         = "../images/btc.png"
  const ltc         = "../images/ltc.png"
  const usdt        = "../images/usdt.png"
  const doge        = "../images/doge.png"
  const busd        = "../images/busd.png"
  const shiba       = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum        = "../images/eth.png"
  const chainlink       = "../images/chainlink.png"
  const polkadot        = "../images/pokadot.png"
  const cardano         = "../images/cardano.png"
  const solana          = "../images/solana.png"
  const payeer          = "../images/payeer.png"
  const pm              = "../images/pm.png"
  const bnb             = "../images/bnb.png"


  const notifHandler = async (id) =>{
    alert('Veuillez confirmer la suppression de notification!')
    if (id) {
      axios.post(`/api/v1/delete-notifiers`,
        {
          ID: id,
        },
      )
    }
  }

  const [OrdinaryNumberInput, setOrdinaryNumberInput] = useState("")
  const [OrdinaryOmNumberInput, setOrdinaryOmNumberInput] = useState("")
  const [OrdinaryMomoNumberInput, setOrdinaryMomoNumberInput] = useState("")
  const [OrdinaryMoovNumberInput, setOrdinaryMoovNumberInput] = useState("")

  const [ActualNumber, setActualNumber] = useState([])
  const [ActualOmNumber, setActualOmNumber] = useState([])
  const [ActualMomoNumber, setActualMomoNumber] = useState([])
  const [ActualMoovNumber, setActualMoovNumber] = useState([])

  const OrdinaryNumbers = (event) =>{
    event.preventDefault()
    setOrdinaryNumberInput(event.target.value)
  }

  const OrdinaryMomoNumbers = (event) =>{
    event.preventDefault()
    setOrdinaryMomoNumberInput(event.target.value)
  }

  const OrdinaryOmNumbers = (event) =>{
    event.preventDefault()
    setOrdinaryOmNumberInput(event.target.value)
  }

  const OrdinaryMoovNumbers = (event) =>{
    event.preventDefault()
    setOrdinaryMoovNumberInput(event.target.value)
  }

  const NumberChangeHandler = () =>{
    if(OrdinaryNumberInput !== "")
    console.log(OrdinaryNumberInput)
    alert('Veuillez confirmer la modification!')
    axios.post(`/api/v1/update-ordinary-numbers`,
      {
        ID : 1,
        content: OrdinaryNumberInput,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    )
    .then(
      (response) => {

        if (response.data.msg === 'success') {
  
          if(LineLink){
            window.location.replace("https://nkab-exchange.net/operation");
          } else {
             window.location.replace("http://localhost:3000/operation");
          }

        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const OmNumberChangeHandler = () =>{
    if(OrdinaryNumberInput !== "")
    console.log(OrdinaryNumberInput)
    alert('Veuillez confirmer la modification!')
    axios.post(`/api/v1/update-ordinary-om-numbers`,
      {
        ID : 1,
        content: OrdinaryOmNumberInput,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    )
    .then(
      (response) => {

        if (response.data.msg === 'success') {
  
          if(LineLink){
            window.location.replace("https://nkab-exchange.net/operation");
          } else {
             window.location.replace("http://localhost:3000/operation");
          }

        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const MoovNumberChangeHandler = () =>{
    if(OrdinaryNumberInput !== "")
    console.log(OrdinaryNumberInput)
    alert('Veuillez confirmer la modification!')
    axios.post(`/api/v1/update-ordinary-moov-numbers`,
      {
        ID : 1,
        content: OrdinaryMoovNumberInput,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    )
    .then(
      (response) => {

        if (response.data.msg === 'success') {
  
          if(LineLink){
            window.location.replace("https://nkab-exchange.net/operation");
          } else {
             window.location.replace("http://localhost:3000/operation");
          }

        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  const MomoNumberChangeHandler = () =>{
    if(OrdinaryNumberInput !== "")
    console.log(OrdinaryNumberInput)
    alert('Veuillez confirmer la modification!')
    axios.post(`/api/v1/update-ordinary-momo-numbers`,
      {
        ID : 1,
        content: OrdinaryMomoNumberInput,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
    )
    .then(
      (response) => {

        if (response.data.msg === 'success') {
  
          if(LineLink){
            window.location.replace("https://nkab-exchange.net/operation");
          } else {
             window.location.replace("http://localhost:3000/operation");
          }

        }
      },
      (error) => {
        console.log(error)
      },
    )
  }

  useEffect(() => {

    const fetchOrdinaryNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-numbers')

        setActualNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryNumbers()

  }, [])

  useEffect(() => {

    const fetchOrdinaryOmNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-om-numbers')

        setActualOmNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryOmNumbers()

  }, [])

  useEffect(() => {

    const fetchOrdinaryMomoNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-momo-numbers')

        setActualMomoNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryMomoNumbers()

  }, [])

  useEffect(() => {

    const fetchOrdinaryMoovNumbers = async () => {
      try {

        const { data } = await axios.get('/api/v1/ordinary-moov-numbers')

        setActualMoovNumber(data[0].content)

      } catch (error) { }
    }
    fetchOrdinaryMoovNumbers()

  }, [])

  const creatFirst = () => {

    localStorage.setItem("isMarchandActive", false)
                
    if(LineLink){
      window.location.replace("https://nkab-exchange.net/operation");
    } else {
       window.location.replace("http://localhost:3000/operation");
    }

  }

  const toogleHandleChange = (val) => {

      setChecked(val)

      if(isMarchand === "true"){

        alert('Veuillez confirmer la modification!')
        let activation = "disabled"
        if (activation) {
          axios.post(`/api/v1/update-ordinary-status`,
            {
              ID : 1,
              status: activation,
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {

              if (response.data.msg === 'success') {

                let activation = "enabled"
                if (activation) {
                  axios.post(`/api/v1/update-marchand-status`,
                    {
                      ID : 1,
                      status: activation,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                    },
                  )
                  .then(
                    (response) => {
        
                      if (response.data.msg === 'success') {
        
                        localStorage.setItem("isMarchandActive", false)
                
                        if(LineLink){
                          window.location.replace("https://nkab-exchange.net/operation");
                        } else {
                           window.location.replace("http://localhost:3000/operation");
                        }
        
                      }
                    },
                    (error) => {
                      console.log(error)
                    },
                  )
                }

              }
            },
            (error) => {
              console.log(error)
            },
          )
        }

      } 

      if(isMarchand === "false") {

        alert('Veuillez confirmer la modification!')
        let activation = "enabled"
        if (activation) {
          axios.post(`/api/v1/update-ordinary-status`,
            {
              ID : 1,
              status: activation,
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {
              if (response.data.msg === 'success') {

                let activation = "disabled"
                if (activation) {
                  axios.post(`/api/v1/update-marchand-status`,
                    {
                      ID : 1,
                      status: activation,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                    },
                  )
                  .then(
                    (response) => {
                      if (response.data.msg === 'success') {
        
                        localStorage.setItem("isMarchandActive", true)
                
                        if(LineLink){
                          window.location.replace("https://nkab-exchange.net/operation");
                        } else {
                           window.location.replace("http://localhost:3000/operation");
                        }
        
                      }
                    },
                    (error) => {
                      console.log(error)
                    },
                  )
                }

              }
            },
            (error) => {
              console.log(error)
            },
          )
        }
      }
  }


  return (
    <div className="container pb-4">
      <div className='mt-2'>

      <div className="d-flex justify-content-center nkab-lite-rate p-3 mt-4" style={{color:"#00360c"}}>
          <div className="col-sm-12">
            <div className="text-center">
              <div>
                <h4 className='color-grey'><strong>{AllOp}</strong> opérations effectuées</h4>
                <h4 className='color-grey'><strong>{Operation.length}</strong> opérations affichées</h4>
              </div> 
              <div></div>
            </div>
          </div>
        </div>

        <div className="operations-page-wrapper">
          <div className="container">
            <ul className="operations-infographic-cards">
              <li className="color-1">
                <img className='images-drapeau mr-2' src={`../${om}`} alt="" />
                <h5>Orange</h5>
                <div>
                  <span>Marchand </span>
                  <span><ReactSwitch checked={checked === "true"?true:false} onChange={toogleHandleChange} /></span>
                  <span> Ordinaire</span>
                </div>
                <h6 className='mb-2'><strong>{ActualNumber}</strong></h6>
                {
                  checked === "true" ? <>
                  <Stack component="form" sx={{ width: '25ch'}} spacing={2} noValidate autoComplete="off" >
                    <TextField hiddenLabel id="filled-hidden-label-small" onChange={OrdinaryNumbers} placeholder={`${ActualNumber}`} variant="filled" size="small" />
                  </Stack>
                  <div className='d-flex justify-content-center mb-4 mt-2'>{OrdinaryNumberInput !== ""?<span className='btn btn-primary' onClick={NumberChangeHandler}>Valider</span>:<span className='btn btn-danger'>Valider</span>}</div>
                  </>:null
                }
              </li>
              <li className="color-2">
                <img className='images-drapeau mr-2' src={`../${om}`} alt="" />
                <h5>Orange</h5>
                <h6 className='mb-2'><strong>{ActualOmNumber}</strong></h6>
                <Stack component="form" sx={{ width: '25ch'}} spacing={2} noValidate autoComplete="off" >
                  <TextField hiddenLabel id="filled-hidden-label-small" onChange={OrdinaryOmNumbers} placeholder={`${ActualOmNumber}`} variant="filled" size="small" />
                </Stack>
                <div className='d-flex justify-content-center mb-4 mt-2'>{OrdinaryOmNumberInput !== ""?<span className='btn btn-primary' onClick={OmNumberChangeHandler}>Valider</span>:<span className='btn btn-danger'>Valider</span>}</div>
              </li>
              <li className="color-3">
                <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />
                <h5>Moov</h5>
                <h6 className='mb-2'><strong>{ActualMoovNumber}</strong></h6>
                <Stack component="form" sx={{ width: '25ch'}} spacing={2} noValidate autoComplete="off" >
                  <TextField hiddenLabel id="filled-hidden-label-small" onChange={OrdinaryMoovNumbers} placeholder={`${ActualMoovNumber}`} variant="filled" size="small" />
                </Stack>
                <div className='d-flex justify-content-center mb-4 mt-2'>{OrdinaryMoovNumberInput !== ""?<span className='btn btn-primary' onClick={MoovNumberChangeHandler}>Valider</span>:<span className='btn btn-danger'>Valider</span>}</div>
              </li>
              <li className="color-4">
                <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />
                <h5>Mtn</h5>
                <h6 className='mb-2'><strong>{ActualMomoNumber}</strong></h6>
                <Stack component="form" sx={{ width: '25ch'}} spacing={2} noValidate autoComplete="off" >
                  <TextField hiddenLabel id="filled-hidden-label-small" onChange={OrdinaryMomoNumbers} placeholder={`${ActualMomoNumber}`} variant="filled" size="small" />
                </Stack>
                <div className='d-flex justify-content-center mb-4 mt-2'>{OrdinaryMomoNumberInput !== ""?<span className='btn btn-primary' onClick={MomoNumberChangeHandler}>Valider</span>:<span className='btn btn-danger'>Valider</span>}</div>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div style={{ marginBottom: `${'10px'}` }}>
            <span
              className='goback'
              onClick={() => history.goBack()}
              style={{ color: '#0a146e', cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {'Retour'}
            </span>
          </div>
          <div style={{ marginBottom: `${'10px'}` }}>
            <a href="/sell-or-buy-crypto" style={{textDecoration:"none"}}>
              <span
                type="submit"
                className='goback'
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faForward} /> {'Accueil du service'}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="__op-body-table">
        <table className='__op-responsive-table'>
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Contact</th>
              <th scope="col">Opération</th>
              <th scope="col">Crypto</th>
              <th scope="col">Taux</th>
              <th scope="col">Quantité</th>
              <th scope="col">Réseau</th>
              <th scope="col">Par</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
              <th scope="col">Statut</th>
              <th scope="col">Détails</th>
            </tr>
          </thead>
          <tbody>
            {Operation.length === 0 ? <>
                <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
                  <div><Skeleton count={3} /></div>
                </SkeletonTheme>
                <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
                  <p><Skeleton count={3} /></p>
                </SkeletonTheme>
              </> : Operation.map((item) => (
                <tr key={item.ID}>
                  <th scope="row">
                    {
                      operationAlertData.length > 0 && operationAlertData.map((notif) => (
                        <span key={notif.ID}>
                          {
                            item.OrderId === notif.notifyServicesId ? 
                            <FontAwesomeIcon icon={faBell} style={{color:'#90d9ff'}} onClick={() => notifHandler(notif.ID)} />:null
                          }
                        </span>
                      ))
                    }
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>{item.SenderEmail}</span> : 
                    item.operatorAction === 'sel' ? 
                    <span className='text-danger'>{item.SenderEmail}</span> : 
                    null}
                  </th>
                  <td data-title="Contact">
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>{item.SenderPhone}</span> : 
                    item.operatorAction === 'sel' ? <span className='text-danger'>{item.SenderPhone}</span> : 
                    null}
                  </td>
                  <td data-title="Opération">
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>Achat</span> : 
                    item.operatorAction === 'sel' ? <span className='text-danger'>Vente</span> : 
                    null}
                  </td>
                  <td data-title="Monnaie">
                    {
                      item.serviceName === TRON ? 
                      <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />:
                      item.serviceName === BTC ? 
                      <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />:
                      item.serviceName === LTC ? 
                      <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />:
                      item.serviceName === USDT ? 
                      <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />:
                      item.serviceName === DOGE ? 
                      <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />:
                      item.serviceName === BUSD ? 
                      <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />:
                      item.serviceName === SHIB ? 
                      <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />:
                      item.serviceName === MATIC ? 
                      <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />:
                      item.serviceName === POLKADOT ? 
                      <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />:
                      item.serviceName === ETH ? 
                      <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />:
                      item.serviceName === LINK ? 
                      <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />:
                      item.serviceName === CARDANO ? 
                      <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />:
                      item.serviceName === SOLANA ? 
                      <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />:
                      item.serviceName === PAYEER ? 
                      <img className='images-drapeau mr-2' src={`../${payeer}`} alt="" />:
                      item.serviceName === PM ? 
                      <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />:
                      item.serviceName === BNB ? 
                      <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />:null
                    } 
                  </td>
                  <td data-title="Taux">
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>{item.rate} XOF</span> : 
                    item.operatorAction === 'sel' ? <span className='text-danger'>{item.rate} XOF</span> : 
                    null}
                  </td>
                  <td data-title="Quantité">
                    {item.operatorAction === 'buy' ? 
                      <span className='text-success'>
                      {
                        item.serviceSymbol === "USDT" ? 
                        <>{(item.dollar * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                        item.serviceSymbol === "trx" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        item.serviceSymbol === "PM" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        item.serviceSymbol === "PAYEER" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                      }</span> : 
                        item.operatorAction === 'sel' ? <span className='text-danger'>
                      {
                        item.serviceSymbol === "USDT" ? 
                        <>{(item.dollar * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                        item.serviceSymbol === "trx" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        item.serviceSymbol === "PM" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        item.serviceSymbol === "PAYEER" ? 
                        <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                        <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                      }</span> : 
                    null}
                  </td>
                  <td data-title="Réseau">
                    {item.tetherNet}
                  </td>
                  <td data-title="Par">
                    {
                      item.PaymentMethod === OM ? 
                      <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                      item.PaymentMethod === MOMO ? 
                      <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                      item.PaymentMethod === MOOV ? 
                      <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                      item.PaymentMethod === WAVE ? 
                      <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                      item.PaymentMethod === TM ? 
                      <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                      item.PaymentMethod === FM ? 
                      <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                      item.PaymentMethod === EM ? 
                      <img className='images-drapeau mr-2' src={`../${em}`} alt="" />:null
                    }
                    </td>
                  <td data-title="Montant">
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>{item.SenderChange} XOF</span> : 
                    item.operatorAction === 'sel' ? 
                    <span className='text-danger'>{item.SenderChange} XOF</span> :
                    null}
                  </td>
                  <td data-title="Date">
                    {item.operatorAction === 'buy' ? 
                    <span className='text-success'>{item.updatedAt.slice(0, 10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</span> : 
                    item.operatorAction === 'sel' ? 
                    <span className='text-danger'>{item.updatedAt.slice(0, 10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</span> : 
                    null}
                  </td>
                  <td data-title="Statut">
                    {
                      !cPstatusData?null: cPstatusData.map((cpItem)=>(
                        <span key={cpItem.ID}>
                          {
                            item.OrderId === cpItem.cpm_trans_id?
                            <FontAwesomeIcon icon={faCircle} style={{color:'green'}} />:null
                          }
                        </span>
                      ))
                    }{item.pmode !== "hub2"?null:item.pmode}{' '}

                    {item.operatorAction === 'buy' ? 
                      <>
                        {
                          item.isAdminCompleted === 'non validé' ? 
                          <span className="creditation-btn mr-2"> 
                            <a href={`/buy-confirmation/${item.ID}`} style={{fontSize:'11px'}}> <MoreHorizIcon/> </a> 
                          </span> :
                          item.isAdminCompleted === 'validé' ?
                          <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span> :
                          item.isAdminCompleted === 'annulé' ?
                          <span className="text-danger" style={{fontSize:'11px'}}> <SmsFailedIcon/> </span> :
                          null
                        }{' '}
                      </> : 
                      item.operatorAction === 'sel' ? 
                      <>
                        {
                          item.isAdminCompleted === 'non validé' ? 
                          <span className="creditation-btn mr-2"> 
                            <a href={`/sell-confirmation/${item.ID}`} style={{fontSize:'11px'}}> <MoreHorizIcon/> </a> 
                          </span> :
                          item.isAdminCompleted === 'validé' ?
                          <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span> :
                          item.isAdminCompleted === 'annulé' ?
                          <span className="text-danger" style={{fontSize:'11px'}}> <SmsFailedIcon/> </span> :
                          null
                        }{' '}
                      </>:null
                    }
                  </td>
                  <td data-title="Détails">
                    <span className="details" ><a href="#nkab-popup" onClick={() => detailOption(item.ID)}><ReadMoreIcon/></a></span>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <div>
          <div className='text-center p-2'><span className="stat-btn-see-more" onClick={seeMoreItemsHandler}>Voir plus <FontAwesomeIcon icon={faArrowRight} /></span></div>
        </div>
      </div>

      <div>
        <div id="closed"></div>
        <div className="nkab-popup-wrapper" id="nkab-popup">
        <div className="nkab-popup-container">
        <center>
          <img className='nkab-up-img' src="https://tinypic.host/images/2023/02/16/pngtree-television-news-background-image_909008.jpg" width="400" alt=''/><br/><br/>
          <div>
            <div>ID : <strong className='text-warning'>{opid}</strong> </div>
            <div>{op==="sel"?"Vente":op==="buy"?"Achat":null} de <strong className='text-success'>{qt} {opname}</strong> au taux de <strong>{taux} XOF</strong> par <strong>{name}</strong> sous l'adresse : <strong className='text-info'>{opaddress}</strong> au numéro de téléphone : <strong>{phone}</strong>.</div>
            <div>Moyen de paiement : <strong className='text-info'>{methodpayment}</strong></div>
            <div>
              <span>Statut : </span>
              {
                validated === "validé" ? <span className='text-success'>Validée avec succès !</span> : validated === "non validé"? <span className='text-danger'>Non traitée</span> : validated === "annulé"? <span className='text-danger'>échec de l'opération</span> :  null
              } 
            </div>
          </div>
          <div className='row d-flex text-center'>
              <span className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                <span>{isCopied ? 'Adresse copiée!' : "Cliquer ici pour copier l'adresse"}</span>
              </span>
            </div>
        </center>
        <a className="nkab-popup-close" href="#closed">X</a>
        </div>
        </div>
      </div>
      <BottomNavBar />
    </div>
  )
}

export default OperationScreen
