import axios from 'axios'
import React, { useEffect, useState } from 'react'

const OpInterfaceComponent = ({themeMode}) => {

  const userID                 = localStorage.getItem('userID')
  const [OpList, setOpList] = useState([])

  useEffect(() => {

    const fetchDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/all-p-op',
        )

        if(data && userID){
          const dataGetted = data.filter((obj) => {
            return obj.userId === Number(userID)
          })

          if(dataGetted){
            const _dataGetted = dataGetted
            const items = _dataGetted.slice(0, 30)
            setOpList(items)
          }
        }

      } catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [userID, OpList])



  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  const TRON    = "TRON"
  const BTC     = "Bitcoin"
  const LTC     = "Litecoin"
  const USDT   = "Tether(USDT)"
  const DOGE    = "Dogecoin"
  const BUSD    = "Binance USD"
  const SHIB    = "Shiba Inu"
  const MATIC    = "Polygon"
  const ETH     = "Ethereum"
  const LINK    = "Chainlink"
  const POLKADOT    = "Polkadot"
  const CARDANO    = "Cardano"
  const SOLANA    = "Solana"
  const PAYEER  = "Payeer"
  const PM      = "Perfect Money"
  const BNB     = "Binance Smart Chain"

  const tron      = "../images/tron.png"
  const btc    = "../images/btc.png"
  const ltc   = "../images/ltc.png"
  const usdt   = "../images/usdt.png"
  const doge    = "../images/doge.png"
  const busd    = "../images/busd.png"
  const shiba    = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum    = "../images/eth.png"
  const chainlink    = "../images/chainlink.png"
  const polkadot    = "../images/pokadot.png"
  const cardano    = "../images/cardano.png"
  const solana    = "../images/solana.png"
  const payeer      = "../images/payeer.png"
  const pm      = "../images/pm.png"
  const bnb      = "../images/bnb.png"


  return (
    <div>

<div className="__op-body-table">
        <table className='__op-responsive-table'>
          <thead style={{ fontSize: `${'11px'}` }}>
            <tr>
              <th scope="col">Identifiant</th>
              <th scope="col">Contact</th>
              <th scope="col">Opération</th>
              <th scope="col">Type</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
              <th scope="col">Statut</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: `${'11px'}` }}>
            {!OpList ? null : (
              OpList.map((item) => (
                <tr key={item.ID}>
                  <th scope="row"> {item.operationUId} </th>
                  <td data-title="Contact"> {item.phone} </td>
                  <td data-title="Opération"> {item.options} </td>
                  <td data-title="Coin"> {item.moneyName}</td>
                  <td data-title="Montant"> {`${Number(item.montant)} XOF`} </td>
                  <td data-title="Date">{item.updatedAt.slice(0, 10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</td>
                  <td data-title="Statut" style={{ fontSize: `${'12px'}` }}>
                    {item.status === 'failed' ? <span className='text-danger'>échouée</span> : 
                    item.status === 'successful' ? <span className='text-success'>validée</span> : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OpInterfaceComponent