import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import axios from 'axios'
import ChatCustomer from '../components/ChatCustomer'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BottomNavBar from '../components/BottomNavBar'

export default function FinalTronPaymentScreen(props) {

  const STATUS = {
    STARTED: 'Traitement en cours...',
  }
  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }
  
  const Logged                        = localStorage.getItem('userStatus')
  const userID                        = localStorage.getItem('userID')
  const operatorListName              = localStorage.getItem('operatorListName')
  const operatorAction                = localStorage.getItem('operatorAction')
  const operatorUserInfoName          = localStorage.getItem('operatorUserInfoName')
  const operatorUserInfoLastName      = localStorage.getItem('operatorUserInfoLastName')
  const operatorUserInfoPhone         = localStorage.getItem('operatorUserInfoPhone')
  const serviceSymbol                 = localStorage.getItem('serviceSymbol')
  const serviceName                   = localStorage.getItem('serviceName')
  const converted                     = Number(localStorage.getItem('converted'))
  const dollar                        = Number(localStorage.getItem('dollar'))
  const rate                          = Number(localStorage.getItem('rate'))
  const finalPrice                    = localStorage.getItem('finalPrice')
  const tetherNet                     = localStorage.getItem('tetherNet')
  // const lat                        = localStorage.getItem('lat')
  // const long                       = localStorage.getItem('long')

  const [displayConfirme,             setDisplayConfirme  ] = useState(false)
  const [adress,                      setAdress           ] = useState('')
  const [isCopied,                    setIsCopied         ] = useState(false);
  const [copyText,                    setCopyText         ] = useState(false);
  const [GetUser,                     setGetUser          ] = useState([])
  const [User,                        setUser             ] = useState([])
  const {id}                                                = useParams()

  const INITIAL_COUNT   = 10
  const INITIAL_COUNT2  = 300
  const [secondsRemaining,      setSecondsRemaining ] = useState(INITIAL_COUNT)
  const [secondsRemaining2, setSecondsRemaining2    ] = useState(INITIAL_COUNT2)
  const [status,                setStatus           ] = useState(STATUS.STOPPED)
  const [status2,           setStatus2              ] = useState(STATUS2.STOPPED2)

  const [statusCount,           setStatusCount      ] = useState()

  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay   = (minutesRemaining - minutesToDisplay) / 60

  const adrTotal         = adress.length
  const limitAdrCount    = 8
  const adr_Coin         = adress
  
  ///////////////////////////////////////////
  // const TRX_adress    = "TT6h8QDASpG29jLcffD35A5yygE76z4t3y"
  const TRX_adress    = "TCKBZUtgFhkziK7Vh6ohdnpXFNhn3gkAsZ"
  // const TRX_adress    = "TJh4e8rQusgKsEfTgacxEipMwUcXpdi3jw"
  //  const TRX_adress = "TBqRP4C6Vz76Ly6au29V4aXjgDvcYke9EZ"


  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }
  },[Logged])



  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])

  useEffect(()=>{

    if(GetUser && userID){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      setUser(userGetted[0])
    }

  },[GetUser, userID, User])


  function alerter() {
    props.history.push(`/orderhistory/`)
  }


  useEffect(() => {
    setStatusCount(twoDigits(secondsRemaining))
  }, [secondsRemaining, statusCount])

  useEffect(() => {
    setStatus(STATUS.STARTED)
  }, [STATUS.STARTED])
  
  useEffect(()=>{
    setStatus2(STATUS2.STARTED2)
  },[STATUS2.STARTED2])

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },

    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )
  
  useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },
  
    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
  )

  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining2 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining2])

  useEffect(() => {
    if (
      Number(twoDigits(minutesRemaining)) === 0 &&
      Number(twoDigits(secondsToDisplay)) === 0
    ) {
      setDisplayConfirme(true)
    }
  }, [minutesRemaining, secondsToDisplay])





  useEffect(()=>{
    if(TRX_adress){
      setCopyText(TRX_adress)
    }
  },[TRX_adress])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const payByBscHandler = () => {
    const OrderId = id
    const SenderName = `${operatorUserInfoLastName} ${operatorUserInfoName}`
    const SenderPhone = operatorUserInfoPhone
    const SenderChange = finalPrice
    const PaymentMethod = operatorListName
    const SenderId = User.ID
    const SenderCountry = User.countryName

      if (
          SenderName &&
          SenderPhone &&
          SenderChange &&
          PaymentMethod &&
          SenderId &&
          OrderId &&
          operatorAction &&
          SenderCountry &&
          serviceName &&
          serviceSymbol &&
          converted &&
          dollar && 
          rate &&
          adr_Coin
        ) {
        axios.post(
            '/api/v1/operation/create',
            {
              SenderId,
              SenderCountry,
              SenderName,
              SenderPhone,
              SenderChange : Number(SenderChange),
              PaymentMethod,
              OrderId,
              operatorAction,
              SenderEmail: User.email,
              serviceName,
              serviceSymbol,
              tetherNet,
              memo:"no memo",
              converted,
              dollar,
              rate,
              adr_Coin,
              pmode: "coins",
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          )
          .then(
            (response) => {
              if(response){
                function alertHandler() {
                  confirmAlert({
                    title: `Validé avec succès !`,
                    message: ` Nous vous remercions d'avoir validé votre envoi et vous invitons à suivre le 
                    statut du traitement dans votre historique !`,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => alerter(),
                      },
                    ],
                  })
                }
                alertHandler()
  
                localStorage.removeItem('servicesId')
                localStorage.removeItem('serviceName')
                localStorage.removeItem('serviceSymbol')
                localStorage.removeItem('serviceImageLink')
                localStorage.removeItem('rate')
                localStorage.removeItem('adr_Coin')
                localStorage.removeItem('montant')
                localStorage.removeItem('converted')
                localStorage.removeItem('cfa')
                localStorage.removeItem('dollar')
                localStorage.removeItem('operatorListName')
                localStorage.removeItem('operatorAction')
                localStorage.removeItem('operatorUserInfoId')
                localStorage.removeItem('operatorUserInfoName')
                localStorage.removeItem('operatorUserInfoLastName')
                localStorage.removeItem('operatorUserInfoEmail')
                localStorage.removeItem('operatorUserInfoPhone')
                localStorage.removeItem('codePostalCountry')
                localStorage.removeItem('operatorUserInfoCountry')
                localStorage.removeItem('tetherNet')
                localStorage.removeItem('finalPrice')
                localStorage.removeItem('finalPrice3')
                localStorage.removeItem('lat')
                localStorage.removeItem('long')
              }
            },
            (error) => {},
          )
      }
  }

  const loadingType = 'Décompte en cours...'
  const loadingAdres = "Contrôle d'adresse entrant en cours..."

  return (
    <div className="container pb-5">
      <div className='mt-5'>
        <div>
          <div className='pb-5'>
            {
              <div className='pb-5'>
                <div className={`${themeMode === "true" ? "final-seesion final-seesion-t pb-5" : "final-seesion pb-5"}`}>
                  <div className="order-left-side">
                    <div className="nkab-card nkab-card-body">
                      <h4 className="final-step-alert">N°: {id} </h4>
                      <div className="final-step-alert">
                        Priez suivre les instructions ci-dessous pour l'envoi des
                        {operatorAction === 'sel' ? (
                          <>
                            <strong>
                              {' '}
                              {
                                serviceSymbol === "USDT || PM" ? 
                                <>${' '}{(converted * 1).toFixed(2)}</>:
                                serviceName === "TRON" ?
                                <>{(converted * 1).toFixed(2)}{' '}{serviceSymbol}</>:
                                <>{(converted * 1).toFixed(5)}{' '}{serviceSymbol}</>
                              }
                              
                            </strong>{' '}
                          </>
                        ) : null}{' '}:
                      </div>
                      <span style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                        <strong>N°1 : Copiez</strong> l'
                        <strong>adresse</strong> pour le paiement,
                      </span>
                      <br />
                      <span style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                        <strong>N°2 : </strong>Envoyez exactement le montant
                        {operatorAction === 'sel' ? (
                          <>
                            <strong>
                              {' '}
                              ${' '}{(converted * 1).toFixed(2)}
                            </strong>{' '}
                          </>
                        ) : null}{' '}
                        dans un délai de <strong>5 à 10 minutes</strong>.
                      </span>
                      <br />
                    </div>
                    <div className="nkab-card nkab-card-body">
                      {operatorAction === 'sel' ? (
                        <div>
                          {serviceSymbol === 'trx' ? (
                            <div>
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Notre adresse de réception{' '} <strong>TRX</strong> :{' '} </span>
                              <div>
                                <span className='refInput' readOnly style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{copyText} </span>
                                <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                                  <span>{isCopied ? 'Adresse copiée!' : "Copier"}</span>
                                </button>
                              </div>
                              <br />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="order-right-side">
                    <div className="nkab-card nkab-card-body">
                      <div className='row'>
                        <h5>
                          <div className='row text-center'>
                            {displayConfirme === false ? (
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Bouton de validation dans...</span>
                            ) : displayConfirme === true ? (
                              <><br/>
                              <span style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Renseignez votre adresse <strong className='uppercase'>{serviceSymbol}</strong> après l'envoi puis validez ! </span>
                              <input
                                className="wavePaymentNameInput mt-2"
                                style={{border: '1px solid #90d9ff'}}
                                type="text"
                                placeholder="Entrez votre adresse créditeur"
                                onChange={(e) =>
                                  setAdress(e.target.value)
                                }
                              />
                              </>
                            ) : null}
                          </div>
                          {displayConfirme === false ? (
                            <div className='bolder' style={{color:`${themeMode === "true" ? "#ffa2a2" : "color-r"}`}}>
                              <span>
                                {Number(twoDigits(minutesRemaining))} {'mn'}{' '}
                              </span>
                              <span>{' : '}</span>
                              <span>
                                {Number(twoDigits(secondsToDisplay))} {'s'}{' '}
                              </span>
                            </div>
                          ) : null}
                        </h5>
                      </div>
                    </div>
                    {displayConfirme === false ? (
                      <LoadingBox loadingType={loadingType} />
                    ) : displayConfirme === true ? (
                      <div className="nkab-card nkab-card-body">
                        {
                          !adress && adrTotal < limitAdrCount ? 
                          <h2 className="confirmeDisplaing2" >
                            Valider
                          </h2>:
                          adress && adrTotal >= limitAdrCount ? 
                          <h2 className="confirmeDisplaing" onClick={payByBscHandler} >
                            Valider
                          </h2>:<LoadingBox loadingType={loadingAdres} />
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')
