import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { useSelector } from 'react-redux'

export default function EditUserWave(props) {
  
  var _ProWallet                       = localStorage.getItem('_ProWallet')


  const auth                           = useSelector((state) => state.auth)
  const { id }                         = useParams()
  const { user }                       = auth
  const history                        = useHistory()
  const [checker,          setChecker] = useState(false)

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }


  const handleCheck = () => {
    setChecker(true)
  }

  const handleUpdate = async () => {
    if(checker){
      try {
        axios.post(`/api/api/v1/admin/last/verification/create-verification`,
          {
            verifStatus : "Vérifié",
            adminId : user._id,
            waveAcountId : id,
          },
        ).then((response) => {
          if(response.data._id){
            props.history.push('/profile')
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }


  return (
    <div className="color-w container" style={{backgroundColor:"#90d9ff"}}>
      <div className="about-session">
        <div className="container emp-profile">
          <div className="row">
            <div style={{ marginBottom: `${'10px'}` }}>
              <span
                className='goback'
                onClick={() => history.goBack()}
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faBackward} /> {'Retour'}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="email">Identifiant</label>
                <input
                  className="form-control input-signin input-add"
                  type="text"
                  name="email"
                  defaultValue={id}
                  disabled
                />
              </div>

              <div className="form-group">
                {
                  <input
                    className="input-checkbox"
                    type="checkbox"
                    id="isAdmin"
                    onClick={handleCheck}
                  />
                }
                <span>
                  {' '}
                  <label htmlFor="isAdmin">is Vérified</label>
                </span>
              </div>
            </div>
            <br />

            <div className="form-group">
              <input
                type="submit"
                style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}` }}
                className="btn btn-lg btn-primary btn-block"
                value="Mettre à jour"
                onClick={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
