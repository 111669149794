import { faExchangeAlt, faMoneyBillAlt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import Comments from './Comments'

const StatisticOfOperations = () => {

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])
      
  return (
    <div className='mt-5'>
        <div className='row'>
            <div className='col-md-4 mb-5'> <Comments/> </div>
            <div className='col-md-8'>
                <div className='p-2'>
                    <div className='row mb-2'>
                        <div className="_our-header">
                          <h1 className="header__title" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>E-NKAB est disponible</h1>   
                        </div>

                        <div className='row mb-3'>
                            <h4 style={{color:`${themeMode === "true" ? "#90d9ff" : "#000"}`}}>Alimenter votre portefeuille!</h4>
                            <p style={{color:`${themeMode === "true" ? "#90d9ff" : "#000"}`}}>NKAB Exchange vous propose plusieurs options pour créditer votre compte. Trouvez la méthode qui vous convient le mieux.</p>
                          <div className='mb-5'> 
                            <img className='mr-2' src="../../images/ecob.png" width="30" height="30" alt="" />
                            <img className='mr-2' src="../../images/bicici.png" width="30" height="30" alt="" />
                            <img className='mr-2' src="../../images/bni.png" width="30" height="30" alt="" />
                            <img className='mr-2' src="../../images/om.png" width="30" height="30" alt="" />
                            <img className='mr-2' src="../../images/momo.png" width="30" height="30" alt="" />
                            <img src="../../images/flooz.png" width="30" height="30" alt="" />
                          </div>
                            <div className='col-md-6'>
                                <div className='card d-flex p-3' style={{backgroundColor:`${themeMode === "true" ? "#0E233A" : ""}`}}>
                                    <div className='mr-2'><span style={{fontSize:'30px'}}><FontAwesomeIcon className='pointer nkab-item-hover' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} icon={faMoneyBillAlt} /></span></div>
                                    <div>
                                        <span style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Achat et vente de cryptos</span>
                                        <p style={{fontSize:"13px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Achetez et vendez des cryptomonnaies directement depuis votre portefeuille E-NKAB.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='card d-flex p-3' style={{backgroundColor:`${themeMode === "true" ? "#0E233A" : ""}`}}>
                                    <div className='mr-2'><span style={{fontSize:'30px'}}><FontAwesomeIcon className='pointer nkab-item-hover' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}} icon={faExchangeAlt} /></span></div>
                                    <div>
                                        <span style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Echange entre cryptos</span>
                                        <p style={{fontSize:"13px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Echangez désormais entre cryptomonnaies directement via votre portefeuille E-NKAB.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='p-2'>
                    <div className='row nkab-info-card mb-2'>
                        <div><strong style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Fiabilité</strong> <hr/></div>
                        <div style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Faible risque</div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-6 order-md-2 mb-3">
                    <img
                      src="../images/forma_image_nkab2.png"
                      className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate"
                      alt="..."
                      data-aos="fade-up"
                      data-aos-delay="100"
                    />
                  </div>
                  <div className="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate text-center" data-aos="fade-up" >
                    <h5 className="text-md-start" style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> Partenaires de paiement </h5>
                    <div className='mb-5'> 
                      <img className='mr-2' src="../../images/hub2.png" width="40" height="20" alt="" />
                      <img className='mr-2' src="../../images/mastercardvisa3.png" width="30" height="20" alt="" />
                      <img src="../../images/cinetpay_in.png" width="30" height="20" alt="" />
                    </div>

                    <div className="text-center text-md-start">
                      <a href="/about" className="btn btn-primary shadow lift me-1 bg-color-b" style={{border:"none"}}>
                        Qui nous sommes{' '}
                        <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                      </a>
                      <span className="lift" style={{fontSize:"20px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Documentation</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StatisticOfOperations