import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DomLoading from '../components/loadings/DomLoading/DomLoading'
import { Button, Grid } from '@mui/material'

export default function OperationProConfirmation2(props) {

  const Logged                                           = localStorage.getItem('userStatus')
  const role                                             = localStorage.getItem('role')
  const { id }                                           = useParams()

  const [Operation,             setOperation           ] = useState([])
  const [loading,               setLoading             ] = useState(false)
  const [WitnessesStopReq,      setWitnessesStopReq    ] = useState(false)
  const [isCopied,              setIsCopied            ] = useState(false);
  const [copyText,              setCopyText            ] = useState(false);
  const [LoadingCancel,             setLoadingCancel        ] = useState(false)
  const [AllOp,                  setAllOp             ] = useState([])
  const [AllOpStopReq,           setAllOpStopReq      ] = useState(false)
  const [MyId,           setMyId           ] = useState([])

  const LineLink = true
  // const LineLink = false

  const TRX_adress = `${Operation.adress}`

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }

    if (Number(role) !== 1) {
      window.location.replace("https://nkab-exchange.net/");
    }
  },[Logged, role])



  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!WitnessesStopReq){
          const { data } = await axios.get(
            '/api/v1/get-p-buy',
          )

          if(data && id){
            const dataGetted = data.filter((obj) => {
              return obj.ID === Number(id)
            })
  
            setOperation(dataGetted[0])
          if(data.length > 0){
            setWitnessesStopReq(true)
          }
        }
      }} catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [id, Operation, WitnessesStopReq])



  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!AllOpStopReq){

          const { data } = await axios.get('/api/v1/get-p-recharge')

          if(data && Operation){
            const dataGetted = data.filter((obj) => {
              return obj.operationUId === Operation.operationUId
            })
  
            setAllOp(dataGetted[0])
            setMyId(AllOp.ID)

          if(data.length > 0){
            setAllOpStopReq(true)
          }
        }
      }} catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [id, Operation, AllOpStopReq])

  const _myID = Number(MyId)


  const successbyadminhandler = async () => {
    setLoading(true)
    const validation = 1
    if(validation && Number(role) === 1){
      axios.post(
          `/api/v1/p-buy/validation`,
          {
            ID: Number(id),
            status: "successful"
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {

              axios.post(
                `/api/update/all-p-op`,
                {
                  ID: _myID,
                  status: "successful"
                },
              ).then((response) => {
                if (response.data.msg === 'success'){
                  if(LineLink){
                    window.location.replace("https://nkab-exchange.net/operation-pro");
                  } else {
                     window.location.replace("http://localhost:3000/operation-pro");
                  }
                }
              })

              if(LineLink){
                window.location.replace("https://nkab-exchange.net/operation-pro");
              } else {
                  window.location.replace("http://localhost:3000/operation-pro");
              }
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }


  const canselbyadminhandler = async () => {

    setLoadingCancel(true)
    const validation = 1
    if(validation && Number(role) === 1){
      axios.post(
          `/api/v1/p-buy/validation`,
          {
            ID: Number(id),
            status: "failed"
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {

              axios.post(
                `/api/update/all-p-op`,
                {
                  ID: _myID,
                  status: "successful"
                },
              ).then((response) => {
                if (response.data.msg === 'failed'){
                  if(LineLink){
                    window.location.replace("https://nkab-exchange.net/operation-pro");
                  } else {
                     window.location.replace("http://localhost:3000/operation-pro");
                  }
                }
              })
              
              if(LineLink){
                window.location.replace("https://nkab-exchange.net/operation-pro");
              } else {
                 window.location.replace("http://localhost:3000/operation-pro");
              }
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }




  useEffect(()=>{
    if(TRX_adress){
      setCopyText(TRX_adress)
    }
  },[TRX_adress])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const loadMsg = "Patienter un moment..."

  return (
    <div className="container">
      <div className="py-5 text-center">
        <h2>NKAB CONFIRMATION DE {Operation.options}</h2>
          <div className='withdrav-content'>
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">
                  <span className='danger bolder uppercase'>{Operation.email}</span> effectue une opération d'<strong>{Operation.options}</strong> de <strong>{Operation.moneyName}</strong>
                </h5>
                <h5 className=''> Montant d'achat' : <strong style={{color:"#0a146e"}}>{Operation.montant} XOF ≃ {Operation.dollar} $ {Operation.moneyName === "Perfect Money"? "PM":null}</strong></h5>
                <h5 className=''> Taux d'achat' : <strong style={{color:"#0a146e"}}>{Operation.taux} XOF </strong></h5>
                <h5 className=''> Taux d'achat' :  <span className='refInput' readOnly >{copyText} </span>
                    <button className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                        <span>{isCopied ? 'Adresse copiée!' : "Copier"}</span>
                    </button>
                </h5>
              </div>
              <div className="card-footer text-muted">
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(0,10)}{' '}à{' '}
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(11,19)}
              </div>
            </div>
          </div> 
        <div className="withdrav-content">
          <h4 className="text-center mt-3 mb-3">
            <span className="text-primary"> {Operation.montant} XOF </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Type d'opération</h6> </div>
              <span className="text-muted"> {Operation.options} </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Montant (XOF)</h6> </div>
              <span className="text-muted">{Operation.montant} XOF</span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div> <h6 className="my-0">Montant (US)</h6> </div>
              <span className="text-muted"> {Operation.dollar} $ </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span><strong>Total (XOF)</strong></span>
              <span className="text-muted"><strong>{Operation.montant} XOF</strong></span>
            </li>
          </ul>

          <Grid container>
              <Grid item xs sx={{ mr: 1}}>
                {
                  LoadingCancel === false ? 
                  <Button type="submit" onClick={canselbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} >Annuler </Button>:
                  LoadingCancel === true ?
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} ><div className="nkab-spinner "></div> </Button>:null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loading === false ? 
                  <Button type="submit" onClick={successbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Confirmer </Button> :
                  loading === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
            </Grid>
        </div>
      </div>
    </div>
  )
}
