import React, { useEffect, useRef, useState } from 'react'
import './RInterfaceComponent.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import axios from 'axios'
import { uid } from 'uid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const RInterfaceComponent = ({Wallets, PaymentSuccess, nkab_rge_id, nkab_rge_reference, themeMode}) => {

    const LineLink = true
    // const LineLink = false

    const SenderCountry = localStorage.getItem('SenderCountry')
    const [BtnOp, setBtnOp] = useState(false)

    function viewOpBtn(){
        setBtnOp(!BtnOp)
    }
    const banqueImage  = localStorage.getItem("banqueImage")
    const banqueName  = localStorage.getItem("banqueName")
    const banqueClickedName = localStorage.getItem("banqueClickedName")
    const userID  = localStorage.getItem('userID')
    const userEmail = localStorage.getItem('userEmail')
    const userLastName = localStorage.getItem("userLastName")
    const userName = localStorage.getItem("userName")
    const nkabWarning = localStorage.getItem("nkabWarning")
    const nkabMarkTitle = localStorage.getItem("nkabMarkTitle")
    const nkab_rge_simple_tolk = localStorage.getItem("nkab_rge_simple_tolk")

    const [BanqueStatus, setBanqueStatus ] = useState(false)

    const [BankOptionClicked1, setBankOptionClicked1 ] = useState("")
    const [BankOptionClicked2, setBankOptionClicked2 ] = useState("")
    const [BankOptionClicked3, setBankOptionClicked3 ] = useState("")
    const [BankOptionClicked4, setBankOptionClicked4 ] = useState("")
    const [CodeSecret, setCodeSecret ] = useState("")
    const [NkabSpinner,  setNkabSpinner] = useState(false)
    const NetAddress  = "..."

    useEffect(()=>{
        if(PaymentSuccess === "successful"){
            setNkabSpinner(!NkabSpinner)
        }
    },[PaymentSuccess, NkabSpinner])

    const STATUS = { STARTED: 'Traitement en cours...', }

    const INITIAL_COUNT1 = 3600

    const [secondsRemaining1, setSecondsRemaining1] = useState(INITIAL_COUNT1)
    const [status1, setStatus1] = useState(STATUS.STOPPED)

    const[last_cfa_char_nbr, setLast_cfa_char_nbr] = useState()
    const [total,setTotal] = useState()

    useEffect(()=>{
        setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])
    
    useEffect(()=>{

        if(secondsRemaining1 > 0){
          setTotal(secondsRemaining1)
          const cfa_nbr = total
          if(cfa_nbr){
            const cfa_nbr_to_text = cfa_nbr.toString()
            const cfa_nbr_last_char = cfa_nbr_to_text.slice(-2)
            setLast_cfa_char_nbr(Number(cfa_nbr_last_char))
          }
        }

      },[secondsRemaining1, total])

      useInterval(
          () => {
          if (secondsRemaining1 > 0) {
              setSecondsRemaining1(secondsRemaining1 - 1)
          } else {
              setStatus1(STATUS.STOPPED)
          }
          },
  
          status1 === STATUS.STARTED ? 1000 : null,
          // passing null stops the interval
      )

      function useInterval(callback, delay) {
          const savedCallback = useRef()
  
          // Remember the latest callback.
          useEffect(() => {
          savedCallback.current = callback
          }, [callback])
  
      // Set up the interval.
      useEffect(() => {
        function tick() {
          savedCallback.current()
        }
        if (delay !== null) {
          let id = setInterval(tick, delay)
          return () => clearInterval(id)
        }
      }, [delay])
      }

    const twoDigits = (num) => String(num).padStart(2, '0')
  
    const handleCloser2 = () => { localStorage.setItem('isRActive', false)};

    function ChoixBanque1(){
        setBanqueStatus(true)
    }
    function ChoixBanque1b(){
        setBanqueStatus(false)
    }
    //BanqueS
    function ChoixBanque2a(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/wave.png")
        localStorage.setItem("banqueName", "Wave")
    }
    function ChoixBanque2b(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/vbancaire.png")
        localStorage.setItem("banqueName", "Virement bancaire")
    }
    function ChoixBanque2c(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/dbancaire.png")
        localStorage.setItem("banqueName", "Dépôt bancaire")
    }
    function ChoixBanque2d(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/cash.png")
        localStorage.setItem("banqueName", "Cash")
    }
    function ChoixBanque2e(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/om.png")
        localStorage.setItem("banqueName", "Orange")
    }
    function ChoixBanque2f(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/momo.png")
        localStorage.setItem("banqueName", "mtn")
    }
    function ChoixBanque2g(){
        setBanqueStatus(false)
        localStorage.setItem("banqueImage", "../../images/flooz.png")
        localStorage.setItem("banqueName", "Moov")
    }
    function bniClicked(){
        setBankOptionClicked1("nkp_bank_option_clicked")
        localStorage.setItem("banqueClickedName", "BNI")
        setBankOptionClicked2("")
        setBankOptionClicked3("")
        setBankOptionClicked4("")
    }
    function biciciClicked(){
        localStorage.setItem("banqueClickedName", "BICICI")
        setBankOptionClicked2("nkp_bank_option_clicked")
        setBankOptionClicked1("")
        setBankOptionClicked3("")
        setBankOptionClicked4("")
    }
    function ecobClicked(){
        localStorage.setItem("banqueClickedName", "ECOBANK")
        setBankOptionClicked3("nkp_bank_option_clicked")
        setBankOptionClicked1("")
        setBankOptionClicked2("")
        setBankOptionClicked4("")
    }
    function ubaClicked(){
        setBankOptionClicked4("nkp_bank_option_clicked")
        localStorage.setItem("banqueClickedName", "UBA")
        setBankOptionClicked1("")
        setBankOptionClicked2("")
        setBankOptionClicked3("")
    }

    const ci = "Côte d'Ivoire"
    const sen = "Sénégal"
    const burk = "Burkina Fasso"
    const benin = "Bénin"
    const togo = "Togo"
    const mali = "Mali"
    const niger = "Niger"
    const cam = "Cameroun"
    const guinee = "Guinée"

    const _ci       = "Côte d'Ivoire"
    const _sen      = "Sénégal"
    const _burk     = "Burkina Faso"
    const _benin    = "Bénin"
    const _togo     = "Togo"
    const _mali     = "Mali"
    const _niger    = "Niger"

    const [Montant, setMontant ] = useState(0)
    const [Adress,                  setAdress                   ] = useState()
    const [Phone,                   setPhone                    ] = useState("")
    const [AountName,               setAountName                ] = useState("")
    const [AcountCredName,          setAcountCredName           ] = useState("")
    const [ServiceBankName,         setServiceBankName          ] = useState("")
    const [RDV,                     setRDV                      ] = useState()
    const [Times,                   setTimes                    ] = useState()
    const [PassConfirmed,           setPassConfirmed            ] = useState("")

    const numCompte =   
                (banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire") && banqueClickedName === "BNI"      ? "N° Compte : 009498720006": 
                (banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire") && banqueClickedName === "BICICI"   ? "N° Compte : 010663800053": (banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire") && banqueClickedName === "ECOBANK"  ? "N° Compte : 121540971001":(banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire") && banqueClickedName === "UBA"      ? "N° Compte : 108580002659": "Pas de Compte"

  
    const rdv = !RDV?"0":RDV
    const _time = !Times?"0":Times
    
    const handleMontant = (e) => {

        const { value, name } = e.target
  
        setMontant(value)
    }

    const handleAdress = (e) => {
      const { value } = e.target
      setAdress(value)
    }

    const handleRDV = (e) => {
      const { value } = e.target
      setRDV(value)
    }

    const handleTime = (e) => {
      const { value } = e.target
      setTimes(value)
    }

    const handlePhone = (e) => {
      const { value } = e.target
      setPhone(value)
    }

    const handleAountName = (e) => {
      const { value } = e.target
      setAountName(value)
    }

    const handleAcountCredName = (e) => {
      const { value } = e.target
      setAcountCredName(value)
    }

    const handleServiceBankName = (e) => {
      const { value } = e.target
      setServiceBankName(value)
    }

    const handleCodeSecret = (e) => {
      const { value } = e.target
      setCodeSecret(value)
    }

    const taux = 600

    const charginglimit = 16
    const chargingMin = charginglimit*taux

    const [MerchantId, setMerchantId] = useState("")
    const [MerchantIdKey, setMerchantIdKey] = useState("")
    const [PayInRate, setPayInRate] = useState([])
    var [RechargeItemAlert, setRechargeItemAlert] = useState("")
    var [PhoneAlert, setPhoneAlert] = useState("")

    useEffect(()=>{

        if((Number(Montant) > 1)  && !banqueName){
            setRechargeItemAlert("Quelque chose ne va pas !")
        }

        if((Number(Montant) === 0)  && banqueName !== ""){
            setRechargeItemAlert("")
        }

        if(Phone !== ""){
            setPhoneAlert("ok")
        }

        if(Phone === ""){
            setPhoneAlert("")
        }

    },[Montant, Phone, banqueName])
  
    // PAY-IN
  
    const ciRateIN = [{
        // orange: 0.015, //"1.50%",
        // moov: 0.01, //"1%",
        // mtn: 0.02, //"2%",
        // wave: 0.01, //"1%"

        orange: 0.0225, //"2.25%",
        moov: 0.0225, //"2.25%",
        mtn: 0.0225, //"2.25%",
        wave: 0.02, //"2.0%"
    }]
  
    const senRateIN = [{
    //   orange: 0.015, //"1.50%",
    //   freeMoney: 0.015, //"1.50%",
    //   wave: 0.02, //"2.00%"

      orange: 0.025, //"2.50%",
      freeMoney: 0.025, //"2.50%",
      wave: 0.03, //"3%"
    }]
  
    const maliRateIN = [{
    //   orange: 0.02, //"2.00%",
    //   mobyCash: 0.02, //"2.00%",

      orange: 0.03, //"3%",
      mobyCash: 0.03, //"3%",
    }]
  
    const bfRateIN = [{
    //   orange: 0.025, //"2.50%",
    //   mobyCash: 0.025, //"2.50%",

      orange: 0.035, //"3.50%",
      mobyCash: 0.035, //"3.50%",
    }]
  
    const camRateIN = [{
    //   orange: 0.025, //"2.50%",
    //   mtn: 0.025, //"2.50%",

      orange: 0.035, //"3.50%",
      mtn: 0.035, //"3.50%",
    }]
  
    const guineeRateIN = [{
    //   orange: 0.0375, //"3.75%",
    //   mtn: 0.025, //"2.50%",

      orange: 0.0475, //"4.75%",
      mtn: 0.035, //"3.50%",
    }]
  
    const beninRateIN = [{
    //   moov: 0.02, //"2.00%",
    //   mtn: 0.02, //"2.00%",

      moov: 0.03, //"3.00%",
      mtn: 0.03, //"3.00%",
    }]
  
    const togoRateIN = [{
    //   moov: 0.045, //"4.50%",
    //   togocel: 0.045, //"4.50%",

      moov: 0.055, //"5.50%",
      togocel: 0.055, //"5.50%",
    }]


    useEffect(()=>{
        if(banqueName){
            if(SenderCountry === ci){

                setMerchantId("o3XPUfGPrmspx4AQoWLmr")
                setMerchantIdKey("sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl")

                if(banqueName === "Orange"){
                    setPayInRate(ciRateIN[0].orange)
                }

                if(banqueName === "mtn"){
                    setPayInRate(ciRateIN[0].mtn)
                }

                if(banqueName === "Moov"){
                    setPayInRate(ciRateIN[0].moov)
                }

                if(banqueName === "Wave"){
                    setPayInRate(ciRateIN[0].wave)
                }
            }
            if(SenderCountry === togo){

                setMerchantId("Ee9HM0SbkkPpmvpY8iJXa")
                setMerchantIdKey("sk_HSWq5h91I0E7sVSxXOACPBbMILX6Qq61")

                if(banqueName === "Moov"){
                    setPayInRate(togoRateIN[0].moov)
                }
            }
            if(SenderCountry === burk){
                setMerchantId("4eEdWoS5T2UiPkEdsO7Zt")
                setMerchantIdKey("sk_yEvF54xNdflGibQpzVMLfAl7Iu6twdfO")

                if(banqueName === "Orange"){
                    setPayInRate(bfRateIN[0].orange)
                }
            }
            if(SenderCountry === mali){
                setMerchantId("dNoKavNhtMQaNZTSCJa4s")
                setMerchantIdKey("sk_VMctIMcIRcAfPuzZxoc4r9TbStp7rNnG")

                if(banqueName === "Orange"){
                    setPayInRate(maliRateIN[0].orange)
                }
            }
            if(SenderCountry === sen){
                setMerchantId("EF0esPiIBKxkdZcSwERyw")
                setMerchantIdKey("sk_7UiOwbEogK6QEv0Blm7T3XXQv2gzOq8M")

                if(banqueName === "Orange"){
                    setPayInRate(senRateIN[0].orange)
                }

                if(banqueName === "Wave"){
                    setPayInRate(senRateIN[0].wave)
                }
            }
            if(SenderCountry === cam){
                setMerchantId("BguxmE4b9kYYCn86IUCPZ")
                setMerchantIdKey("sk_TUuDIbxLurPIKD2yPpvuMfocp2h8bTEy")

                if(banqueName === "Orange"){
                    setPayInRate(camRateIN[0].orange)
                }

                if(banqueName === "mtn"){
                    setPayInRate(camRateIN[0].mtn)
                }
            }
            if(SenderCountry === benin){
                setMerchantId("Bbqyg6WMe4MZALrTbVsKc")
                setMerchantIdKey("sk_VkKDUSWRpN7rLvQgcGvr6Rq4LHqvudth")

                if(banqueName === "mtn"){
                    setPayInRate(beninRateIN[0].mtn)
                }

                if(banqueName === "Moov"){
                    setPayInRate(beninRateIN[0].moov)
                }
            }
            if(SenderCountry === guinee){
                setMerchantId("sYSJyhGpeOXb95iO7WOlh")
                setMerchantIdKey("sk_JIO1fgAmaVb9i7wGUrYA9tQDDjYVoiiU")

                if(banqueName === "Orange"){
                    setPayInRate(guineeRateIN[0].orange)
                }

                if(banqueName === "mtn"){
                    setPayInRate(guineeRateIN[0].mtn)
                }
            }
        }
    },[
        SenderCountry,
        PayInRate,  
        banqueName,
        beninRateIN,
        bfRateIN,
        camRateIN,
        ciRateIN,
        guineeRateIN,
        maliRateIN,
        senRateIN,
        togoRateIN,

    ])
    
    const [SeeDoc, setSeeDoc ] = useState(false)
    const [Loader, setLoader  ] = useState(false)
    
    function seeDocHandler(){
        setSeeDoc(true)
    }
    
    const wavePaymentLink = 'https://pay.wave.com/m/M_DyjfDYDUCSym/c/ci/'

    const RechargeHandler = () => {
    
        localStorage.setItem("nkab_rge_simple_tolk", "yes")

        setPassConfirmed("Code Pin correct ! Félicitation :)")
        setStatus1(STATUS.STARTED)

        const OperationUId = uid(8)
        const OperationUId2 = uid(21)

        if(userID && OperationUId && banqueName && userEmail && userLastName && userName && Phone && numCompte && rdv){
            axios.post(
                '/api/v1/p-recharge/create',
                {
                    operationUId : `ref_${OperationUId}`,
                    userId : userID,
                    tr_Id: `sop_${OperationUId2}`,
                    options : "RECHARGE",
                    banqueName,
                    banqueImage,
                    fullName : `${userLastName} ${userName}`,
                    email : userEmail.toLowerCase(),
                    phone : Phone,
                    rbserved : Number(Montant),
                    acountName : AountName,
                    acountCredName : AcountCredName,
                    serviceBankName : ServiceBankName,
                    dollar: 0,
                    numcompte : banqueName === "Cash" || banqueName === "Wave" ? "..." : numCompte,
                    rdv,
                    status : "processing",
                    times : _time,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                },
            ).then((res)=>{ 
                if(res){
                    axios.post('/api/v1/all-p-op/create', {
                        operationUId : `ref_${OperationUId}`,
                        userId : userID,
                        tr_Id: `sop_${OperationUId2}`,
                        options : "RECHARGE",
                        moneyName : banqueName,
                        moneyImage : banqueImage,
                        montant : Number(Montant),
                        converted : "null",
                        dollar: 0,
                        fullName : `${userLastName} ${userName}`,
                        phone : Phone,
                        status: "null",
                        taux : "null",
                        netAddress : NetAddress,
                        adress : "null",
                        email : userEmail.toLowerCase(),
                        numcompte : banqueName === "Cash" || banqueName === "Wave" ? "..." : numCompte,
                        rdv,
                        times : _time,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    },
                ).then((res)=>{
                    if(res){
                        axios.post(
                            '/api/v1/notification/create-notif',
                            {
                              notifierId : `ref_${OperationUId}`,
                              notifyImageLink : banqueName,
                              notifyServicesId : `ref_${OperationUId}`,
                              notifyOperatorAction : "Recharge",
                              notifySenderName : "Pro",
                              notifySenderPhone : Phone,
                              notifySenderEmail : userEmail.toLowerCase(),
                              createdAt: new Date(),
                              updatedAt: new Date(),
                            },
                          ).then((res)=>{
                            if(res && banqueName === "Wave"){
                                localStorage.setItem("rch_sop", `sop_${OperationUId2}`)
                                setSeeDoc(false)
                                setLoader(false)
                                setMontant(0)
                                localStorage.removeItem("banqueImage")
                                localStorage.removeItem("banqueName")
                                localStorage.removeItem("changeImage")
                                localStorage.removeItem("changeName")
                                localStorage.removeItem("moneyImage")
                                localStorage.removeItem("moneyName")
                                localStorage.removeItem("banqueClickedName")
                                setBanqueStatus(false)
                                setBankOptionClicked1("")
                                setBankOptionClicked2("")
                                setBankOptionClicked3("")
                                setBankOptionClicked4("")
                                localStorage.setItem("isRActive",true)
                                
                                window.location.replace(`${wavePaymentLink}`)

                            }else if(res && banqueName !== "Wave"){
                                localStorage.setItem("rch_sop", `sop_${OperationUId2}`)
                                setSeeDoc(false)
                                setLoader(false)
                                setMontant(0)
                                localStorage.removeItem("banqueImage")
                                localStorage.removeItem("banqueName")
                                localStorage.removeItem("changeImage")
                                localStorage.removeItem("changeName")
                                localStorage.removeItem("moneyImage")
                                localStorage.removeItem("moneyName")
                                localStorage.removeItem("banqueClickedName")
                                setBanqueStatus(false)
                                setBankOptionClicked1("")
                                setBankOptionClicked2("")
                                setBankOptionClicked3("")
                                setBankOptionClicked4("")
                                localStorage.setItem("isRActive",true)
    
                                if(LineLink){
                                  window.location.replace("https://nkab-exchange.net/nkab-pro");
                                } else {
                                   window.location.replace("http://localhost:3000/nkab-pro");
                                }
                            }
                          })
                    }
                })
                
            }})
        }

        

    }

    function nkabOpen(){
        setNkabSpinner(true)
    }

    //Intention de paiement 
    function RechargePaymentHandler(){

        setPassConfirmed("Code Pin correct ! Félicitation :)")
        setStatus1(STATUS.STARTED)

        const OperationUId = uid(8)

        const montants = Number((Number(Number(Montant)+(Number(Montant)*PayInRate))*1).toFixed(0))

        localStorage.setItem("amount", Number(Montant))
        localStorage.setItem("phone", Phone)
        localStorage.setItem("_options", "RECHARGE")

        var raw = JSON.stringify({
          "customerReference": `ref_${OperationUId}`,
          "purchaseReference": `ref_${new Date()}`,
          "amount": montants,
          "currency": "XOF"
        });
            
        var requestOptions = {
          method: 'POST',
          body: raw,
          redirect: 'follow'
        };

        axios.post(`/api/payment`, {requestOptions, MerchantId, MerchantIdKey})
        .then(result => {

            if(result.data.id && result.data.token){

                localStorage.setItem("hub2id", result.data.id)
                localStorage.setItem("hub2token", result.data.token)

                var raw2 = JSON.stringify({
                    "token": result.data.token,
                    "paymentMethod": "mobile_money",
                    "country": `${SenderCountry === _ci ? 'CI' :SenderCountry === _sen ? 'SN' :SenderCountry === _mali ? 'ML' :SenderCountry === _togo ? 'TG' :SenderCountry === _burk ? 'BF' :SenderCountry === _benin ? 'BJ' :SenderCountry === _niger ? 'CM' : ""}`,
                    "provider": `${banqueName}`,
                    "mobileMoney": {
                        "msisdn": `${Phone}`,
                        "otp": `${CodeSecret}`
                    }
                });
            
                var requestOptions2 = {
                    method: 'POST',
                    body: raw2,
                    redirect: 'follow'
                };

                if(localStorage.getItem("hub2id") !== ""){

                    const hub2id = localStorage.getItem("hub2id")
    
                    axios.post(`/api/payment-step2`, {requestOptions2, MerchantId, MerchantIdKey, hub2id})

                    .then(result => {
                        localStorage.setItem("nkab_rge_id", result.data.id)
                        localStorage.setItem("nkab_rge_reference", result.data.customerReference)
                        localStorage.setItem("nkab_rge_token", result.data.token)
        
                        if(result.data.token){
                            localStorage.setItem("isRActive",true)
                        }
                        
                    })
                }
            }
        })
        
    }

    const [ ShowPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [SeePrice, setSeePrice ] = useState(false)

  function seePriceHandler(){
      setSeePrice(true)
  }
  function unSeePriceHandler(){
      setSeePrice(false)
  }
  
  function BSection(){ 
    localStorage.setItem('BI', 'BInterface')
    localStorage.setItem('RI', '')
    localStorage.setItem('TI', '')
  }
  function TSection(){ 
    localStorage.setItem('TI', 'TInterface')
    localStorage.setItem('RI', '')
    localStorage.setItem('BI', '')
  }

  const handleClose = () => {
    setNkabSpinner(false)
  };
  

    function repriseaZero(){

        setLoader(false)
        setMontant(0)
        localStorage.removeItem("nkab_rge_reference")
        localStorage.removeItem("nkab_rge_id")
        localStorage.removeItem("hub2token")
        localStorage.removeItem("hub2id")
        localStorage.removeItem("nkabWarning")
        localStorage.removeItem("nkabMarkTitle")
        localStorage.removeItem("_options")
        localStorage.removeItem("LastStatus")
        localStorage.removeItem("amount")
        localStorage.removeItem("phone")
        localStorage.removeItem("nkab_rge_simple_tolk")
        setSeeDoc(false)
        setLoader(false)
        setMontant(0)
        localStorage.removeItem("banqueImage")
        localStorage.removeItem("banqueName")
        localStorage.removeItem("changeImage")
        localStorage.removeItem("changeName")
        localStorage.removeItem("moneyImage")
        localStorage.removeItem("moneyName")
        localStorage.removeItem("banqueClickedName")
        setBanqueStatus(false)
        setBankOptionClicked1("")
        setBankOptionClicked2("")
        setBankOptionClicked3("")
        setBankOptionClicked4("")
        
        if(LineLink){
            window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
            window.location.replace("http://localhost:3000/nkab-pro");
        }
    }

  return (
    <div className="nkab-charge-container-t mb-5">
        <div>
            <div className='d-flex justify-content-between p-4 nkab-r-text bolder'>
                <div className="nkab-r-title-t">Recharger</div>
                <div className="nkab-dote-menu-t" onClick={viewOpBtn}>...</div>
            </div>
            <div className='nkp_status color-or bolder'>Solde disponible</div>
            {
                !SeePrice ?
                <div onClick={seePriceHandler} className='d-flex justify-content-center mt-4'>
                    <div className='uppercase pointer' style={{fontSize:"30px", color:"#c7b301"}}>********</div>
                    <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/visible--v1.png" alt="visible--v1"/></div>
                </div>:
                <div onClick={unSeePriceHandler} className='d-flex justify-content-center mt-4'>
                    <div className='uppercase bolder pointer' style={{fontSize:"25px", color:"#c7b301"}}>{(Wallets*1).toFixed(2)} XOF</div>
                    <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="27" height="27" src="https://img.icons8.com/ios/50/c7b301/closed-eye.png" alt="closed-eye"/></div>
                </div>
            }
            {
                BtnOp ? 
                <div className='d-flex justify-content-center p-4 bolder'>
                    {/* <div className='nkab-r-btn ml-3 mr-3'>Recharger</div> */}
                    <div className='nkab-r-btn ml-3 mr-3' onClick={BSection}>Acheter</div>
                    <div className='nkab-r-btn ml-3 mr-3' onClick={TSection}>Transférer</div>
                </div>:null
            }
        </div>
        <div className='center nkab-pro-field-color mt-3'>
            {
                !SeeDoc?<>
                <div className='d-flex justify-content-center nkab-selector-widget mb-4'>
                    <div className="mb-1 nkp_items">
                        <div>
                            <span className={`d-flex align-items-center btn-square btn btn-light btn-lg uppercase nkp_items_square-t`} onClick={BanqueStatus ? ChoixBanque1b : ChoixBanque1}>
                                <img src={!banqueImage?`${"../../images/question.png"}`:banqueImage} width="20" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-16" />
                                <span className="d-block text-left w-100 color-or bolder">{!banqueName?`${"Recharger par"}`:banqueName}</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className="text-gray-500 flex-shrink-0">
                                    <path fillRule="evenodd" d="M2.707 4.293L8 9.585l5.293-5.292a1 1 0 111.414 1.414l-6 6a.996.996 0 01-.53.277l-.118.014h-.118a.997.997 0 01-.648-.29l-6-6a1 1 0 011.414-1.415z" clipRule="evenodd"></path>
                                </svg>
                            </span>
                            {
                                BanqueStatus?
                                <div className="position-relative nkp_items_list">
                                    <div className={`ml-0 mt-2 w-100 text-left position-absolute nkp_position-absolute-t`}>
                                        <div className="_3rpmn">
                                            <div className="_65T61 css-2b097c-container">
                                                <div className="p4Aly mt-2 pb-2 css-0">
                                                    <div className={`px-2 _5HQUI css-0 select-scroll-container-t`}>
                                                        {
                                                            SenderCountry === ci || SenderCountry === sen ? 
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2a}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/wave.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Dépôt Wave</span>
                                                                </span>
                                                            </div> : null
                                                        }
                                                        <div className="mt-1 css-0" id="react-select-2-option-1" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2b}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/vbancaire.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 bolder">Virement bancaire</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2c}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/dbancaire.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 bolder">Dépôt bancaire</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                            <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2d}>
                                                                <div className="d-flex flex-row">
                                                                    <img src="../../images/cash.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                </div>
                                                                <span className="tag tag--md tag-transparent border-gray-400 bolder">Cash</span>
                                                            </span>
                                                        </div>
                                                        { SenderCountry === burk || SenderCountry === sen || SenderCountry === mali || SenderCountry === guinee ? <>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2e}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/om.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Orange</span>
                                                                </span>
                                                            </div>
                                                            </> : SenderCountry === benin ? <>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2f}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/momo.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">mtn</span>
                                                                </span>
                                                            </div>
                                                            </> : SenderCountry === cam ? <>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2e}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/om.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Orange</span>
                                                                </span>
                                                            </div>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2f}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/momo.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">mtn</span>
                                                                </span>
                                                            </div>
                                                            </> : SenderCountry === togo ? <>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2g}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/flooz.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Moov</span>
                                                                </span>
                                                            </div>
                                                            </> : SenderCountry === ci ? <>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2e}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/om.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Orange</span>
                                                                </span>
                                                            </div>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2f}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/momo.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">mtn</span>
                                                                </span>
                                                            </div>
                                                            <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                                <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixBanque2g}>
                                                                    <div className="d-flex flex-row">
                                                                        <img src="../../images/flooz.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/>
                                                                    </div>
                                                                    <span className="tag tag--md tag-transparent border-gray-400 bolder">Moov</span>
                                                                </span>
                                                            </div>
                                                        </>:null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>:null
                            }
                        </div>
                    </div>
                </div>
                {
                    banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire" ?
                    <div className='d-flex justify-content-center'>
                        <div className={` mt-3 alert alert-info`} style={{borderRadius:"10px"}} role="alert">Veuillez sélectionner une banque!</div>
                    </div>:
                    banqueName === "Cash" ?
                    <div className='d-flex justify-content-center'>
                        <div className={` mt-3 alert alert-info`} style={{borderRadius:"10px"}} role="alert"><strong>Où nous sommes situés :</strong> <br/><FontAwesomeIcon icon={faMapMarkedAlt} /> Hotel Belle Côte, Rue Jacynthes, Cocody, Angré, Abidjan<br/><FontAwesomeIcon icon={faPhoneAlt} /> +225 27 22 40 79 27</div>
                    </div>:null
                }
                                                    
                {
                    banqueName === "Dépôt bancaire" || banqueName === "Virement bancaire"?
                    <div className='d-flex justify-content-center uppercase mt-4 mb-4'>
                        <span className={`m-2 pointer nkp_bank_option ${BankOptionClicked1 || banqueClickedName === "BNI" ? "nkp_bank_option_clicked" : null}`} onClick={bniClicked}><img src="../../images/bni.png" width="50" alt="nkab drapeau" /></span>
                        <span className={`m-2 pointer nkp_bank_option ${BankOptionClicked2 || banqueClickedName === "BICICI" ? "nkp_bank_option_clicked" : null}`} onClick={biciciClicked}><img src="../../images/bicici.png" width="50" alt="nkab drapeau" /></span>
                        <span className={`m-2 pointer nkp_bank_option ${BankOptionClicked3 || banqueClickedName === "ECOBANK" ? "nkp_bank_option_clicked" : null}`} onClick={ecobClicked}><img src="../../images/ecob.png" width="50" alt="nkab drapeau" /></span>
                        <span className={`m-2 pointer nkp_bank_option ${BankOptionClicked4 || banqueClickedName === "UBA" ? "nkp_bank_option_clicked" : null}`} onClick={ubaClicked}><img src="../../images/uba.png" width="50" alt="nkab drapeau" /></span>
                    </div>:null
                }
                <div className='mb-3'>
                    <div className='d-flex justify-content-center'>
                        <OutlinedInput className='nkab-r-input' placeholder="10.000" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleMontant}/>
                    </div>
                    <div className='d-flex justify-content-center' style={{fontSize:"11px"}}>
                        <span className={`${Montant > 5 && Montant < (charginglimit*taux) ? "text-danger mr-2": Number(Montant) > 5 && Number(Montant) > (charginglimit*taux) ? "text-success mr-2" : "mr-2"}`}>Payer: <strong>{ banqueName === "Orange" || banqueName === "mtn" || banqueName === "Moov" || banqueName === "Wave" ? Number(Montant)+(Number(Montant)*PayInRate) : banqueName === "Virement bancaire" || banqueName === "Dépôt bancaire" ?Number(Montant)+100:0 } XOF</strong></span>
                        <span className='ml-2'>Min <strong>9600 XOF</strong></span>
                    </div>
                </div>
                {
                    banqueName === "Cash" ? 
                    <div style={{width:"100%"}}>
                        <iframe src="https://www.google.com/maps/embed/v1/place?q=Rue+Jacynthes,+Cocody,+Abidjan,+Côte+d'Ivoire&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" width="100%" height="300" title='nkab exchange map'>
                            <a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a>
                        </iframe>
                    </div>:null
                }
                {
                    banqueName  === "Wave" ? <div>
                        <div className='d-flex justify-content-center'>
                            <div className={` mt-3 alert alert-danger`} style={{borderRadius:"10px"}} role="alert">Téléphone obligatoire !</div>
                        </div>
                    {
                        Number(Montant) > (charginglimit*taux) ? 
                        <div className='d-flex justify-content-center mr-4 ml-1 mt-4'>
                            <div className={`ml-4 mr-1 alert alert-info`} role="alert">Vous serez redirigé sur votre application <strong>WAVE Money</strong> pour finaliser le paiement de <strong>{!Montant?"...?":<>{banqueName === "Wave" ? Number(Montant)+(Number(Montant)*PayInRate):banqueName === "Orange" || banqueName === "mtn" || banqueName === "Moov" ? Number(Montant)+(Number(Montant)*PayInRate) :banqueName === "Virement bancaire" || banqueName === "Dépôt bancaire" ?Number(Montant)+100:Number(Montant)}</>} XOF</strong> après avoir cliqué sur "<strong>Continuer</strong>"!</div>
                        </div>:null
                    }</div>:
                    banqueName  === "Moov" ? <div>
                    {
                        Number(Montant) > (charginglimit*taux) ? 
                        <div className='mr-4 ml-1 mt-4'>
                            <div className={`ml-4 mr-1 alert alert-info`} role="alert">Vous allez recevoir <strong>une notification</strong> de débit sur votre téléphone pour confirmer votre paiement après avoir cliqué sur "<strong>Continuer</strong>"!</div>
                        </div>:null
                    }</div>:
                    banqueName  === "Orange" ? <div>
                    {
                        Number(Montant) > (charginglimit*taux) ? 
                        <div className='mr-4 ml-1 mt-4'>
                            {
                                SenderCountry === ci ? <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composer le <strong>#144*82#</strong> puis votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>Continuer</strong>"!</div>: 
                                SenderCountry === sen ?
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Vous allez recevoir une notification pour générer un code à sens unique via le <strong>#144*391#</strong> </div>:
                                SenderCountry === burk ?
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composer le <strong>*144*4*6*100#</strong> puis votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>Continuer</strong>"!</div>:
                                SenderCountry === mali ?
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composer le <strong>#144*77#</strong> puis votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>Continuer</strong>"!</div>:
                                SenderCountry === guinee ?
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composer le <strong>#144#</strong> l'option 4 puis l'option 2 votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>Continuer</strong>"!</div>:
                                SenderCountry === cam ?
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Vous allez recevoir un message puis composer le <strong>#150*50#</strong> puis votre <strong>code secret</strong> et entrer le <strong>code OTP</strong> ci-dessus ensuite cliquer sur "<strong>Continuer</strong>"!</div>:null
                            }
                            
                        </div>:null
                    }</div>:
                    banqueName  === "mtn" ? <div>
                    {
                        Montant > (charginglimit*taux) ? 
                        <>
                        {
                            SenderCountry === ci ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composez <strong>*133#</strong> apres reception du SMS de debit sur votre téléphone après avoir cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === benin ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret MTN</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === cam ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret MTN</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === guinee ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret MTN</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:null
                        }
                        </>:null
                    }</div>:
                    banqueName  === "Moov" ? <div>
                    {
                        Number(Montant) > (charginglimit*taux) ? 
                        <>
                        {
                            SenderCountry === ci ?  
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret Moov Money</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === mali ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret Moov Money</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === togo ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Entrez votre <strong>code secret Flooz</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === benin ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composez le <strong>*855*7*8#</strong>puis entrez votre <strong>code secret Moov</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:
                            SenderCountry === burk ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Composez le <strong>*555*6#</strong>puis entrez votre <strong>code secret Moov</strong> via le SMS reçu sur votre téléphone ensuite cliquer sur "<strong>Continuer</strong>" pour terminer!</div>
                            </div>:null
                        }
                        </>:null
                    }</div>:null
                }

                {
                    banqueName === "Cash"?<>
                    <div className='d-flex justify-content-center mt-3'>
                        <div className='alert alert-danger color-r' style={{borderRadius:"10px"}} role="alert">Veuillez nous préciser une date de rendez-vous!</div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Date du rendez-vous.." type="date" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleRDV}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Heure de rendez-vous..." type="time" min="09:00" max="18:00" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleTime}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° téléphone" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    </>:
                    banqueName  === "Orange"?
                    <>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° Créditeur Ex: 07 XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom du créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAountName}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput
                                sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}}
                                className='color-white'
                                onChange={handleCodeSecret}
                                placeholder="Code OTP..."
                                id="outlined-adornment-password"
                                type={ShowPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        className='color-white'
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </div>
                    </div>
                    </>:
                    banqueName  === "Moov"?
                    <>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° Créditeur Ex: XX XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom du créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAountName}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput
                                sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}}
                                className='color-white'
                                onChange={handleCodeSecret}
                                placeholder="Code secret moov money..."
                                id="outlined-adornment-password"
                                type={ShowPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        className='color-white'
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </div>
                    </div>
                    </>:
                    banqueName  === "mtn"?
                    <>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° Créditeur Ex: XX XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom du créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAountName}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput
                                sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}}
                                className='color-white'
                                onChange={handleCodeSecret}
                                placeholder="Code secret mtn money..."
                                id="outlined-adornment-password"
                                type={ShowPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        className='color-white'
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </div>
                    </div>
                    </>:
                    banqueName  === "Wave"?
                    <>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° Créditeur Ex: XX XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom du compte créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAountName}/>
                        </div>
                    </div>
                    </>:
                    <>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° Créditeur Ex: XX XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom du compte créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAountName}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="n° de compte créditeur" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleAcountCredName}/>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='d-flex justify-content-center'>
                            <OutlinedInput className='nkab-r-input color-white' placeholder="Nom de la banque" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleServiceBankName}/>
                        </div>
                    </div>
                    </>
                }
                </>:
                <div>
                    {
                        banqueClickedName === "ECOBANK" ? <>
                            <div className='p-2 text-center'>
                                <h3 className='nkp_price'>Relevé d'Identité Bancaire (RIB)</h3>
                            </div>
                            <div className='nkab-bank-table'>
                                <table className="table table-bordered nkp_price">
                                    <thead>
                                        <tr>
                                            <th scope="col">Code Banque</th>
                                            <th scope="col">Code Guichet</th>
                                            <th scope="col">Numéro Compte</th>
                                            <th scope="col">Clé Rib</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CI059</td>
                                            <td>01044</td>
                                            <td>121540971001</td>
                                            <td>05</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Nom & Prénoms</th>
                                            <td colSpan="3">NK GROUP TECHNOLOGY</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">IBAN</th>
                                            <td colSpan="3">CI93 CI05 9010 4412 1540 9710 0105</td>
                                        </tr>
                                        <tr>
                                            <th scope="col" colSpan="4">DOMICILIATION</th>
                                        </tr>
                                        <tr>
                                            <th rowSpan="4"><img src="../../images/ekob_large.png" width="200" alt="nkab drapeau" /></th>
                                            <td colSpan="3"><strong>ECOBANK CÔTE D'IVOIRE</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">01 BP 4107 Abidjan 01</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Tél : 20 31 92 00</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Code Swift : ECOCCIAB</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {
                                Number(Montant) > (charginglimit*taux) ? 
                                <div className='mr-4 ml-1 mt-4'>
                                    <div className={`ml-4 mr-1 alert alert-info`} role="alert">Rassurez-vous d'avoir effectuer le <strong>{banqueName}</strong> de <strong>{!Montant?"...?":Montant} XOF</strong> en vous servant de ces informations ci-dessus avant de cliquer sur "<strong>Valider</strong>" pour terminer!</div>
                                </div>:null
                            }

                            <div className='d-flex justify-content-center mb-5'>
                                {
                                    Loader ? 
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_loader'>
                                        <div className="bouncing-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </span>:
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_popup_link-1 text-center pointer' onClick={RechargeHandler}>Valider</span>
                                }
                            </div>
                        </>:
                        banqueClickedName === "UBA" ? <>
                            <div className='p-2 text-center'>
                                <h3 className='nkp_price'>Relevé d'Identité Bancaire (RIB)</h3>
                            </div>
                            <div className='nkab-bank-table'>
                                <table className="table table-bordered nkp_price">
                                    <thead>
                                        <tr>
                                            <th scope="col">Code Banque</th>
                                            <th scope="col">Code Guichet</th>
                                            <th scope="col">Numéro Compte</th>
                                            <th scope="col">Clé Rib</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CI150</td>
                                            <td>01008</td>
                                            <td>108580002659</td>
                                            <td>74</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Nom & Prénoms</th>
                                            <td colSpan="3">NK GROUP TECHNOLOGY</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">IBAN</th>
                                            <td colSpan="3">CI93 CI15 0010 0810 8580 0026 5974</td>
                                        </tr>
                                        <tr>
                                            <th scope="col" colSpan="4">DOMICILIATION</th>
                                        </tr>
                                        <tr>
                                            <th rowSpan="4"><img src="../../images/uba_large.png" width="200" alt="nkab drapeau" /></th>
                                            <td colSpan="3"><strong>UBA CÔTE D'IVOIRE</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">17 BP 808 Abidjan 17</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Tél : 22 50 77 14</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Code Swift : UNAFCIAB</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {
                                Number(Montant) > (charginglimit*taux) ? 
                                <div className='mr-4 ml-1 mt-4'>
                                    <div className={`ml-4 mr-1 alert alert-info`} role="alert">Rassurez-vous d'avoir effectuer le <strong>{banqueName}</strong> de <strong>{!Montant?"...?":Montant} XOF</strong> en vous servant de ces informations ci-dessus avant de cliquer sur "<strong>Valider</strong>" pour terminer!</div>
                                </div>:null
                            }
                            
                            <div className='d-flex justify-content-center mb-5'>
                                {
                                    Loader?
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_loader'>
                                        <div className="bouncing-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </span>:
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_popup_link-1 text-center pointer' onClick={RechargeHandler}>Valider</span>
                                }
                            </div>
                        </>:
                        banqueClickedName === "BNI" ? <>
                        <div className='p-2 text-center'>
                            <h3 className='nkp_price'>Relevé d'Identité Bancaire (RIB)</h3>
                        </div>
                        <div className='nkab-bank-table'>
                            <table className="table table-bordered nkp_price">
                                <thead>
                                    <tr>
                                        <th scope="col">Code Banque</th>
                                        <th scope="col">Code Guichet</th>
                                        <th scope="col">Numéro Compte</th>
                                        <th scope="col">Clé Rib</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>CI092</td>
                                        <td>01018</td>
                                        <td>009498720006</td>
                                        <td>05</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Nom & Prénoms</th>
                                        <td colSpan="3">NK GROUP TECHNOLOGY</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">IBAN</th>
                                        <td colSpan="3">CI93 CI09 2010 1800 9498 7200 0605</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" colSpan="4">DOMICILIATION</th>
                                    </tr>
                                    <tr>
                                        <th rowSpan="4"><img src="../../images/bni_large.png" width="200" alt="nkab drapeau" /></th>
                                        <td colSpan="3"><strong>BNI CÔTE D'IVOIRE</strong></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">? BP ? Abidjan ?</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">Tél : XX XX XX XX</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">Code Swift : CSSSCIAB</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {
                            Number(Montant) > (charginglimit*taux) ? 
                            <div className='mr-4 ml-1 mt-4'>
                                <div className={`ml-4 mr-1 alert alert-info`} role="alert">Rassurez-vous d'avoir effectuer le <strong>{banqueName}</strong> de <strong>{!Montant?"...?":Montant} XOF</strong> en vous servant de ces informations ci-dessus avant de cliquer sur "<strong>Valider</strong>" pour terminer!</div>
                            </div>:null
                        }

                        <div className='d-flex justify-content-center mb-5'>
                            {
                                Loader?
                                <span style={{textDecoration:"none"}} className='nkp_input nkp_loader'>
                                    <div className="bouncing-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </span>:
                                <span style={{textDecoration:"none"}} className='nkp_input nkp_popup_link-1 text-center pointer' onClick={RechargeHandler}>Valider</span>
                            }
                        </div>
                        </>:
                        banqueClickedName === "BICICI" ? <>
                            <div className='p-2 text-center'>
                                <h3 className='nkp_price'>Relevé d'Identité Bancaire (RIB)</h3>
                            </div>
                            <div className='nkab-bank-table'>
                                <table className="table table-bordered nkp_price">
                                    <thead>
                                        <tr>
                                            <th scope="col">Code Banque</th>
                                            <th scope="col">Code Guichet</th>
                                            <th scope="col">Numéro Compte</th>
                                            <th scope="col">Clé Rib</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CI006</td>
                                            <td>01693</td>
                                            <td>010663800053</td>
                                            <td>77</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Nom & Prénoms</th>
                                            <td colSpan="3">KOUASSI N'GUESSAN NOEL</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">IBAN</th>
                                            <td colSpan="3">CI93 CI00 6016 9301 0663 8000 5377</td>
                                        </tr>
                                        <tr>
                                            <th scope="col" colSpan="4">DOMICILIATION</th>
                                        </tr>
                                        <tr>
                                            <th rowSpan="4"><img src="../../images/bicici_large.png" width="200" alt="nkab drapeau" /></th>
                                            <td colSpan="3"><strong>BICICI CÔTE D'IVOIRE</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">? BP ? Abidjan ?</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Tél : XX XX XX XX</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">Code Swift : BICICIAB</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {
                                Number(Montant) > (charginglimit*taux) ? 
                                <div className='mr-4 ml-1 mt-4'>
                                    <div className={`ml-4 mr-1 alert alert-info`} role="alert">Rassurez-vous d'avoir effectuer le <strong>{banqueName}</strong> de <strong>{!Montant?"...?":Montant} XOF</strong> en vous servant de ces informations ci-dessus avant de cliquer sur "<strong>Valider</strong>" pour terminer!</div>
                                </div>:null
                            }

                            <div className='d-flex justify-content-center mb-5'>
                                {
                                    Loader?
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_loader'>
                                        <div className="bouncing-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </span>:
                                    <span style={{textDecoration:"none"}} className='nkp_input nkp_popup_link-1 text-center pointer' onClick={RechargeHandler}>Valider</span>
                                }
                            </div>
                        </>:null
                    }
                </div>
            }


            {
                SeeDoc?null:                       
                <div className='d-flex justify-content-center mb-5'>
                    {
                        banqueName  === "Virement bancaire" || banqueName === "Dépôt bancaire" ? <>
                            {
                                nkab_rge_simple_tolk === "yes" ?
                                <div className='mt-4'>
                                    <div className='d-flex justify-content-center'>Une opération en cours de traitement</div>
                                    <div className='d-flex justify-content-center'><Button className='bolder text-warning' onClick={repriseaZero}>Nouvelle oépration ?</Button></div>
                                </div>: <>
                                    {
                                        Number(Montant) < (charginglimit*taux) || Phone.length < 9 || AountName === "" || AcountCredName === "" || ServiceBankName === "" ? 
                                        <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>:
                                        <span style={{textDecoration:"none"}} className='nkp_popup_link-1 text-center pointer' onClick={seeDocHandler}>Continuer</span>
                                    }
                                </>
                            }
                        </>:
                        banqueName  === "Wave" || banqueName === "Cash" ? <>
                            {
                                nkab_rge_simple_tolk === "yes" ?
                                <div>
                                    <div className='d-flex justify-content-center'>Une opération en cours de traitement</div>
                                    <div className='d-flex justify-content-center'><Button className='bolder text-warning' onClick={repriseaZero}>Nouvelle oépration ?</Button></div>
                                </div>: <>
                                    {
                                    (Number(Montant) > (charginglimit*taux)) && Loader ?
                                    <span style={{textDecoration:"none"}} className='nkp_loader pt-3 text-center'>
                                        <div className="bouncing-loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </span> :
                                    (Number(Montant) > (charginglimit*taux)) || !Loader || Phone.length > 9 || RDV !=="" || Times !== "" ?
                                    <span style={{textDecoration:"none"}} className='nkp_popup_link-1 text-center pointer' onClick={RechargeHandler}>Continuer</span>:
                                    <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>
                                    }
                                </>
                            }
                        </>:
                        banqueName  === "Orange" || banqueName === "mtn" || banqueName === "Moov" ? <>
                            {
                                nkab_rge_id !== null && nkab_rge_reference !== null ?
                                <>
                                    {
                                        !nkabWarning ? 
                                        <>
                                            {
                                                nkabMarkTitle === "successful" && nkabMarkTitle !== null ? <div className='row'>
                                                <div className='d-flex justify-content-center'>
                                                    <svg className='svg' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                        <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                                        <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                                    </svg>
                                                </div>
                                                    <p className="success p">Opération réussie!</p>
                                                    <div className='d-flex justify-content-center'><Button className='bolder text-success' onClick={repriseaZero}>Revenir</Button></div>
                                                </div>:
                                                nkabMarkTitle === "failed" && nkabMarkTitle !== null ? <div className='row'>
                                                <div className='d-flex justify-content-center'>
                                                    <svg className='svg' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                                    </svg>
                                                </div>
                                                <p className="error p">Opération échouée!</p>
                                                <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Revenir</Button></div>
                                                </div>: 
                                                nkabMarkTitle === null ?
                                                <div className='row'>
                                                    <div className='d-flex justify-content-center mb-3'>
                                                        <div style={{textDecoration:"none"}} className='d-flex color-or pt-4'>
                                                            <div>Traitement en cours...</div>
                                                            <div className="nkab-spinner"></div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Reprendre l'opération ?</Button></div>
                                                </div>:null
                                            }
                                        </>
                                        :
                                        <div>
                                            <div className='d-flex justify-content-center'>{nkabWarning}</div>
                                            <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Revenir</Button></div>
                                        </div>
                                        
                                    }
                                </>:
                                (Number(Montant) > (charginglimit*taux)) && !Loader && Phone.length > 9 ?
                                <span style={{textDecoration:"none"}} className='nkp_popup_link-1 text-center pointer' onClick={RechargePaymentHandler}>Continuer</span>:
                                <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>
                            }
                        </>:
                        <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>
                    }
                </div>
            }      

            <Dialog
                open={NkabSpinner}
                PaperProps={{
                component: 'form',
                }}
            >
                <DialogTitle><span style={{color:"#fff", fontSize:"21px"}}>Code Pin</span></DialogTitle>
                <DialogContent>
                <DialogContentText>
                <span style={{color:"#fff", fontSize:"16px"}}>Saisissez votre code pin  à fin de finaliser votre <strong>achat</strong></span>
                </DialogContentText>
                    { !NkabSpinner?"Connexion":<div className="nkab-spinner"></div> }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{color:"#c7b301"}}>Revenir à la page</Button>
                </DialogActions>
            </Dialog>  
        </div>
        <div></div>
    </div>
  )
}

export default RInterfaceComponent