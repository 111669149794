import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


export default function Consultation() {

    const [recentSuccess, setRecentSuccess] = useState([])
    const [OperationStopReq, setOperationStopReq] = useState(false)


  useEffect(() => {

    const fetchData = async () => {
      try {

        if(!OperationStopReq){
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            const _data = data
            const items = _data.slice(0, 500)
            
            if(items.length > 0){

              var RecentSuccess = items.filter(obj => {
                return obj.isAdminCompleted === "validé";
              });

              if(RecentSuccess.length > 0){

                setRecentSuccess(RecentSuccess)
                setOperationStopReq(true)
                
              }

            }
          }
        }
        
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()

  }, [OperationStopReq])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const ci    = "Côte d'Ivoire"
  const sen   = "Sénégal"
  const burk  = "Burkina Fasso"
  const benin = "Benin"
  const togo  = "Togo"
  const mali  = "Mali"
  
  return (
    <div className="container">
        <section className="temoigne-section-main pb-4">
            <div className="up-elment4">
            <h1 className="middle-big-text bolder">Transactions récentes</h1>
            <p className="small-text"> Transactions les plus récentes effectuées par nos clients </p>
            </div>

            {
                recentSuccess.length === 0 ? 
                <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
                <p><Skeleton count={3} /></p>
                </SkeletonTheme>:
                <Carousel
                responsive={responsive}
                itemClass="react-multi-carousel-item"
                >
                { recentSuccess.map((confirme, index)=>(
                    <div key={index}>
                        <div className='lead-container'>
                        <div>
                            <span><strong>Pays : </strong></span>
                            <span>
                            {
                                confirme.SenderCountry === ci ?
                                <img className="images-drapeau" src="../images/ci.png" alt="" style={{borderRadius:"50px"}}/>:
                                confirme.SenderCountry === sen ?
                                <img className="images-drapeau" src="../images/sen.png" alt="" style={{borderRadius:"50px"}}/>:
                                confirme.SenderCountry === burk ?
                                <img className="images-drapeau" src="../images/burk.png" alt="" style={{borderRadius:"50px"}}/>:
                                confirme.SenderCountry === benin ?
                                <img className="images-drapeau" src="../images/benin.png" alt="" style={{borderRadius:"50px"}}/>:
                                confirme.SenderCountry === togo ?
                                <img className="images-drapeau" src="../images/togo.png" alt="" style={{borderRadius:"50px"}}/>:
                                confirme.SenderCountry === mali ?
                                <img className="images-drapeau" src="../images/mali.png" alt="" style={{borderRadius:"50px"}}/>:null
                            }
                            {' '}
                            {confirme.SenderCountry}
                            </span>
                        </div>
                        <div>
                            <span>
                                {
                                confirme.operatorAction === "sel" ? <strong>Vente</strong> : 
                                confirme.operatorAction === "buy" ? <strong>Achat</strong> : null
                                } :{' '}
                                {
                                confirme.operatorAction === "sel" ? <strong className='danger'>
                                {confirme.serviceName === "Tether" || confirme.serviceName === "Tether(USDT)" ? <> {(confirme.converted* 1).toFixed(2)}{' $ USDT'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Binance Smart Chain" ? <> {(confirme.converted* 1).toFixed(5)}{' BNB'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Litecoin" ? <> {(confirme.converted* 1).toFixed(5)}{' LTC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Bitcoin" ? <> {(confirme.converted* 1).toFixed(5)}{' BTC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "TRON" ? <> {(confirme.converted* 1).toFixed(2)}{' TRX'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Dogecoin" ? <> {(confirme.converted* 1).toFixed(2)}{' DOGE'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Payeer" ? <> {(confirme.converted* 1).toFixed(2)}{' PAYEER'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Perfect Money" ? <> {(confirme.converted* 1).toFixed(2)}{' PM'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Binance USD" ? <> {(confirme.converted* 1).toFixed(2)}{' BUSD'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Shiba Inu" ? <> {(confirme.converted* 1).toFixed(2)}{' SHIB'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Polygon" ? <> {(confirme.converted* 1).toFixed(2)}{' MATIC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Ethereum" ? <> {(confirme.converted* 1).toFixed(2)}{' ETH'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Polkadot" ? <> {(confirme.converted* 1).toFixed(2)}{' DOT'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Chainlink" ? <> {(confirme.converted* 1).toFixed(2)}{' LINK'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Cardano" ? <> {(confirme.converted* 1).toFixed(2)}{' ADA'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Solana" ? <> {(confirme.converted* 1).toFixed(2)}{' SOL'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                null}
                                </strong>:
                                confirme.operatorAction === "buy" ? <strong className='success'>
                                {confirme.serviceName === "Tether" || confirme.serviceName === "Tether(USDT)" ? <> {(confirme.converted* 1).toFixed(2)}{' $ USDT'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Binance Smart Chain" ? <> {(confirme.converted* 1).toFixed(5)}{' BNB'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Litecoin" ? <> {(confirme.converted* 1).toFixed(5)}{' LTC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Bitcoin" ? <> {(confirme.converted* 1).toFixed(5)}{' BTC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "TRON" ? <> {(confirme.converted* 1).toFixed(2)}{' TRX'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Dogecoin" ? <> {(confirme.converted* 1).toFixed(2)}{' DOGE'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Payeer" ? <> {(confirme.converted* 1).toFixed(2)}{' PAYEER'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>: 
                                confirme.serviceName === "Perfect Money" ? <> {(confirme.converted* 1).toFixed(2)}{' PM'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Binance USD" ? <> {(confirme.converted* 1).toFixed(2)}{' BUSD'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Shiba Inu" ? <> {(confirme.converted* 1).toFixed(2)}{' SHIB'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Polygon" ? <> {(confirme.converted* 1).toFixed(2)}{' MATIC'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Ethereum" ? <> {(confirme.converted* 1).toFixed(2)}{' ETH'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Polkadot" ? <> {(confirme.converted* 1).toFixed(2)}{' DOT'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Chainlink" ? <> {(confirme.converted* 1).toFixed(2)}{' LINK'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Cardano" ? <> {(confirme.converted* 1).toFixed(2)}{' ADA'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                confirme.serviceName === "Solana" ? <> {(confirme.converted* 1).toFixed(2)}{' SOL'} <span className='color-b'> ≃ </span> <span style={{backgroundColor:'#0a146e', color:'#fff', borderRadius:'3px', padding:'3px'}}>${(confirme.dollar * 1).toFixed(2)}</span> </>:
                                null}
                                </strong>:null
                                }
                            </span>
                        </div>
                        <div>
                            <span>
                            <strong>Publié le : </strong>
                            </span>
                            <span>{' '}{confirme.updatedAt.slice(0,10)} à {Number(confirme.updatedAt.slice(11,13)) + 2}{confirme.updatedAt.slice(13,19)}</span>
                        </div>
                        </div>
                    </div>
                    ))
                }
                </Carousel>
            }

        </section>
    </div>
  );
}