import { Box, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import coinGecko from '../../api/coinGecko'

const CurrencyUpdateData = ({themeMode, LineLink}) => {

    const tron        = "../images/tron.png"
    const btc         = "../images/btc.png"
    const ltc         = "../images/ltc.png"
    const usdt        = "../images/usdt.png"
    const doge        = "../images/doge.png"
    const busd        = "../images/busd.png"
    const shiba       = "../images/shiba.png"
    const polygonmatic    = "../images/polygonmatic.png"
    const ethereum        = "../images/eth.png"
    const chainlink       = "../images/chainlink.png"
    const polkadot        = "../images/pokadot.png"
    const cardano         = "../images/cardano.png"
    const solana          = "../images/solana.png"
    const pm              = "../images/pm.png"
    const bnb             = "../images/bnb.png"
    const ton             = "../images/ton.png"

    const [BTCModif, setBTCModif] = useState(false)
    const [TRONModif, setTRONModif] = useState(false)
    const [LTCModif, setLTCModif] = useState(false)
    const [USDTModif, setUSDTModif] = useState(false)
    const [DOGEModif, setDOGEModif] = useState(false)
    const [BUSDModif, setBUSDModif] = useState(false)
    const [SHIBAModif, setSHIBAModif] = useState(false)
    const [MATICModif, setMATICModif] = useState(false)
    const [ETHModif, setETHModif] = useState(false)
    const [LINKModif, setLINKModif] = useState(false)
    const [DOTModif, setDOTModif] = useState(false)
    const [ADAModif, setADAModif] = useState(false)
    const [SOLModif, setSOLModif] = useState(false)
    const [PMModif, setPMModif] = useState(false)
    const [BNBModif, setBNBModif] = useState(false)
    const [TONModif, setTONModif] = useState(false)

    function customBTCHandler(){ setBTCModif(!BTCModif) }
    function customTRONHandler(){ setTRONModif(!TRONModif) }
    function customLTCHandler(){ setLTCModif(!LTCModif) }
    function customUSDTHandler(){ setUSDTModif(!USDTModif) }
    function customDOGEHandler(){ setDOGEModif(!DOGEModif) }
    function customBUSDHandler(){ setBUSDModif(!BUSDModif) }
    function customSHIBAHandler(){ setSHIBAModif(!SHIBAModif) }
    function customMATICHandler(){ setMATICModif(!MATICModif) }
    function customETHHandler(){ setETHModif(!ETHModif) }
    function customLINKHandler(){ setLINKModif(!LINKModif) }
    function customDOTHandler(){ setDOTModif(!DOTModif) }
    function customADAHandler(){ setADAModif(!ADAModif) }
    function customSOLHandler(){ setSOLModif(!SOLModif) }
    function customPMHandler(){ setPMModif(!PMModif) }
    function customBNBHandler(){ setBNBModif(!BNBModif) }
    function customTONHandler(){ setTONModif(!TONModif) }

    const [BNBStop, setBNBStop] = useState(false)
    const [NT_BNB, setNT_BNB] = useState([])

    const [BTCStop, setBTCStop] = useState(false)
    const [NT_BTC, setNT_BTC] = useState([])

    const [TRONStop, setTRONStop] = useState(false)
    const [NT_TRON, setNT_TRON] = useState([])

    const [USDTStop, setUSDTStop] = useState(false)
    const [NT_USDT, setNT_USDT] = useState([])

    const [LINKStop, setLINKStop] = useState(false)
    const [NT_LINK, setNT_LINK] = useState([])

    const [MATICStop, setMATICStop] = useState(false)
    const [NT_MATIC, setNT_MATIC] = useState([])

    const [DOTEStop, setDOTEStop] = useState(false)
    const [NT_DOTE, setNT_DOTE] = useState([])

    const [BUSDStop, setBUSDStop] = useState(false)
    const [NT_BUSD, setNT_BUSD] = useState([])

    const [PMStop, setPMStop] = useState(false)
    const [NT_PM, setNT_PM] = useState([])

    const [SOLStop, setSOLStop] = useState(false)
    const [NT_SOL, setNT_SOL] = useState([])

    const [ADAStop, setADAStop] = useState(false)
    const [NT_ADA, setNT_ADA] = useState([])

    const [ETHStop, setETHStop] = useState(false)
    const [NT_ETH, setNT_ETH] = useState([])

    const [SHIBAStop, setSHIBAStop] = useState(false)
    const [NT_SHIBA, setNT_SHIBA] = useState([])

    const [DOGEStop, setDOGEStop] = useState(false)
    const [NT_DOGE, setNT_DOGE] = useState([])

    const [LTCStop, setLTCStop] = useState(false)
    const [NT_LTC, setNT_LTC] = useState([])

    const [TONStop, setTONStop] = useState(false)
    const [NT_TON, setNT_TON] = useState([])

    const [coins2, setCoins2] = useState([])

    const [CurrencyHandle, setCurrencyHandle] = useState()

    const currencyHandleChange = ({target: {value}}) => {
      setCurrencyHandle(value)
    }

    const validateBTCHandler = () => {

        setBTCModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-btc', 
                    {
                        ID: 1,
                        btc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateLTCHandler = () => {

        setLTCModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-ltc', 
                    {
                        ID: 1,
                        ltc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateUSDTHandler = () => {

        setUSDTModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-usdt', 
                    {
                        ID: 1,
                        usdt: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateDOGEHandler = () => {

        setDOGEModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-doge', 
                    {
                        ID: 1,
                        doge: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateBUSDHandler = () => {

        setBUSDModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-busd', 
                    {
                        ID: 1,
                        busd: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateSHIBAHandler = () => {

        setSHIBAModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-shiba', 
                    {
                        ID: 1,
                        shiba: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateMATICHandler = () => {

        setMATICModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-matic', 
                    {
                        ID: 1,
                        matic: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateETHHandler = () => {

        setETHModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-eth', 
                    {
                        ID: 1,
                        eth: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateDOTHandler = () => {

        setDOTModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-dote', 
                    {
                        ID: 1,
                        dote: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateADAHandler = () => {

        setADAModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-ada', 
                    {
                        ID: 1,
                        ada: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateSOLHandler = () => {

        setSOLModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-sol', 
                    {
                        ID: 1,
                        sol: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validatePMHandler = () => {

        setPMModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-pm', 
                    {
                        ID: 1,
                        pm: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateBNBHandler = () => {

        setBNBModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-bnb', 
                    {
                        ID: 1,
                        bnb: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateTRONHandler = () => {

        setTRONModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-tron', 
                    {
                        ID: 1,
                        tron: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateLINKHandler = () => {

        setLINKModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-link', 
                    {
                        ID: 1,
                        link: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateTONHandler = () => {

        setTONModif(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-ton', 
                    {
                        ID: 1,
                        ton: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }



    //UPDATING CURRENCY DATA
    
    useEffect(()=>{
      
      const fetchDada2 = async () => {
    
        const response = await coinGecko.get('/coins/markets', {
          params: {
            vs_currency: 'usd',
            ids:
              'binancecoin,binance-usd,tron,litecoin,dogecoin,shiba-inu,matic-network,chainlink,polkadot,cardano,solana,the-open-network',
          },
        })
        if(response.data.length > 0){
          setCoins2(response.data)
        }
      }
      fetchDada2()
    },[])
    
    useEffect(()=>{
        const fetchBTC = async () => {
    
          try {
            if(coins2.length > 0){
    
              if(coins2[11].current_price){
    
                try {
        
                    axios.post('/api/update-nt-busd', 
                        {
                            ID: 1,
                            busd: coins2[11].current_price,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                        }
                        )
        
                } catch (err) {}
              }
            }
          } catch (error) {
          }
        }
        fetchBTC()
    
    },[coins2])

    
    useEffect(()=>{
        const fetchBTC = async () => {
    
          try {
            if(coins2.length > 0){

                if(coins2[0].id === "binancecoin"){
          
                  if(coins2[0].current_price){
            
                    try {
            
                        axios.post('/api/update-nt-bnb', 
                            {
                                ID: 1,
                                bnb: coins2[0].current_price,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                            )
            
                    } catch (err) {}
                  }
          
                } else if(coins2[0].id === "solana"){
          
                  if(coins2[0].current_price){
            
                    try {
            
                        axios.post('/api/update-nt-sol', 
                            {
                                ID: 1,
                                sol: coins2[0].current_price,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                            )
            
                    } catch (err) {}
                  }
          
                }
            }
          } catch (error) {
          }
        }
        fetchBTC()
    
      },[coins2])
      
      useEffect(()=>{
          const fetchBTC = async () => {
      
          try {
              if(coins2.length > 0){

                if(coins2[1].id === "solana"){
            
                  if(coins2[1].current_price){
              
                    try {
              
                        axios.post('/api/update-nt-sol', 
                            {
                                ID: 1,
                                sol: coins2[1].current_price,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                            )
              
                    } catch (err) {}
                  }
            
                } else if(coins2[1].id === "binancecoin"){
            
                  if(coins2[1].current_price){
              
                    try {
              
                        axios.post('/api/update-nt-bnb', 
                            {
                                ID: 1,
                                bnb: coins2[1].current_price,
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                            )
              
                    } catch (err) {}
                  }
            
                }
              }
          } catch (error) {
          }
          }
          fetchBTC()
      
      },[coins2])
    
      useEffect(()=>{
      const fetchBTC = async () => {
  
        try {
          if(coins2.length > 0){
  
            if(coins2[5].current_price){
  
              try {
      
                  axios.post('/api/update-nt-ada', 
                      {
                          ID: 1,
                          ada: coins2[5].current_price,
                          createdAt: new Date(),
                          updatedAt: new Date(),
                      }
                      )
      
              } catch (err) {}
            }
          }
        } catch (error) {
        }
      }
      fetchBTC()
  
      },[coins2])
    
      useEffect(()=>{
      const fetchBTC = async () => {
  
        try {
          if(coins2.length > 0){
  
            if(coins2[4].current_price){
  
              try {
      
                  axios.post('/api/update-nt-ton', 
                      {
                          ID: 1,
                          ton: coins2[4].current_price,
                          createdAt: new Date(),
                          updatedAt: new Date(),
                      }
                      )
      
              } catch (err) {}
            }
          }
        } catch (error) {
        }
      }
      fetchBTC()
  
      },[coins2])



    //FETCHING DATA
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BTCStop){

                try {
                    const res = await axios.get('/api/nt-btc')

                    if(res.data[0].ID){
                        setNT_BTC(res.data[0].btc)
                        setBTCStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BTCStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TRONStop){

                try {
                    const res = await axios.get('/api/nt-tron')

                    if(res.data[0].ID){
                        setNT_TRON(res.data[0].tron)
                        setTRONStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TRONStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LTCStop){

                try {
                    const res = await axios.get('/api/nt-ltc')

                    if(res.data[0].ID){
                        setNT_LTC(res.data[0].ltc)
                        setLTCStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LTCStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!USDTStop){

                try {
                    const res = await axios.get('/api/nt-usdt')

                    if(res.data[0].ID){
                        setNT_USDT(res.data[0].usdt)
                        setUSDTStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[USDTStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOGEStop){

                try {
                    const res = await axios.get('/api/nt-doge')

                    if(res.data[0].ID){
                        setNT_DOGE(res.data[0].doge)
                        setDOGEStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOGEStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BUSDStop){

                try {
                    const res = await axios.get('/api/nt-busd')

                    if(res.data[0].ID){
                        setNT_BUSD(res.data[0].busd)
                        setBUSDStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BUSDStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SHIBAStop){

                try {
                    const res = await axios.get('/api/nt-shiba')

                    if(res.data[0].ID){
                        setNT_SHIBA(res.data[0].shiba)
                        setSHIBAStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SHIBAStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!MATICStop){

                try {
                    const res = await axios.get('/api/nt-matic')

                    if(res.data[0].ID){
                        setNT_MATIC(res.data[0].matic)
                        setMATICStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[MATICStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ETHStop){

                try {
                    const res = await axios.get('/api/nt-eth')

                    if(res.data[0].ID){
                        setNT_ETH(res.data[0].eth)
                        setETHStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ETHStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LINKStop){

                try {
                    const res = await axios.get('/api/nt-link')

                    if(res.data[0].ID){
                        setNT_LINK(res.data[0].link)
                        setLINKStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LINKStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOTEStop){

                try {
                    const res = await axios.get('/api/nt-dote')

                    if(res.data[0].ID){
                        setNT_DOTE(res.data[0].dote)
                        setDOTEStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOTEStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ADAStop){

                try {
                    const res = await axios.get('/api/nt-ada')

                    if(res.data[0].ID){
                        setNT_ADA(res.data[0].ada)
                        setADAStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ADAStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SOLStop){

                try {
                    const res = await axios.get('/api/nt-sol')

                    if(res.data[0].ID){
                        setNT_SOL(res.data[0].sol)
                        setSOLStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SOLStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!PMStop){

                try {
                    const res = await axios.get('/api/nt-pm')

                    if(res.data[0].ID){
                        setNT_PM(res.data[0].pm)
                        setPMStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[PMStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BNBStop){

                try {
                    const res = await axios.get('/api/nt-bnb')

                    if(res.data[0].ID){
                        setNT_BNB(res.data[0].bnb)
                        setBNBStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BNBStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TONStop){

                try {
                    const res = await axios.get('/api/nt-ton')

                    if(res.data[0].ID){
                        setNT_TON(res.data[0].ton)
                        setTONStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TONStop])




  return (
    
    <div className={`${themeMode === "true" ? "row final-seesion-t" : "row final-seesion"}`}>
    <div className='center'>
        <div><span className={`${themeMode === "true" ? "color-or" : ""}`}>Données variantes des <strong>valeurs en dollar</strong></span></div>
        <hr className={`${themeMode === "true" ? "color-or" : ""}`}/>
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Bitcoin</span>
                {
                    BTCModif ? <span className='ml-2 stat-see-card-detail' onClick={validateBTCHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customBTCHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        BTCModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="btc" label="XXXXX BTC" name="btc" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_BTC*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div>
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Tron</span>
                {
                    TRONModif ? <span className='ml-2 stat-see-card-detail' onClick={validateTRONHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customTRONHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        TRONModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="tron" label="XXXXX TRX" name="tron" autoFocus onChange={currencyHandleChange} />
                            </Box>
                        </div> : <div>$ {(NT_TRON*1).toFixed(5)}</div>
                    } 
                </div>
            </div>
        </div>  
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Litecoin</span>
                {
                    LTCModif ? <span className='ml-2 stat-see-card-detail' onClick={validateLTCHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customLTCHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        LTCModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="ltc" label="XXXXX LTC" name="ltc" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_LTC*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div>  
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Tether</span>
                {
                    USDTModif ? <span className='ml-2 stat-see-card-detail' onClick={validateUSDTHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customUSDTHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        USDTModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_USDT*1).toFixed(5)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Dogecoin</span>
                {
                    DOGEModif ? <span className='ml-2 stat-see-card-detail' onClick={validateDOGEHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customDOGEHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        DOGEModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="doge" label="XXXXX DOGE" name="doge" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_DOGE*1).toFixed(5)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Binance usd</span>
                {
                    BUSDModif ? <span className='ml-2 stat-see-card-detail' onClick={validateBUSDHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customBUSDHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        BUSDModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="busd" label="XXXXX BUSD" name="busd" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_BUSD*1).toFixed(4)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Shiba</span>
                {
                    SHIBAModif ? <span className='ml-2 stat-see-card-detail' onClick={validateSHIBAHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customSHIBAHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        SHIBAModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="shiba" label="XXXXX SHIBA" name="shiba" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_SHIBA*1).toFixed(9)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Polygon matic</span>
                {
                    MATICModif ? <span className='ml-2 stat-see-card-detail' onClick={validateMATICHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customMATICHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        MATICModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="matic" label="XXXXX MATIC" name="matic" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_MATIC*1).toFixed(4)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Ethereum</span>
                {
                    ETHModif ? <span className='ml-2 stat-see-card-detail' onClick={validateETHHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customETHHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        ETHModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="ethereum" label="XXXXX ETH" name="ethereum" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_ETH*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Chain link</span>
                {
                    LINKModif ? <span className='ml-2 stat-see-card-detail' onClick={validateLINKHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customLINKHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        LINKModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="chainlink" label="XXXXX LINK" name="chainlink" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_LINK*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Polkadot</span>
                {
                    DOTModif ? <span className='ml-2 stat-see-card-detail' onClick={validateDOTHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customDOTHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        DOTModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="polkadot" label="XXXXX DOT" name="polkadot" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_DOTE*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Cardano</span>
                {
                    ADAModif ? <span className='ml-2 stat-see-card-detail' onClick={validateADAHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customADAHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        ADAModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="cardano" label="XXXXX ADA" name="cardano" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_ADA*1).toFixed(4)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Solana</span>
                {
                    SOLModif ? <span className='ml-2 stat-see-card-detail' onClick={validateSOLHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customSOLHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        SOLModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="solana" label="XXXXX SOL" name="solana" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_SOL*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Perfect Money</span>
                {
                    PMModif ? <span className='ml-2 stat-see-card-detail' onClick={validatePMHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customPMHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        PMModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="pm" label="XXXXX PM" name="pm" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_PM*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Binance Smart Chain</span>
                {
                    BNBModif ? <span className='ml-2 stat-see-card-detail' onClick={validateBNBHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customBNBHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        BNBModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="bnb" label="XXXXX BNB" name="bnb" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_BNB*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
    <div className='col-md-3'>
        <div className='stat-card'>
            <div className='stat-card-title'>
                <img className='images-drapeau mr-2' src={`../${ton}`} alt="" />
                <span className='ml-2 stat-see-card-detail-bnt'>Toncoin</span>
                {
                    TONModif ? <span className='ml-2 stat-see-card-detail' onClick={validateTONHandler}>Valider</span> :
                    <span className='ml-2 stat-see-card-detail' onClick={customTONHandler}>Modifier</span>
                }
            </div>
            <div className='d-flex justify-content-around'>
                <div className='stat-card-content'>
                    {
                        TONModif ? 
                        <div>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField fullWidth id="ton" label="XXXXX TON" name="ton" autoFocus onChange={currencyHandleChange}/>
                            </Box>
                        </div> : <div>$ {(NT_TON*1).toFixed(2)}</div>
                    } 
                </div>
            </div>
        </div> 
    </div>
</div>
  )
}

export default CurrencyUpdateData