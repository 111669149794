import axios from 'axios'
import React, { useEffect, useState } from 'react'

const ListePreium = () => {

    const [Nkabpro, setNkabpro] = useState([])
    const [TotalWallets, setTotalWallets] = useState([])
  
    //   console.log(PremiumsData)
    
    useEffect(()=>{

    const fetchData = async () => {
        try {

        const { data } = await axios.get('/api/nkabpro')

        if(data.length > 0){
            setNkabpro(data)
        }

        } catch (error) { }
    }
    fetchData()

    },[])

    useEffect(()=>{

        if(Nkabpro.length > 0){

            const Datas = (Nkabpro.reduce((total, currentItem) =>  total = total + Number(currentItem.proPrice) , 0 ));
            if(Datas > 0){
                setTotalWallets((Datas*1).toFixed(2))
            } else {
                setTotalWallets(0)
            }
        } else {
            setTotalWallets(0)
        }
    },[Nkabpro])
    
  return (
    <div>
        <div className='mt-5 mb-5'>
            <div className="d-flex justify-content-center profile-section p-3"  style={{backgroundColor:"#dbdbdba1"}}>
                <div className='text-center'>
                    <h1 className='color-grey' style={{fontSize:"35px"}}>Cumul total actuel : <strong style={{color:"#0E233A"}}>{TotalWallets}</strong> XOF</h1>
                    <hr/>
                </div>
            </div>
        </div>
        <div className="__op-body-table">
            <table className='__op-responsive-table'>
                <thead>
                    <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Portefeuille</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Nkabpro.length > 0 && Nkabpro.map((item)=>(
                            <tr key={item.ID}>
                                <th scope="row"><span style={{color:'#90d9ff'}}>{item.proEmail}</span></th>
                                <td data-title="Contact">{item.proPhone}</td>
                                <td data-title="Portefeuille"><strong>{item.proPrice}</strong> XOF</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default ListePreium