import React, { useEffect, useState } from 'react'
import './RInterfaceComponent.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import axios from 'axios'
import { uid } from 'uid'
import './TInterfaceComponent.css'

const TInterfaceComponent = ({Wallets, MyCodePin, nkab_trans_id, nkab_trans_reference, themeMode}) => {

    const LineLink = true
    // const LineLink = false

    const [BtnOp, setBtnOp] = useState(false)
    const SenderCountry = localStorage.getItem('SenderCountry')
    const changeName  = localStorage.getItem("changeName")
    const changeImage = localStorage.getItem("changeImage")
    const userEmail = localStorage.getItem('userEmail')
    const userID  = localStorage.getItem('userID')
    const userLastName = localStorage.getItem("userLastName")
    const userName = localStorage.getItem("userName")
    const nkabWarning = localStorage.getItem("nkabWarning")
    const nkabMarkTitle = localStorage.getItem("nkabMarkTitle")

    const [CodePin, setCodePin] = useState()
    const [Loader, setLoader] = useState(false)
    const [PassConfirmed, setPassConfirmed] = useState("")

    const handleChangeInput = (e) => {
      const { value } = e.target
      setCodePin(value)
    }

    function viewOpBtn(){
        setBtnOp(!BtnOp)
    }

    const [ChangeStatus, setChangeStatus] = useState(false)

    const INIT3 = {mt:0}
    const [Montant, setMontant] = useState(INIT3)
    const [Phone, setPhone] = useState([]);
    
    const handleMontant = (e) => {

        const { value } = e.target
  
        setMontant(value)
    }
    
    const handlePhone = (e) => {

        const { value } = e.target
  
        setPhone(value)
    }

    const [MerchantId, setMerchantId] = useState("")
    const [MerchantIdKey, setMerchantIdKey] = useState("")
    const [PayInRate, setPayInRate] = useState([])

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const [passwordInput, setPasswordInput] = useState("")

    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }

    function ChoixChange1(){
        setChangeStatus(true)
    }

    function ChoixChange1b(){
        setChangeStatus(false)
    }

    function ChoixChange2a(){
        setChangeStatus(false)
        localStorage.setItem("changeImage", "../../images/wave.png")
        localStorage.setItem("changeName", "Wave")
    }

    function ChoixChange2b(){
        setChangeStatus(false)
        localStorage.setItem("changeImage", "../../images/om.png")
        localStorage.setItem("changeName", "Orange")
    }

    function ChoixChange2c(){
        setChangeStatus(false)
        localStorage.setItem("changeImage", "../../images/flooz.png")
        localStorage.setItem("changeName", "Moov")
    }

    function ChoixChange2d(){
        setChangeStatus(false)
        localStorage.setItem("changeImage", "../../images/momo.png")
        localStorage.setItem("changeName", "mtn")
    }

    function RSection(){ 
        localStorage.setItem('RI', 'RInterface')
        localStorage.setItem('BI', '')
        localStorage.setItem('TI', '')
    }

    function BSection(){ 
        localStorage.setItem('RI', '')
        localStorage.setItem('BI', 'BInterface')
        localStorage.setItem('TI', '')
    }

    const ci = "Côte d'Ivoire"
    const sen = "Sénégal"
    const burk = "Burkina Fasso"
    const benin = "Bénin"
    const togo = "Togo"
    const mali = "Mali"
    const niger = "Niger"
    const cam = "Cameroun"
    const guinee = "Guinée"
  
    // PAY-IN
  
    const ciRateIN = [{
        // orange: 0.015, //"1.50%",
        // moov: 0.01, //"1%",
        // mtn: 0.02, //"2%",
        // wave: 0.01, //"1%"

        orange: 0.0225, //"2.25%",
        moov: 0.0225, //"2.25%",
        mtn: 0.0225, //"2.25%",
        wave: 0.02, //"2.0%"
    }]
  
    const senRateIN = [{
    //   orange: 0.015, //"1.50%",
    //   freeMoney: 0.015, //"1.50%",
    //   wave: 0.02, //"2.00%"

      orange: 0.025, //"2.50%",
      freeMoney: 0.025, //"2.50%",
      wave: 0.03, //"3%"
    }]
  
    const maliRateIN = [{
    //   orange: 0.02, //"2.00%",
    //   mobyCash: 0.02, //"2.00%",

      orange: 0.03, //"3%",
      mobyCash: 0.03, //"3%",
    }]
  
    const bfRateIN = [{
    //   orange: 0.025, //"2.50%",
    //   mobyCash: 0.025, //"2.50%",

      orange: 0.035, //"3.50%",
      mobyCash: 0.035, //"3.50%",
    }]
  
    const camRateIN = [{
    //   orange: 0.025, //"2.50%",
    //   mtn: 0.025, //"2.50%",

      orange: 0.035, //"3.50%",
      mtn: 0.035, //"3.50%",
    }]
  
    const guineeRateIN = [{
    //   orange: 0.0375, //"3.75%",
    //   mtn: 0.025, //"2.50%",

      orange: 0.0475, //"4.75%",
      mtn: 0.035, //"3.50%",
    }]
  
    const beninRateIN = [{
    //   moov: 0.02, //"2.00%",
    //   mtn: 0.02, //"2.00%",

      moov: 0.03, //"3.00%",
      mtn: 0.03, //"3.00%",
    }]
  
    const togoRateIN = [{
    //   moov: 0.045, //"4.50%",
    //   togocel: 0.045, //"4.50%",

      moov: 0.055, //"5.50%",
      togocel: 0.055, //"5.50%",
    }]


    useEffect(()=>{
        if(changeName){
            if(SenderCountry === ci){

                setMerchantId("o3XPUfGPrmspx4AQoWLmr")
                setMerchantIdKey("sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl")

                if(changeName === "Orange"){
                    setPayInRate(ciRateIN[0].orange)
                }

                if(changeName === "mtn"){
                    setPayInRate(ciRateIN[0].mtn)
                }

                if(changeName === "Moov"){
                    setPayInRate(ciRateIN[0].moov)
                }

                if(changeName === "Wave"){
                    setPayInRate(ciRateIN[0].wave)
                }
            }
            if(SenderCountry === togo){

                setMerchantId("Ee9HM0SbkkPpmvpY8iJXa")
                setMerchantIdKey("sk_HSWq5h91I0E7sVSxXOACPBbMILX6Qq61")

                if(changeName === "Moov"){
                    setPayInRate(togoRateIN[0].moov)
                }
            }
            if(SenderCountry === burk){
                setMerchantId("4eEdWoS5T2UiPkEdsO7Zt")
                setMerchantIdKey("sk_yEvF54xNdflGibQpzVMLfAl7Iu6twdfO")

                if(changeName === "Orange"){
                    setPayInRate(bfRateIN[0].orange)
                }
            }
            if(SenderCountry === mali){
                setMerchantId("dNoKavNhtMQaNZTSCJa4s")
                setMerchantIdKey("sk_VMctIMcIRcAfPuzZxoc4r9TbStp7rNnG")

                if(changeName === "Orange"){
                    setPayInRate(maliRateIN[0].orange)
                }
            }
            if(SenderCountry === sen){
                setMerchantId("EF0esPiIBKxkdZcSwERyw")
                setMerchantIdKey("sk_7UiOwbEogK6QEv0Blm7T3XXQv2gzOq8M")

                if(changeName === "Orange"){
                    setPayInRate(senRateIN[0].orange)
                }

                if(changeName === "Wave"){
                    setPayInRate(senRateIN[0].wave)
                }
            }
            if(SenderCountry === cam){
                setMerchantId("BguxmE4b9kYYCn86IUCPZ")
                setMerchantIdKey("sk_TUuDIbxLurPIKD2yPpvuMfocp2h8bTEy")

                if(changeName === "Orange"){
                    setPayInRate(camRateIN[0].orange)
                }

                if(changeName === "mtn"){
                    setPayInRate(camRateIN[0].mtn)
                }
            }
            if(SenderCountry === benin){
                setMerchantId("Bbqyg6WMe4MZALrTbVsKc")
                setMerchantIdKey("sk_VkKDUSWRpN7rLvQgcGvr6Rq4LHqvudth")

                if(changeName === "mtn"){
                    setPayInRate(beninRateIN[0].mtn)
                }

                if(changeName === "Moov"){
                    setPayInRate(beninRateIN[0].moov)
                }
            }
            if(SenderCountry === guinee){
                setMerchantId("sYSJyhGpeOXb95iO7WOlh")
                setMerchantIdKey("sk_JIO1fgAmaVb9i7wGUrYA9tQDDjYVoiiU")

                if(changeName === "Orange"){
                    setPayInRate(guineeRateIN[0].orange)
                }

                if(changeName === "mtn"){
                    setPayInRate(guineeRateIN[0].mtn)
                }
            }
        }
    },[
        SenderCountry,
        PayInRate,  
        changeName,
        beninRateIN,
        bfRateIN,
        camRateIN,
        ciRateIN,
        guineeRateIN,
        maliRateIN,
        senRateIN,
        togoRateIN,

    ])
    
    const wavePaymentLink = 'https://pay.wave.com/m/M_DyjfDYDUCSym/c/ci/'
    
    const TransferHandler = () => {

        localStorage.setItem("amount", Montant)
        localStorage.setItem("changeName", changeName)
        localStorage.setItem("changeImage", changeImage)
        localStorage.setItem("email", userEmail.toLowerCase())
        localStorage.setItem("phone", Phone)
        localStorage.setItem("_options", "TRANSFERT")

        if(Number(CodePin) === Number(MyCodePin)){

            setLoader(true)
            handleClose();
        
            const OperationUId = uid(8)
            const OperationUId2 = uid(21)

            if(changeName === "Wave"){
            
                if(OperationUId && changeName && changeImage && Montant && userEmail && Phone){
                    axios.post('/api/v1/p-trans/create', {
                            operationUId : `ref_${OperationUId}`,
                            userId : userID,
                            tr_Id: `sop_${OperationUId2}`,
                            options : "TRANSFERT",
                            changeName,
                            changeImage : changeImage,
                            montant : Montant,
                            email : userEmail.toLowerCase(),
                            fullName : `${userLastName} ${userName}`,
                            phone : Phone,
                            status: "processing",
                            createdAt: new Date(),
                            updatedAt: new Date(),
                        },
                    ).then((res)=>{ 
                        if(res){
                            axios.post('/api/v1/all-p-op/create', {
                                operationUId : `ref_${OperationUId}`,
                                userId : userID,
                                tr_Id: `sop_${OperationUId2}`,
                                options : "TRANSFERT",
                                moneyName : changeName,
                                moneyImage : changeImage,
                                montant : Montant,
                                converted : "no",
                                dollar: "no",
                                fullName : `${userLastName} ${userName}`,
                                phone : Phone,
                                status: "no",
                                taux : "no",
                                netAddress : "no",
                                adress : "no",
                                email : userEmail.toLowerCase(),
                                numcompte : "no",
                                rdv : "no",
                                times : "no",
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                        ).then((res)=>{
                            if(res){
                                axios.post('/api/v1/notification/create-notif', {
                                        notifierId : `ref_${OperationUId}`,
                                        notifyImageLink : changeImage === "../../images/flooz.png" ? "FLOOZ" : changeImage === "../../images/om.png" ? "OM" : changeImage === "../../images/momo.png" ? "MOMO" : changeImage === "../../images/wave.png" ? "WAVE" : changeImage,
                                        notifyServicesId : `ref_${OperationUId}`,
                                        notifyOperatorAction : "TRANSFERT",
                                        notifySenderName : "premium",
                                        notifySenderPhone : Phone,
                                        notifySenderEmail : userEmail.toLowerCase(),
                                        createdAt: new Date(),
                                        updatedAt: new Date(),
                                    },
                                ).then((res)=>{
                                    if(res){
                                        localStorage.setItem("trans_sop", `sop_${OperationUId2}`)
                                        setLoader(false)
                                        setMontant(0)
                                        localStorage.removeItem("banqueImage")
                                        localStorage.removeItem("banqueName")
                                        localStorage.removeItem("changeImage")
                                        localStorage.removeItem("changeName")
                                        localStorage.removeItem("moneyImage")
                                        localStorage.removeItem("moneyName")
                                        localStorage.removeItem("banqueClickedName")
                                        localStorage.setItem("isRActive",true)

                                        window.location.replace(`${wavePaymentLink}`);
    
                                        // if(LineLink){
                                        //     window.location.replace("https://nkab-exchange.net/nkab-pro");
                                        // } else {
                                        //     window.location.replace("http://localhost:3000/nkab-pro");
                                        // }
                                    }
                                })
                            }
                        })
                    }
                })
                }
            } else if(changeName !== "Wave"){

                var myHeaders = new Headers();
    
                myHeaders.append("merchantId",    `${MerchantId}`);
                myHeaders.append("environment",   "live");
                myHeaders.append("Content-Type",  "application/json");
                myHeaders.append("ApiKey",        `${MerchantIdKey}`);

                const localMount = Number(Montant) - (Number(Montant) * 0.04)
            
                var raw = JSON.stringify({
                    "reference": `ref_${OperationUId}`,
                    "amount": SenderCountry === ci ? localMount : 0,
                    "currency": "XOF",
                    "description": "nkab paiement",
                    "destination": {
                    "type": "mobile_money",
                    "country": `${SenderCountry === ci ? 'CI' :SenderCountry === sen ? 'SN' :SenderCountry === mali ? 'ML' :SenderCountry === togo ? 'TG' :SenderCountry === burk ? 'BF' :SenderCountry === benin ? 'BJ' :SenderCountry === cam ? 'CM' : ""}`,
                    "provider": `${changeName}`,
                    "msisdn": `${Phone}`
                    }
                });
            
                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                axios.post(`/api/transfers`, {requestOptions, MerchantId, MerchantIdKey})
                .then(result => {
                    if(result.data.reference){
    
                        localStorage.setItem("nkab_trans_id", result.data.id)
                        localStorage.setItem("nkab_trans_reference", result.data.reference)
                        localStorage.setItem("isRActive",true)
        
                        if(LineLink){
                            window.location.replace("https://nkab-exchange.net/nkab-pro");
                        } else {
                            window.location.replace("http://localhost:3000/nkab-pro");
                        }
                    }
                })
            }

        } else if(Number(CodePin) !== Number(MyCodePin)){
            setPassConfirmed("Code Pin incorrect !")
        }

    }

    const transMin = 1500

    const [SeePrice, setSeePrice ] = useState(false)
  
    function seePriceHandler(){
        setSeePrice(true)
    }
    function unSeePriceHandler(){
        setSeePrice(false)
    }

    function NewCodeHandler(){
        handleClose();
        localStorage.setItem('NewCode', 'ok')
    }

    function UpdateCodeHandler(){
        handleClose();
        localStorage.setItem('UpdateCode', 'ok')
    }

    function repriseaZero(){

        setLoader(false)
        setMontant(0)

        localStorage.removeItem("banqueImage")
        localStorage.removeItem("banqueName")
        localStorage.removeItem("changeImage")
        localStorage.removeItem("changeName")
        localStorage.removeItem("moneyImage")
        localStorage.removeItem("moneyName")
        localStorage.removeItem("banqueClickedName")
        localStorage.removeItem("nkab_trans_reference")
        localStorage.removeItem("nkab_trans_id")
        localStorage.removeItem("_options")
        localStorage.removeItem("nkabWarning")
        localStorage.removeItem("nkabMarkTitle")
        localStorage.removeItem("nkab_rge_simple_tolk")
        
        if(LineLink){
            window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
            window.location.replace("http://localhost:3000/nkab-pro");
        }
    }

  return (
    <div className="nkab-charge-container-t mb-5">
        <div>
            <div className='d-flex justify-content-between p-4 nkab-r-text bolder'>
                <div className="nkab-r-title-t">Transférer</div>
                <div className="nkab-dote-menu-t"  onClick={viewOpBtn}>...</div>
            </div>
            <div className='nkp_status color-or bolder'>Solde disponible</div>
            {
                !SeePrice ?
                <div onClick={seePriceHandler} className='d-flex justify-content-center mt-4'>
                    <div className='uppercase pointer' style={{fontSize:"30px", color:"#c7b301"}}>********</div>
                    <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="17" height="17" src="https://img.icons8.com/ios/50/c7b301/visible--v1.png" alt="visible--v1"/></div>
                </div>:
                <div onClick={unSeePriceHandler} className='d-flex justify-content-center mt-4'>
                    <div className='uppercase bolder pointer' style={{fontSize:"25px", color:"#c7b301"}}>{(Wallets*1).toFixed(2)} XOF</div>
                    <div className='nkp_eye ml-2 pointer'><img style={{color:"#c7b301"}} width="27" height="27" src="https://img.icons8.com/ios/50/c7b301/closed-eye.png" alt="closed-eye"/></div>
                </div>
            }
            {
                BtnOp ? 
                <div className='d-flex justify-content-center p-4 bolder'>
                    {/* <div className='nkab-r-btn ml-3 mr-3'>Recharger</div> */}
                    <div className='nkab-r-btn ml-3 mr-3' onClick={RSection} >Recharge</div>
                    <div className='nkab-r-btn ml-3 mr-3' onClick={BSection} >Acheter</div>
                </div>:null
            }
        </div>

        { Montant > 1000 && Wallets < Montant ? <div className='text-center'><strong className='text-danger'>Solde insufisant!</strong></div>:null }

        <div className='center nkab-pro-field-color mt-3'>
            <div className='d-flex justify-content-center nkab-selector-widget mb-4'>
                <div className="mb-1 nkp_items">
                    <div>
                        <span className={`d-flex align-items-center btn-square btn btn-light btn-lg uppercase nkp_items_square-t`} onClick={ChangeStatus ? ChoixChange1b : ChoixChange1}>
                            <img src={!changeImage?`${"../../images/question.png"}`:changeImage} width="20" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-16" />
                            <span className="d-block text-left w-100 color-or bolder">{!changeName?`${"Transférer via"}`:changeName}</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className="text-gray-500 flex-shrink-0">
                                <path fillRule="evenodd" d="M2.707 4.293L8 9.585l5.293-5.292a1 1 0 111.414 1.414l-6 6a.996.996 0 01-.53.277l-.118.014h-.118a.997.997 0 01-.648-.29l-6-6a1 1 0 011.414-1.415z" clipRule="evenodd"></path>
                            </svg>
                        </span>
                        {
                            ChangeStatus?
                            <div className="position-relative nkp_items_list">
                                <div className={`ml-0 mt-2 w-100 text-left position-absolute nkp_position-absolute-t`}>
                                    <div className="_3rpmn">
                                        <div className="_65T61 css-2b097c-container">
                                            <div className="p4Aly mt-2 pb-2 css-0">
                                                <div className={`px-2 _5HQUI css-0 select-scroll-container-t`}>
                                                    <div className="mt-1 css-0" id="react-select-2-option-0" tabIndex="-1">
                                                        <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixChange2a}>
                                                            <div className="d-flex flex-row">
                                                                <img src="../../images/wave.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span>Wave</span>
                                                            </div>
                                                            <span className="tag tag--md tag-transparent border-gray-400 bolder">WAVE</span>
                                                        </span>
                                                    </div>
                                                    <div className="mt-1 css-0" id="react-select-2-option-1" tabIndex="-1">
                                                        <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixChange2b}>
                                                            <div className="d-flex flex-row">
                                                                <img src="../../images/om.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span>Orange</span>
                                                            </div>
                                                            <span className="tag tag--md tag-transparent border-gray-400 bolder">OM</span>
                                                        </span>
                                                    </div>
                                                    <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                        <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixChange2c}>
                                                            <div className="d-flex flex-row">
                                                                <img src="../../images/flooz.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span>Moov</span>
                                                            </div>
                                                            <span className="tag tag--md tag-transparent border-gray-400 bolder">FLOOZ</span>
                                                        </span>
                                                    </div>
                                                    <div className="mt-1 css-0" id="react-select-2-option-2" tabIndex="-1">
                                                        <span className={`d-flex justify-content-between border-0 font-weight-normal p-2 mb-0_5 btn-lg btn-block nkp_item-position-t`} onClick={ChoixChange2d}>
                                                            <div className="d-flex flex-row">
                                                                <img src="../../images/momo.png" width="30" alt="nkab drapeau" className="dark-theme-icon-disable mr-2 icon-24"/><span>Mobile</span>
                                                            </div>
                                                            <span className="tag tag--md tag-transparent border-gray-400 bolder">MOMO</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:null
                        }
                    </div>
                </div>
            </div>
            <div className='mb-3'>
                <div className='d-flex justify-content-center'>
                    <OutlinedInput className={`nkab-r-input ${Montant > 1 && ((Montant < transMin) || Wallets < 0) ? "controler_danger" : Montant > 50 && (Montant > transMin) ? "controler_success" : null}`} placeholder="Montant" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handleMontant}/>
                </div>
                {/* <div className='d-flex justify-content-center' style={{fontSize:"11px"}}><span className={`${Number(Montant) < 0 || Number(Montant) > 0 ? Wallets < 500 || Montant < 1500 ? "text-danger" : "text-success mr-2":"nkp_price mr-2"}`}>Le numéro bénéficiaire recevra: <strong>{exchange.cfa-(exchange.cfa*PayOutRate)} XOF</strong></span><span className='ml-2'><strong>9600 XOF</strong></span></div> */}
            </div>
            <div className='mb-3'>
                <div className='d-flex justify-content-center'>
                    <OutlinedInput className='nkab-r-input' placeholder="n° Béneféficiaire Ex: 07 XX XX XX XX" type="text" sx={{width:"90%", border:`1px solid #8ad8ff56`, borderRadius:"10px", backgroundColor: "transparent"}} onChange={handlePhone}/>
                </div>
            </div>
            <div className='mb-3'>
                <div className={`d-flex justify-content-around ${Montant > 1 && ((Montant < transMin) || Wallets < 0) ? "text-danger" : Montant > 50 && (Montant > transMin) ? "text-success" : null}`} style={{fontSize:"11px"}}>
                    <span className='nkp_price'><strong>Le numéro bénéficiare reçoi : </strong></span>
                    <span className="nkp_price"><strong>{Montant.length > 0 ? <>{Montant - (Montant * 0.04) }XOF</>:"0 XOF"}</strong> </span>
                </div>
            </div>
            {
                nkab_trans_id !== null && nkab_trans_reference !== null ?
                <>
                {
                    !nkabWarning ? 
                    <>
                        {
                            nkabMarkTitle === "successful" ? <div className='row'>
                                <div className='d-flex justify-content-center'>
                                    <svg className='svg' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                    </svg>
                                </div>
                                <p className="success p">Opération réussie!</p>
                                <div className='d-flex justify-content-center'><Button className='bolder text-success' onClick={repriseaZero}>Revenir</Button></div>
                            </div>:
                            nkabMarkTitle === "failed" ? <div className='row'>
                                <div className='d-flex justify-content-center'>
                                    <svg className='svg' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                    </svg>
                                </div>
                                <p className="error p">Opération échouée!</p>
                                <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Revenir</Button></div>
                            </div>:
                                <div className='row'>
                                    <div className='d-flex justify-content-center mb-3'>
                                        <div style={{textDecoration:"none"}} className='d-flex color-or pt-4'>
                                            <div>Traitement en cours...</div>
                                            <div className="nkab-spinner"></div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Reprendre l'opération ?</Button></div>
                                </div>
                        }
                    </>
                    :
                    <div>
                        <div className='d-flex justify-content-center'>{nkabWarning}</div>
                        <div className='d-flex justify-content-center'><Button className='bolder text-danger' onClick={repriseaZero}>Revenir</Button></div>
                    </div>
                    
                }
                </>:
                <div className='d-flex justify-content-center mb-5'>
                    {
                        Montant && Phone.length > 9 ? 
                        <span style={{textDecoration:"none"}} className='nkp_popup_link-1 text-center pointer' onClick={handleClickOpen}>Continuer</span>:
                        <span style={{textDecoration:"none"}} className='nkp_popup_link-2 text-center'>Continuer</span>
                    }
                </div>
            }

            <Dialog
                open={open}
                PaperProps={{
                component: 'form',
                }}
            >
                <DialogTitle><span style={{color:"#fff", fontSize:"21px"}}>Code Pin</span></DialogTitle>
                <DialogContent>
                <DialogContentText>
                <span style={{color:"#fff", fontSize:"16px"}}>Saisissez votre code pin à fin de finaliser votre <strong>achat</strong></span>
                </DialogContentText>
                <OutlinedInput
                    autoFocus
                    required
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    margin="dense"
                    id="name"
                    name="cp"
                    label="Code pin"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff sx={{color:"#c7b301"}}/> : <Visibility sx={{color:"#c7b301"}} />}
                        </IconButton>
                        </InputAdornment>
                    }
                    fullWidth
                    variant="standard"
                    placeholder="code pin"
                    sx={{color:"#fff", mb:"7px", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                />
                <OutlinedInput
                    required
                    value={CodePin}
                    onChange={handleChangeInput}
                    margin="dense"
                    id="name"
                    name="cp"
                    label="Code pin"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff sx={{color:"#c7b301"}} /> : <Visibility sx={{color:"#c7b301"}} />}
                        </IconButton>
                        </InputAdornment>
                    }
                    fullWidth
                    variant="standard"
                    placeholder="confirmez le code pin"
                    sx={{color:"#fff", borderRadius:"10px", border:"0.5px solid #fff", width:"100%"}}
                    className='nkab-popup-tex-color'
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} sx={{color:"#c7b301"}}>Revenir</Button>
                <Button sx={{color:"#c7b301"}} onClick={TransferHandler}>Confirmer</Button>
                </DialogActions>
                <DialogActions>
                    <Button onClick={UpdateCodeHandler} sx={{color:"#c7b301"}}>Code oublié ?</Button>
                {
                    MyCodePin.length > 3 ? null : 
                    <Button sx={{color:"#c7b301"}} onClick={NewCodeHandler}>Générer un code</Button>
                }
                </DialogActions>
            </Dialog>
        </div>
        <div></div>
    </div>
  )
}

export default TInterfaceComponent