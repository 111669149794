import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function NextVerificationScreen(props) {

  const Logged                                              = localStorage.getItem('userStatus')
  var   _ProWallet                                          = localStorage.getItem('_ProWallet')

  var   pay                                                 = props.location.hash.slice(10, 100)
  const auth                                                = useSelector((state) => state.auth)
  const { user }                                            = auth

  //React Hook
  const [name,                         setName            ] = useState('null')
  const [lastName,                     setLastName        ] = useState('null')
  const [email,                        setEmail           ] = useState('null')
  const [telNumber,                    setTelNumber       ] = useState('null')
  const [date,                         setDate            ] = useState('null')
  const [validDate,                    setValidDate       ] = useState('null')
  const [expiredDate,                  setExpiredDate     ] = useState('null')
  const [borning,                      setBorning         ] = useState('null')
  const [city,                         setCity            ] = useState('null')
  const [cni,                          setCni             ] = useState('null')
  const [recepisse,                    setRecepisse       ] = useState('null')
  const [consulaire,                   setConsulaire      ] = useState('null')
  const [passport,                     setPassport        ] = useState('null')
  const [country,                      setCountry         ] = useState('null')
  const [waveNumber1,                  setWaveNumber1     ] = useState('null')
  const [waveNumber2,                  setWaveNumber2     ] = useState('null')
  const [waveNumber3,                  setWaveNumber3     ] = useState('null')
  const [waveNumber4,                  setWaveNumber4     ] = useState('null')
  const [countryName,                  setCountryName     ] = useState('')
  var   [ciSelected,                   setCiSelected      ] = useState()
  var   [senSelected,                  setSenSelected     ] = useState()
  var   [togoSelected,                 setTogoSelected    ] = useState()
  var   [burkSelected,                 setBurkSelected    ] = useState()
  var   [beninSelected,                setBeninSelected   ] = useState()


  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(()=>{
    if(Logged === 'Logged'){
      window.location.replace("https://nkab-exchange.net/");
    }
  },[Logged])


  useEffect(() => {
    if (ciSelected === 'pay=ci=selected') {
      props.history.push(`#type=${ciSelected}`)
    } else if (senSelected === 'pay=sen=selected') {
      props.history.push(`#type=${senSelected}`)
    } else if (togoSelected === 'pay=togo=selected') {
      props.history.push(`#type=${togoSelected}`)
    } else if (burkSelected === 'pay=burk=selected') {
      props.history.push(`#type=${burkSelected}`)
    } else if (beninSelected === 'pay=benin=selected') {
      props.history.push(`#type=${beninSelected}`)
    }
  }, [
    props.history,
    beninSelected,
    burkSelected,
    ciSelected,
    senSelected,
    togoSelected,
  ])



  const ciHandler = () => {
    setCiSelected('pay=ci=selected')
    setSenSelected('')
    setTogoSelected('')
    setBurkSelected('')
    setBeninSelected('')
  }
  const senHandler = () => {
    setSenSelected('pay=sen=selected')
    setCiSelected('')
    setTogoSelected('')
    setBurkSelected('')
    setBeninSelected('')
  }
  const togoHandler = () => {
    setTogoSelected('pay=togo=selected')
    setSenSelected('')
    setCiSelected('')
    setBurkSelected('')
    setBeninSelected('')
  }
  const burkHandler = () => {
    setBurkSelected('pay=burk=selected')
    setTogoSelected('')
    setSenSelected('')
    setCiSelected('')
    setBeninSelected('')
  }
  const beninHandler = () => {
    setBeninSelected('pay=benin=selected')
    setBurkSelected('')
    setTogoSelected('')
    setSenSelected('')
    setCiSelected('')
  }

  useEffect(() => {
    if (pay === 'ci=selected') {
      setCountry('+225')
      setCountryName("Côte d'Ivoire")
    }

    if (pay === 'sen=selected') {
      setCountry('+221')
      setCountryName('Sénégal')
    }
    if (pay === 'togo=selected') {
      setCountry('+228')
      setCountryName('Togo')
    }
    if (pay === 'burk=selected') {
      setCountry('+226')
      setCountryName('Burkina Fasso')
    }
    if (pay === 'benin=selected') {
      setCountry('+229')
      setCountryName('Benin')
    }
  }, [pay])

  const cniHandler = () => {
    setCni('CNI')
    setRecepisse('null')
    setConsulaire('null')
    setPassport('null')
  }

  const recepisseHandler = () => {
    setRecepisse('Récépissé')
    setCni('null')
    setConsulaire('null')
    setPassport('null')
  }

  const consulaireHandler = () => {
    setConsulaire('Consulaire')
    setPassport('null')
    setRecepisse('null')
    setCni('null')
  }

  const passportHandler = () => {
    setPassport('Passport')
    setRecepisse('null')
    setCni('null')
    setConsulaire('null')
  }

  const submitHandler = async () => {
    if (user) {
      const userId = user._id
      axios
        .post(
          '/api/api/v1/verification/create-verification',
          {
            name,
            lastName,
            email,
            countryName,
            country,
            telNumber,
            date,
            borning,
            city,
            waveNumber1,
            waveNumber2,
            waveNumber3,
            waveNumber4,
            cni,
            recepisse,
            consulaire,
            passport,
            validDate,
            expiredDate,
            userId,
          },
        )
        .then(
          (response) => {
            if (response.data._id) {
              props.history.push(`/redirect=true/nk-next-verification2/${response.data._id}`)
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="color-w signin-session">
        <form className="form form-register">
          <div className="verif-session">
            <img
              className="register-logo"
              src="../images/logo.png"
              alt="Logo NKAB Exchange"
            />
          </div>
          <div className="register-form-session">
            <div className="pt-4 pb-4">
              <span className="warning-word">
                Afin d'élargir le cercle de paiement, nous mettons à votre
                disposition le service de paiement{' '}
                <strong className="strong-word">WAVE </strong>
                pour plus de flexibilité.
              </span>
              <br />
              <span className="warning-word">
                Pour toute personne souhaitant utiliser le moyen de paiement
                cité pour ses opérations devra faire vérifier son compte{' '}
                <strong className="strong-word">WAVE NKAB EXCHANGE</strong> pour
                prévenir tout type d'arnaque !
              </span>
            </div>
            <div>
              <div className="div-verification">
                <input className="input-verification input-add" type="text" id="name" placeholder="Entrez votre nom" onChange={(e) => setName(e.target.value)} />
                <input className="input-verification input-add" type="text" id="lastname" placeholder="Entrez vos prénoms" onChange={(e) => setLastName(e.target.value)} />
              </div>
              <div className="div-verification">
                <input className="input-verification-mail input-add" type="email" id="email" placeholder="Ex: test@gmail.com" onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="div-verification">
                <div className="dropdown">
                  <button
                    className="btn btn-register dropdown-toggle register-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      fontSize: `${'13px'}`,
                    }}
                  >
                    {!countryName ? (
                      <span className="country-title">Sélectionnez pays</span>
                    ) : countryName ? (
                      <>{countryName}</>
                    ) : null}
                  </button>
                  <div className="dropdown-menu country" aria-labelledby="dropdownMenuButton" >
                    <div className="dropdown-item cursor-pointer" style={{ width: `${'18.5rem'}` }} >
                      <span className="images-drapeau-content" onClick={ciHandler} >
                        <img className="images-drapeau" src="../../images/ci.png" alt="Côte d'Ivoire" />
                      </span>
                      <span className="images-drapeau-title" onClick={ciHandler} >
                        Côte d'Ivoire
                      </span>
                    </div>
                    <div className="dropdown-item cursor-pointer" style={{ width: `${'18.5rem'}` }} >
                      <span className="images-drapeau-content" onClick={senHandler} >
                        <img className="images-drapeau" src="../../images/sen.png" alt="Sénégal" />
                      </span>
                      <span className="images-drapeau-title" onClick={senHandler} >
                        Sénégal
                      </span>
                    </div>
                    <div className="dropdown-item cursor-pointer" style={{ width: `${'18.5rem'}` }} >
                      <span className="images-drapeau-content" onClick={togoHandler} >
                        <img className="images-drapeau" src="../../images/togo.png" alt="Togo" />
                      </span>
                      <span className="images-drapeau-title" onClick={togoHandler} >
                        Togo
                      </span>
                    </div>
                    <div className="dropdown-item cursor-pointer" style={{ width: `${'18.5rem'}` }} >
                      <span className="images-drapeau-content" onClick={burkHandler} >
                        <img className="images-drapeau" src="../../images/burk.png" alt="Burkina Fasso" />
                      </span>
                      <span className="images-drapeau-title" onClick={burkHandler} >
                        Burkina Fasso
                      </span>
                    </div>
                    <div className="dropdown-item cursor-pointer" style={{ width: `${'18.5rem'}` }} >
                      <span className="images-drapeau-content" onClick={beninHandler} >
                        <img className="images-drapeau" src="../../images/benin.png" alt="Bénin" />
                      </span>
                      <span className="images-drapeau-title" onClick={beninHandler} >
                        Bénin
                      </span>
                    </div>
                  </div>
                </div>
                {!country ? (
                  <input className="input-register input-add" type="tel" id="telNumber" placeholder="Téléphone" onChange={(e) => setTelNumber(e.target.value)} />
                ) : country ? (
                  <input className="input-register input-add" type="tel" id="telNumber" placeholder={`${country}`} onChange={(e) => setTelNumber(e.target.value)} />
                ) : null}
              </div>
              <div className="div-verification">
                <input className="input-verification input-add" type="date" id="birthday" placeholder="Date de naissance" onChange={(e) => setDate(e.target.value)} />
                <input className="input-verification input-add" type="text" id="inputCity" placeholder="Lieu de naissance" onChange={(e) => setBorning(e.target.value)} />
              </div>
              <div className="div-verification">
                <input className="input-verification input-add" type="city" id="city" placeholder="Lieu de résidence" onChange={(e) => setCity(e.target.value)} />
              </div>
              <br />
              <br />
              <div>
                <span className="color-or">COMPTES WAVE</span>
              </div>
              <div className="div-verification">
                <span className="color-r">
                  Ces numéros renseignés seront les seuls numéros que vous
                  devriez utiliser pour toutes vos transactions WAVE.
                </span>
              </div>
              <br />
              <div className="div-verification">
                <input className="input-verification input-add" type="text" id="waveNumber1" placeholder="Numéro WAVE 1" onChange={(e) => setWaveNumber1(e.target.value)} />
                <input className="input-verification input-add" type="text" id="waveNumber2" placeholder="Numéro WAVE 2 (optionnel)" onChange={(e) => setWaveNumber2(e.target.value)} />
              </div>
              <div className="div-verification">
                <input className="input-verification input-add" type="text" id="waveNumber3" placeholder="Numéro WAVE 3 (optionnel)" onChange={(e) => setWaveNumber3(e.target.value)} />
                <input className="input-verification input-add" type="text" id="waveNumber4" placeholder="Numéro WAVE 4 (optionnel)" onChange={(e) => setWaveNumber4(e.target.value)} />
              </div>
              <br />
              <br />
              <div>
                <label htmlFor="identityFiles" className="warning-word">
                  Précisez la nature de la pièce
                </label>
                <div className="radio-check">
                  <span>
                    <input className="checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={cniHandler} />{' '}
                    <label className="uppercase color-own2 checkbox" htmlFor="flexRadioDefault1" onClick={cniHandler} >
                      CNI
                    </label>
                  </span>
                  <span>
                    <input className="checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={recepisseHandler} />{' '}
                    <label className="uppercase color-own2 checkbox" htmlFor="flexRadioDefault2" onClick={recepisseHandler} >
                      ONI et Récépissé
                    </label>
                  </span>
                  <span>
                    <input className="checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={consulaireHandler} />{' '}
                    <label className="uppercase color-own2 checkbox" htmlFor="flexRadioDefault3" onClick={consulaireHandler} >
                      Carte consulaire
                    </label>
                  </span>
                  <span>
                    <input className="checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault4" onClick={passportHandler} />{' '}
                    <label className="uppercase color-own2 checkbox" htmlFor="flexRadioDefault4" onClick={passportHandler} >
                      Passport
                    </label>
                  </span>
                </div>
              </div>
              <div className="div-verification radio-check2">
                <label className="warning-word">Date de délivrance</label>
                <label className="warning-word">Date d'expiration</label>
              </div>
              <div className="div-verification">
                <input className="input-verification input-add" type="date" id="validDate" placeholder="Date de délivrance" onChange={(e) => setValidDate(e.target.value)} />
                <input className="input-verification input-add" type="date" id="expiredDate" placeholder="Date d'expiration" onChange={(e) => setExpiredDate(e.target.value)} />
              </div>
            </div>
            <div>
              <label />
              <span type="submit" className="primary-span" onClick={submitHandler} >
                Je valide
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
