import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';

export default function LargeFileSreen(props) {

  var    _ProWallet                                = localStorage.getItem('_ProWallet')
  const  params                                    = useParams()
  const  id                                        = params.id
  const  history                                   = useHistory()
  const [nextWaveVerif,       setNextWaveVerif   ] = useState([])

  const LineLink = true

  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(() => {
    const fetchNextWaveVerifDada = async () => {
        try {
          const { data } = await axios.get(
            `/api/api/v1/next/verification`,
          )
          setNextWaveVerif(data)
        } catch (error) {
          console.log(error)
        }
    }
    fetchNextWaveVerifDada()
  }, [id])

  return (
    <div className='container' style={{backgroundColor:"#90d9ff"}}>
        <section className="py-5 text-center container">
            <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">Vérification d'image</h1>
                <p className="lead text-muted">Ici vous pouvez mieux voir les images de plus près afin de vous assurer une meilleure visibilité.</p>
                <p>
                <span
                onClick={() => history.goBack()}
                style={{ color: '#0a146e', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faBackward} /> {'[Retour]'}
              </span>
                </p>
            </div>
            </div>
        </section>

        <section className="album py-5">
            {
              nextWaveVerif.map((w, index)=>(
                  <div key={index}>
                  {
                      id === w._id ? <img src={w.filePath} className="bs-card-video imgverif" width="423" height="350" alt="" />:null
                  }
                  </div>
              ))
            }
        </section>
    </div>
  )
}
