import { Box, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const CurrencyRateChargeCouvUpdateData = ({themeMode, LineLink}) => {


    const tron        = "../images/tron.png"
    const btc         = "../images/btc.png"
    const ltc         = "../images/ltc.png"
    const usdt        = "../images/usdt.png"
    const doge        = "../images/doge.png"
    const busd        = "../images/busd.png"
    const shiba       = "../images/shiba.png"
    const polygonmatic    = "../images/polygonmatic.png"
    const ethereum        = "../images/eth.png"
    const chainlink       = "../images/chainlink.png"
    const polkadot        = "../images/pokadot.png"
    const cardano         = "../images/cardano.png"
    const solana          = "../images/solana.png"
    const pm              = "../images/pm.png"
    const bnb             = "../images/bnb.png"
    const ton             = "../images/ton.png"


    const [BNBChargeStop, setBNBChargeStop] = useState(false)
    const [NT_ChargeBNB, setNT_ChargeBNB] = useState([])
    
    const [BTCChargeStop, setBTCChargeStop] = useState(false)
    const [NT_ChargeBTC, setNT_ChargeBTC] = useState([])

    const [TRONChargeStop, setTRONChargeStop] = useState(false)
    const [NT_ChargeTRON, setNT_ChargeTRON] = useState([])

    const [USDTChargeStop, setUSDTChargeStop] = useState(false)
    const [NT_ChargeUSDT, setNT_ChargeUSDT] = useState([])

    const [USDTCharge2Stop, setUSDTCharge2Stop] = useState(false)
    const [NT_Charge2USDT, setNT_Charge2USDT] = useState([])

    const [LINKChargeStop, setLINKChargeStop] = useState(false)
    const [NT_ChargeLINK, setNT_ChargeLINK] = useState([])

    const [MATICChargeStop, setMATICChargeStop] = useState(false)
    const [NT_ChargeMATIC, setNT_ChargeMATIC] = useState([])

    const [DOTEChargeStop, setDOTEChargeStop] = useState(false)
    const [NT_ChargeDOTE, setNT_ChargeDOTE] = useState([])

    const [BUSDChargeStop, setBUSDChargeStop] = useState(false)
    const [NT_ChargeBUSD, setNT_ChargeBUSD] = useState([])

    const [PMChargeStop, setPMChargeStop] = useState(false)
    const [NT_ChargePM, setNT_ChargePM] = useState([])

    const [SOLChargeStop, setSOLChargeStop] = useState(false)
    const [NT_ChargeSOL, setNT_ChargeSOL] = useState([])

    const [ADAChargeStop, setADAChargeStop] = useState(false)
    const [NT_ChargeADA, setNT_ChargeADA] = useState([])

    const [ETHChargeStop, setETHChargeStop] = useState(false)
    const [NT_ChargeETH, setNT_ChargeETH] = useState([])

    const [SHIBAChargeStop, setSHIBAChargeStop] = useState(false)
    const [NT_ChargeSHIBA, setNT_ChargeSHIBA] = useState([])

    const [DOGEChargeStop, setDOGEChargeStop] = useState(false)
    const [NT_ChargeDOGE, setNT_ChargeDOGE] = useState([])

    const [LTCChargeStop, setLTCChargeStop] = useState(false)
    const [NT_ChargeLTC, setNT_ChargeLTC] = useState([])

    const [TONChargeStop, setTONChargeStop] = useState(false)
    const [NT_ChargeTON, setNT_ChargeTON] = useState([])

    const [BTCModifCharge, setBTCModifCharge] = useState(false)
    const [TRONModifCharge, setTRONModifCharge] = useState(false)
    const [LTCModifCharge, setLTCModifCharge] = useState(false)
    const [USDTModifCharge, setUSDTModifCharge] = useState(false)
    const [USDTModifCharge2, setUSDTModifCharge2] = useState(false)
    const [DOGEModifCharge, setDOGEModifCharge] = useState(false)
    const [BUSDModifCharge, setBUSDModifCharge] = useState(false)
    const [SHIBAModifCharge, setSHIBAModifCharge] = useState(false)
    const [MATICModifCharge, setMATICModifCharge] = useState(false)
    const [ETHModifCharge, setETHModifCharge] = useState(false)
    const [LINKModifCharge, setLINKModifCharge] = useState(false)
    const [DOTModifCharge, setDOTModifCharge] = useState(false)
    const [ADAModifCharge, setADAModifCharge] = useState(false)
    const [SOLModifCharge, setSOLModifCharge] = useState(false)
    const [PMModifCharge, setPMModifCharge] = useState(false)
    const [BNBModifCharge, setBNBModifCharge] = useState(false)
    const [TONModifCharge, setTONModifCharge] = useState(false)

    function customChargeBTCHandler(){ setBTCModifCharge(!BTCModifCharge) }
    function customChargeTRONHandler(){ setTRONModifCharge(!TRONModifCharge) }
    function customChargeLTCHandler(){ setLTCModifCharge(!LTCModifCharge) }
    function customChargeUSDTHandler(){ setUSDTModifCharge(!USDTModifCharge) }
    function customCharge2USDTHandler(){ setUSDTModifCharge2(!USDTModifCharge2) }
    function customChargeDOGEHandler(){ setDOGEModifCharge(!DOGEModifCharge) }
    function customChargeBUSDHandler(){ setBUSDModifCharge(!BUSDModifCharge) }
    function customChargeSHIBAHandler(){ setSHIBAModifCharge(!SHIBAModifCharge) }
    function customChargeMATICHandler(){ setMATICModifCharge(!MATICModifCharge) }
    function customChargeETHHandler(){ setETHModifCharge(!ETHModifCharge) }
    function customChargeLINKHandler(){ setLINKModifCharge(!LINKModifCharge) }
    function customChargeDOTHandler(){ setDOTModifCharge(!DOTModifCharge) }
    function customChargeADAHandler(){ setADAModifCharge(!ADAModifCharge) }
    function customChargeSOLHandler(){ setSOLModifCharge(!SOLModifCharge) }
    function customChargePMHandler(){ setPMModifCharge(!PMModifCharge) }
    function customChargeBNBHandler(){ setBNBModifCharge(!BNBModifCharge) }
    function customChargeTONHandler(){ setTONModifCharge(!TONModifCharge) }

    const [CurrencyHandle, setCurrencyHandle] = useState()

    const currencyHandleChange = ({target: {value}}) => {
      setCurrencyHandle(value)
    }

    const validateBTCChargeHandler = () => {

        setBTCModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargebtc', 
                    {
                        ID: 1,
                        btc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeTRONHandler = () => {

        setTRONModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargetron', 
                    {
                        ID: 1,
                        tron: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateChargeLTCHandler = () => {

        setLTCModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeltc', 
                    {
                        ID: 1,
                        ltc: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }
    }

    const validateChargeUSDTHandler = () => {

        setUSDTModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeusdt', 
                    {
                        ID: 1,
                        usdt: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }
    const validateCharge2USDTHandler = () => {

        setUSDTModifCharge2(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeusdt2', 
                    {
                        ID: 1,
                        usdt: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeDOGEHandler = () => {

        setDOGEModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargedoge', 
                    {
                        ID: 1,
                        doge: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeBUSDHandler = () => {

        setBUSDModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargebusd', 
                    {
                        ID: 1,
                        busd: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeSHIBAHandler = () => {

        setSHIBAModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeshiba', 
                    {
                        ID: 1,
                        shiba: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeMATICHandler = () => {

        setMATICModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargematic', 
                    {
                        ID: 1,
                        matic: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeETHHandler = () => {

        setETHModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeeth', 
                    {
                        ID: 1,
                        eth: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeLINKHandler = () => {

        setLINKModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargelink', 
                    {
                        ID: 1,
                        link: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeDOTHandler = () => {

        setDOTModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargedote', 
                    {
                        ID: 1,
                        dote: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeADAHandler = () => {

        setADAModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeada', 
                    {
                        ID: 1,
                        ada: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeSOLHandler = () => {

        setSOLModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargesol', 
                    {
                        ID: 1,
                        sol: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargePMHandler = () => {

        setPMModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargepm', 
                    {
                        ID: 1,
                        pm: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeBNBHandler = () => {

        setBNBModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargebnb', 
                    {
                        ID: 1,
                        bnb: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }

    const validateChargeTONHandler = () => {

        setTONModifCharge(false)

        if(CurrencyHandle !== ""){

            try {
    
                axios.post('/api/update-nt-chargeton', 
                    {
                        ID: 1,
                        ton: CurrencyHandle,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                    }
                    ).then((res)=>{
                        if(res.data.msg === "success"){
    
                            if(LineLink){
                            window.location.replace("https://nkab-exchange.net/dashboard");
                            } else {
                            window.location.replace("http://localhost:3000/dashboard");
                            }
                        }
                    })
    
            } catch (err) {}
        }

    }



    //FETCH CHARGE DATA
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BTCChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargebtc')

                    if(res.data[0].ID){
                        setNT_ChargeBTC(res.data[0].btc)
                        setBTCChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BTCChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TRONChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargetron')

                    if(res.data[0].ID){
                        setNT_ChargeTRON(res.data[0].tron)
                        setTRONChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TRONChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LTCChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargeltc')

                    if(res.data[0].ID){
                        setNT_ChargeLTC(res.data[0].ltc)
                        setLTCChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LTCChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!USDTChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargeusdt')

                    if(res.data[0].ID){
                        setNT_ChargeUSDT(res.data[0].usdt)
                        setUSDTChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[USDTChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!USDTCharge2Stop){

                try {
                    const res = await axios.get('/api/nt-chargeusdt2')

                    if(res.data[0].ID){
                        setNT_Charge2USDT(res.data[0].usdt)
                        setUSDTCharge2Stop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[USDTCharge2Stop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOGEChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargedoge')

                    if(res.data[0].ID){
                        setNT_ChargeDOGE(res.data[0].doge)
                        setDOGEChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOGEChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BUSDChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargebusd')

                    if(res.data[0].ID){
                        setNT_ChargeBUSD(res.data[0].busd)
                        setBUSDChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BUSDChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SHIBAChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargeshiba')

                    if(res.data[0].ID){
                        setNT_ChargeSHIBA(res.data[0].shiba)
                        setSHIBAChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SHIBAChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!MATICChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargematic')

                    if(res.data[0].ID){
                        setNT_ChargeMATIC(res.data[0].matic)
                        setMATICChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[MATICChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ETHChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargeeth')

                    if(res.data[0].ID){
                        setNT_ChargeETH(res.data[0].eth)
                        setETHChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ETHChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!LINKChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargelink')

                    if(res.data[0].ID){
                        setNT_ChargeLINK(res.data[0].link)
                        setLINKChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[LINKChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!DOTEChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargedote')

                    if(res.data[0].ID){
                        setNT_ChargeDOTE(res.data[0].dote)
                        setDOTEChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[DOTEChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!ADAChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargeada')

                    if(res.data[0].ID){
                        setNT_ChargeADA(res.data[0].ada)
                        setADAChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[ADAChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!SOLChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargesol')

                    if(res.data[0].ID){
                        setNT_ChargeSOL(res.data[0].sol)
                        setSOLChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[SOLChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!PMChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargepm')

                    if(res.data[0].ID){
                        setNT_ChargePM(res.data[0].pm)
                        setPMChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[PMChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!BNBChargeStop){

                try {
                    const res = await axios.get('/api/nt-chargebnb')

                    if(res.data[0].ID){
                        setNT_ChargeBNB(res.data[0].bnb)
                        setBNBChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[BNBChargeStop])
    
    useEffect(()=>{

        const fetchData = async () => {

            if(!TONChargeStop){

                try {
                    const res = await axios.get('/api/nt-Chargeton')

                    if(res.data[0].ID){
                        setNT_ChargeTON(res.data[0].ton)
                        setTONChargeStop(true)
                    }
                } catch (error) {
                }
            }
        }
        fetchData()

    },[TONChargeStop])



  return (

    <div className={`${themeMode === "true" ? "row mt-3 final-seesion-t" : "row mt-3 final-seesion"}`}>
        <div className='center'>
            <div><span className={`${themeMode === "true" ? "color-or" : ""}`}>Données variantes des <strong>charges de couverture</strong></span></div>
            <hr className={`${themeMode === "true" ? "color-or" : ""}`}/>
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Bitcoin (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {BTCModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateBTCChargeHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BTCModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="btc" label="XXXXX BTC" name="btc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeBTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeBTC}</span>
                            </div>
                        } 
                    </div>
                </div>
            </div>
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Tron (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {TRONModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeTRONHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            TRONModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="tron" label="XXXXX TRON" name="tron" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeTRONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeTRON}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>  
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Litecoin (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {LTCModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeLTCHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            LTCModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ltc" label="XXXXX LTC" name="ltc" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeLTCHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeLTC}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>  
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Tether (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {USDTModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeUSDTHandler}>Valider</span> : null}
                    {USDTModifCharge2 ? <span className='stat-see-card-detail-achat' onClick={validateCharge2USDTHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            USDTModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : USDTModifCharge2 ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeUSDTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeUSDT} (trc20)</span>
                            </div>
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            USDTModifCharge2 ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="usdt" label="XXXXX USDT" name="usdt" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : USDTModifCharge ? null :
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customCharge2USDTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_Charge2USDT} (bep20)</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Dogecoin (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {DOGEModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeDOGEHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            DOGEModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="doge" label="XXXXX DOGE" name="doge" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeDOGEHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeDOGE}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Binance usd (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {BUSDModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeBUSDHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BUSDModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="busd" label="XXXXX BUSD" name="busd" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeBUSDHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeBUSD}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Shiba (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {SHIBAModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeSHIBAHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            SHIBAModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="shiba" label="XXXXX SHIBA" name="shiba" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeSHIBAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeSHIBA}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Polygon matic (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {MATICModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeMATICHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            MATICModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="matic" label="XXXXX MATIC" name="matic" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeMATICHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeMATIC}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Ethereum (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {ETHModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeETHHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            ETHModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="eth" label="XXXXX ETH" name="eth" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeETHHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeETH}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Chain link (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {LINKModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeLINKHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            LINKModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="link" label="XXXXX LINK" name="link" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeLINKHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeLINK}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Polkadot (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {DOTModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeDOTHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            DOTModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="dot" label="XXXXX DOT" name="dot" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeDOTHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeDOTE}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Cardano (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {ADAModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeADAHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'> 
                        {
                            ADAModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ada" label="XXXXX ADA" name="ada" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeADAHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeADA}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Solana (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {SOLModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeSOLHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            SOLModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="sol" label="XXXXX SOL" name="sol" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeSOLHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeSOL}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Perfect Money (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {PMModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargePMHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            PMModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="pm" label="XXXXX PM" name="pm" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargePMHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargePM}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Binance Smart Chain (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {BNBModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeBNBHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            BNBModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="bnb" label="XXXXX BNB" name="bnb" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeBNBHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeBNB}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                    <img className='images-drapeau mr-2' src={`../${ton}`} alt="" />
                    <span className='ml-2 stat-see-card-detail-bnt'>Toncoin (<strong style={{color:"#ff2020"}}>couv+</strong>)</span>
                    {TONModifCharge ? <span className='stat-see-card-detail-achat' onClick={validateChargeTONHandler}>Valider</span> : null}
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                        {
                            TONModifCharge ? 
                            <div>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField fullWidth id="ton" label="XXXXX TON" name="ton" autoFocus onChange={currencyHandleChange}/>
                                </Box>
                            </div> : 
                            <div>
                                <span className='stat-see-card-detail-achat' onClick={customChargeTONHandler}>Modifier</span>
                                <span className='ml-2' style={{fontSize:"16px"}}>$ {NT_ChargeTON}</span>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div>
        {/* <div className='col-md-3'>
            <div className='stat-card'>
                <div className='stat-card-title'>
                </div>
                <div className='d-flex justify-content-around'>
                    <div className='stat-card-content'>
                    </div>
                </div>
            </div> 
        </div> */}
    </div>
  )
}

export default CurrencyRateChargeCouvUpdateData