import { faBackward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import DomLoading from '../components/loadings/DomLoading/DomLoading'
import BottomNavBar from '../components/BottomNavBar'

export default function SendUsdtOrBscAfterSuccessByAdmin(props) {

  var   _ProWallet                     = localStorage.getItem('_ProWallet')
  const Logged                         = localStorage.getItem('userStatus')
  const role                           = localStorage.getItem('role')
  const auth                           = useSelector((state) => state.auth) 
  const params                         = useParams()
  const history                        = useHistory()
  const orderId                        = params.id
  const { user }                       = auth

  const [orderData,                      setOrderData                  ] = useState()
  const [finalPrice,                     setFinalPrice                 ] = useState()
  const [editMoney,                      setEditMoney                  ] = useState(false)
  const [Montant,                        setMontant                    ] = useState(0)
  const [total,                          setTotal                      ] = useState()
  const [last_currency_char_nbr,         setLast_currency_char_nbr     ] = useState()
  const [montantTotal,                   setMontantTotal               ] = useState()
  const [stopExchangeCount,              setStopExchangeCount          ] = useState(false)


  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }

    if (Number(role) !== 1) {
      window.location.replace("https://nkab-exchange.net/");
    }
  },[Logged, role])
  


  useEffect(() => {
    if (Montant === 0) {
      setTotal(Number(montantTotal))
      const currency_nbr = total
      if (currency_nbr) {
        const currency_nbr_to_text = currency_nbr.toString()
        const currency_nbr_last_char = currency_nbr_to_text.slice(-1)
        setLast_currency_char_nbr(Number(currency_nbr_last_char))
      }
    } else if (Montant > 0) {
      setTotal(Number(Montant))
      const currency_nbr = total
      if (currency_nbr) {
        const currency_nbr_to_text = currency_nbr.toString()
        const currency_nbr_last_char = currency_nbr_to_text.slice(-1)
        setLast_currency_char_nbr(Number(currency_nbr_last_char))
      }
    }
  }, [Montant, montantTotal, total])

  useEffect(() => {
    if (stopExchangeCount === false) {
      if (last_currency_char_nbr === 0) {
        const FinalCount = total
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 5) {
        const FinalCount = total
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 1) {
        const FinalCount = total - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 2) {
        const FinalCount = total - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 3) {
        const FinalCount = total - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 4) {
        const FinalCount = total - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 5) {
        const FinalCount = total + 0
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 6) {
        const FinalCount = total - 1
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 7) {
        const FinalCount = total - 2
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 8) {
        const FinalCount = total - 3
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }

      if (last_currency_char_nbr === 9) {
        const FinalCount = total - 4
        setFinalPrice(FinalCount)
        setStopExchangeCount(true)
      }
    }
  }, [last_currency_char_nbr, total, stopExchangeCount, finalPrice])

  useEffect(() => {
    const fetchorderUnic2Data = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/orders/${orderId}`,
        )
        setOrderData(data)
        setMontantTotal(data.exchange[0].cfa)
      } catch (error) {
        console.log(error)
      }
    }
    fetchorderUnic2Data()
  }, [orderId])


  const sendaftersuccessbyadminhandler = async () => {
    if (
      orderData._id &&
      orderData.operatorUserInfoId &&
      orderData.serviceName &&
      orderData.exchange[0].converted &&
      orderData.exchange[0].dollar &&
      orderData.operatorListName &&
      orderData.operatorUserInfoCountry
    ) {
      axios
        .post(
          `/api/v1/orderconfirmed/create-orderconfirmed`,
          {
            ccb_user_id: orderData.operatorUserInfoId,
            ccb_charge_id: 'No charge',
            ccb_code: 'No code',
            ccb_order_id: orderData._id,
            ccb_status_id: 'Quantité envoyée',
            finalprice: finalPrice,
            order_name: orderData.serviceName,
            order_converted: orderData.exchange[0].converted,
            order_dollar: orderData.exchange[0].dollar,
            operator_name: orderData.operatorListName,
            order_country: orderData.operatorUserInfoCountry,
          },
        )
        .then(
          (response) => {
            if (response.data._id) {
              window.location.replace('https://nkab-exchange.net/operation')
              // props.history.push(`/operation`)
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  const canselbyadminhandler = async () => {
    if (
      orderData._id &&
      orderData.operatorUserInfoId &&
      orderData.serviceName &&
      orderData.exchange[0].converted &&
      orderData.exchange[0].dollar &&
      orderData.operatorListName &&
      orderData.operatorUserInfoCountry
    ) {
      axios
        .post(
          `/api/v1/orderconfirmed/create-orderconfirmed`,
          {
            ccb_user_id: orderData.operatorUserInfoId,
            ccb_charge_id: 'No charge',
            ccb_code: 'No code',
            ccb_order_id: orderData._id,
            ccb_status_id: 'Envoi de quantité annulé',
            finalprice: finalPrice,
            order_name: orderData.serviceName,
            order_converted: orderData.exchange[0].converted,
            order_dollar: orderData.exchange[0].dollar,
            operator_name: orderData.operatorListName,
            order_country: orderData.operatorUserInfoCountry,
          },
        )
        .then(
          (response) => {
            if (response.data._id) {
              props.history.push(`/operation`)
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  function editedbyadminhandler() {
    setEditMoney(true)
  }

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="py-5 text-center">
        <img
          className="d-block mx-auto mb-4"
          src={user.avatar}
          alt=""
          width="100"
          height="80"
        />
        <h2>NKAB CREDIT</h2>
        <div className="lead">
          Vous êtes sur le point d'envoyer{' '}
          <strong className="money-font">
            {' '}
            {!orderData ? <DomLoading /> : orderData.serviceName === 'Binance Smart Chain' ? (
              <>{orderData.exchange[0].converted} BNB </>
            ) : orderData.serviceName === 'Tether || Tether(USDT)' ? (
              <>$ {orderData.exchange[0].converted} </>
            ) : orderData.serviceName === 'TRON' ? (
              <>{orderData.exchange[0].converted} TRX</>
            ) : <DomLoading />}{' '}
          </strong>{' '}
          à l'adresse{' '}
          <strong className="money-font">
            {' '}
            {!orderData ? <DomLoading /> : orderData.adr_Coin}{' '}
          </strong>{' '}
          portant le nom de{' '}
          <strong className="money-font">
            {' '}
            {!orderData ? <DomLoading /> : ( <> {orderData.operatorUserInfoLastName}{' '} {orderData.operatorUserInfoName} </> )}
          </strong>{' '}
          après qu'il ait effectué une opération de vente de{' '}
          <strong className="money-font">
            {!orderData ? <DomLoading /> : orderData.serviceName}
          </strong>{' '}
          au taux de{' '}
          <strong className="money-font">
            {!orderData ? <DomLoading /> : orderData.rate} XOF{' '}
          </strong>
          ayant pour quantité{' '}
          <strong className="money-font">
            {!orderData ? <DomLoading /> : 
              <>
              {
                orderData.serviceSymbol === "USDT" ? 
                <>{(orderData.exchange[0].dollar * 1).toFixed(2)}$ {orderData.serviceSymbol}</>:
                orderData.serviceSymbol === "PAYEER" ? 
                <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                orderData.serviceSymbol === "PM" ? 
                <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                orderData.serviceSymbol === "trx" ? 
                <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                <>{(orderData.exchange[0].converted * 1).toFixed(5)} {orderData.serviceSymbol}</>
              }
              </>
            }
          </strong>{' '}
          <strong className="money-font">
            {!orderData ? <DomLoading /> : 
            orderData.serviceSymbol === 'USDT' ? (
              (orderData.exchange[0].dollar * 1).toFixed(0)
            ) : orderData.serviceSymbol === 'BNB' || orderData.serviceSymbol === 'trx' ? (
              <>{orderData.exchange[0].converted}</>
            ) : <DomLoading />}
          </strong>
          <strong className="money-font">
            {!orderData ? <DomLoading /> : (
              <> {' '} {orderData.serviceSymbol !== 'USDT' ? orderData.serviceSymbol : <DomLoading />} </>
            )}
          </strong>
          .
        </div>
        <br />
        <div className="withdrav-content">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary">
              {!orderData ? <DomLoading /> : 
              orderData.serviceName === 'Binance Smart Chain' ? (
                <>{orderData.exchange[0].converted} BNB </>
              ) : 
              orderData.serviceName === 'TRON' ? (
                <>{orderData.exchange[0].converted} TRX </>
              ) : orderData.serviceName === 'Tether || Tether(USDT)' ? (
                <>$ {orderData.exchange[0].converted} </>
              ) : <DomLoading />}
            </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Produit</h6>
              </div>
              <span className="text-muted">
                {!orderData ? <DomLoading /> : orderData.serviceName}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0 text-success">Quantité</h6>
              </div>
              <span className="text-success">
                {!orderData ? <DomLoading /> : 
                  <>
                  {
                    orderData.serviceSymbol === "USDT" ? 
                    <>{(orderData.exchange[0].dollar * 1).toFixed(2)}$ {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "PAYEER" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "PM" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    orderData.serviceSymbol === "trx" ? 
                    <>{(orderData.exchange[0].converted * 1).toFixed(2)} {orderData.serviceSymbol}</>:
                    <>{(orderData.exchange[0].converted * 1).toFixed(5)} {orderData.serviceSymbol}</>
                  }
                  </>
                }
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Taux</h6>
              </div>
              <span className="text-muted">
                {!orderData ? <DomLoading /> : orderData.rate} XOF
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>
                Total (
                {!orderData
                  ? <DomLoading />
                  : orderData.serviceSymbol === 'USDT'
                  ? '$'
                  : orderData.serviceSymbol === 'BNB'
                  ? 'BNB'
                  : orderData.serviceSymbol === 'trx'
                  ? 'TRX'
                  : <DomLoading />}
                )
              </span>
              <strong>
                {editMoney ? (
                  <input
                    type="number"
                    placeholder="Entrez un nouveau montant"
                    onChange={(e) => setMontant(e.target.value)}
                  />
                ) : (
                  <>
                    {!orderData ? <DomLoading /> : orderData.serviceSymbol === 'USDT' ? (
                      <>$ {(orderData.exchange[0].dollar * 1).toFixed(0)}</>
                    ) : orderData.serviceSymbol === 'BNB' ? (
                      <>{orderData.exchange[0].converted} BNB</>
                    ) : orderData.serviceSymbol === 'trx' ? (
                      <>{orderData.exchange[0].converted} TRX</>
                    ) : <DomLoading />}
                  </>
                )}
              </strong>
            </li>
          </ul>

          <span className="card p-2">
            <div className="input-group">
              <span
                className="w-100 btn btn-primary btn-lg"
                onClick={sendaftersuccessbyadminhandler}
              >
                Approuver l'envoi maintenant
              </span>
            </div>
          </span>

          <span className="p-2 d-flex justify-content-between">
            <div className="input-group">
              <button
                className="w-100 btn bg-danger btn-lg"
                type="submit"
                onClick={canselbyadminhandler}
              >
                Annuler l'envoi
              </button>
            </div>
            <div className="input-group">
              <button
                className="w-100 btn bg-or btn-lg"
                type="submit"
                onClick={editedbyadminhandler}
              >
                Editer la quantité
              </button>
            </div>
          </span>

          <span className="p-2 d-flex justify-content-between">
            <button type="button" className="btn bg-or btn-lg px-4 gap-3" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faBackward} /> {'Retour'}
            </button>
          </span>
        </div>
      </div>
      <BottomNavBar />
    </div>
  )
}
