import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function NoServices() {
  return (
    <section className='service-indisponible'>
        <div className='text-center'>
            <img src="../../images/no-service.png" width="200" alt="" />
        </div>
      <div className='text-center'>
        <h3 className='danger blink' style={{marginTop:'10px'}}>Service bientôt disponible !</h3>
        <div className="error-actions">
          <a href="/" className="btn btn-primary btn-lg mt-2" style={{backgroundColor: `${'#0a146e'}`}}>
            <span className="glyphicon glyphicon-home">
              <FontAwesomeIcon icon={faHome} />
            </span>
              {' '} Revenir à l'accueil 
          </a>
          <a href="/contact" className="btn btn-default btn-lg mt-2" style={{color: `${'#ffffff'}`}}>
            <span className="glyphicon glyphicon-envelope"></span> 
            Contact Support 
          </a>
        </div>
        <p className='color-white'>Statut : <span className='color-or'>Encours...</span></p>
      </div>
    </section>
  )
}
