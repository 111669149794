import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './NewServiceScreen.css'

const NewServiceScreen = (props) => {

  const Logged                                              = localStorage.getItem('userStatus')
  var   _ProWallet                                          = localStorage.getItem('_ProWallet')

  const [serviceName,                setServiceName       ] = useState('')
  const [serviceSymbol,              setServiceSymbol     ] = useState('')
  const [serviceImageLink,           setServiceImageLink  ] = useState('')

  const LineLink = true


  if (_ProWallet === 'true') {

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/nkab-pro");
    } else {
       window.location.replace("http://localhost:3000/nkab-pro");
    }
  }

  useEffect(()=>{
    if(Logged === 'Logout'){

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
  },[Logged])


  const handleSubmit = () => {
    const isActivated = 0
    axios.post('/api/api/v1/create-moneyservices', {
        serviceName,
        serviceSymbol,
        serviceImageLink,
        isActivated,
        createdAt: new Date(),
        updatedAt: new Date(),
      })
      .then(
        (response) => {
          if(response.data.msg === "success"){
            props.history.push('/dashboard')
          }
        },
        (error) => {
          console.log(error)
        },
      )
  }

  return (
    <div>
      <div className="container" style={{backgroundColor:"#90d9ff"}}>
        <div className="">
          <div className="d-flex justify-content-center">
            <h1 className="user-middle-big-text service-elment">
              Création de nouvelle crypto monnaie d'achat et de vente.
            </h1>
          </div>
          <div className="color-w services-session">
            <div className="form d-flex">
              <div className="services-logo-session">
                <img
                  className="register-logo"
                  src="../images/logo.png"
                  alt="Logo NKAB Exchange"
                />
              </div>
              <div className="services-input p-4">
                <div className="services-input-div">
                  <input
                    className="input-register"
                    style={{color:'#ffffff'}}
                    type="text"
                    id="name"
                    placeholder="Ex: Bitcoin"
                    required
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </div>
                <div className="services-input-div">
                  <input
                    className="input-register"
                    style={{color:'#ffffff'}}
                    type="text"
                    id="symbol"
                    placeholder="Ex: BTC"
                    required
                    onChange={(e) => setServiceSymbol(e.target.value)}
                  />
                </div>
                <div className="services-input-div">
                  <input
                    className="input-register"
                    style={{color:'#ffffff'}}
                    type="text"
                    id="link"
                    placeholder="Ex: ../images/btc.png"
                    required
                    onChange={(e) => setServiceImageLink(e.target.value)}
                  />
                </div>
                <div>
                  <button className="primary" onClick={handleSubmit}> Je valide </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewServiceScreen
