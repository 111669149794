import { MoneyOutlined, Send } from '@mui/icons-material';
import { Alert, Autocomplete, Avatar, Box, Button, Container, CssBaseline, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React, { useState } from 'react'
import { uid } from 'uid';

const defaultTheme = createTheme();

const ForAllTest = () => {

    const SenderCountry       = localStorage.getItem('SenderCountry')

    const [NkabSpinner,  setNkabSpinner] = useState(false)

    const [OmOptionClicked, setOmOptionClicked] = useState("")
    const [MomoOptionClicked, setMomoOptionClicked] = useState("")
    const [FloozOptionClicked, setFloozOptionClicked] = useState("")
    const [FmOptionClicked, setFmOptionClicked] = useState("")
    const [TmOptionClicked, setTmOptionClicked] = useState("")
    const [WaveOptionClicked, setWaveOptionClicked] = useState("")
    const [OpSelcted, setOpSelcted] = useState(false)
    const [operatorNames, setOperatorNames ] = useState()
    const [Country, setCountry ] = useState("")
    const [Montant, setMontant ] = useState(0)
    const [Phone, setPhone ] = useState("")
    const [BankCode, setBankCode ] = useState("")
    const [AccountOwner, setAccountOwner ] = useState("")
    const [AccountNumber, setAccountNumber ] = useState("")
    const [NkabProvider, setNkabProvider ] = useState("")
    const [BankOptionClicked, setBankOptionClicked ] = useState("")
    const [NotifId, setNotifId ] = useState("")
    const [NotifToken, setNotifToken ] = useState("")
    const [NotifActif, setNotifActif ] = useState(false)
    const [NotifOtpActif, setNotifOtpActif ] = useState(false)
    const [NkabOtp, setNkabOtp ] = useState("")
    const [Confirmation, setConfirmation ] = useState("")

    const countries = [
      { code: 'CI', label: "Côte d'Ivoire", phone: '225' },
      { code: 'ML', label: "Mali", phone: '223' },
      { code: 'SN', label: "Senegal", phone: '221' },
      { code: 'TG', label: "Togo", phone: '228' },
      { code: 'BF', label: "Burkina Fasso", phone: '226' },
      { code: 'BJ', label: "Benin", phone: '229' },
      { code: 'NE', label: "Niger", phone: '227' },
    ];

    const ci = "Côte d'Ivoire"
    const sen = "Senegal"
    const burk = "Burkina Fasso"
    const benin = "Benin"
    const togo = "Togo"
    const mali = "Mali"
    const niger = "Niger"

    const _ci = "Côte d'Ivoire"
    const _sen = "Sénégal"
    const _burk = "Burkina Fasso"
    const _benin = "Bénin"
    const _togo = "Togo"
    const _mali = "Mali"
    const _niger = "Niger"

    const Frais = 10

    const checkCountryHanlder = (e) => {
        setCountry(e.target.textContent)
    }

    const checkMontantHanlder = (e) => {
        setMontant(e.target.value)
    }

    const checkPhoneHanlder = (e) => {
        setPhone(e.target.value)
    }

    const checkBankCodeHanlder = (e) => {
        setBankCode(e.target.value)
    }

    const checkAccountOwnerHanlder = (e) => {
        setAccountOwner(e.target.value)
    }

    const checkAccountNumberHanlder = (e) => {
        setAccountNumber(e.target.value)
    }

    const checkNkabOtpHanlder = (e) => {
        setNkabOtp(e.target.value)
    }

    const checkConfirmationHanlder = (e) => {
        setConfirmation(e.target.value)
    }
  
    function OrangeHanlder(){
      setNkabSpinner(false)
      setOperatorNames('Orange Money')
      setNkabProvider('orange')
      setOmOptionClicked("nkp_bank_option_clicked")
      setMomoOptionClicked("")
      setFloozOptionClicked("")
      setFmOptionClicked("")
      setTmOptionClicked("")
      setWaveOptionClicked("")
      setOpSelcted(true)
    }
    function MobileHanlder(){
      setNkabSpinner(false)
      setOperatorNames('Mobile Money')
      setNkabProvider('mtn')
      setOmOptionClicked("")
      setMomoOptionClicked("nkp_bank_option_clicked")
      setFloozOptionClicked("")
      setFmOptionClicked("")
      setTmOptionClicked("")
      setWaveOptionClicked("")
      setOpSelcted(true)
    }
    function MoovHanlder(){
      setNkabSpinner(false)
      setOperatorNames('Moov Money')
      setNkabProvider('moov')
      setOmOptionClicked("")
      setMomoOptionClicked("")
      setFloozOptionClicked("nkp_bank_option_clicked")
      setFmOptionClicked("")
      setTmOptionClicked("")
      setWaveOptionClicked("")
      setOpSelcted(true)
    }
    function WaveHanlder(){
      setNkabSpinner(false)
      setOperatorNames('Wave')
      setNkabProvider('wave')
      setOmOptionClicked("")
      setMomoOptionClicked("")
      setFmOptionClicked("")
      setTmOptionClicked("")
      setFloozOptionClicked("")
      setWaveOptionClicked("nkp_bank_option_clicked")
    }
    function FreeHanlder(){
      setNkabSpinner(false)
      setOperatorNames('Free Money')
      setNkabProvider('free')
      setOmOptionClicked("")
      setMomoOptionClicked("")
      setFmOptionClicked("nkp_bank_option_clicked")
      setTmOptionClicked("")
      setFloozOptionClicked("")
      setWaveOptionClicked("")
      setOpSelcted(true)
    }
    function TMHanlder(){
      setNkabSpinner(false)
      setOperatorNames('TMoney')
      setNkabProvider('tmoney')
      setOmOptionClicked("")
      setMomoOptionClicked("")
      setFmOptionClicked("")
      setTmOptionClicked("nkp_bank_option_clicked")
      setFloozOptionClicked("")
      setWaveOptionClicked("")
      setOpSelcted(true)
    }


    //Test de transaction mobile
    function handleTransactionMobile(){

      setNkabSpinner(true)
  
      var myHeaders = new Headers();

      myHeaders.append("merchantId",    "o3XPUfGPrmspx4AQoWLmr");
      myHeaders.append("environment",   "live");
      myHeaders.append("Content-Type",  "application/json");
      myHeaders.append("ApiKey",        "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");
  
      var raw = JSON.stringify({
        "reference": `ref_${uid(8)}`,
        "amount": Number(Montant),
        "currency": "XOF",
        "description": "nkab paiement",
        "destination": {
          "type": "mobile_money",
          "country": `${Country === ci ? 'CI' :Country === sen ? 'SN' :Country === mali ? 'ML' :Country === togo ? 'TG' :Country === burk ? 'BF' :Country === benin ? 'BJ' :Country === niger ? 'NE' : ""}`,
          "provider": `${NkabProvider}`,
          "msisdn": `${Phone}`
        }
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      fetch("https://api.hub2.io/transfers", requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
  
    }

    function bniClicked(){
        setBankOptionClicked("BNI")
    }
    function biciciClicked(){
        setBankOptionClicked("BICICI")
    }
    function ecobClicked(){
        setBankOptionClicked("ECOBANK")
    }
    function ubaClicked(){
        setBankOptionClicked("UBA")
    }


    //Test de transaction Bank
    function handleTransactionBank(){
  
      var myHeaders = new Headers();

      myHeaders.append("environment",   "live");
      myHeaders.append("merchantId",    "o3XPUfGPrmspx4AQoWLmr");
      myHeaders.append("Content-Type",  "application/json");
      myHeaders.append("ApiKey",        "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");
  
      var raw = JSON.stringify({
        "reference": `${uid(8)}`,
        "amount": Number(Montant),
        "currency": "XOF",
        "description": `Exchange transfert ${BankOptionClicked}`,
        "destination": {
          "type": "bank_transfer",
          "country": `${Country === ci ? 'CI' :Country === sen ? 'SN' :Country === mali ? 'ML' :Country === togo ? 'TG' :Country === burk ? 'BF' :Country === benin ? 'BJ' :Country === niger ? 'NE' : ""}`,
          "bankName": `${BankOptionClicked.toUpperCase()} ${Country.toUpperCase()}`,
          "bankCode": `${BankCode.toUpperCase()}`,
          "accountOwner": `${AccountOwner.toUpperCase()}`,
          "accountNumber": `${AccountNumber.toUpperCase()}`
        }
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      fetch("https://api.hub2.io/transfers", requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
  
    }


    //Test d'intention de paiement
    function handlePayment(){
        
        var myHeaders = new Headers();

        myHeaders.append("merchantId",    "o3XPUfGPrmspx4AQoWLmr");
        myHeaders.append("environment",   "live");
        myHeaders.append("Content-Type",  "application/json");
        myHeaders.append("ApiKey",        "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");
        
        var raw = JSON.stringify({
          "customerReference": `DBK_pay_Test_${NkabProvider}_${SenderCountry === _ci ? 'CI' :SenderCountry === _sen ? 'SN' :SenderCountry === _mali ? 'ML' :SenderCountry === _togo ? 'TG' :SenderCountry === _burk ? 'BF' :SenderCountry === _benin ? 'BJ' :SenderCountry === _niger ? 'NE' : ""}_01`,
          "purchaseReference": `DBK_pay_Test_${NkabProvider}_${SenderCountry === _ci ? 'CI' :SenderCountry === _sen ? 'SN' :SenderCountry === _mali ? 'ML' :SenderCountry === _togo ? 'TG' :SenderCountry === _burk ? 'BF' :SenderCountry === _benin ? 'BJ' :SenderCountry === _niger ? 'NE' : ""}/${new Date()}`,
          "amount": Number(Montant),
          "currency": "XOF"
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        // 1 Créer une intention de paiement
        fetch("https://api.hub2.io/payment-intents", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            //**********************>
            // POST/payment-itents  -
            //                      -
            //                      -
            // retourne paymentId   -
            // <---------------------
            if(result.token){

              // setNextMessageOtp(result.nextAction.message)
              setNotifToken(result.token)
              setNotifId(result.id)
              setNotifOtpActif(true)

              // // Préenregistrement par NKAB EXCHANGE en serveur

              // axios.post(
              //     '/api/v1/payments/create-intents',
              //     {
              //       notifierId,
              //       notifyImageLink,
              //       notifyServicesId,
              //       notifyOperatorAction,
              //       notifySenderName : userName,
              //       notifySenderPhone : operatorUserInfoPhone,
              //       notifySenderEmail : userEmail,
              //       createdAt: new Date(),
              //       updatedAt: new Date(),
              //     },
              //   ).then((res)=>{
              //     if(res){
              //       props.history.push(`/order/${OperationUId}`)
              //     }
              //   })
            }
        })
        .catch(error => console.log('error', error));
  
    }

    function handleCanselPayment(){

    }

    function handleConfirmeOtp(){

      var myHeaders = new Headers();

      myHeaders.append("environment",   "live");
      myHeaders.append("MerchantId",    "o3XPUfGPrmspx4AQoWLmr");
      myHeaders.append("Content-Type",  "application/json");
      myHeaders.append("ApiKey",        "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");

      var raw = JSON.stringify({
      "token": NotifToken,
      "paymentMethod": "mobile_money",
      "country": `${SenderCountry === _ci ? 'CI' :SenderCountry === _sen ? 'SN' :SenderCountry === _mali ? 'ML' :SenderCountry === _togo ? 'TG' :SenderCountry === _burk ? 'BF' :SenderCountry === _benin ? 'BJ' :SenderCountry === _niger ? 'NE' : ""}`,
      "provider": `${NkabProvider}`,
      "mobileMoney": {
          "msisdn": `${Phone}`,
          "otp": `${NkabOtp}`
      }
      });

      console.log(raw)
      console.log(NotifId)

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // 2 Effectuer une tentative de paiement
      fetch(`https://api.hub2.io/payment-intents/${NotifId}/payments`, requestOptions)
      .then(response => response.json())
      .then(result => {
          console.log(result)
          if(result.token){
            setNotifId(result.id)
            setNotifToken(result.token)
            setNotifActif(true)
            // notification de valider le paiement entrant son code secret de confirmation 

            var myHeaders = new Headers();

            myHeaders.append("merchantId",  "o3XPUfGPrmspx4AQoWLmr");
            myHeaders.append("environment", "live");
            myHeaders.append("ApiKey",      "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            if(NotifId !== "" && NotifToken !== ""){

              // 3 (Optional) Côté client, authentifier la tentative de paiement
              fetch(`https://api.hub2.io/payment-intents/${NotifId}?token=${NotifToken}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                  console.log(result)
                  if(result.id && result.token){
                    setNotifId(result.id)
                    setNotifToken(result.token)
                  }
              })
              .catch(error => console.log('error', error));

            }
          }
      })
      .catch(error => console.log('error', error));
    }

    function handleConfirmePayment(){

      if(NotifId !== "" && NotifToken !== ""){

        var myHeaders = new Headers();

        myHeaders.append("Environment",   "live");
        myHeaders.append("MerchantId",    "o3XPUfGPrmspx4AQoWLmr");
        myHeaders.append("Content-Type",  "application/json");
        myHeaders.append("ApiKey",        "sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl");

        var raw = JSON.stringify({
        "token": `${NotifToken}`,
        "confirmationCode": `${Confirmation}`
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        //Test de demande d'autorisation de paiement
        fetch(`https://api.hub2.io/payment-intents/${NotifId}/authentication`, requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

      }
    }

  return (
    <>
    <div className='d-flex justify-content-center mt-5'><h3>Administrateur</h3></div>
    
    <div className='mb-5'>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
          <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}>
            <Send />
          </Avatar>
          <Typography sx={{fontSize: "20px"}}> Transaction Mobile </Typography>
          <Box component="form" onSubmit={handleTransactionMobile} noValidate sx={{ mt: 1 }}>
            <Autocomplete
                id="country"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                />
                {option.label}
                </Box>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Choisir un pays" name="country" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} />
                )}
                onChange={checkCountryHanlder}
            />
            {Country !==""?<div className='d-flex justify-content-center mt-2'><span>Choisir un opérateur</span></div>:null}
            {
            Country === ci ? 
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === sen ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FmOptionClicked}`} onClick={FreeHanlder}><img src="../../images/fm.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === mali ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === togo ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${TmOptionClicked}`} onClick={TMHanlder}><img src="../../images/tm.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === burk ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === benin ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
            </div> :
            Country === niger ?
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
            </div> : null
            }
            <TextField margin="normal" required fullWidth name="phone" label="Numéro bénéficiaire" type="number" onChange={checkPhoneHanlder} autoComplete="current-phone" />
            <TextField margin="normal" required fullWidth id="montant" label="Montant à transférer" name="montant"type="number" onChange={checkMontantHanlder} autoFocus />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }} >
                { !NkabSpinner?"Confirmer":<div className="nkab-spinner"></div> } 
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </div>

    <div className='pt-5 mb-5 pb-5'>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
          <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}>
            <MoneyOutlined />
          </Avatar>
          <Typography sx={{fontSize: "20px"}}> Transaction Bancaire </Typography>
          <Box component="form" onSubmit={handleTransactionBank} noValidate sx={{ mt: 1 }}>
            <Autocomplete
                id="country"
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                />
                {option.label}
                </Box>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Choisir un pays" name="country" inputProps={{ ...params.inputProps, autoComplete: 'new-password', }} />
                )}
                onChange={checkCountryHanlder}
            />
            {Country !==""?<div className='d-flex justify-content-center mt-2'><span>Choisir un banque</span></div>:null}
            <div className='d-flex justify-content-center uppercase mt-2'>
                <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={bniClicked}><img src="../../images/bni.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={biciciClicked}><img src="../../images/bicici.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={ubaClicked}><img src="../../images/uba.png" width="50" alt="nkab drapeau" /></span>
                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={ecobClicked}><img src="../../images/ecob.png" width="50" alt="nkab drapeau" /></span>
            </div>
            <TextField margin="normal" required fullWidth name="bankcode" label="Code bancaire" type="number" id="bankcode" onChange={checkBankCodeHanlder} />
            <TextField margin="normal" required fullWidth name="accountowner" label="Nom & Prénoms du titulaire" type="text" id="accountowner" onChange={checkAccountOwnerHanlder}/>
            <TextField margin="normal" required fullWidth name="accountnumber" label="Numéro de compte" type="text" id="accountNumber" onChange={checkAccountNumberHanlder}/>
            <TextField margin="normal" required fullWidth id="montant" label="Montant à transférer" name="montant"type="number" onChange={checkMontantHanlder} />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }} >
                { !NkabSpinner?"Confirmer":<div className="nkab-spinner"></div> } 
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </div>


    <div className='d-flex justify-content-center mt-5'><h3>Client</h3></div>
    {
      !NotifActif && !NotifOtpActif?
      <div className='mb-5'>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}>
              <Send />
            </Avatar>
            <Typography sx={{fontSize: "20px"}}> Paiement/Rechargement </Typography>
            <div className='row mt-4'>
              <div className='d-flex justify-content-between'>
                <span>Montant</span>
                <span><strong>{Montant}</strong> XOF</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>frais opérateur</span>
                <span><strong>{Frais}</strong> XOF</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>Total à payer</span>
                <span><strong>{Number(Montant)>1?Number(Montant)+Frais:0}</strong> XOF</span>
              </div>
            </div>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField margin="normal" required fullWidth name="phone" label="Numéro de téléphone" type="number" onChange={checkPhoneHanlder} autoFocus />
              <TextField margin="normal" required fullWidth id="montant" label="Entrez le montant" name="montant"type="number" onChange={checkMontantHanlder} />
              <div className='d-flex justify-content-center mt-2'><span>Choisir l'opérateur</span></div>

              {
                SenderCountry === _ci ? 
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _sen ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FmOptionClicked}`} onClick={FreeHanlder}><img src="../../images/fm.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _mali ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _togo ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${TmOptionClicked}`} onClick={TMHanlder}><img src="../../images/tm.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _burk ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _benin ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                </div> :
                SenderCountry === _niger ?
                <div className='d-flex justify-content-center uppercase mt-2'>
                    <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                    <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                </div> : null
              }

              <Grid container>
                <Grid item xs sx={{ mr: 1}}>
                  <Button fullWidth onClick={handleCanselPayment} variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#696969' }} > Annuler </Button>
                </Grid>
                <Grid item xs sx={{ ml: 1}}>
                  <Button fullWidth onClick={handlePayment} variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301'}} >
                      { !NkabSpinner?"Payer":<div className="nkab-spinner"></div> } 
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      </div>:null
    }
    
    {
      !NotifActif && NotifOtpActif?
      <div className='mb-5'>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}>
              <Send />
            </Avatar>
            <Typography sx={{fontSize: "20px"}}> Paiement/Rechargement </Typography>
            <div className='row mt-4'>
              <div className='d-flex justify-content-between'>
                <span>Montant</span>
                <span><strong>{Montant}</strong> XOF</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>frais opérateur</span>
                <span><strong>{Frais}</strong> XOF</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>Total à payer</span>
                <span><strong>{Number(Montant)+Frais}</strong> XOF</span>
              </div>
            </div>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField margin="normal" fullWidth disabled name="phone" label={`+225 ${Phone}`} type="number" onChange={checkPhoneHanlder} autoFocus />
              <TextField margin="normal" fullWidth disabled id="montant" label={`${Number(Montant)+Frais} XOF`} name="montant"type="number" onChange={checkMontantHanlder} />
              <div className='d-flex justify-content-center mt-2'><span>Choisir l'opérateur</span></div>
              {
              SenderCountry === _ci ? 
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _sen ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FmOptionClicked}`} onClick={FreeHanlder}><img src="../../images/fm.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _mali ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _togo ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${TmOptionClicked}`} onClick={TMHanlder}><img src="../../images/tm.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _burk ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _benin ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
              </div> :
              SenderCountry === _niger ?
              <div className='d-flex justify-content-center uppercase mt-2'>
                  <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                  <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
              </div> : null
              }
              {
                operatorNames === "Mobile Money" || operatorNames === "Moov Money" ?
                <div className='mt-3'><Alert severity="info">Vous allez bientôt recevoir un message USSD sur votre téléphone pour valider la transaction.</Alert></div>
                :<>
                  <div className='mt-4'><span>Composer le <strong>#144*82#</strong>. Ensuite entrer le code OTP.</span></div>
                  <TextField fullWidth margin="normal" required id="otpcode" label="Entrez le code OTP" name="otpcode"type="number" onChange={checkNkabOtpHanlder} />

                  <Grid container>
                      <Button fullWidth onClick={handleConfirmeOtp} variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }} >
                          { !NkabSpinner?"Confirmer":<div className="nkab-spinner"></div> } 
                      </Button>
                  </Grid>
                </>
              }
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      </div>:null
    }
    
    {
      NotifActif && NotifOtpActif?
      <div className='mb-5'>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
            <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}>
              <Send />
            </Avatar>
            <Typography sx={{fontSize: "20px"}}> Saisir votre code secret </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Grid container>
                <Grid item xs>
                  <TextField fullWidth margin="normal" required id="secretcode" label="Entrez le code secret" name="secretcode"type="number" onChange={checkConfirmationHanlder} />
                </Grid>
                {/* <Grid item>
                  <Button type="submit" variant="contained" sx={{ ml: 3, mt: 3, mb: 2, bgcolor: '#035718' }} > Obtenir </Button>
                </Grid> */}
              </Grid>
              <Button fullWidth onClick={handleConfirmePayment} variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }} >
                  { !NkabSpinner?"Confirmez le maintenant":<div className="nkab-spinner"></div> } 
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      </div>:null
    }
    </>
  )
}

export default ForAllTest