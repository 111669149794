import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DomLoading from '../components/loadings/DomLoading/DomLoading'
import { uid } from 'uid'
import { Button, Grid } from '@mui/material'
import BottomNavBar from '../components/BottomNavBar'

export default function SendAfterSuccessByAdmin(props) {
  
  var _ProWallet = localStorage.getItem('_ProWallet')
  const Logged = localStorage.getItem('userStatus')
  const role = localStorage.getItem('role')
  const userID = localStorage.getItem('userID')

  const { id }                                                = useParams()
  const [montantTotal,              setMontantTotal         ] = useState()
  const [cinetPayApi,               setCinetPayApi          ] = useState([])
  const [passwordApi,               setPasswordApi          ] = useState([])
  const [loading,                   setLoading              ] = useState(false)
  const [editMoney,                 setEditMoney            ] = useState(false)
  const [isCopied,                  setIsCopied             ] = useState(false);
  const [copyText,                  setCopyText             ] = useState(false);
  const [Operation,                 setOperation            ] = useState([])
  const [GetUser,                   setGetUser              ] = useState([])
  const [User,                      setUser                 ] = useState([])
  const [OperationStopReq,          setOperationStopReq     ] = useState(false)
  const [finalPrice3,               setFinalPrice3          ] = useState()
  const [loadingWave,               setLoadingWave          ] = useState(false)
  const [loadingHub2,               setLoadingHub2          ] = useState(false)
  const [LoadingCancel,             setLoadingCancel        ] = useState(false)
  const [PayOutRate, setPayOutRate] = useState([])

  const apikey                                                = cinetPayApi.cinetPayAPI
  const password                                              = passwordApi.aPIPassword

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  useEffect(()=>{
    if(Logged === 'Logout'){
      window.location.replace("https://nkab-exchange.net/login");
    }

    if (Number(role) !== 1) {
      props.history.push('/')
    }
  },[Logged, props.history, role])



  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get('/api/v1/get-users')
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])

  useEffect(()=>{

    if(GetUser && userID){
      const userGetted = GetUser.filter((obj) => {
        return obj.ID === Number(userID)
      })
      setUser(userGetted[0])
    }

  },[GetUser, userID, User])

  useEffect(() => {

    const fetchDada = async () => {
      try {
        if(!OperationStopReq){
          const { data } = await axios.get('/api/v1/operations')

          if(data && id){
            const dataGetted = data.filter((obj) => {
              return obj.ID === Number(id)
            })
  
            setOperation(dataGetted[0])
          }
          if(data.length > 0){
            setOperationStopReq(true)
          }
        }

      } catch (error) {}
    }

    fetchDada()
  }, [id, Operation, OperationStopReq])

  const [MerchantId, setMerchantId] = useState("")
  const [MerchantIdKey, setMerchantIdKey] = useState("")

  const ci = "Côte d'Ivoire"
  const sen = "Sénégal"
  const burk = "Burkina Fasso"
  const benin = "Bénin"
  const togo = "Togo"
  const mali = "Mali"
  const niger = "Niger"
  const cam = "Cameroun"
  const guinee = "Guinée"

  useEffect(()=>{
      if(Operation.ID > 0){
          if(Operation.SenderCountry === ci){

              setMerchantId("o3XPUfGPrmspx4AQoWLmr")
              setMerchantIdKey("sk_AOoWaEk0oXP1ldYEy5qdB5UnCVPu0lrl")
          }
          if(Operation.SenderCountry === togo){

              setMerchantId("Ee9HM0SbkkPpmvpY8iJXa")
              setMerchantIdKey("sk_HSWq5h91I0E7sVSxXOACPBbMILX6Qq61")
          }
          if(Operation.SenderCountry === burk){
              setMerchantId("4eEdWoS5T2UiPkEdsO7Zt")
              setMerchantIdKey("sk_yEvF54xNdflGibQpzVMLfAl7Iu6twdfO")
          }
          if(Operation.SenderCountry === mali){
              setMerchantId("dNoKavNhtMQaNZTSCJa4s")
              setMerchantIdKey("sk_VMctIMcIRcAfPuzZxoc4r9TbStp7rNnG")
          }
          if(Operation.SenderCountry === sen){
              setMerchantId("EF0esPiIBKxkdZcSwERyw")
              setMerchantIdKey("sk_7UiOwbEogK6QEv0Blm7T3XXQv2gzOq8M")
          }
          if(Operation.SenderCountry === cam){
              setMerchantId("BguxmE4b9kYYCn86IUCPZ")
              setMerchantIdKey("sk_TUuDIbxLurPIKD2yPpvuMfocp2h8bTEy")
          }
          if(Operation.SenderCountry === benin){
              setMerchantId("Bbqyg6WMe4MZALrTbVsKc")
              setMerchantIdKey("sk_VkKDUSWRpN7rLvQgcGvr6Rq4LHqvudth")
          }
          if(Operation.SenderCountry === guinee){
              setMerchantId("sYSJyhGpeOXb95iO7WOlh")
              setMerchantIdKey("sk_JIO1fgAmaVb9i7wGUrYA9tQDDjYVoiiU")
          }
      }
  },[ ])

  useEffect(() => {
    const fetchIDs = async () => {
      try {
        const { data } = await axios.get('/api/v1/get-cinetpay-api')
        setCinetPayApi(data[0])
      } catch (error) {}
    }
    fetchIDs()
  }, [])

  useEffect(() => {
    const fetchIDs = async () => {
      try {
        const { data } = await axios.get('/api/v1/get-api-pwd')
        setPasswordApi(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    fetchIDs()
  }, [])



  useEffect(() => {

    if(Operation){
      if(!editMoney){
        const montantTotal3 = Number( (Operation.SenderChange * 1).toFixed(0), )
        const cfa_nbr3 = montantTotal3
        const cfa_nbr_to_text3 = cfa_nbr3.toString()
        const cfa_nbr_last_char3 = cfa_nbr_to_text3.slice(-1)
        const last_cfa_char_nbr3 = Number(cfa_nbr_last_char3)
  
  
          if (last_cfa_char_nbr3 === 0) {
            const FinalCount3 = montantTotal3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 1) {
            const FinalCount3 = montantTotal3 - 1
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 2) {
            const FinalCount3 = montantTotal3 - 2
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 3) {
            const FinalCount3 = montantTotal3 - 3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 4) {
            const FinalCount3 = montantTotal3 - 4
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 5) {
            const FinalCount3 = montantTotal3 + 0
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 6) {
            const FinalCount3 = montantTotal3 - 1
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 7) {
            const FinalCount3 = montantTotal3 - 2
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 8) {
            const FinalCount3 = montantTotal3 - 3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 9) {
            const FinalCount3 = montantTotal3 - 4
            setFinalPrice3(FinalCount3)
          }
      } else {
        const __montant = Number(montantTotal)
        const montantTotal3 = Number( (__montant * 1).toFixed(0), )
        const cfa_nbr3 = montantTotal3
        const cfa_nbr_to_text3 = cfa_nbr3.toString()
        const cfa_nbr_last_char3 = cfa_nbr_to_text3.slice(-1)
        const last_cfa_char_nbr3 = Number(cfa_nbr_last_char3)
  
  
          if (last_cfa_char_nbr3 === 0) {
            const FinalCount3 = montantTotal3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 1) {
            const FinalCount3 = montantTotal3 - 1
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 2) {
            const FinalCount3 = montantTotal3 - 2
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 3) {
            const FinalCount3 = montantTotal3 - 3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 4) {
            const FinalCount3 = montantTotal3 - 4
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 5) {
            const FinalCount3 = montantTotal3 + 0
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 6) {
            const FinalCount3 = montantTotal3 - 1
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 7) {
            const FinalCount3 = montantTotal3 - 2
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 8) {
            const FinalCount3 = montantTotal3 - 3
            setFinalPrice3(FinalCount3)
          }
          if (last_cfa_char_nbr3 === 9) {
            const FinalCount3 = montantTotal3 - 4
            setFinalPrice3(FinalCount3)
          }
      }
    }
    
  }, [finalPrice3, Operation, editMoney, montantTotal])

  

  useEffect(()=>{
    if(Operation){
      setCopyText(Operation.adr_Coin)
    }
  },[Operation])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const wavesuccessbyadminhandler = async () => {
    setLoadingWave(true)
    const validation = 'validé'
    if(Operation.ID){
      axios.post(`/api/v1/operation/validation`,
          {
            ID: Number(id),
            isAdminCompleted: validation
          },
        )
        .then(
          (response) => {
            if (response) {
  
              const email = Operation.SenderEmail
              const op = Operation.operatorAction
              const opdate = Operation.updatedAt
              const qt = (Number(Operation.converted)*1).toFixed(5)
              const opname = Operation.serviceSymbol
              const opaddress = Operation.adr_Coin
              const opid = Operation.OrderId

              if(email && op && opdate && qt && opname && opaddress && opid){
                axios.post(
                    `/api/service-success`,
                    {
                      email,
                      op,
                      opdate,
                      qt,
                      opname,
                      opaddress,
                      opid,
                    },
                  )
                  .then( (response) => { 
                    if(response.data.message === "Message envoyé avec succès! "){
                      window.location.replace('https://nkab-exchange.net/operation')
                    } 
                  } )
              }
            }
          },
          (error) => {},
        )
    }
  }

  // PAY-OUT

  const ciRateOUT = [{

      orange: 0.01, //"1%",
      moov: 0.01, //"1%",
      mtn: 0.01, //"1%",
      wave: 0.04, //"4%"
  }]

  const senRateOUT = [{

    orange: 0.01, //"1%",
    freeMoney: 0.03, //"3.00%",
    wave: 0.045, //"4.50%"
  }]

  const maliRateOUT = [{

    orange: 0.04, //"4%",
    mobyCash: 0.04, //"4%",
  }]

  const bfRateOUT = [{
    orange: 0.04, //"4%",
    mobyCash: 0.04, //"4%",
  }]

  const camRateOUT = [{
    orange: 0.04, //"4%",
    mtn: 0.04, //"4%",
  }]

  const guineeRateOUT = [{
    orange: 0.04, //"4%",
    mtn: 0.04, //"4%",
  }]

  const beninRateOUT = [{
    moov: 0.04, //"4%",
    mtn: 0.04, //"4%",
  }]

  const togoRateOUT = [{
    moov: 0.04, //"4%",
    tmoney: 0.04, //"4%",
  }]

  const changes = Operation.SenderChange

  const my_provider = Operation.PaymentMethod === "Orange Money" ? "Orange" : Operation.PaymentMethod === "Mobile Money" ? "mtn" : Operation.PaymentMethod === "Moov Money" ? "Moov" : ""

  const my_country = Operation.SenderCountry === ci ? 'CI' : Operation.SenderCountry === sen ? 'SN' : Operation.SenderCountry === mali ? 'ML' : Operation.SenderCountry === togo ? 'TG' : Operation.SenderCountry === burk ? 'BF' : Operation.SenderCountry === benin ? 'BJ' : Operation.SenderCountry === cam ? 'CM' : ""


  useEffect(()=>{
          if(Operation.SenderCountry === ci){

              if(my_provider === "Orange"){
                  setPayOutRate(ciRateOUT[0].orange)
              }

              if(my_provider === "mtn"){
                  setPayOutRate(ciRateOUT[0].mtn)
              }

              if(my_provider === "Moov"){
                  setPayOutRate(ciRateOUT[0].moov)
              }
          }
          if(Operation.SenderCountry === togo){

              if(my_provider === "Moov"){
                  setPayOutRate(togoRateOUT[0].moov)
              }
          }
          if(Operation.SenderCountry === burk){

              if(my_provider === "Orange"){
                  setPayOutRate(bfRateOUT[0].orange)
              }
          }
          if(Operation.SenderCountry === mali){

              if(my_provider === "Orange"){
                  setPayOutRate(maliRateOUT[0].orange)
              }
          }
          if(Operation.SenderCountry === sen){

              if(my_provider === "Orange"){
                  setPayOutRate(senRateOUT[0].orange)
              }
          }
          if(Operation.SenderCountry === cam){

              if(my_provider === "Orange"){
                  setPayOutRate(camRateOUT[0].orange)
              }

              if(my_provider === "mtn"){
                  setPayOutRate(camRateOUT[0].mtn)
              }
          }
          if(Operation.SenderCountry === benin){

              if(my_provider === "mtn"){
                  setPayOutRate(beninRateOUT[0].mtn)
              }

              if(my_provider === "Moov"){
                  setPayOutRate(beninRateOUT[0].moov)
              }
          }
          if(Operation.SenderCountry === guinee){

              if(my_provider === "Orange"){
                  setPayOutRate(guineeRateOUT[0].orange)
              }

              if(my_provider === "mtn"){
                  setPayOutRate(guineeRateOUT[0].mtn)
              }
          }
  },[
    Operation.SenderCountry,
    my_provider,
      beninRateOUT,
      bfRateOUT,
      camRateOUT,
      ciRateOUT,
      guineeRateOUT,
      maliRateOUT,
      senRateOUT,
      togoRateOUT,

  ])

  const wavesuccessbyadminhandlerHub2 = async () => {
        
    const OperationUId = uid(8)
    setLoadingHub2(true)
    const validation = 'validé'

    if(Operation.ID){
      axios.post(`/api/v1/operation/validation`,
          {
            ID: Number(id),
            isAdminCompleted: validation
          },
        )
        .then(
          (response) => {
            if (response) {
  
              const email = Operation.SenderEmail
              const op = Operation.operatorAction
              const opdate = Operation.updatedAt
              const qt = (Number(Operation.converted)*1).toFixed(5)
              const opname = Operation.serviceSymbol
              const opaddress = Operation.adr_Coin
              const opid = Operation.OrderId

              if(email && op && opdate && qt && opname && opaddress && opid){
                axios.post(
                    `/api/service-success`,
                    {
                      email,
                      op,
                      opdate,
                      qt,
                      opname,
                      opaddress,
                      opid,
                    },
                  )
                  .then( (response) => { 
                    if(response.data.message === "Message envoyé avec succès! "){
      
                      try {
                        var myHeaders = new Headers();
                    
                                myHeaders.append("merchantId",    `${MerchantId}`);
                                myHeaders.append("environment",   "live");
                                myHeaders.append("Content-Type",  "application/json");
                                myHeaders.append("ApiKey",        `${MerchantIdKey}`);
                            
                                var raw = JSON.stringify({
                                    "reference": `ref_${OperationUId}`,
                                    "amount": changes,
                                    "currency": "XOF",
                                    "description": "nkab paiement",
                                    "destination": {
                                    "type": "mobile_money",
                                    "country": `${my_country}`,
                                    "provider": `${my_provider}`,
                                    "msisdn": `${Operation.SenderPhone}`
                                    }
                                });
            
                                var requestOptions = {
                                    method: 'POST',
                                    body: raw,
                                    redirect: 'follow'
                                };

                                axios.post(`/api/transfers`, {requestOptions, MerchantId, MerchantIdKey})
                                .then(result => {
                                    if(result.data.reference){
                    
                                        localStorage.setItem("nkab_trans_id", result.data.id)
                                        localStorage.setItem("nkab_trans_reference", result.data.reference)
                                    }
                                })
                                
                      } catch (error) {}
                    } 
                  } )
              }
            }
          },
          (error) => {},
        )
    }
  }
  const nkab_trans_reference   = localStorage.getItem("nkab_trans_reference")
  const nkab_trans_id          = localStorage.getItem("nkab_trans_id")


  useEffect(()=>{

      const fetchDada12 = async () => {

              try {
  
                  if(nkab_trans_reference !== ""){
  
                      var requestOptions = {
                          method: 'GET',
                          redirect: 'follow'
                      };
          
                      axios.post(`/api/transfers-step2`, {nkab_trans_id, requestOptions, MerchantId, MerchantIdKey})
                      .then(result => {
  
                          const MyStatus = result.data.status
  
                          if(MyStatus === "successful"){

                            localStorage.removeItem("nkab_trans_reference")
                            localStorage.removeItem("nkab_trans_id")

                            window.location.replace("https://nkab-exchange.net/operation");

                          }else if(MyStatus === "failed"){

                            localStorage.removeItem("nkab_trans_reference")
                            localStorage.removeItem("nkab_trans_id")

                            window.location.replace("https://nkab-exchange.net/operation");

                          }
                          })
                  }
              } catch (error) {}
      }
      fetchDada12()

  },[nkab_trans_id, nkab_trans_reference, MerchantId, MerchantIdKey])


  const successbyadminhandler = async () => {

    setLoading(true)

    if(Operation.ID){
      
      try {
        //Connexion à l'api de transfert d'argent
        var formdata = new FormData()
        formdata.append('apikey', apikey)
        formdata.append('password', password)

        var postOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        }

        fetch(
          'https://client.cinetpay.com/v1/auth/login',
          postOptions,
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.data.token) {

              const _user_phone = Operation.SenderPhone
              const user_lastname = User.lastName
              const user_name = User.name
              const user_email = Operation.SenderEmail

              const _phone = _user_phone.slice(4,20)
              const _prefix = _user_phone.slice(1,4)

              if ( result.data.token ) {
                //Ajout de contact pour la transaction

                var myHeaders = new Headers()
                myHeaders.append(
                  'Content-Type',
                  'application/x-www-form-urlencoded',
                )

                var urlencoded = new URLSearchParams()

                urlencoded.append(
                  'data',
                  `{"phone":"${_phone}","prefix":"${_prefix}","surname":"${user_lastname}","name":"${user_name}","email":"${user_email}"}`,
                )

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow',
                }

                fetch(
                  `https://client.cinetpay.com/v1/transfer/contact?lang=fr&token=${result.data.token}`,
                  requestOptions,
                )
                  .then((response) => {
                    response.text()
                    if(response){
                      fetch(
                        `https://client.cinetpay.com/v1/transfer/contact?lang=fr&token=${result.data.token}`,
                        requestOptions,
                      )
                        .then((response) => {
                          response.text()
                          if(response){
                            if ( _phone && Operation.SenderChange && Operation.OrderId ) {

                              urlencoded.append(
                                'data',
                                `{"phone":"${_phone}","prefix":"${_prefix}","amount":"${finalPrice3}","notify_url":"/api/v1/sendmoneycpstatus/newsendmoneycpstatus","client_transaction_id":"${Operation.OrderId}"}`,
                              )

                              fetch(
                                `https://client.cinetpay.com/v1/transfer/money/send/contact?token=${result.data.token}&lang=fr`,
                                requestOptions,
                              )
                                .then((response) => {
                                  response.json()
                                  if(response){
                                    const validation = 'validé'
                                    axios.post(
                                        `/api/v1/operation/validation`,
                                        {
                                          ID: Number(id),
                                          isAdminCompleted: validation
                                        },
                                      )
                                      .then(
                                        (response) => {
                                          if (response) {
                                            window.location.replace('https://nkab-exchange.net/operation')
                                          }
                                        },
                                        (error) => {
                                          console.log(error)
                                        },
                                      )

                                  }
                                }).catch((error) =>
                                  console.log('error', error),
                                )
                            }
                          }
                        })
                        .catch((error) =>
                          console.log('error', error),
                        )
                    }
                  })
                  .catch((error) =>
                    console.log('error', error),
                  )
              }
            }
            return result.data
          })
          .catch((error) => console.log('error', error))
      } catch (error) {}
    }
  }

  function canselbyadminhandler(){

    setLoadingCancel(true)
    const validation = 'annulé'

    if(validation){
      axios.post(
          `/api/v1/operation/validation`,
          {
            ID: Number(id),
            isAdminCompleted: validation
          },
        )
        .then(
          (response) => {
            if (response.data.msg === 'success') {
              window.location.replace('https://nkab-exchange.net/operation')
            }
          },
          (error) => {
            console.log(error)
          },
        )
    }
  }

  function editedbyadminhandler(){
    setEditMoney(true)
  }

  const loadMsg = "Veuillez patienter un moment..."

  return (
    <div className="container" style={{backgroundColor:"#90d9ff"}}>
      <div className="py-5 text-center">
        <h2>NKAB CREDIT</h2>
        { !Operation ? <DomLoading />:
          <div className='withdrav-content'>
            <div className="card text-center">
              <div className='alert alert-danger' role="alert">
                Vous êtes sur le point d'envoyer{' '}
                <strong className="money-font"> {Operation.SenderChange} XOF </strong>{' '}
                au numéro{' '}
                <strong className="money-font"> {Operation.SenderPhone} </strong>{' '}
                portant le nom de{' '}
                <strong className="money-font"> {Operation.SenderName} </strong>{' '}
                après qu'il ait effectué une opération de vente de{' '}
                <strong className="money-font"> {Operation.serviceName} </strong>{' '}
                au taux de{' '}
                <strong className="money-font"> {Operation.rate} XOF{' '} </strong>
                ayant pour quantité{' '}
                <strong className="money-font">
                  {!Operation ? <DomLoading /> : 
                    <>
                    {
                      Operation.serviceSymbol === "USDT" ? 
                      <>{(Operation.dollar * 1).toFixed(2)}$ {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PAYEER" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PM" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "trx" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      <>{(Operation.converted * 1).toFixed(5)} {Operation.serviceSymbol}</>
                    }
                    </>
                  }
                </strong>
                .
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  {Operation.operatorAction === 'sel' ? (
                    <>{'Vente de '}</>
                  ) :Operation.operatorAction === 'buy' ? (
                    <>{'Achat de '}</>
                  ) : <DomLoading />}
                  <span className='danger bolder uppercase'>{Operation.serviceName}</span> par l'adresse : <br/><br/>
                  <div>
                      <span className='refInput'>{copyText} </span>
                      <span className='button-isCopied p-2 mt-2' onClick={handleCopyClick}>
                        <span>{isCopied ? 'Adresse copiée!' : 'Copier'}</span>
                      </span>
                    </div>
                </h5><br/>
                <h5 className='success bolder'>
                  Quantité : 
                  {!Operation ? <DomLoading /> : 
                    <>
                    {
                      Operation.serviceSymbol === "USDT" ? 
                      <>{(Operation.dollar * 1).toFixed(2)}$ {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PAYEER" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "PM" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      Operation.serviceSymbol === "trx" ? 
                      <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                      <>{(Operation.converted * 1).toFixed(5)} {Operation.serviceSymbol}</>
                    }
                    </>
                  }
                </h5>
              </div>
              <div className="card-footer text-muted">
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(0,10)}{' '}à{' '}
                {!Operation.updatedAt?<DomLoading />:Number(Operation.updatedAt.slice(11,13))}
                {!Operation.updatedAt?<DomLoading />:Operation.updatedAt.slice(11,19)}
              </div>
            </div>
          </div>
        } 
        <br />
        <div className="withdrav-content">
          <h4 className="text-center mb-3">
            <span className="text-primary"> {Operation.SenderChange} XOF </span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Produit</h6>
              </div>
              <span className="text-muted">
                {!Operation ? <DomLoading /> : Operation.serviceName}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Quantité</h6>
              </div>
              <span className="text-muted">
                {!Operation ? <DomLoading /> : 
                  <>
                  {
                    Operation.serviceSymbol === "USDT" ? 
                    <>{(Operation.dollar * 1).toFixed(2)}$ {Operation.serviceSymbol}</>:
                    Operation.serviceSymbol === "PAYEER" ? 
                    <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                    Operation.serviceSymbol === "PM" ? 
                    <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                    Operation.serviceSymbol === "trx" ? 
                    <>{(Operation.converted * 1).toFixed(2)} {Operation.serviceSymbol}</>:
                    <>{(Operation.converted * 1).toFixed(5)} {Operation.serviceSymbol}</>
                  }
                  </>
                }
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-sm">
              <div>
                <h6 className="my-0">Taux</h6>
              </div>
              <span className="text-muted">
                {!Operation ? <DomLoading /> : Operation.rate} XOF
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (XOF)</span>
              <strong>
                { editMoney? 
                <input className='bolder' type='number' placeholder='Entrez un nouveau montant' onChange={(e)=>setMontantTotal(e.target.value)} />:
                <>{Operation.SenderChange} XOF</> } 
              </strong>
            </li>
          </ul>

          {/* <span className="card p-2" >
            {
              loading === false ? 
              <div className="input-group">
                {
                  Operation.PaymentMethod === "Wave" ?
                  <span className="w-100 btn btn-primary btn-lg" onClick={wavesuccessbyadminhandler}>
                    Créditer maintenant
                  </span> : 
                  <span className="w-100 btn btn-primary btn-lg" onClick={successbyadminhandler}>
                    Créditer maintenant
                  </span>
                }
              </div> :
              loading === true ? 
              <div className="input-group">
                <span className="w-100 secondary-span2 btn-lg">
                  <LoadingBox loadMsg={loadMsg} />
                </span>
              </div> : <DomLoading />
            }
          </span>

            <span className="p-2 d-flex justify-content-between">
                <div className="input-group">
                    <button className="w-100 btn btn-danger btn-lg" type="submit" onClick={canselbyadminhandler}>
                        Annuler
                    </button>
                </div>
                <div className="input-group">
                    <button className="w-100 btn bg-or btn-lg" type="submit" onClick={editedbyadminhandler}>
                        Editer
                    </button>
                </div>
            </span> */}



            <Grid container>
              <Grid item xs sx={{ mr: 1}}>
                {
                  LoadingCancel === false ? 
                  <Button type="submit" onClick={canselbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} >Annuler </Button>:
                  LoadingCancel === true ?
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#d84949' }} ><div className="nkab-spinner "></div> </Button>:null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loading === false ? 
                  <Button type="submit" onClick={successbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >CinetPay </Button> :
                  loading === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loadingWave === false ? 
                  <Button type="submit" onClick={wavesuccessbyadminhandler} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Wave </Button> :
                  loadingWave === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1, mr: 1}}>
                {
                  loadingHub2 === false ? 
                  <Button type="submit" onClick={wavesuccessbyadminhandlerHub2} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} >Hub2 </Button> :
                  loadingHub2 === true ? 
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558' }} ><div className="nkab-spinner "></div> </Button> : null
                }
              </Grid>
              <Grid item xs sx={{ ml: 1}}>
                <Button
                  type="submit"
                  onClick={editedbyadminhandler}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                >Editer </Button>
              </Grid>
            </Grid>
        </div>
      </div>
      <BottomNavBar />
    </div>
  )
}
