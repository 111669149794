import React from 'react'
import { Link } from 'react-router-dom'
import ChatCustomer from '../components/ChatCustomer'
import BottomNavBar from '../components/BottomNavBar'

export default function OurPolitical() {

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    window.location.replace("https://nkab-exchange.net/nkab-pro");
  }

  return (
    <div className="container">
      <div className="main-session">
        <div className="buy-item-section-details justify-content-center">
          <div className="d-flex justify-content-center">
            <h1 className="detail-title">Politique de confidentialité</h1>
          </div>
          <div className="d-flex justify-content-center">
            <button className="buy-button-head" style={{borderRadius:'50px'}}>
              <Link
                to="/#oparation=buy"
                style={{
                  color: `${'#0e2a47'}`,
                  textTransform: `${'uppercase'}`,
                }}
              >
                Je veux acheter des crypto-actifs
              </Link>
            </button>
            <button className="sell-button-head" style={{borderRadius:'50px'}}>
              <Link
                to="/#oparation=sel"
                style={{
                  color: `${'#0e2a47'}`,
                  textTransform: `${'uppercase'}`,
                }}
              >
                Je veux vendre mes crypto-actifs
              </Link>
            </button>
          </div>
        </div>
        {/* <div className="alert-head-button-session">
          <div className="alert-head-button">
            Possibilité d'effectuer du transfert d'argent !
          </div>
          <button disabled={true} className="transaction-button-head blink">
            TRANSFERT D'ARGENT
          </button>
        </div> */}
      </div>
      <section>
        <div className="container">
          <div className="w3-container">
            <div className="justify-content-center">
              <div className="up-elment">
                <h1 className="middle-big-text">
                  Notre politique de confidentialité
                </h1>
                <p className="small-text"></p>
              </div>
              <div className="details-section">
                <div className="waring-text">
                  <span>
                    <strong>Politique de confidentialité</strong>
                    <p>Mise vigueur le 28/03/2022</p>
                    <p>
                      <strong>Introduction</strong>
                    </p>
                    <p>
                      La confidentialité des visiteurs de notre site web{' '}
                      <strong>www.nkab-exchange.net </strong>
                      est très importante à nos yeux, et nous nous engageons à
                      la protéger. Cette politique détaille ce que nous faisons
                      de vos informations personnelles.
                    </p>
                    <br />
                    <p>
                      Consentir à notre utilisation de cookies en accord avec
                      cette politique lors de votre première visite de notre
                      site web nous permet d’utiliser des cookies à chaque fois
                      que vous consultez notre site.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 1 : Collecte d’informations personnelles
                      </strong>
                    </p>
                    <p>
                      Les types d’informations personnelles suivants peuvent
                      etre collectés, stockés et utilisés :
                      <br />* Des informations à propos de votre ordinateur, y
                      compris votre adresse IP, votre localisation géographique,
                      le type et la version de votre navigateur, et votre
                      système d’exploitation ;
                    </p>
                    <p>
                      * Des informations sur vos visites et votre utilisation de
                      ce site web y compris la source référente, la durée de la
                      visite, les pages vues, et les chemins de navigation de
                      sites web ;
                    </p>
                    <p>
                      * Des informations comme votre adresse e-mail, que vous
                      nous fournissez lors de votre inscription au site ;
                    </p>
                    <p>
                      Des informations que vous saisissez quand vous créez un
                      profil sur notre site web comme votre nom, votre photo de
                      profil, votre genre, votre date de naissance ;
                    </p>
                    <p>
                      Des informations relatives aux achats et ventes que vous
                      faites, aux services que vous utilisez ou aux transactions
                      que vous effectuez sur notre site, qui incluent votre nom,
                      adresse, numéro de téléphone, adresse e-mail et autres
                      informations;
                    </p>
                    <p>
                      Des informations contenues dans toutes les communications
                      que vous nous envoyez par e-mail ou sur notre site web, y
                      compris leurs contenus ;
                    </p>
                    <p>
                      Toute autre information personnelle que vous nous
                      communiquez.
                    </p>
                    <p>
                      Avant de nous divulguer des informations personnelles
                      concernant une autre personne, vous devez obtenir le
                      consentement de ladite personne en ce qui concerne la
                      divulgation et le traitement de ces informations
                      personnelles selon les termes de cette politique.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 2 : Utilisation de vos informations personnelles
                      </strong>
                    </p>
                    <p>
                      Les informations personnelles qui nous sont fournies par
                      le biais de notre site web seront utilisées dans les
                      objectifs décrits dans cette politique ou dans les pages
                      du site pertinentes. Nous pouvons utiliser vos
                      informations personnelles pour:
                    </p>
                    <p>* Administrer notre site web et notre entreprise ;</p>
                    <p>* Personnaliser notre site web pour vous ;</p>
                    <p>
                      * Permettre votre utilisation des services proposés sur
                      notre site web ;
                    </p>
                    <p>* Vous envoyer les monnaies achetées sur notre site ;</p>
                    <p>
                      Vous envoyer des factures et et collecter vos paiements ;
                    </p>
                    <p>
                      * Vous envoyer des notifications par e-mail que vous avez
                      expressément demandées ;
                    </p>
                    <p>
                      * Vous envoyer notre newsletter par mail, si vous l’avez
                      demandé (vous pouvez nous informer à tout moment de votre
                      volonté de ne plus recevoir notre newsletter) ;
                    </p>
                    <p>
                      * Traiter les demandes et les réclamations relatives à
                      votre site web effectuées par vous ou vous concernant ;
                    </p>
                    <p>
                      * Maintenir la sécurité de notre site web et empêcher la
                      fraude ;
                    </p>
                    <p>
                      * Vérifier le respect des conditions générales qui
                      régissent l’utilisation de notre site web (y compris
                      surveiller les messages privés envoyés par le biais du
                      service de messagerie privé de notre site web) .
                    </p>
                    <p>
                      Si vous soumettez des informations personnelles sur notre
                      site web dans le but de les publier, nous les publierons
                      et pourrons utiliser ces informations conformément aux
                      autorisations que vous nous accordez.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 3 : Divulgation de vos informations personnelles
                      </strong>
                    </p>
                    <p>
                      Nous pouvons divulguer vos informations personnelles à
                      n’importe lequel de nos employés, dirigeants, assureurs,
                      conseillers professionnels, agents, fournisseurs, ou
                      sous-traitants dans la mesure où cela est raisonnablement
                      nécessaire aux fins énoncées dans cette politique.
                    </p>
                    <p>Nous pouvons divulguer vos informations personnelles:</p>
                    <p>
                      * Dans la mesure où nous sommes tenus de le faire par la
                      loi ;
                    </p>
                    <p>
                      * Dans le cadre de toute procédure judiciaire en cours ou
                      à venir ;
                    </p>
                    <p>
                      * Pour établir, exercer ou défendre nos droits légaux (y
                      compris fournir des informations à d’autres à des fins de
                      prévention des fraudes ) ;
                    </p>
                    <p>
                      À toute personne que nous estimons raisonnablement faire
                      partie intégrante d’un tribunal ou autre autorité
                      compétente pour la divulgation de ces informations
                      personnelles si, selon notre opinion, un tel tribunal ou
                      une telle autorité serait susceptible de demander la
                      divulgation de ces informations personnelles.
                    </p>
                    <p>
                      Sauf disposition contraire de la présente politique, nous
                      ne transmettrons pas vos informations personnelles à des
                      tierces parties.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 4 : Transferts internationaux de données
                      </strong>
                    </p>
                    <p>
                      Les informations que nous collectons peuvent être
                      stockées, traitées et transférées dans tous les pays dans
                      lesquels nous opérons, afin de nous permettre d’utiliser
                      les informations en accord avec cette politique.
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 5 : Conservation de vos informations
                        personnelles
                      </strong>
                    </p>
                    <p>
                      Cette Section détaille nos politiques de conservation des
                      données et nos procédures, conçues pour nous aider à nous
                      conformer nos obligations légales concernant la
                      conservation et la suppression d’informations
                      personnelles.
                    </p>
                    <p>
                      Les informations personnelles que nous traitons à quelque
                      fin que ce soit ne sont pas conservées plus longtemps que
                      nécessaire. Nous supprimons systematiquement les données
                      personnelles après une periode de cinq (05) ans.
                    </p>
                    <p>
                      Nonobstant les autres dispositions de cette section, nous
                      conserverons des documents (y compris des documents
                      électroniques) contenant des données personnelles dans la
                      mesure où nous sommes tenus de le faire par la loi ;
                    </p>
                    <br />
                    <p>
                      <strong>
                        Article 6 : Sécurité de vos informations personnelles
                      </strong>
                    </p>
                    <p>
                      Nous prendrons des précautions techniques et
                      organisationnelles raisonnables pour empêcher la perte,
                      l’abus ou l’altération de vos informations personnelles.
                    </p>
                    <p>
                      Nous stockerons toutes les informations personnelles que
                      vous nous fournissez sur des serveurs sécurisés (protégés
                      par mot de passe et pare-feu).
                    </p>
                    <p>
                      Toutes les transactions financières électroniques
                      effectuées par le biais de notre site web seront protégées
                      par des technologies de cryptage.
                    </p>
                    <p>
                      Vous êtes responsable de la confidentialité du mot de
                      passe que vous utilisez pour accéder à notre site web ;
                      nous ne vous demanderons pas votre mot de passe (sauf
                      quand vous vous identifiez sur notre site web).
                    </p>
                    <br />
                    <p>
                      <strong>A. Amendements</strong>
                    </p>
                    <p>
                      Nous pouvons parfois mettre cette politique à jour en
                      publiant une nouvelle version sur notre site web. Vous
                      devez vérifier cette page régulièrement pour vous assurer
                      de prendre connaissance de tout changement effectué à
                      cette politique. Nous pouvons vous informer des
                      changements effectués à cette politique par courrier
                      électronique ou par le biais du service de messagerie
                      privée de notre site web.
                    </p>
                    <br />
                    <p>
                      <strong>B. Vos droits</strong>
                    </p>
                    <p>
                      Vous pouvez nous demander de vous fournir toute
                      information personnelle que nous détenons sur vous ;
                    </p>
                    <br />
                    <p>
                      <strong>C. Mise à jour des informations</strong>
                    </p>
                    <p>
                      Merci de nous faire savoir si les informations
                      personnelles que nous détenons à votre sujet doivent être
                      corrigées ou mises à jour.
                    </p>
                    <p>
                      Pour toute question relative à l’application des présentes
                      CGU, vous pouvez joindre l’éditeur.
                    </p>
                    <br />
                    <p>
                      <strong>Article 7 : Mise à jour des informations</strong>
                    </p>
                    <p>
                      Notre site web utilise des cookies. Un cookie est un
                      fichier contenant un identifiant (une chaîne de lettres et
                      de chiffres) envoyé par un serveur web vers un navigateur
                      web et stocké par le navigateur. L’identifiant est alors
                      renvoyé au serveur à chaque fois que le navigateur demande
                      une page au serveur. Les cookies peuvent être «
                      persistants » ou « de session » : un cookie persistant est
                      stocké par le navigateur et reste valide jusqu’à sa date
                      d’expiration, à moins d’être supprimé par l’utilisateur
                      avant cette date d’expiration ; quant à un cookie de
                      session, il expire à la fin de la session utilisateur,
                      lors de la fermeture du navigateur. Les cookies ne
                      contiennent en général aucune information permettant
                      d’identifier personnellement un utilisateur, mais les
                      informations personnelles que nous stockons à votre sujet
                      peuvent être liées aux informations stockées dans les
                      cookies et obtenues par les cookies. Nous n’utilisons des
                      cookies de session et des cookies persistants sur notre
                      site web.
                    </p>
                    <p>
                      Nous utilisons Google Analytics et Adwords sur notre site
                      web pour : reconnaître un ordinateur lorsqu’un utilisateur
                      consulte le site web, suivre les utilisateurs lors de leur
                      navigation sur le site web, améliorer l’utilisation d’un
                      site web, analyser l’utilisation du site web, administrer
                      le site web, empêcher la fraude et améliorer la sécurité
                      du site web, envoyer des publicités ciblées pouvant
                      intéresser certains utilisateurs.
                    </p>
                    <p>
                      La plupart des navigateurs vous permettent de refuser ou
                      d’accepter les cookies sauf que bloquer tous les cookies
                      aura un impact négatif sur l’utilisation de nombreux sites
                      web. Si vous bloquez les cookies, vous ne pourrez pas
                      utiliser toutes les fonctionnalités de notre site web.
                    </p>
                    <p>
                      Vous pouvez supprimer les cookies déjà stockés sur votre
                      ordinateur.
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ChatCustomer />
      <BottomNavBar />

    </div>
  )
}
